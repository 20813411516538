import {Injectable} from '@angular/core';
import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector
} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {Project} from '../+project/project.model';
import {
  firestoreInvestigationCollection,
  firestoreUserInvestigationCollection
} from '../firebase/database-paths';
import {aggregateUpdates} from '../fns/aggregate-updates';
import {
  deleteInvestigations,
  updateInvestigations,
  upsertInvestigations
} from './investigation.actions';
import {Investigation} from './investigation.model';
import {investigationFeatureKey} from './investigation.reducer';

@Injectable({
  providedIn: 'root'
})
export class InvestigationService implements NgPatFirebaseConnectionService {
  private _queryCollaborationService!: NgPatFirestoreCollectionQuery<Investigation>;
  private _queryPrivateService!: NgPatFirestoreCollectionQuery<Investigation>;

  connectionKey = investigationFeatureKey;
  connection: NgPatServiceConnector = new NgPatServiceConnector(
    this,
    this.store
  );

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  ngPatOnInit() {
    this._queryCollaborationService =
      new NgPatFirestoreCollectionQuery<Investigation>(
        {
          queryMember: true,
          upsertManyAction: (investigations: Investigation[]) =>
            upsertInvestigations({investigations}),
          updateManyAction: (investigations: Investigation[]) =>
            updateInvestigations({
              investigations: aggregateUpdates(investigations)
            }),
          deleteManyAction: (ids: string[]) => deleteInvestigations({ids})
        },
        this.store,
        this.customFirestoreService
      );

    this._queryPrivateService =
      new NgPatFirestoreCollectionQuery<Investigation>(
        {
          queryMember: false,
          upsertManyAction: (investigations: Investigation[]) =>
            upsertInvestigations({investigations}),
          updateManyAction: (investigations: Investigation[]) =>
            updateInvestigations({
              investigations: aggregateUpdates(investigations)
            }),
          deleteManyAction: (ids: string[]) => deleteInvestigations({ids})
        },
        this.store,
        this.customFirestoreService
      );
  }

  deleteDoc$(investigation: Project | undefined, uid: string) {
    if (investigation) {
      const path: string = investigation.isPrivate
        ? firestoreUserInvestigationCollection(uid)
        : firestoreInvestigationCollection();

      // const path = studyGroup.isPrivate ?
      return this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(investigation: Project | undefined, ids: string[], uid: string) {
    if (investigation) {
      const isPrivate = investigation.isPrivate;
      const path: string = isPrivate
        ? firestoreUserInvestigationCollection(uid)
        : firestoreInvestigationCollection();
      return this.customFirestoreService.deleteDocs$(path, ids);
    }
    return of(true);
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._queryCollaborationService.onConnect(
      firestoreInvestigationCollection(),
      null,
      <string>user.uid
    );
    this._queryPrivateService.onConnect(
      firestoreUserInvestigationCollection(<string>user.uid),
      null,
      null
    );
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._queryCollaborationService.onDisconnect();
    this._queryPrivateService.onDisconnect();
  }
}
