import {ngPatLogout} from '@ngpat/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {updateQuizs} from '../+quizzes/quiz.actions';
import {updateStudyGroups} from '../+study-groups/study-group.actions';
import {iterateChanges} from './member.fns';
import * as MembersActions from './members.actions';
import {Member} from './members.model';

export const membersFeatureKey = 'members';

export interface MembersState extends EntityState<Member> {
  // additional entities state properties
  selectedMembersID: string | null;
}

export interface PartialMembersState {
  readonly [membersFeatureKey]: MembersState;
}

export function selectUserId(a: Member): string {
  //In this case this would be optional since primary key is id
  return a.uid;
}

export const membersAdapter: EntityAdapter<Member> =
  createEntityAdapter<Member>({
    selectId: selectUserId
  });

export const initialMembersState: MembersState = membersAdapter.getInitialState(
  {
    // additional entity state properties
    selectedMembersID: null
  }
);

export const reducer = createReducer(
  initialMembersState,
  on(MembersActions.addMember, (state, action) =>
    membersAdapter.addOne(action.member, state)
  ),
  on(MembersActions.setMember, (state, action) =>
    membersAdapter.setOne(action.member, state)
  ),
  on(MembersActions.addMembers, (state, action) =>
    membersAdapter.addMany(action.members, state)
  ),
  on(MembersActions.updateMember, (state, action) =>
    membersAdapter.updateOne(action.member, state)
  ),
  on(MembersActions.updateMembers, (state, action) =>
    membersAdapter.updateMany(action.members, state)
  ),
  on(MembersActions.upsertMember, (state, action) => {
    // const _state = membersAdapter.upsertOne(action.member, state);
    const _state = {
      ...state,
      ids: <string[]>[...state.ids],
      entities: {
        ...state.entities
      }
    };

    const member: Member | undefined = _state.entities[action.member.uid];

    if (member && action.member.uid && member.projects !== undefined) {
      const projectIDS: string[] = [
        ...action.member.projectIDs,
        ...member.projectIDs
      ];

      const _member: Member = {
        uid:
          action.member.uid && action.member.uid.length
            ? action.member.uid
            : member.uid,
        username:
          action.member.username && action.member.username.length
            ? action.member.username
            : member.username,
        projectIDs: [...new Set(projectIDS)],
        projects: {
          ...member.projects,
          ...action.member.projects
        }
      };

      _state.entities[action.member.uid] = {
        ..._member
      };
    } else {
      _state.entities[action.member.uid] = action.member;
      _state.ids = <string[]>[..._state.ids, action.member.uid];
    }

    return _state;
  }),
  on(MembersActions.upsertMembers, (state, action) =>
    membersAdapter.upsertMany(action.members, state)
  ),
  on(MembersActions.mapMember, (state, {entityMap}) => {
    return membersAdapter.mapOne(entityMap, state);
  }),
  on(MembersActions.mapMembers, (state, {entityMap}) => {
    return membersAdapter.map(entityMap, state);
  }),
  on(MembersActions.deleteMember, (state, action) =>
    membersAdapter.removeOne(action.id, state)
  ),
  on(MembersActions.deleteMembers, (state, action) =>
    membersAdapter.removeMany(action.ids, state)
  ),
  on(MembersActions.loadMembers, (state, action) =>
    membersAdapter.setAll(action.members, state)
  ),
  on(MembersActions.setMembers, (state, action) =>
    membersAdapter.setMany(action.members, state)
  ),
  on(MembersActions.clearMembers, state => membersAdapter.removeAll(state)),
  on(ngPatLogout, state => ({
    ...initialMembersState,
    ...membersAdapter.removeAll(state)
  })),
  on(MembersActions.selectMembersID, (state, action) => {
    return {
      ...state,
      selectedMembersID: action.id
    };
  }),
  on(updateStudyGroups, (state, action) => {
    return iterateChanges<MembersState>(
      action,
      'studyGroups',
      JSON.parse(JSON.stringify(state))
    );
  }),
  on(updateQuizs, (state, action) => {
    return iterateChanges<MembersState>(
      action,
      'quizs',
      JSON.parse(JSON.stringify(state))
    );
  })
);
