import {createAction, props} from '@ngrx/store';
import {EntityMap, EntityMapOne, Update} from '@ngrx/entity';

import {CreateQuizParams, CreateReviewQuizFromWrongAnswers, CreateSubQuizParams, Quiz} from './quiz.model';
import {Project, ProjectType} from '../+project/project.model';

export const addQuiz = createAction(
  '[Quiz/API] Add Quiz',
  props<{quiz: Quiz}>()
);

export const setQuiz = createAction(
  '[Quiz/API] Set Quiz',
  props<{quiz: Quiz}>()
);

export const upsertQuiz = createAction(
  '[Quiz/API] Upsert Quiz',
  props<{quiz: Quiz}>()
);

export const addQuizs = createAction(
  '[Quiz/API] Add Quizs',
  props<{quizs: Quiz[]}>()
);

export const upsertQuizs = createAction(
  '[Quiz/API] Upsert Quizs',
  props<{quizs: Quiz[]}>()
);

export const updateQuiz = createAction(
  '[Quiz/API] Update Quiz',
  props<{quiz: Update<Quiz>}>()
);

export const updateQuizs = createAction(
  '[Quiz/API] Update Quizs',
  props<{quizs: Update<Quiz>[]}>()
);

export const mapQuiz = createAction(
  '[Quiz/API] Map Quiz',
  props<{entityMap: EntityMapOne<Quiz>}>()
);

export const mapQuizs = createAction(
  '[Quiz/API] Map Quizs',
  props<{entityMap: EntityMap<Quiz>}>()
);

export const deleteQuiz = createAction(
  '[Quiz/API] Delete Quiz',
  props<{id: string}>()
);
export const deleteQuizFromfirestore = createAction(
  '[Quiz/API] Delete Quiz From Firestore',
  props<{id: string}>()
);

export const deleteQuizs = createAction(
  '[Quiz/API] Delete Quizs',
  props<{ids: string[]}>()
);

export const loadQuizs = createAction(
  '[Quiz/API] Load Quizs',
  props<{quizs: Quiz[]}>()
);

export const setQuizs = createAction(
  '[Quiz/API] Set Quizs',
  props<{quizs: Quiz[]}>()
);

export const clearQuizs = createAction('[Quiz/API] Clear Quizs');

export const selectQuizID = createAction(
  '[Quiz/API] Select Quiz',
  props<{id: string}>()
);

// export const createQuizOfType = createAction(
//   '[Quiz/API] Create Quiz of Type',
//   props<{projectType: ProjectType}>()
// );

// export const createQuizProjectParams = createAction(
//   '[Quiz/API] Create Quiz of Project Params',
//   props<{project: Project; projectType: ProjectType}>()
// );


export const updateFirestorePartialQuiz = createAction(
  '[Quiz/API] Save Partial Quiz',
  props<{changes: Partial<Quiz>; quiz: Quiz}>()
);

export const createReviewQuizFromWrongAnswers = createAction(
  '[Quiz/API] Create Review Quiz',
  props<{payload: CreateReviewQuizFromWrongAnswers}>()
);

export const createQuizParams = createAction(
  '[Quiz/API] Create Quiz By Type Value',
  props<{params: CreateQuizParams}>()
);

export const createSubQuizParams = createAction(
  '[Quiz/API] Create Sub Quiz By Type Value',
  props<{params: CreateSubQuizParams}>()
);
