<h3 mat-dialog-title>Confirm delete question</h3>
<mat-dialog-content>
  <p *ngIf="!isDeleting()" class="project-name font-medium">
    Are you sure you want to delete the question
    <span class="dlc-color-primary">{{ action.question.question }}</span
    >?
  </p>

  <div
    *ngIf="isDeleting()"
    class="flex flex-col justify-start items-start mb-8">
    <div class="mb-8">Deleting {{ action.question.question }}</div>
    <mat-spinner class="self-center" mode="indeterminate"></mat-spinner>
  </div>

  <div
    *ngIf="hasDeleteError()"
    class="flex flex-col justify-start items-start mb-8">
    {{ deleteError() }}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    *ngIf="!isDeleting()"
    dlc-rounded-text-icon-button
    (click)="dialogRef.close(false)">
    <mat-icon>close</mat-icon>
    Cancel
  </button>
  <button
    *ngIf="!isDeleting()"
    class="ml-4"
    dlc-rounded-text-icon-button
    color="warn"
    (click)="onDeleteQuestionAndAssets()">
    <mat-icon>delete</mat-icon>
    Delete
  </button>
</mat-dialog-actions>
