import {InjectionToken} from '@angular/core';
import {CustomParameters} from 'firebase/auth';

export interface NgPatEmailAuthProviderConfig {
  /**
   * Whether to enable email/password authentication.
   * Defaults to true
   */
  enabled?: boolean;

  /**
   * Whether to show username dlc-input-text field.
   * Defaults to true
   */
  username?: boolean;
}

export interface NgPatGoogleAuthProviderConfig {
  /**
   * See https://developers.google.com/identity/protocols/oauth2/scopes for more
   * information on scopes.
   */
  scopes?: string[];
  languageCode?: string;
  customParameters?: CustomParameters;
}

export interface NgPatAppleAuthProviderConfig {
  /**
   * https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms#3332112
   * email, name are the only parameters supported.
   */
  scopes?: string[];
  locale?: string;
  customParameters?: CustomParameters;
}

export interface NgPatAuthProvidersConfig {
  /**
   * Whether to enable email/password authentication.
   * Defaults to true
   */
  email?: NgPatEmailAuthProviderConfig;
  google?: NgPatGoogleAuthProviderConfig;
  apple?: NgPatAppleAuthProviderConfig;
  signInWithRedirect?: boolean;
}

export const NG_PAT_AUTH_PROVIDERS_CONFIG =
  new InjectionToken<NgPatAuthProvidersConfig>('NG_PAT_AUTH_PROVIDERS_CONFIG');

export type PasswordVisibility = 'password' | 'text';
