export enum AuthLoginAction {
  LOGIN = 'login',
  LOGGING_IN = 'loggingIn',
  SUCCESS = 'success',
  CANCEL = 'cancel',
  ERROR = 'error'
}

/**
 * Values must match AuthLoginAction
 */
export type AuthLoginStatus = 'login' | 'loggingIn' | 'success' | 'cancel' | 'error';

export const authLoginMap: {[key: string]: string} = {
  [AuthLoginAction.LOGIN]: 'Sign In',
  [AuthLoginAction.LOGGING_IN]: 'Logging In',
  [AuthLoginAction.SUCCESS]: 'Login Success',
  [AuthLoginAction.CANCEL]: 'Login Cancelled',
  [AuthLoginAction.ERROR]: 'Invalid Login'
};

export interface LoginHeader {
  LOGIN: string;
  LOGGING_IN: string;
  SUCCESS: string;
  CANCEL: string;
  ERROR: string;
}

export function currentLoginState(action: AuthLoginAction): string {
  return authLoginMap[action] as string;
}
/**
 * Copy value to apps/firebase/functions/src/models.ts:11
 */
export interface OTLIDFirestore {
  otlid: string;
  jwtToken: string | null;
  uid: string | null;
  email: string | null;
  displayName: string | null;
  action: AuthLoginStatus;
}

export const OTLID_QUERY_PARAM = 'otlid';

/**
 * Copy value to apps/firebase/functions/src/models.ts:11
 */
const FIRESTORE_OT_AUTH_CODE_COLLECTION = 'otLoginAuthCodes';

export function firestoreOtlidCollection(): string {
  return `${FIRESTORE_OT_AUTH_CODE_COLLECTION}`;
}

export function firestoreOtlidById(otlid: string): string {
  return `${FIRESTORE_OT_AUTH_CODE_COLLECTION}/${otlid}`;
}
