import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ecConvertTime',
  standalone: true
})
export class EcConvertTimePipe implements PipeTransform {
  /**
   * Convert time from milliseconds to a string
   * @param value
   */
  transform(value: number): string {
    const seconds = value / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;

    if (hours >= 1) {
      return `${Math.floor(hours)}h ${Math.floor(minutes % 60)}m ${Math.floor(
        seconds % 60
      )}s`;
    } else if (minutes >= 1) {
      return `${Math.floor(minutes)}m ${Math.floor(seconds % 60)}s`;
    } else {
      return `${Math.floor(seconds)}s`;
    }
  }
}
