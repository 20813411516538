export enum PLATFORM_DYN_STORE {
  NONE = 'none',
  ADD_TRUE_FALSE = 'ADD_TRUE_FALSE',
  ADD_MULTI_CHOICE = 'ADD_MULTI_CHOICE',
  INVITE = 'INVITE',
  MEMBERS = 'MEMBERS',
  QUIZZES = 'QUIZZES',
  CREATE_QUIZ = 'CREATE_QUIZ',
  QUIZ_CREATED = 'QUIZ_CREATED',
  COPY_QUIZ = 'COPY_QUIZ'
}
