import {LoginEffects} from './login.effects';
import {loginFeatureKey, loginInitialState, loginReducer} from './login.reducer';

export const LOGIN_ROOT_REDUCERS = {
  [loginFeatureKey]: loginReducer
};

export const LOGIN_ROOT_STATE_INITIALIZER = {
  [loginFeatureKey]: loginInitialState
};

export const LOGIN_ROOT_EFFECTS = [LoginEffects];
