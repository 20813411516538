import {Action, createReducer, on} from '@ngrx/store';
import {
  actionUiStateInitialized,
  setIsDesktop,
  setIsMobile,
  loadNavItemEntities,
  loadUIToStore,
  resetNavigation,
  selectProjectAction,
  setCurrentNavItemID,
  setNavigateWithParams,
  setCurrentURL,
  setCurrentTheme,
  setThemes
} from './ui.actions';
import {initialUiState, UiState} from './ui.model';

export const reducer = createReducer(
  initialUiState,
  on(actionUiStateInitialized, state => ({...state, uiStateInitialized: true})),
  on(
    loadUIToStore,
    (state: UiState, action: Action & {payload: Partial<UiState>}) => ({
      ...state,
      ...action.payload
    })
  ),
  on(setIsDesktop, (state: UiState, {isDesktop}) => ({
    ...state,
    isDesktop,
    isMobile: !isDesktop
  })),
  on(setIsMobile, (state: UiState, {isMobile}) => ({
    ...state,
    isMobile,
    isLargeScreen: !isMobile
  })),
  on(setCurrentURL, (state: UiState, {currentURL}) => ({
    ...state,
    currentURL
  })),
  on(selectProjectAction, (state: UiState, action) => ({
    ...state,
    selectedProject: action.selectedProject
  })),
  on(loadNavItemEntities, (state: UiState, action) => {
    return <UiState>{
      ...state,
      navItemEntities: {
        ...state.navItemEntities,
        ...action.navItemEntities
      }
    };
  }),
  on(resetNavigation, state => {
    return {
      ...state,
      selectedProject: null
    };
  }),
  on(setCurrentNavItemID, (state: UiState, {currentNavItemId}) => {
    return {
      ...state,
      currentNavItemId
    };
  }),
  on(
    setNavigateWithParams,
    (state: UiState, {previousNavItemID, currentNavItemID, navParams}) => {
      return {
        ...state,
        currentNavItemID,
        previousNavItemID,

        /**
         * Sets params such as
         *
         */
        ...navParams
      };
    }
  ),
  on(setCurrentTheme, (state: UiState, {currentTheme}) => ({
    ...state,
    currentTheme
  })),
  on(setThemes, (state: UiState, {themes}) => ({
    ...state,
    themes
  }))
);
