<h3 *ngIf="showTitle" class="mat-h3 p-accent">Mentor / Parent Subscription</h3>
<div class="mat-caption">
  <div class="h4">Quizzes / Study Groups</div>
  <ul>
    <li>Create unlimited quizzes.</li>
    <li>Create unlimited study groups.</li>
    <li>Add membersList to a study group.</li>
    <li>Invite and collaborate with other students in your study groups.</li>
    <li>Multiple membersList can edit a quiz at the same time.</li>
    <li>
      Copy quizzes from private space to study groups or from study groups to
      private space.
    </li>
  </ul>
  <div class="h4">Classes</div>
  <ul>
    <li>Can join classes by invitation only.</li>
    <li>Can monitor their own students grades by invitation only.</li>
    <li>Can not see other student's grades.</li>
  </ul>
  <div class="h4">Privacy and Control</div>
  <ul>
    <li>The study group creator is the moderator of a study group.</li>
    <li>The study group creator can remove membersList.</li>
    <li>
      Quiz results are only visible to the quiz taker, not to the entire study
      group.
    </li>
  </ul>
  <div class="p-mt-20 cta-wrapper">
    <ng-content></ng-content>
  </div>
</div>
