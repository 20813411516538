import {CommonModule} from '@angular/common';
import {
  Component,
  computed,
  inject,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {
  ngPatBuyNativeAction,
  NgPatNormalizedOffer,
  NgPatNormalizedPricePlatform,
  selectNgPatIsLoggedIn
} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {DlcRoundedTextButtonComponent} from '../../../../button/dlc-rounded-text-button/dlc-rounded-text-button.component';
import {DlcWebPricingService} from '../../../dlc-web-pricing/dlc-web-pricing/dlc-web-pricing.service';

enum PurchaseButtonState {
  PURCHASE = 'Purchase',
  DISABLED = 'Disabled',
  CANCEL = 'Cancel'
}

@Component({
  selector: 'dlc-feature-price',
  standalone: true,
  imports: [CommonModule, DlcRoundedTextButtonComponent],
  templateUrl: './dlc-feature-price.component.html',
  styleUrl: './dlc-feature-price.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-feature-price',
    '[class.dlc-feature-price--most-popular]': 'isMostPopular()',
    '[class.dlc-feature-price--owned]': 'anyOwned()'
  }
})
export class DlcFeaturePriceComponent {
  store = inject(Store);
  webPriceService: DlcWebPricingService = inject(DlcWebPricingService);

  isLoggedIn: Signal<boolean> = <Signal<boolean>>this.store.selectSignal(selectNgPatIsLoggedIn);

  offer: InputSignal<NgPatNormalizedOffer | undefined> = input();

  isCustomPrice = input(false);

  priceInteger = computed(() => {
    return this.offer()?.price.split('.')[0];
  });

  priceDecimal = computed(() => {
    const decimal = this.offer()?.price.split('.')[1];

    if (decimal !== undefined) {
      return `.${decimal}`;
    }

    return `.00`;
  });

  isOwned = computed(() => {
    return this.offer()?.owned;
  });

  anyOwned = input(false);

  isMostPopular = computed(() => {
    return this.offer()?.isMostPopular;
  });

  btnState = PurchaseButtonState;

  purchaseButtonState = computed(() => {
    const selectedOffer = this.offer();

    if (selectedOffer) {
      if (selectedOffer.owned) {
        if (selectedOffer.platform === NgPatNormalizedPricePlatform.WEB) {
          return PurchaseButtonState.CANCEL;
        } else {
          return PurchaseButtonState.DISABLED;
        }
      } else {
        return PurchaseButtonState.PURCHASE;
      }
    }

    return PurchaseButtonState.PURCHASE;
  });

  interval = computed(() => {
    const offer = this.offer();

    if (offer) {
      return offer.interval.includes('month') ? 'monthly' : 'yearly';
    }
    return '';
  });

  doLogin: OutputEmitterRef<boolean> = output();
  showCheckoutProgressBar: OutputEmitterRef<boolean> = output();

  get isNativePlatform(): boolean {
    return Capacitor.isNativePlatform();
  }

  onSelectedOffer(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const price: NgPatNormalizedOffer | undefined = this.offer();

    if (price) {
      if (this.isNativePlatform) {
        this.store.dispatch(ngPatBuyNativeAction({price}));
      } else {
        this.showCheckoutProgressBar.emit(true);
        this.webPriceService.checkout(price);
      }
    }
  }

  onCancelOffer(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const price: NgPatNormalizedOffer | undefined = this.offer();

    if (price) {
      this.webPriceService.cancelSubscription(price);
    }
  }
}
