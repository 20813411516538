import algoliasearch, {SearchIndex} from 'algoliasearch';
import {from, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {uuid} from '@ngpat/fn';
import {SearchResponse} from '@algolia/client-search';

export interface AlgoliaChipsAutocompleteKeys {
  appId: string;
  apiKey: string;
  index: string;
}
export class AlgoliaChipsAutocomplete<AlgoliaResultHit> {
  private searchIndex!: SearchIndex;
  private _valueChanges$: Subject<void> = new Subject<void>();
  valueChanges$: Observable<string> = this._valueChanges$.pipe(
    map(() => {
      return this.getSelectedAsString()
    })
  )
  selectedViewValues: string[] = [];
  selectedObjects: AlgoliaResultHit[] = [];

  constructor(private params: AlgoliaChipsAutocompleteKeys, private prop: string = 'name') {
    const _client = algoliasearch(
      this.params.appId,
      this.params.apiKey
    );

    this.searchIndex = _client.initIndex(this.params.index);

  }

  add(event: MatChipInputEvent, emitEvent = true): void {
    const value = (event.value || '').trim();
    // const value = <any>event.value;

    // Add our subject
    if (value) {
      this.selectedViewValues.push(value);
      this.selectedObjects.push(<AlgoliaResultHit>{
        id: uuid(),
        [this.prop]: value
      });
    }



    // Clear the input value
    event.chipInput!.clear();

    if (emitEvent) {
      this.emitValueChanges();
    }

  }

  addMany(values: string[], emitEvent = true): void {
    values.forEach((value: string) => {
      this.selectedViewValues.push(value);
      this.selectedObjects.push(<AlgoliaResultHit>{
        id: uuid(),
        [this.prop]: value
      });
    });

    if (emitEvent) {
      this.emitValueChanges();
    }
  }

  /**
   * Adds many comma delimited values
   * Input would be something like 'value1, value2, value3'
   * @param value
   * @param emitEvent
   */
  setManyCommaDelimited(value: string, emitEvent = false) {
    this.clear();
    this.addMany(value.split(','), emitEvent);
  }

  /**
   * Returns the index of the removed subject
   * @param subject
   */
  remove(subject: string, emitEvent = true): number {
    const index = this.selectedViewValues.indexOf(subject);

    if (index >= 0) {
      this.selectedViewValues.splice(index, 1);
      this.selectedObjects.splice(index, 1);

      // this.announcer.announce(`Removed ${subject}`);
    }

    if (emitEvent) {
      this.emitValueChanges();
    }

    return index;
  }

  selected(event: MatAutocompleteSelectedEvent, emitEvent = true): void {
    this.selectedViewValues.push(event.option.viewValue);
    this.selectedObjects.push(event.option.value);

    if (emitEvent) {
      this.emitValueChanges();
    }
  }

  search<T>(search: string): Observable<T[]> {
    return from(this.searchIndex.search(search)).pipe(
      map((results: SearchResponse<unknown>) => {
        return <T[]>results.hits;
      })
    );
  }

  clear() {
    this.selectedViewValues = [];
    this.selectedObjects = [];
  }

  /**
   * Returns a string of the selected object props by the given property
   * suchs as 'name' or 'id', with the results separated by a comma
   * for example: 'name1, name2, name3'
   * @param prop
   */
  getSelectedAsString(): string {
    return this.selectedObjects.map((o: AlgoliaResultHit) => {
      return (<never>o)[this.prop];
    }).join(', ');
  }

  private emitValueChanges() {
    this._valueChanges$.next();
  }
}
