export interface RectangleDimensions {
  width: number;
  height: number;
}

/**
 * @example
 *
 *
 * const newWidth = getScaledDimensionOfRectangle(newHeight, orginalWidth, originalHeight );
 *
 * @param targetDim
 * @param originalDimension1
 * @param originalDimension2
 */
export function getScaledDimensionOfRectangle(
  targetDim: number,
  originalDimension1: number,
  originalDimension2: number
): number {
  return (targetDim / originalDimension1) * originalDimension2;
}

/**
 * Scale a rectangle to fit within a max width and height.
 * @param originalWidth
 * @param originalHeight
 * @param maxWidth
 * @param maxHeight
 */
export function scaleRectangle(
  originalWidth: number,
  originalHeight: number,
  maxWidth: number,
  maxHeight: number
): RectangleDimensions {
  if (maxWidth && (maxHeight === null || maxHeight === 0)) {
    return {
      width: maxWidth,
      height: getScaledDimensionOfRectangle(
        maxWidth,
        originalWidth,
        originalHeight
      )
    };
  } else if (maxHeight && (maxWidth === null || maxWidth === 0)) {
    return {
      width: getScaledDimensionOfRectangle(
        maxHeight,
        originalHeight,
        originalWidth
      ),
      height: maxHeight
    };
  }

  const ratio = Math.min(maxWidth / originalWidth, maxHeight / originalHeight);

  return {width: originalWidth * ratio, height: originalHeight * ratio};
}
