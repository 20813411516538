import {Inject, Injectable} from '@angular/core';
import {
  AlgoliaPublishedQuiz,
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment
} from '@gigasoftware/evolving-cognition/domain';
import {AlgoliaChipsAutocomplete} from '@gigasoftware/shared/domain';


@Injectable()
export class AlgoliaSearchSubjects extends AlgoliaChipsAutocomplete<AlgoliaPublishedQuiz> {

  constructor(
    @Inject(EC_ENVIRONMENT) private environment: EvolutionCognitionEnvironment,
  ) {
    super({
      appId: environment.algoliaSubjects.appId,
      apiKey: environment.algoliaSubjects.apiKey,
      index: environment.algoliaSubjects.index
    }, 'name')
  }

}
