import {
  addDatabasePaths,
  addRemoteConfigParams,
  createDefaultFirebaseConfig,
  ENV_SERVER_ENUM
} from '@ngpat/firebase';
import {firebaseEvolvingCognitionMarketing} from '@secrets';
import {MarketingEnvironmentModel} from './environment.model';

const firebaseConfigParams = createDefaultFirebaseConfig({
  firebase: firebaseEvolvingCognitionMarketing,
  env: ENV_SERVER_ENUM.DEV,
  appName: 'ec-marketing',
  remoteConfigParams: addRemoteConfigParams(),
  databasePaths: addDatabasePaths(),
  appNameInFirebaseConfig: false
});

export const environment: MarketingEnvironmentModel = {
  env: ENV_SERVER_ENUM.DEV,
  production: false,
  firebaseConfigParams: firebaseConfigParams,
  appUrl: 'https://dev.app.evolving-cognition.com/home'
};
