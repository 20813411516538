import {createAction, props} from '@ngrx/store';
import {ProjectType} from './+project/project.model';

/**
 * Should only be called by the app component.
 */
export const configurePlatform = createAction(
  '[Platform] Configure',
  props<{
    themes: string[];
    defaultProjectType: ProjectType;
    projectTypes: ProjectType[];
  }>()
);
