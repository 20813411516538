import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {Router, UrlTree} from '@angular/router';
import {
  NgPatStripeTrialParams,
  selectNgPatStripeTrialParams
} from '@ngpat/store';

/**
 * https://medium.com/@ryanchenkie_40935/angular-authentication-using-route-guards-bf7a4ca13ae3
 */
@Injectable({
  providedIn: 'root'
})
export class TrialGuardService {
  constructor(private _router: Router, private store: Store) {}

  canActivate(): Observable<boolean | UrlTree> {
    const canActivate = true;

    return this.store.pipe(
      select(selectNgPatStripeTrialParams),
      debounceTime(200),
      map((p: NgPatStripeTrialParams) => {
        if (p.hasActiveSubscription || p.isInTrial || p.hasPromoCode) {
          return canActivate;
        } else {
          return this._router.parseUrl('/subscribe');
        }
      })
    );
  }
}
