<svg
  class="tooltip-bubble"
  [attr.width]="chartTooltipState().svgWidth"
  [attr.height]="chartTooltipState().svgHeight"
  [attr.viewBox]="chartTooltipState().viewBox"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <mask
    id="bubble_mask"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    [attr.width]="chartTooltipState().maskWidth"
    [attr.height]="chartTooltipState().maskHeight">
    <rect
      class="tooltip-mask"
      [attr.width]="chartTooltipState().maskWidth"
      [attr.height]="chartTooltipState().maskHeight"
      fill="white" />
  </mask>
  <g mask="url(#bubble_mask)">
    <rect
      mask="url(#bubble_mask)"
      class="tooltip-background"
      [attr.width]="chartTooltipState().backgroundWidth"
      [attr.height]="chartTooltipState().backgroundHeight"
      [attr.rx]="chartTooltipState().rx" />
    <path
      mask="url(#bubble_mask)"
      class="tooltip-divot"
      [style.transform]="translateDivot()"
      [attr.d]="chartTooltipState().divotPath" />
  </g>
</svg>
<div
  class="ng-pat-charts-tooltip-content"
  [style.height.px]="chartTooltipState().backgroundHeight">
  <ng-content></ng-content>
</div>
