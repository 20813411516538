import 'cordova-plugin-purchase';
import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {WindowService} from '@ngpat/utils';
import {Store} from '@ngrx/store';
import {HttpsCallableResult} from 'firebase/functions';
import {BehaviorSubject, switchMap} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {NgPatAccountState} from '../../+account/account.model';
import {selectNgPatLoggedInUID} from '../../+account/account.selectors';
import {NgPatServiceConnector} from '../../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../../+websocket-registry/websocket-registry.models';
import {NgPatEntityStore} from '../../custom-store/entity-store/ng-pat-entity-store';
import {getPlatformFromNormalizedPlatform} from '../normalized-price.fns';
import {NgPatNormalizedOffer} from '../normalized-price.model';
import {
  addAppstoreInAppPurchases,
  appStoreMessage,
  appStorePurchaseSuccess,
  upsertAppstoreInAppPurchase
} from './in-app-purchase.actions';
import {getActiveInAppPurchase, transformCdvPurchaseProduct} from './in-app-purchase.fns';
import {
  IAPMessage,
  IAPProduct,
  IAPProductAndIRegisterProduct,
  IapticInAppPurchaseValidationResponse
} from './in-app-purchase.model';
import {IAP_CONFIG, IAPConfig} from './injection-tokens';
import {DEVICE_TYPE, InAppPurchaseWrapper} from './wrapper/index';
import IRegisterProduct = CdvPurchase.IRegisterProduct;
import LogLevel = CdvPurchase.LogLevel;

import Platform = CdvPurchase.Platform;
import Product = CdvPurchase.Product;
import Receipt = CdvPurchase.Receipt;
import Transaction = CdvPurchase.Transaction;
import UnverifiedReceipt = CdvPurchase.UnverifiedReceipt;
import VerifiedReceipt = CdvPurchase.VerifiedReceipt;

const IN_APP_PURCHASE_CONNECTOR_KEY = 'IN_APP_PURCHASE_CONNECTOR_KEY';

@Injectable({
  providedIn: 'root'
})
export class NgPatInAppPurchaseService implements NgPatFirebaseConnectionService {
  connectionKey = IN_APP_PURCHASE_CONNECTOR_KEY;
  connection: NgPatServiceConnector = new NgPatServiceConnector(this, this.ngrxStore);

  loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  productStore: NgPatEntityStore<Product> = new NgPatEntityStore();

  store: InAppPurchaseWrapper;

  constructor(
    private windowService: WindowService,
    private customFirestoreService: NgPatFirestoreService,
    private ngrxStore: Store,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(IAP_CONFIG) private iapConfig: IAPConfig
  ) {
    console.log('[IAP] CONSTRUCTOR');

    try {
      console.log('[IAP] CONFIG', JSON.stringify(this.iapConfig, null, 2));
    } catch (e: unknown) {
      console.log('[IAP] ERROR', e);
    }

    this.store = new InAppPurchaseWrapper(
      {
        ...this.iapConfig.config,
        win: this.windowService.nativeWindow
      },
      this.onDeviceReady.bind(this)
    );
  }

  onDeviceReady(deviceType: DEVICE_TYPE) {
    const that = this;

    console.log('[IAP] DEVICE READY');

    // if (deviceType === DEVICE_TYPE.NATIVE) {
    //   this.initializeCdvStore(uid);
    // } else {
    //   this.initializeWebStore(uid);
    // }

    // CAUTION: THIS CRASHES THE APP!!!
    // this.store.CdvPurchase.store.ready(() => {
    //   console.log('[EC IN APP PURCHASE SERVICE] STORE READY');
    //   this.CdvPurchaseReady$.next(true);
    // });

    // TODO - should this have take(1) or takeOneAfterTrue?
    this.loggedIn$
      .pipe(
        distinctUntilChanged(),
        switchMap((isLoggedIn: boolean) => {
          return this.ngrxStore.select(selectNgPatLoggedInUID).pipe(
            distinctUntilChanged(),
            map((uid: string | null): [boolean, string | null] => {
              return [isLoggedIn, uid];
            })
          );
        })
      )
      .subscribe(([isLoggedIn, uid]: [boolean, string | null]) => {
        console.log('[IAP] LOGGED IN', isLoggedIn, uid);
        if (deviceType === DEVICE_TYPE.NATIVE) {
          this.initializeCdvStore(uid);
        } else if (isLoggedIn && uid && uid.length > 0) {
          this.initializeWebStore(uid);
        }
      });
  }

  /**
   * Device Ready
   */
  initializeCdvStore(uid: string | null) {
    const that = this;

    const store = this.store;

    const log: CdvPurchase.Logger = new CdvPurchase.Logger(
      {verbosity: CdvPurchase.LogLevel.DEBUG},
      'IAP'
    );

    const products: CdvPurchase.IRegisterProduct[] = [
      ...this.getProductsByPlatform()
      // CdvPurchase.Test.testProducts.PAID_SUBSCRIPTION_ACTIVE
      // CdvPurchase.Test.testProducts.CONSUMABLE,
      // CdvPurchase.Test.testProducts.CONSUMABLE_FAILING
    ];

    try {
      console.log('[IAP] PRODUCTS', JSON.stringify(products, null, 2));
    } catch (e: unknown) {
      console.log('[IAP] ERROR', e);
    }

    // We should first register all our products or we cannot use them in the app.
    store.register(products);
    store.verbosity = LogLevel.DEBUG;

    if (uid !== null && uid !== undefined) {
      store.applicationUsername = uid; // the plugin will hash this with md5 where needed
    }

    // Show errors on the dedicated Div.
    store.error(this.errorHandler.bind(this));

    // Define events handler for our subscription products
    store
      .when()
      .productUpdated((product: Product) => {
        // Re-render the interface on updates
        log.info('[IAP] PRODUCT UPDATED STRINGFIED: ' + JSON.stringify(product));
        this.renderUI();
        this.productStore.upsertOne(product);

        const iapProduct: IAPProduct = transformCdvPurchaseProduct(product);

        log.info('[IAP] PRODUCT PARSED' + JSON.stringify(iapProduct, null, 2));

        this.ngrxStore.dispatch(upsertAppstoreInAppPurchase({appstoreInAppPurchase: iapProduct}));
      })
      .receiptUpdated((receipt: Receipt) => {
        // Re-render the interface on updates
        log.info('[IAP] Receipt updated.');
        // log.info('Receipt updated: ' + JSON.stringify(receipt));
        this.renderUI();
      })
      .approved((transaction: Transaction) => {
        log.info('[IAP] Transaction approved: ' + JSON.stringify(transaction));
        // verify approved transactions
        transaction.verify();
      })
      .verified((receipt: VerifiedReceipt) => {
        // finish transactions from verified receipts
        log.info('[IAP] Receipt verified');
        receipt.finish();
        this.renderUI();
      })
      .unverified((receipt: UnverifiedReceipt) => {
        // finish transactions from verified receipts
        log.info('[IAP] Receipt unverified');
      })
      .finished((transaction: Transaction) => {
        log.info('[IAP] Transaction finished');
        this.renderUI();
      })
      .receiptsReady(() => {
        log.info('[IAP] Receipts ready');
      })
      .receiptsVerified(() => {
        log.info('[IAP] Receipts verified');
      });

    const iaptic = new CdvPurchase.Iaptic({
      appName: this.iapConfig.config.appName,
      apiKey: this.iapConfig.config.apiKey
    });

    // For subscriptions and secured transactions, we setup a receipt validator.
    this.store.CdvPurchase.store.validator = iaptic.validator;
    this.store.CdvPurchase.store.validator_privacy_policy = [
      'analytics',
      'support',
      'tracking',
      'fraud'
    ];

    // Load informations about products and purchases
    log.info('[IAP] calling store initialize()');

    this.store.CdvPurchase.store.initialize([
      // {platform: Platform.TEST},
      {platform: Platform.GOOGLE_PLAY},
      {
        platform: Platform.APPLE_APPSTORE,
        options: {
          needAppReceipt: true,
          discountEligibilityDeterminer: iaptic.appStoreDiscountEligibilityDeterminer
        }
      }
      // {
      //   platform: Platform.BRAINTREE,
      //   options: {
      //     clientTokenProvider: iaptic.braintreeClientTokenProvider,
      //     threeDSecure: {
      //       threeDSecureRequest: {
      //         exemptionRequested: true
      //       }
      //     },
      //     googlePay: {
      //       googleMerchantName: 'Iaptic',
      //       countryCode: 'FR',
      //       environment: 'TEST'
      //     }
      //   }
      // }
    ] as CdvPurchase.PlatformWithOptions[]);

    console.log('[IAP] STORE REFRESH');
    store.refresh();
  }

  errorHandler(err: CdvPurchase.IError) {
    console.log('[IAP] Error', JSON.stringify(err, null, 2));
  }

  /**
   *
   * @param normalizedOffer
   */
  orderOffer(normalizedOffer: NgPatNormalizedOffer): void {
    const that = this;
    console.log('[IAP] BUY', normalizedOffer);

    const offer = this.store.CdvPurchase.store
      .get(normalizedOffer.productId, getPlatformFromNormalizedPlatform(normalizedOffer.platform))
      ?.getOffer(normalizedOffer.offerId);

    if (offer) {
      this.store.CdvPurchase.store.order(offer).then(result => {
        const message: IAPMessage = {
          message: '',
          isError: false,
          productId: normalizedOffer.productId,
          offerId: normalizedOffer.offerId
        };

        if (result && result.isError) {
          message.isError = true;
          if (result.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
            message.message = 'Payment cancelled';
          } else {
            message.message = result.message;
          }
        } else {
          message.message = 'Success';
          that.ngrxStore.dispatch(appStorePurchaseSuccess({productId: normalizedOffer.productId}));
        }

        that.ngrxStore.dispatch(appStoreMessage(message));
      });
    }
  }

  renderUI() {
    // TODO
  }

  initializeWebStore(uid: string) {
    console.log('[IAP] INITIALIZE WEB STORE');

    this.verifyInAppPurchaseFn(uid).then(
      (response: HttpsCallableResult<IapticInAppPurchaseValidationResponse>) => {
        console.log('response', response);

        /**
         * https://www.iaptic.com/documentation/api/v3/#api-Types-Purchase
         */
        const purchases: IAPProductAndIRegisterProduct[] = getActiveInAppPurchase(
          response.data,
          this.iapConfig.subscriptionProducts
        );

        console.log('purchase', purchases);

        if (purchases.length > 0) {
          this.ngrxStore.dispatch(addAppstoreInAppPurchases({appstoreInAppPurchases: purchases}));
        }

        // console.log(device);
        // console.log(subscriptionID);
        // console.log(checkout);

        // this.ngrxStore.dispatch(
        //   upsertAppstoreInAppPurchase({
        //     appstoreInAppPurchase: {}
        //   })
        // );
      },
      (e: any) => {
        console.log(e);
        // this._snackBar.open(JSON.stringify(e), 'Close');
      }
    );
  }

  /**
   * https://www.iaptic.com/documentation/setup/cordova
   * Docs https://www.iaptic.com/documentation/api/v3/#api-Customers-GetCustomerPurchases
   * https://validator.iaptic.com/v3/customers/${uid}/purchases
   * @param uid
   */
  async verifyInAppPurchaseFn(
    uid: string
  ): Promise<HttpsCallableResult<IapticInAppPurchaseValidationResponse>> {
    const url = `https://validator.iaptic.com/v3/customers/${uid}/purchases`;

    const payload = {
      appName: this.iapConfig.config.appName,
      url
    };

    const callable = this.customFirestoreService.httpsCallable('verifyInAppPurchaseV2');

    return <Promise<HttpsCallableResult<IapticInAppPurchaseValidationResponse>>>callable(payload);
  }

  getProductsByPlatform(): IRegisterProduct[] {
    return this.iapConfig.subscriptionProducts.filter(
      p => p.platform === InAppPurchaseWrapper.currentPlatform
    );
  }

  getProductByIdAndPlatform(id: string): IRegisterProduct | undefined {
    return this.iapConfig.subscriptionProducts.find(
      p => p.id === id && p.platform === InAppPurchaseWrapper.currentPlatform
    );
  }

  onConnect(user: NgPatAccountState) {
    if (this.loggedIn$) {
      this.loggedIn$.next(true);
    }
  }

  onDisconnect(user: NgPatAccountState) {
    this.loggedIn$.next(false);
  }
}
