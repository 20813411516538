<form
  [formGroup]="nameFormGroup"
  class="flex flex-col justify-start items-start">
  <mat-checkbox
    *ngIf="showCollaborationOption"
    formControlName="isCollaborative"
    color="primary"
    >Create a team ( Collaborate )</mat-checkbox
  >

  <mat-checkbox
    *ngIf="showPrivateOption && showIsPrivate"
    formControlName="isPrivate"
    color="primary"
    class="mt-4"
    >Is Private ( Only visible to you )</mat-checkbox
  >

  <mat-checkbox
    *ngIf="showAssignedOption"
    formControlName="assigned"
    color="primary"
    class="mt-4 mb-4"
    >Assigned ( Students can take quiz )</mat-checkbox
  >

  <dlc-form-field class="mb-8 w-full" appearance="outline">
    <dlc-label>Quiz Name</dlc-label>
    <input dlcInput formControlName="name" autocomplete="off" />
    <dlc-error *ngIf="nameFormGroup.controls['name'].hasError('required')"
      >Required</dlc-error
    >
  </dlc-form-field>

  <dlc-form-field class="mb-8 w-full" appearance="outline">
    <dlc-label>Quiz Description</dlc-label>
    <input dlcInput formControlName="description" autocomplete="off" />
  </dlc-form-field>

  <!-- GRADE LEVELS -->
  <mat-form-field class="mb-4 w-full" appearance="outline">
    <mat-label>Grade Level</mat-label>
    <input type="text" matInput formControlName="gradeLevel" [matAutocomplete]="gradeLevelAuto">
    <mat-autocomplete #gradeLevelAuto="matAutocomplete"  [displayWith]="displayFn">
      @for (gradeLevel of filteredGradeLevels | async; track gradeLevel) {
        <mat-option [value]="gradeLevel">{{gradeLevel.name}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>

  <!-- SUBJECTS -->
  <mat-form-field class="w-full" appearance="outline" floatLabel="always">
    <mat-label>Subjects</mat-label>
    <mat-chip-grid #chipGrid aria-label="Subjects selection">
      @for (subject of algoliaSubjectAutocomplete.selectedViewValues; track subject) {
        <mat-chip-row (removed)="algoliaAutoCompleteRemove(subject)">
          {{subject}}
          <button matChipRemove [attr.aria-label]="'remove ' + subject">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>
    <input placeholder="math, science, chemistry, history" #subjectInput formControlName="subjects"
           [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="algoliaAutoCompleteAdd($event)"/>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="algoliaAutoCompleteSelect($event)">
      @for (subject of filteredSubjects | async; track subject) {
        <mat-option [value]="subject">{{subject.name}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>

  <div class="mb-6">
    <mat-checkbox formControlName="aggregateFalseAnswers"><span class="font-medium">Aggregate false answers.</span>  <span>
    Use answers from all questions to create wrong answers for each question.
  </span></mat-checkbox>
  </div>
</form>

<button
  dlc-rounded-text-button
  color="primary"
  [disabled]="nameFormGroup.invalid"
  (click)="onSubmit()">
  CREATE
</button>
