import {FirebaseApp} from 'firebase/app';
import {Firestore} from 'firebase/firestore';
import {Auth} from 'firebase/auth';
import {FirebaseStorage} from 'firebase/storage';
import {Functions} from 'firebase/functions';
import {RemoteConfig} from 'firebase/remote-config';
import {NgPatFirestoreTriggerEmailDoc} from '../services/ng-pat-trigger-email-from-firestore.service';

export interface Exists<T> {
  data: T;
  exists: boolean;
}

/**
 * For angular service to write NgPatFirestoreTriggerEmailDoc
 * to Firestore
 */
export interface FirestoreWriteEmailConfig {
  /**
   * Firestore Document ID
   */
  id: string;

  /**
   * Firestore  Email Doc - NgPatFirestoreTriggerEmailDoc
   */
  doc: NgPatFirestoreTriggerEmailDoc;
}

/**
 * Keys and values must match remove config keys
 * https://console.firebase.google.com/u/0/project/evolving-cognition-app/config
 */
export enum RemoteConfigKey {
  trialDays = 'trialDays',
  maxNumberQuizzesWhileInTrial = 'maxNumberQuizzesWhileInTrial'
}

/**
 * Configuration from firebaseConfigParams project.
 */
export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  // databaseURL?: string; // used for firebaseConfigParams real time database, not firestore
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export interface DatabasePaths {
  // Path to users collection
  users: string;
}

export interface RemoteConfigParams {
  settings: {
    /**
     * // https://firebase.google.com/docs/remote-config/get-started?platform=web
     * Defines the maximum age in milliseconds of an entry in the config cache before
     * it is considered stale. Defaults to 43200000 (Twelve hours).
     */
    minimumFetchIntervalMillis: number;

    /**
     * The time in milliseconds to refresh the remote config.
     */
    refreshIntervalMillis: number;
  };
}

/**
 * Firebase services configuration.
 * Wraps firebaseConfigParams config with additional params
 * for path to user collection and remote config
 * polling params.
 */
export interface NgPatFirebaseAppConfig {
  env: ENV_SERVER_ENUM;

  firebase: FirebaseConfig;
  appNameInFirebaseConfig: boolean;
  defaultRemoteConfig?: any;

  /** database paths */
  databasePaths?: DatabasePaths;
  remoteConfigParams?: RemoteConfigParams;

  appName: string | null;
}

export interface NgPatFirebaseAppInstance extends NgPatFirebaseAppConfig {
  app: FirebaseApp;
  db: Firestore;
  auth: Auth;
  storage: FirebaseStorage;
  functions: Functions;
  analytics: any;
  remoteConfig: RemoteConfig;
}

export interface NgPatAggregateFirebaseSnapshotChanges<T> {
  added: T[];
  // modified: Update<{ id: string }>[];
  modified: T[];
  removed: string[];
}

export interface NgPatFirebaseConfigCreatorParams {
  firebase: FirebaseConfig;
  env: ENV_SERVER_ENUM;
  remoteConfigParams: RemoteConfigParams;
  // Add app name to firebaseConfigParams
  defaultRemoteConfig?: any;
  databasePaths: any;
  appNameInFirebaseConfig: boolean;
  appName: string | null;
}

/**
 * Needs to match apps/firebaseConfigParams/functions/src/models.ts:20
 */
export enum ENV_SERVER_ENUM {
  DEV = 'dev',
  PROD = 'prod',
  LOCAL = 'local'
}
