export interface AspectRatios {
  FOUR_THREE: '4/3';
  SIXTEEN_NINE: '16/9';
  SIXTEEN_TEN: '16/10';
  TWENTYONE_NINE: '21/9';
  ONE_ONE: '1/1';
  THREE_FOUR: '3/4';
  NINE_SIXTEEN: '9/16';
  TEN_SIXTEEN: '10/16';
  NINE_TWENTYONE: '9/21';
}

export const ASPECT_RATIO: AspectRatios = {
  FOUR_THREE: '4/3',
  SIXTEEN_NINE: '16/9',
  SIXTEEN_TEN: '16/10',
  TWENTYONE_NINE: '21/9',
  ONE_ONE: '1/1',
  THREE_FOUR: '3/4',
  NINE_SIXTEEN: '9/16',
  TEN_SIXTEEN: '10/16',
  NINE_TWENTYONE: '9/21'
};

export const aspectRatioValues: string[] = Object.values(ASPECT_RATIO);

export interface ImageAspectRatios {
  FOUR_THREE: '4/3';
  SIXTEEN_NINE: '16/9';
  SIXTEEN_TEN: '16/10';
  TWENTYONE_NINE: '21/9';
  ONE_ONE: '1/1';
  THREE_FOUR: '3/4';
  NINE_SIXTEEN: '9/16';
  TEN_SIXTEEN: '10/16';
  NINE_TWENTYONE: '9/21';
}

export interface VideoAspectRatios {
  FOUR_THREE: '4/3';
  SIXTEEN_NINE: '16/9';
  SIXTEEN_TEN: '16/10';
  TWENTYONE_NINE: '21/9';
  ONE_ONE: '1/1';
  THREE_FOUR: '3/4';
  NINE_SIXTEEN: '9/16';
  TEN_SIXTEEN: '10/16';
  NINE_TWENTYONE: '9/21';
}

export const imageAspectRatios: ImageAspectRatios = {
  FOUR_THREE: '4/3',
  SIXTEEN_NINE: '16/9',
  SIXTEEN_TEN: '16/10',
  TWENTYONE_NINE: '21/9',
  ONE_ONE: '1/1',
  THREE_FOUR: '3/4',
  NINE_SIXTEEN: '9/16',
  TEN_SIXTEEN: '10/16',
  NINE_TWENTYONE: '9/21'
};

export const imageAspectRatioValues: string[] =
  Object.values(imageAspectRatios);

export const videoAspectRatios: VideoAspectRatios = {
  FOUR_THREE: '4/3',
  SIXTEEN_NINE: '16/9',
  SIXTEEN_TEN: '16/10',
  TWENTYONE_NINE: '21/9',
  ONE_ONE: '1/1',
  THREE_FOUR: '3/4',
  NINE_SIXTEEN: '9/16',
  TEN_SIXTEEN: '10/16',
  NINE_TWENTYONE: '9/21'
};

export const videoAspectRatioValues: string[] =
  Object.values(videoAspectRatios);

export const aspectRatioMap: Record<string, number> = {
  '4/3': 4 / 3,
  '16/9': 16 / 9,
  '16/10': 16 / 10,
  '21/9': 21 / 9,
  '1/1': 1 / 1,
  '3/4': 3 / 4,
  '9/16': 9 / 16,
  '10/16': 10 / 16,
  '9/21': 9 / 21
};

/**
 * Evaluates the aspect ratio and returns the number value.
 * @param aspectRatio
 */
export function evaluateAspectRatio(aspectRatio: string): number {
  return aspectRatioMap[aspectRatio];
}
