// @ts-nocheck
import freeGlobal from './_freeGlobal';

/** Detect free variable `exports`. */
var freeExports =
  typeof exports == 'object' && exports && !exports.nodeType && exports;

/** Detect free variable `module`. */
var freeModule = freeExports && typeof module == 'object' && module && module;

/** Detect the popular CommonJS extension `module.exports`. */
var moduleExports = freeModule && freeModule.exports === freeExports;

/** Detect free variable `process` from Node.js. */
var freeProcess = moduleExports && freeGlobal.process;

/** Used to access faster Node.js helpers. */
var nodeUtil = (function () {
  try {
    // Use `util.types` for Node.js 10+.
    // var types =
    //   freeModule &&
    //   freeModule['require'] &&
    //   freeModule['require']('util').types;
    //
    // if (types) {
    //   return types;
    // }

    // Legacy `process.binding('util')` for Node.js < 10.
    return freeProcess && freeProcess.binding && freeProcess.binding('util');
  } catch (e) {}
})();

export default nodeUtil;
