import {NgPatFirestoreService} from '@ngpat/firebase';
import {shuffle, uuid} from '@ngpat/fn';
import {
  NgPatAccountState,
  NgPatEntityStore,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector
} from '@ngpat/store';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {filter, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {Project} from '../../+project/index';
import {selectProjectByType} from '../../common.selectors';
import {firestoreQuizGradeByProject} from '../../firebase/index';
import {createInitialQuizResult, createTestByQuiz} from '../quiz.fns';
import {
  Question,
  QuestionWithAnswer,
  Quiz,
  TakeQuizAggregateMultiChoiceAnswers,
  TakeQuizResult
} from '../quiz.model';
import {
  aggregateAnswers,
  calculateProgressBasedOnTakeQuizResult,
  cloneInitialQuizTestProgress,
  QuizTestProgress
} from './quiz-test.fns';

export class QuizTest implements NgPatFirebaseConnectionService {
  showTestResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _aggregateMultiChoiceAnswers: TakeQuizAggregateMultiChoiceAnswers = {
    aggregateFalseAnswers: true,
    totalAnswers: 4
  };

  private _user$: BehaviorSubject<NgPatAccountState | null> =
    new BehaviorSubject<NgPatAccountState | null>(null);

  private user$: Observable<NgPatAccountState> = <Observable<NgPatAccountState>>(
    this._user$.asObservable().pipe(
      filter((user: NgPatAccountState | null) => {
        return user !== null && user !== undefined;
      })
    )
  );

  private _timeStart = 0;

  connectionKey = uuid();
  connection: NgPatServiceConnector = new NgPatServiceConnector(this, this.store);

  queue: NgPatEntityStore<QuestionWithAnswer> = new NgPatEntityStore(
    {},
    {
      selectId: (q: QuestionWithAnswer) => q.questionID
    }
  );

  selectIsLastEntitySelected$ = this.queue.selectIsLastEntitySelected$();
  selectIsLastEntitySelectedLast$ = this.selectIsLastEntitySelected$.pipe(take(1));

  currentQuestion$ = this.queue.selectedEntity$();

  result$: BehaviorSubject<TakeQuizResult> = new BehaviorSubject<TakeQuizResult>(
    createInitialQuizResult()
  );

  resultLast$: Observable<TakeQuizResult> = this.result$.pipe(take(1));

  pctComplete$: Observable<number> = this.result$.pipe(
    map((result: TakeQuizResult) => {
      const totalAnswers = Object.values(result.questions).length;
      const totalAnswered = Object.values(result.questions).filter(
        (q: QuestionWithAnswer) => q.isAnswered
      ).length;
      return Math.floor((totalAnswered / totalAnswers) * 100);
    })
  );

  progress$: BehaviorSubject<QuizTestProgress> = new BehaviorSubject(
    cloneInitialQuizTestProgress()
  );

  totalQuestions$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.totalQuestions)
  );

  numberAnswered$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.numberAnswered)
  );

  pctProgress$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.pctProgress)
  );

  pctCorrect$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.pctCorrect)
  );

  pctWrong$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.pctWrong)
  );

  currentPctScore$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.currentPctScore)
  );

  totalCorrect$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.totalCorrect)
  );

  totalWrong$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.totalWrong)
  );

  averageTimePerQuestionSeconds$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.averageTimePerQuestionSeconds)
  );

  averageTimePerQuestionMS$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.averageTimePerQuestionMS)
  );

  // averageTimePerQuestionMS: Signal<number> = computed(() => {
  //   return this.progress().averageTimePerQuestionMS;
  // });

  constructor(
    private store: Store,
    private customFirestoreService: NgPatFirestoreService,
    public quiz: Quiz,
    public questions: Question[]
  ) {
    this.initialize.call(this);
  }

  initialize() {
    const that = this;
    // this.questions.setMany(questions);
    this.createTestByQuiz()
      .pipe(take(1))
      .subscribe((r: TakeQuizResult) => {
        // New version logic in case quizzes where created without
        // this structure
        const aggregateFalseAnswers =
          this.quiz.aggregateFalseAnswers !== undefined && this.quiz.aggregateFalseAnswers !== null
            ? this.quiz.aggregateFalseAnswers
            : this._aggregateMultiChoiceAnswers.aggregateFalseAnswers;

        const totalAnswers =
          this.quiz.totalQuestions !== undefined && this.quiz.totalQuestions !== null
            ? this.quiz.totalQuestions
            : this._aggregateMultiChoiceAnswers.totalAnswers;

        if (aggregateFalseAnswers) {
          aggregateAnswers(Object.values(r.questions), <TakeQuizAggregateMultiChoiceAnswers>{
            aggregateFalseAnswers,
            totalAnswers
          });
        }

        that.queue.addMany(shuffle(Object.values(r.questions)));

        that.queue.selectFirstIdIfNoIdSelected();

        // that.result.set(r);
        that.result$.next(r);

        that._startTime();
      });
  }

  createTestByQuiz(): Observable<TakeQuizResult> {
    return this.user$.pipe(
      filter(user => !!user && this.questions.length > 0),
      map((user: NgPatAccountState) => {
        return createTestByQuiz(this.quiz, this.questions, <string>user.uid);
      }),
      take(1),
      switchMap((r: TakeQuizResult) => {
        // TODO change gto saveTest after test component is developed
        return of(r);
        // return this.saveTest(r);
      })
    );
  }

  saveTest(r: TakeQuizResult): Observable<TakeQuizResult> {
    return this.getResultPath(r).pipe(
      switchMap((path: string) => {
        return this.customFirestoreService.upsertDoc$(path, r).pipe(map(() => r));
      })
    );
  }

  private getResultPath(r: TakeQuizResult): Observable<string> {
    return this.user$.pipe(
      withLatestFrom(this.getResultProject()),
      map(([user, project]: [NgPatAccountState, Quiz]) => {
        return firestoreQuizGradeByProject(project as Project, <string>user.uid, r.id);
      })
    );
  }

  private getResultProject(): Observable<Quiz> {
    if (
      this.quiz.parentProjectID !== null &&
      this.quiz.parentProjectID !== undefined &&
      this.quiz.parentProjectType !== null &&
      this.quiz.parentProjectType !== undefined
    ) {
      return this.store.pipe(
        select(selectProjectByType(this.quiz.parentProjectType, this.quiz.parentProjectID)),
        take(1)
      );
    } else {
      return of(this.quiz);
    }
  }

  nextQuestion() {
    this.queue.next();
  }

  previousQuestion() {
    this.queue.previous();
  }

  onAnswer(e: QuestionWithAnswer) {
    // console.log('onAnswer', e);

    // const r = this.result();

    combineLatest([this.resultLast$, this.selectIsLastEntitySelectedLast$])
      .pipe(take(1))
      .subscribe(([r, isLastEntitySelected]: [TakeQuizResult, boolean]) => {
        if (r) {
          e.timeToAnswerMS = this._stopTime();
          r.questions[e.questionID] = e;
          // this.result.set(r);
          this.result$.next(r);

          this.progress$.next(calculateProgressBasedOnTakeQuizResult(r));

          this.saveTest(this.result$.value).subscribe(() => {
            /* noop */
          });
        }

        if (isLastEntitySelected) {
          // this.showTestResults.set(true);
          this.showTestResults$.next(true);
        } else {
          this.queue.next();
        }
      });
  }

  private _startTime() {
    this._timeStart = Date.now().valueOf();
  }

  private _stopTime() {
    return Date.now().valueOf() - this._timeStart;
  }

  onConnect(user: NgPatAccountState) {
    this._user$.next(user);
  }

  onDisconnect() {
    // TODO should the quiz be paused?
  }

  onDestroy() {
    this.connection.destroy();
  }
}
