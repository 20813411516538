import {ImageTransform} from './image-cropper/index';

export function initalImageState(): ImageTransform {
  return {
    translateUnit: 'px',
    rotate: 0,
    scale: 1,
    flipH: false,
    flipV: false,
    translateH: 0,
    translateV: 0
  };
}
