import {NgPatRemoteConfigEntity} from '@ngpat/store';
import {CodeName} from './dlc-input-code.model';

/**
 * Convert NgPatRemoteConfigEntity[] to CodeName[]
 */
export function convertRemoteConfigToCodeName(configs: NgPatRemoteConfigEntity[]): CodeName[] {
  return configs.map(config => {
    console.log(config);
    return {
      name: config.id,
      enabled: config.value.enabled,
      description: config.value.description
    };
  });
}
