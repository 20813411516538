<h1 mat-dialog-title>AI Creates Questions</h1>
<div mat-dialog-content>
  <form class="block" [formGroup]="openAiForm" *ngIf="!showSpinner()">
    <!-- OPEN AI MESSAGE -->
    <mat-form-field class="block mt-8 mb-2 w-full" appearance="outline">
      <mat-label>AI Quiz Generator Instruction</mat-label>
      <textarea
        matInput
        [formControl]="message"
        placeholder="Enter instructions for the AI to generate questions"
        rows="5">
      </textarea>
      <mat-error *ngIf="message.invalid && message.dirty">Message is required</mat-error>
    </mat-form-field>

    <div class="mb-8 leading-normal text-sm">
      Use the form below to create the AI instruction for your quiz. The AI will generate questions
      based on this instruction.
    </div>

    <!-- NAME -->
    <mat-form-field class="mb-2 w-full" appearance="outline">
      <mat-label>Quiz Name</mat-label>
      <input matInput formControlName="name" placeholder="Quiz Name" />
    </mat-form-field>

    <!-- DESCRIPTION -->
    <mat-form-field class="mb-2 w-full" appearance="outline">
      <mat-label>Quiz Description</mat-label>
      <input matInput formControlName="description" placeholder="Quiz description" />
    </mat-form-field>

    <!-- GRADE LEVELS -->
    <mat-form-field class="mb-2 w-full" appearance="outline">
      <mat-label>Quiz Grade Level</mat-label>
      <mat-select formControlName="gradeLevel">
        @for (gradeLevel of gradeLevels; track gradeLevel) {
        <mat-option [value]="gradeLevel">{{ gradeLevel.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <!-- END GRADE LEVELS -->

    <!-- SUBJECTS -->
    <mat-form-field class="w-full" appearance="outline" floatLabel="always">
      <mat-label>Quiz Subjects</mat-label>
      <mat-chip-grid #chipGrid aria-label="Fruit selection">
        @for (subject of algoliaSubjectAutocomplete.selectedViewValues; track subject) {
        <mat-chip-row (removed)="algoliaAutoCompleteRemove(subject)">
          {{ subject }}
          <button matChipRemove [attr.aria-label]="'remove ' + subject">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        }
      </mat-chip-grid>
      <input
        placeholder="math, science, chemistry, history"
        #subjectInput
        formControlName="subjects"
        [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="algoliaAutoCompleteAdd($event)" />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="algoliaAutoCompleteSelect($event)">
        @for (fruit of filteredSubjects | async; track fruit) {
        <mat-option [value]="fruit">{{ fruit.name }}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <!-- END SUBJECTS -->
  </form>

  <div class="w-full" *ngIf="showSpinner()">
    <div class="flex flex-col justify-start items-start mb-8">
      <div class="mb-8">Creating Questions...</div>
      <mat-spinner class="self-center" mode="indeterminate"></mat-spinner>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button dlc-rounded-text-icon-button [disabled]="showSpinner()" class="mr-4" mat-dialog-close>
    <mat-icon>close</mat-icon>
    Close
  </button>

  <button
    dlc-rounded-text-icon-button
    [disabled]="(message.invalid && message.dirty) || showSpinner()"
    (click)="generateQuizQuestions()"
    cdkFocusInitial>
    <mat-icon class="material-symbols-outlined">prompt_suggestion</mat-icon>
    Generate
  </button>
</div>
