import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {animationDelay, animationDuration, EASE} from './animation.constants';

export const showElementAnimation: AnimationTriggerMetadata = trigger(
  'showElement',
  [
    transition(':enter', [
      style({
        opacity: 0,
        position: 'relative',
        top: 0,
        width: '100%',
        height: '100%'
      }),
      animate(
        `${animationDuration} ${animationDelay} ease-out`,
        style({opacity: 1, width: '100%', height: '100%'})
      )
      // animate('500ms 550ms ease-out' , style({ opacity: 1, width: '100%', height: '100%'}))
    ]),
    transition(':leave', [
      style({
        opacity: 1,
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%'
      }),
      animate(`${animationDuration} ease-in`, style({opacity: 0}))
    ])
  ]
);

export const expandEnterAnimation: AnimationTriggerMetadata = trigger(
  'expandEnterAnimation',
  [
    transition(':enter', [
      style({
        opacity: 0,
        width: '100%',
        height: '0px',
        minHeight: '0px'
      }),
      animate(`${animationDuration} ease-out`, style({opacity: 1, height: '*'}))
      // animate('500ms 550ms ease-out' , style({ opacity: 1, width: '100%', height: '100%'}))
    ]),
    transition(':leave', [
      style({
        opacity: 1,
        top: 0
        // height: '100%'
      }),
      animate(`${animationDuration} ease-out`, style({opacity: 0, height: 0}))
    ])
  ]
);

export const showElementDelayAnimation: AnimationTriggerMetadata = trigger(
  'showElementDelay',
  [
    transition(':enter', [
      style({opacity: 0, height: 0}),
      animate(
        `${animationDuration} ${animationDelay} ease-out`,
        style({opacity: 1, height: '100%'})
      )
    ]),
    transition(':leave', [
      animate(`${animationDuration} ease-in`, style({opacity: 0, height: 0}))
    ])
  ]
);

export const detailExpand = trigger('detailExpand', [
  state('collapsed', style({height: '0px', minHeight: '0'})),
  state('expanded', style({height: '*'})),
  transition(
    'expanded <=> collapsed',
    animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
  )
]);

export const slideDownAnimations: any[] = [
  trigger('slideDown', [
    state(
      'false',
      style({
        transform: 'translateY(-100%)'
        // height: 'calc(100vh - 64px)',
      })
    ),

    state(
      'true',
      style({
        transform: 'translateY(0)'
      })
    ),

    transition('false <=> true', animate(EASE))
  ])
];
