import {Injectable} from '@angular/core';
import {avg} from '@ngpat/calculations';
import {
  FirestoreCollectionQueryFactoryConfig,
  NgPatFirestoreCollectionQuery,
  ngPatFirestoreCollectionQueryFactory,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {
  aggregateUpdates,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector,
  selectNgPatLoggedInUID
} from '@ngpat/store';
import {ComponentStore} from '@ngrx/component-store';
import {createEntityAdapter, EntityState, Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';
import {Classroom, StudentGrade, StudentGrades, StudentGradesTableData} from '../../+classrooms';
import {highestQuizGradeByStudent} from '../../+classrooms/class-grades/class-grades.fns';
import {MemberListItem} from '../../+members/members.model';
import {getStudentsByProjectID} from '../../+members/members.selectors';
import {Project} from '../../+project/project.model';
import {firestoreQuizGradesByProject} from '../../firebase/database-paths';
import {selectHasActiveSubscription} from '../../subscription/subscription.selectors';
import {calculateGrade} from '../quiz.fns';
import {Quiz, TakeQuizResult} from '../quiz.model';
import {getQuizAssignedByParentID, getQuizzesByParentID} from '../quiz.selectors';
import {memberQuizMap, MemberQuizMap} from './class-grades.fns';

export interface ClassGradesState extends EntityState<TakeQuizResult> {
  classroom: Classroom | null;
}

/**
 * The rest of the columns are
 * dynamically created by Quiz names
 */
export enum COLUMNS {
  AVERAGE = 'Highest Grade'
}

@Injectable()
export class ClassGradesService
  extends ComponentStore<ClassGradesState>
  implements NgPatFirebaseConnectionService
{
  private _onDestroy$: Subject<boolean> = new Subject();
  private _adapter = createEntityAdapter<TakeQuizResult>();
  private connectorRegistered = false;
  private _isConnected$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _path$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _user$: ReplaySubject<NgPatAccountState> = new ReplaySubject<NgPatAccountState>(1);
  private _classroom$: ReplaySubject<Classroom> = new ReplaySubject<Classroom>(1);

  private _queryFactory: FirestoreCollectionQueryFactoryConfig<TakeQuizResult>;
  private _queryCache: {
    [path: string]: NgPatFirestoreCollectionQuery<TakeQuizResult>;
  } = {};

  studentGrades$: Observable<StudentGrades[]>;
  studentGradesTableData$: Observable<StudentGradesTableData[]>;
  displayedColumns$: Observable<string[]>;

  connectionKey = null;
  connection: NgPatServiceConnector = new NgPatServiceConnector(this, this.store);

  constructor(private store: Store, private customFirestoreService: NgPatFirestoreService) {
    super(
      createEntityAdapter<TakeQuizResult>().getInitialState({
        classroom: null
      })
    );

    const that = this;
    this._queryFactory = ngPatFirestoreCollectionQueryFactory<TakeQuizResult>(
      {
        queryMember: false,
        upsertManyUpdater: (questions: TakeQuizResult[]) => that.upsertMany(questions),
        updateManyUpdater: (questions: TakeQuizResult[]) =>
          that.updateMany(aggregateUpdates(questions)),
        deleteManyUpdater: (ids: string[]) => that.deleteMany(ids)
        // logUpsert: true
      },
      store,
      customFirestoreService
    );

    combineLatest([this._isConnected$, this._user$, this._classroom$])
      .pipe(
        switchMap(
          ([isConnected, user, classroom]: [boolean, NgPatAccountState, Classroom]): Observable<
            [boolean, NgPatAccountState, string[]]
          > => {
            return this.store.pipe(
              select(getQuizzesByParentID(classroom.id)),
              map((quizList: Quiz[]) => {
                const paths: string[] = quizList.map((quiz: Quiz) =>
                  firestoreQuizGradesByProject(quiz as Project, <string>user.uid)
                );

                return [isConnected, user, paths];
              })
            );
          }
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([isConnected, user, paths]: [boolean, NgPatAccountState, string[]]) => {
        if (isConnected) {
          const pathSet: Set<string> = new Set(paths);

          // Add Query connections
          for (let q = 0; q < paths.length; q++) {
            const path: string = paths[q];
            if (!that._queryCache[path]) {
              that._queryCache[path] = that._queryFactory.createFirestoreCollectionQuery();
              that._queryCache[path].onConnect(path, null, user.uid);
            }
          }

          // remove unused query connections
          Object.keys(that._queryCache).forEach((key: string) => {
            if (!pathSet.has(key)) {
              that._queryCache[key].onDisconnect(<string>user.uid);
              delete that._queryCache[key];
            }
          });
        }
      });

    this.studentGrades$ = this._classroom$.pipe(
      switchMap((c: Classroom) =>
        combineLatest([
          this.store.pipe(select(getStudentsByProjectID(c.id))),
          this.store.pipe(select(getQuizzesByParentID(c.id))),
          this.store.pipe(select(selectHasActiveSubscription)),
          this.store.pipe(select(selectNgPatLoggedInUID)),
          this._allGrades$
        ]).pipe(
          takeUntil(this._onDestroy$),
          map(
            ([members, quizzes, loggedInUserIsStudent, loggedInUID, allClassGrades]: [
              MemberListItem[],
              Quiz[],
              boolean,
              string | null,
              TakeQuizResult[]
            ]): StudentGrades[] => {
              // console.log('quizzes', quizzes);

              return (
                members
                  // .filter((m: MemberListItem) => {
                  //   return loggedInUserIsStudent ? m.uid === loggedInUID : true;
                  // })
                  .map((m: MemberListItem) => {
                    const quizMap: MemberQuizMap = memberQuizMap(quizzes);

                    const studentGrades: StudentGrades = {
                      uid: m.uid,
                      student: m.member,
                      grades: []
                    };

                    for (const memberQuizMapValue of quizMap.values()) {
                      const attempts = allClassGrades.filter(
                        (r: TakeQuizResult) => r.createdByUID === m.uid
                      ).length;

                      const grade: StudentGrade = {
                        uid: m.uid,
                        student: m.member,
                        result: highestQuizGradeByStudent(
                          studentGrades.student,
                          allClassGrades.filter(
                            (a: TakeQuizResult) => a.quiz.id === memberQuizMapValue.quizID
                          )
                        ),
                        quizTaken: attempts > 0,
                        quizID: memberQuizMapValue.quizID,
                        quizName: memberQuizMapValue.name,
                        attempts
                      };

                      studentGrades.grades.push(grade);
                    }

                    return studentGrades;
                  })
              );
            }
          )
        )
      )
    );

    this.studentGradesTableData$ = this.studentGrades$.pipe(
      map((grades: StudentGrades[]) =>
        grades.map((g: StudentGrades) => {
          const _g: StudentGradesTableData = {
            student: g.student.username
          };

          const grades: number[] = [];
          for (let i = 0; i < g.grades.length; i++) {
            const _grade: StudentGrade = g.grades[i];
            if (_grade && _grade.quizID) {
              _g[`${_grade.quizName}`] =
                _grade.attempts === 0
                  ? 0
                  : calculateGrade(
                      _grade?.result?.totalCorrect,
                      Object.keys(_grade?.result?.result?.questions || {})?.length
                    );

              grades.push(parseFloat(<string>_g[`${_grade.quizName}`]));
            }
          }

          _g[COLUMNS.AVERAGE] = avg(grades).toFixed(0);

          return _g;
        })
      )
    );

    this.displayedColumns$ = this._classroom$.pipe(
      switchMap((c: Classroom) =>
        this.store.pipe(
          select(getQuizAssignedByParentID(c.id)),
          map((q: Quiz[]) => ['student', ...q.map((_q: Quiz) => _q.name), COLUMNS.AVERAGE])
        )
      )
    );
  }

  private _allGrades$: Observable<TakeQuizResult[]> = this.select((state: ClassGradesState) => {
    const {selectAll} = this._adapter.getSelectors();

    return selectAll(state);
  });

  readonly selectClassQuizzes$: Observable<Classroom | null> = this.select(
    (state: ClassGradesState) => state.classroom
  );

  readonly upsertMany = this.updater((state, questions: TakeQuizResult[]) => {
    return this._adapter.upsertMany(questions, state);
  });

  readonly updateMany = this.updater((state, questions: Update<TakeQuizResult>[]) => {
    return this._adapter.updateMany(questions, state);
  });

  readonly deleteMany = this.updater((state, ids: string[]) =>
    this._adapter.removeMany(ids, state)
  );

  readonly addClassroom = this.updater(
    (state: EntityState<TakeQuizResult>, classroom: Classroom) => {
      return {
        ...state,
        classroom
      };
    }
  );

  init(q: Classroom) {
    this.addClassroom(q);
    this._classroom$.next(q);

    this.connection.setConnectionKey(this.getKey(q));
  }

  onConnect(user: NgPatAccountState) {
    if (user.uid) {
      this._isConnected$.next(true);
      // const _path = firestoreQuizGradesByProject(this.quiz, user.uid);
      // this._path$.next(_path);
      this._user$.next(user);
    }
  }

  onDisconnect(user: NgPatAccountState) {
    const that = this;

    this.connection.deleteKey();

    that._isConnected$.next(false);
  }

  onDestroy() {
    this._onDestroy$.next(true);
    this.connection.destroy();
    this._user$.pipe(take(1)).subscribe((user: NgPatAccountState) => {
      this.onDisconnect(user);
    });
  }

  private getKey(q: Classroom) {
    return `${q.id}-class-grades`;
  }
}
