import {selectNgPatStripeSubscriptionsIsInit} from '@ngpat/store';
import {createSelector} from '@ngrx/store';
import {selectUiStateIsLoaded} from '../+ui/ui.selectors';

/**
 * Init must be handled at app level because with multiple apps,
 * can't be sure which remote configs to listen for changes.
 */
export const storesReadyForRemoteConfig = createSelector(
  selectNgPatStripeSubscriptionsIsInit,
  selectUiStateIsLoaded,
  (subscriptionIsInit: boolean, uiStateIsLoaded: boolean): boolean =>
    subscriptionIsInit && uiStateIsLoaded
);
