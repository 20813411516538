import {Injectable, signal, WritableSignal} from '@angular/core';
import {CopyAsset} from '@gigasoftware/shared/entities';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {NgPatProcessQueue} from '@ngpat/utils';
import {
  deleteObject,
  getBlob,
  getDownloadURL,
  listAll,
  ref,
  StorageReference,
  uploadBytesResumable
} from 'firebase/storage';
import {distinctUntilChanged} from 'rxjs';
import {resizeImageToStandardSizes} from '../image/resize';
import {
  getThumbnailPathFromImagePath,
  getTotalBytesFromResizedImages,
  GsImageThumbails,
  GSStorageAssetBlob,
  MAX_IMAGE_SIZES
} from '../image/sizes';
import {getExtensionByBlobType} from '../utils/file-type-path-match';
import {GsFirestoreUpdateDoc} from './entities';

export interface GSFirebaseUploadImageConfig {
  filenameWithoutExtension: string;

  /**
   * The base directory in Firebase Storage where the images will be uploaded.
   *
   */
  baseImageDirectory: string;

  /**
   * Image name is directory name + image name + image extension.
   */
  imageNameIsParentDirectory: boolean;

  /**
   * The maximum sizes of the images to upload.
   */
  maxImageSizes: number[];
}

export interface GSFirebaseUploadImageWithDocConfig extends GSFirebaseUploadImageConfig {
  /**
   * Firestore doc config to update after
   * asset is uploaded or deleted.
   */
  firestoreDoc: GsFirestoreUpdateDoc | null;
}

export const defaultImageUploaderConfig: GSFirebaseUploadImageWithDocConfig = {
  filenameWithoutExtension: '',
  baseImageDirectory: 'images',
  imageNameIsParentDirectory: true,
  maxImageSizes: [600, 400, 200, 100, 50],
  firestoreDoc: null
};

export interface GSAssetUploadProgress {
  status: 'uploading' | 'complete' | 'error';
  totalBytes: number;
  bytesTransferred: number;
  percentage: number;
}

export interface GSUploadProgressQueueItem {
  name: string;
  storageRef: StorageReference;
  storageAssetBlob: GSStorageAssetBlob;
  // Base file name without extension and size suffix
  // To track the progress of the upload all sized images
  baseFileName: string;

  // The percentage of the total bytes that this image takes up.
  percentTotalBytes: number;

  // The accumulated bytes of the previous images
  // used to calculate the progress of the current image.
  // plus the accumulated bytes of the previous images.
  accumulatedBytes: number;
}

/**
 * Params to update a document in firestore
 * with the path of the uploaded asset.
 */

@Injectable({
  providedIn: 'root'
})
export class GsAssetService {
  private uploadAssetProcessQueue: NgPatProcessQueue<GSUploadProgressQueueItem> =
    new NgPatProcessQueue<GSUploadProgressQueueItem>();

  // totalUploadBytes: WritableSignal<number> = signal(0);
  uploadProgress: WritableSignal<number> = signal(0);
  isInProgress: WritableSignal<boolean> = signal(false);

  constructor(private customFirestore: NgPatFirestoreService) {
    this.uploadAssetProcessQueue.isProcessing$
      .pipe(distinctUntilChanged())
      .subscribe((isProcessing: boolean) => {
        this.isInProgress.set(isProcessing);
      });

    this.uploadAssetProcessQueue.findFn = (
      a: GSUploadProgressQueueItem,
      b: GSUploadProgressQueueItem
    ) => {
      return a.name === b.name;
    };

    this.uploadAssetProcessQueue.currentItem$.subscribe((item: GSUploadProgressQueueItem) => {

      // TODO upload the item
      if (item.storageAssetBlob.blob) {
        const uploadTask = uploadBytesResumable(item.storageRef, item.storageAssetBlob.blob);

        uploadTask.on(
          'state_changed',
          snapshot => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded

            const {percentTotalBytes, accumulatedBytes} = item;

            const progress =
              ((snapshot.bytesTransferred / snapshot.totalBytes) * percentTotalBytes +
                accumulatedBytes) *
              100;

            switch (snapshot.state) {
              case 'paused':
                // console.log('Upload is paused');
                break;
              case 'running':
                this.uploadProgress.set(progress);
                // console.log('Upload is running');
                break;
            }
          },
          error => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }

            this.uploadProgress.set(0);
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            // getDownloadURL(uploadTask.snapshot.ref).then(() => {
            // console.log('File available at', downloadURL);
            // });

            this.uploadAssetProcessQueue.next();
          }
        );
      }
    });
  }

  /**
   * Uploads an image to Firebase Storage.
   *
   * Returns the path of the original image uploaded.
   *
   * @param file The image file to upload.
   * @param filenameWithoutExtension The name of the file to upload in the format of `filename`.
   * @param uploadConfig
   */
  async uploadImages(
    file: File | Blob,
    filenameWithoutExtension: string,
    uploadConfig: Partial<GSFirebaseUploadImageWithDocConfig>
  ): Promise<string> {
    // const filenameParts: FileNameParts = getFileNameParts(filenameWithoutExtention);
    this.uploadProgress.set(0);
    this.isInProgress.set(true);

    const _uploadConfig: GSFirebaseUploadImageWithDocConfig = {
      ...defaultImageUploaderConfig,
      ...uploadConfig,
      filenameWithoutExtension
    };

    const extension: string = getExtensionByBlobType(file);

    const originalImageSize = {
      filenameWithoutExtension,
      filenameExtension: extension,
      blob: file
    };

    const resizedImages: GSStorageAssetBlob[] = await resizeImageToStandardSizes(
      file,
      filenameWithoutExtension,
      extension,
      _uploadConfig.maxImageSizes || MAX_IMAGE_SIZES
    );

    resizedImages.unshift(originalImageSize);

    const totalUploadBytes = getTotalBytesFromResizedImages(resizedImages);
    // this.totalUploadBytes.set(getTotalBytesFromResizedImages(resizedImages));

    /**
     * Get firestore storage references
     */

    let refsAccumalated: {list: GSUploadProgressQueueItem[]; accumulatedBytes: number} = {
      list: [],
      accumulatedBytes: 0
    };

    refsAccumalated = resizedImages.reduce(
      (
        acc: {list: GSUploadProgressQueueItem[]; accumulatedBytes: number},
        resizedImage: GSStorageAssetBlob
      ) => {
        const uploadPath = this.getUploadPath(
          _uploadConfig,
          filenameWithoutExtension,
          resizedImage.filenameWithoutExtension,
          resizedImage.filenameExtension
        );

        acc.list.push(<GSUploadProgressQueueItem>{
          name: resizedImage.filenameWithoutExtension,
          storageRef: this.getStorageRef(uploadPath),
          storageAssetBlob: resizedImage,
          accumulatedBytes: acc.accumulatedBytes,
          percentTotalBytes: (resizedImage.blob?.size || 0) / totalUploadBytes
        });

        acc.accumulatedBytes += resizedImage.blob?.size || 0;

        return acc;
      },
      refsAccumalated
    );

    return new Promise(resolve => {
      this.uploadAssetProcessQueue.addItems(refsAccumalated.list, () => {
        // TODO update firestore doc

        const uploadPath = this.getUploadPath(
          _uploadConfig,
          filenameWithoutExtension,
          filenameWithoutExtension,
          extension
        );

        // Update doc property with the path of the uploaded asset.

        const docProperty: string | undefined = _uploadConfig.firestoreDoc?.docProperty;

        const firestoreDocPath: string | null | undefined =
          _uploadConfig.firestoreDoc?.firestoreDocPath;

        if (docProperty && firestoreDocPath) {
          const mergeDoc: {[key: string]: string} = {
            [docProperty]: uploadPath
          };

          this.customFirestore.merge$(firestoreDocPath, mergeDoc).subscribe({
            next: () => {
              resolve(uploadPath);
            }
          });
        }
      });
    });
  }

  /**
   * Return the download URLs of the image thumbnails.
   * @param imagePath
   */
  async getImagesThumbnailPathsAsDownloadUrls(
    imagePath: string | null | undefined
  ): Promise<GsImageThumbails> {
    const thumbnailPaths: GsImageThumbails = {
      imagePath600x600: '',
      imagePath400x400: '',
      imagePath200x200: '',
      imagePath100x100: '',
      imagePath50x50: ''
    };

    if (imagePath) {
      const paths: {size: number; storagePath: string}[] = await Promise.all<{
        size: number;
        storagePath: string;
      }>(
        MAX_IMAGE_SIZES.map((size: number) => {
          return new Promise(resolve => {
            this.getDownloadUrl(getThumbnailPathFromImagePath(imagePath, size)).then(
              (storagePath: string) => {
                resolve({
                  size,
                  storagePath: decodeURI(storagePath)
                });
              }
            );
          });
        })
      );

      paths.forEach(({size, storagePath}) => {
        // console.log('size', size, 'storagePath', storagePath);
        switch (size) {
          case 600:
            thumbnailPaths.imagePath600x600 = storagePath;
            break;
          case 400:
            thumbnailPaths.imagePath400x400 = storagePath;
            break;
          case 200:
            thumbnailPaths.imagePath200x200 = storagePath;
            break;
          case 100:
            thumbnailPaths.imagePath100x100 = storagePath;
            break;
          case 50:
            thumbnailPaths.imagePath50x50 = storagePath;
            break;
        }
      });
    }

    return thumbnailPaths;
  }

  /**
   * Return Firebase Storage path of image thumbnail sizes.
   * @param imagePath
   */
  getImagesThumbnailStoragePaths(imagePath: string | null | undefined): GsImageThumbails {
    const thumbnailPaths: GsImageThumbails = {
      imagePath600x600: '',
      imagePath400x400: '',
      imagePath200x200: '',
      imagePath100x100: '',
      imagePath50x50: ''
    };

    if (imagePath) {
      MAX_IMAGE_SIZES.map((size: number) => {
        return {
          size,
          storagePath: getThumbnailPathFromImagePath(imagePath, size)
        };
      }).forEach(({size, storagePath}: {size: number; storagePath: string}) => {
        switch (size) {
          case 600:
            thumbnailPaths.imagePath600x600 = storagePath;
            break;
          case 400:
            thumbnailPaths.imagePath400x400 = storagePath;
            break;
          case 200:
            thumbnailPaths.imagePath200x200 = storagePath;
            break;
          case 100:
            thumbnailPaths.imagePath100x100 = storagePath;
            break;
          case 50:
            thumbnailPaths.imagePath50x50 = storagePath;
            break;
        }
      });
    }

    return thumbnailPaths;
  }

  private getStorageRef(filePath: string): StorageReference {
    return ref(this.customFirestore.storage, filePath);
  }

  /**
   * Get the upload path for the image.
   * @paran config - The configuration for the image upload.
   * @param assetSetDirectory - The directory where the image set will be uploaded.
   * This will be the filenameWithoutExtension of the base image before resizing.
   * @param filenameWithoutExtension - the resized image name without the extension
   * @param extension - the extension of the image
   * @private
   */
  private getUploadPath(
    config: GSFirebaseUploadImageWithDocConfig,
    assetSetDirectory: string,
    filenameWithoutExtension: string,
    extension: string
  ): string {
    const baseSetDirectory = this.getBaseAssetDirectory(config, assetSetDirectory);

    return `${baseSetDirectory}/${filenameWithoutExtension}.${extension}`;
  }

  /**
   * Get the base directory for the image set.
   * This includes the firebase storage directory and the image set directory.
   *
   * @param config
   * @param assetSetDirectory - normally for images, this is the filenameWithoutExtension of the base image before resizing.
   * @private
   */
  private getBaseAssetDirectory(
    config: GSFirebaseUploadImageWithDocConfig,
    assetSetDirectory: string
  ): string {
    const {baseImageDirectory, imageNameIsParentDirectory} = config;
    if (imageNameIsParentDirectory) {
      return `${baseImageDirectory}/${assetSetDirectory}`;
    }

    return `${baseImageDirectory}`;
  }

  getDownloadUrl(imagePath: string): Promise<string> {
    const imageRef = ref(this.customFirestore.storage, imagePath);
    return getDownloadURL(imageRef);
  }

  downloadBlob(imagePath: string): Promise<Blob> {
    const imageRef = ref(this.customFirestore.storage, imagePath);
    return getBlob(imageRef);
  }

  /**
   * Deletes the directory of a set of assets.
   * @param filenameWithoutExtension
   * @param config
   */
  deleteAssetSet(
    filenameWithoutExtension: string,
    config: Partial<GSFirebaseUploadImageWithDocConfig>
  ) {
    const _config = {
      ...defaultImageUploaderConfig,
      ...config
    };

    const baseSetDirectory = this.getBaseAssetDirectory(_config, filenameWithoutExtension);

    const assetSetRef = ref(this.customFirestore.storage, baseSetDirectory);

    // return deleteObject(assetSetRef);
    return listAll(assetSetRef).then(res =>
      Promise.allSettled(res.items.map(itemRef => deleteObject(itemRef)))
    );
  }

  deleteAssets(imagePaths: string[]): Promise<PromiseSettledResult<void>[]> {
    return Promise.allSettled(
      imagePaths.map(imagePath => {
        const imageRef = ref(this.customFirestore.storage, imagePath);
        return deleteObject(imageRef);
      })
    );
  }

  /**
   * Used primarily for deleting image sets, where multiple image sizes
   * of the same image are uploaded
   * into one directory in Firebase Storage.
   * @param imagePath
   */
  getBaseAssetDirectoryFromPath(imagePath: string): string {
    const parts = imagePath.split('/');
    parts.pop();
    return parts.join('/');
  }

  /**
   * Get the list of directories in Firebase Storage directory
   * @param baseDirectory
   */
  getDirectoryList(baseDirectory: string): Promise<string[]> {
    const ref = this.getStorageRef(baseDirectory);
    return listAll(ref).then(res => res.items.map(p => p.fullPath));
  }

  /**
   * Delete image set based on the image path.
   * The steps are:
   * 1. Get the base directory of the image set.
   * 2. Get the list of images in that directory.
   * 3. Delete the images.
   * @param paths
   */
  async deleteImagesInSetBasedOnImagePath(
    imagePath: string
  ): Promise<PromiseSettledResult<void>[]> {
    const baseImageDirectory = this.getBaseAssetDirectoryFromPath(imagePath);

    const directoryList: string[] = await this.getDirectoryList(baseImageDirectory);

    return this.deleteAssets(directoryList);
  }

  copyImageAssets(paths: CopyAsset[]): Promise<boolean> {
    return this.customFirestore.copyImageAssets(paths);
  }
}
