<mat-dialog-content>
  <div>
    <span class="text-[--dlc-color-primary]">{{ data.joinCode }}</span> Copied
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    dlc-rounded-text-icon-button
    color="primary"
    [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
    Close
  </button>
</mat-dialog-actions>
