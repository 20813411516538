import {createAction, props} from '@ngrx/store';
import {Update, EntityMap, EntityMapOne} from '@ngrx/entity';

import {Course} from './course.model';

export const addCourse = createAction(
  '[Course/API] Add Course',
  props<{course: Course}>()
);

export const setCourse = createAction(
  '[Course/API] Set Course',
  props<{course: Course}>()
);

export const upsertCourse = createAction(
  '[Course/API] Upsert Course',
  props<{course: Course}>()
);

export const addCourses = createAction(
  '[Course/API] Add Courses',
  props<{courses: Course[]}>()
);

export const upsertCourses = createAction(
  '[Course/API] Upsert Courses',
  props<{courses: Course[]}>()
);

export const updateCourse = createAction(
  '[Course/API] Update Course',
  props<{course: Update<Course>}>()
);

export const updateCourses = createAction(
  '[Course/API] Update Courses',
  props<{courses: Update<Course>[]}>()
);

export const mapCourse = createAction(
  '[Course/API] Map Course',
  props<{entityMap: EntityMapOne<Course>}>()
);

export const mapCourses = createAction(
  '[Course/API] Map Courses',
  props<{entityMap: EntityMap<Course>}>()
);

export const deleteCourse = createAction(
  '[Course/API] Delete Course',
  props<{id: string}>()
);

export const deleteCourses = createAction(
  '[Course/API] Delete Courses',
  props<{ids: string[]}>()
);

export const loadCourses = createAction(
  '[Course/API] Load Courses',
  props<{courses: Course[]}>()
);

export const setCourses = createAction(
  '[Course/API] Set Courses',
  props<{courses: Course[]}>()
);

export const clearCourses = createAction('[Course/API] Clear Courses');

export const selectCourseID = createAction(
  '[Course/API] Select Course',
  props<{id: string}>()
);
