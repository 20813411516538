import { OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DlcCommonModule } from '../core/common-behaviors/common-module';
import { DlcOptionModule } from '../core/option/index';
import { DLC_SELECT_SCROLL_STRATEGY_PROVIDER, DlcSelect, DlcSelectTrigger } from './select';
import { DlcFormFieldModule } from '../form-field/form-field.module';

@NgModule({
  imports: [CommonModule, OverlayModule, DlcOptionModule, DlcCommonModule],
  exports: [
    CdkScrollableModule,
    DlcFormFieldModule,
    DlcSelect,
    DlcSelectTrigger,
    DlcOptionModule,
    DlcCommonModule,
  ],
  declarations: [DlcSelect, DlcSelectTrigger],
  providers: [DLC_SELECT_SCROLL_STRATEGY_PROVIDER],
})
export class DlcSelectModule {}
