import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {
  DlcRoundedTextButtonComponent,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {CommonModule} from '@angular/common';

export interface ConfirmCreateReviewQuiz {
  quizName: string;
}

@Component({
  selector: 'ec-plat-confirm-create-review-quiz',
  templateUrl: './ec-confirm-create-review-quiz.component.html',
  styleUrls: ['./ec-confirm-create-review-quiz.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    DlcRoundedTextIconButtonComponent,
    DlcRoundedTextButtonComponent
  ]
})
export class EcConfirmCreateReviewQuizComponent {
  constructor(
    public dialogRef: MatDialogRef<EcConfirmCreateReviewQuizComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmCreateReviewQuiz
  ) {}
}
