import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector:
    'dlc-google-play-store-button,[dlc-google-play-store-button],[dlcGooglePlayStoreButton]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './google-play-store.component.html',
  styleUrl: './google-play-store.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'dlc-google-play-store-button',
    '[style.width]': 'calculatedWidth',
    '[style.height]': 'calculatedHeight'
  }
})
export class DlcGooglePlayStoreComponent {
  private _buttonWidth = 646;
  private _buttonHeight = 250;

  @Input() targetHeight = 58;

  get calculatedWidth(): string {
    // Calculate the width based on the target height
    return `${(this._buttonWidth / this._buttonHeight) * this.targetHeight}px`;
  }

  get calculatedHeight(): string {
    return `${this.targetHeight}px`;
  }
}
