import { Directive, InjectionToken, Input } from '@angular/core';

export const DLC_PREFIX = new InjectionToken<DLCPrefixDirective>('DLCPrefixDirective');

/** Prefix to be placed in front of the form field. */
@Directive({
  selector: '[dlcPrefix], [dlcIconPrefix], [dlcTextPrefix]',
  // standalone: true,
  providers: [{provide: DLC_PREFIX, useExisting: DLCPrefixDirective}],
})
export class DLCPrefixDirective {
  @Input('dlcTextPrefix')
  set _isTextSelector(value: '') {
    this._isText = true;
  }

  _isText = false;
}
