<form [formGroup]="classroomFormGroup" class="create-class-form-fields">
  <dlc-form-field class="mb-8 w-full" appearance="outline">
    <dlc-label>Class Name</dlc-label>
    <input
      dlcInput
      formControlName="name"
      placeholder="Class name (required)" />
    <dlc-error *ngIf="classroomFormGroup.get('name')?.hasError('required')">
      Required
    </dlc-error>
  </dlc-form-field>

  <dlc-form-field class="mb-8 w-full" appearance="outline">
    <dlc-label>Section</dlc-label>
    <input dlcInput formControlName="section" placeholder="Section" />
  </dlc-form-field>

  <dlc-form-field class="mb-8 w-full" appearance="outline">
    <dlc-label>Subject</dlc-label>
    <input dlcInput formControlName="subject" placeholder="Subject" />
  </dlc-form-field>

  <dlc-form-field class="mb-8 w-full" appearance="outline">
    <dlc-label>Room</dlc-label>
    <input dlcInput formControlName="room" placeholder="Room" />
  </dlc-form-field>
</form>

<button
  dlc-rounded-text-icon-button
  color="primary"
  [disabled]="classroomFormGroup.invalid"
  (click)="onSubmit()">
  CREATE
</button>
