<div
  *ngFor="let row of answers()"
  class="my-5 bg-white/5 flex flex-col justify-start items-stretch gap-x-6 p-5 rounded">
  <div class="flex flex-row min-w-0">
    <div
      *ngIf="row.question.imagePath"
      class="w-12 h-12 block overflow-hidden bg-white/[.12] rounded mr-4 self-start">
      <img
        class="w-full h-full"
        dlcImage
        [dlcImagePath]="row.question.imagePath" />
    </div>

    <div
      *ngIf="!row.question.imagePath"
      class="w-12 h-12 block overflow-hidden bg-white/[.12] rounded mr-4 self-start flex flex-row justify-center items-center">
      <mat-icon>image</mat-icon>
    </div>

    <div class="min-w-0 flex-auto">
      <p class="text-sm font-semibold leading-6 text-white">
        {{ row.questionText }}
      </p>
    </div>
  </div>

  <div class="mt-4 flex flex-row justify-between items-center min-w-0">
    <div
      *ngIf="!row.isCorrect"
      class="ec-chip-wrong-answer ec-wrong__bg ec-wrong__bold">
      Wrong
    </div>
    <div
      *ngIf="row.isCorrect"
      class="ec-chip-correct-answer ec_correct__bg ec-correct__bold">
      Correct
    </div>
    <div>
      <span class="font-bold">{{ qr.timeToAnswer(row.timeToAnswerMS) }}s</span>
      to answer
    </div>
  </div>

  <!-- DIVIDER -->
  <div class="my-4 border-t border-white/25"></div>

  <!-- ANSWERS -->
  <div class="mb-4">
    <!-- TRUE / FALSE -->
    <div
      *ngIf="qr.isTrueFalseQuestion(row.question.questionType)"
      class="grid grid-cols-2 gap-4">
      <!-- TRUE -->
      <div class="flex flex-row justify-start items-center">
        <div class="w-6 h-6 mr-1">
          <mat-icon
            *ngIf="
              !qr.isTrueCorrectAnswer(row.answers) &&
              !qr.userAnsweredTrueFalseCorrect(row.answers)
            "
            class="ec-wrong-color"
            >close</mat-icon
          >
        </div>

        <div
          class="ec-review-answer"
          [ngClass]="{
            'ec-review-answer__correct': qr.isTrueCorrectAnswer(row.answers)
          }">
          True
        </div>
        <div class="flex-auto"></div>
      </div>

      <!-- FALSE -->
      <div class="flex flex-row justify-start items-center">
        <div class="w-6 h-6 mr-1">
          <mat-icon
            *ngIf="
              !qr.isFalseCorrectAnswer(row.answers) &&
              !qr.userAnsweredTrueFalseCorrect(row.answers)
            "
            class="ec-wrong-color"
            >close</mat-icon
          >
        </div>

        <div
          class="ec-review-answer"
          [ngClass]="{
            'ec-review-answer__correct': qr.isFalseCorrectAnswer(row.answers)
          }">
          False
        </div>
        <div class="flex-auto"></div>
      </div>
    </div>

    <!-- MULTIPLE CHOICE -->
    <div
      *ngIf="qr.isMultipleChoiceQuestion(row.question.questionType)"
      class="mb-4 grid grid-cols-2 gap-4">
      <div
        *ngFor="let a of qr.getAnswerList(row.answers)"
        class="flex flex-row justify-start items-center">
        <div class="w-6 h-6 mr-1">
          <mat-icon
            *ngIf="!qr.isMultipleChoiceCorrect(a)"
            class="ec-wrong-color"
            >close</mat-icon
          >
        </div>
        <div
          class="ec-review-answer"
          [ngClass]="{'ec-review-answer__correct': a.answer.isCorrect}">
          {{ a.answer.answerText }}
        </div>
        <div class="flex-auto"></div>
      </div>
    </div>
  </div>

  <!-- DIVIDER -->
  <div class="mb-4 border-t border-white/25"></div>

  <!-- REFERENCE LINKS -->
  <div class="flex flex-row justify-start items-center">
    <dlc-label-container class="flex grow mr-4 mt-1">
      <dlc-label>Reference Links</dlc-label>
      <ec-reference-link
        class="w-full"
        [links]="
          getReferenceLinksFromQuestion(row.question)
        "></ec-reference-link>
    </dlc-label-container>
  </div>
</div>
