import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/** An actor's name can't match the actor's role */
export const urlNeedsValue: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const label = control.get('label');
  const url = control.get('url');

  const labelHasValue = label && label.value && label.value.length > 0;
  const urlHasValue = url && url.value && url.value.length > 0;

  // const textIsDirty = text && text.dirty;
  // const urlIsDirty = url && url.dirty;

  return labelHasValue && !urlHasValue ? {urlNeedsValue: true} : null;
};
