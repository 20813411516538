import {Component, ChangeDetectionStrategy, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'dlc-subscriptions-stripe-error',
  templateUrl: './stripe-stripe-error-modal.component.html',
  styleUrls: ['./stripe-stripe-error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule]
})
export class StripeStripeErrorModalComponent {
  constructor(
    public dialogRef: MatDialogRef<StripeStripeErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}
}
