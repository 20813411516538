import {Update} from '@ngrx/entity';

/**
 * @deprecated use from @ngapt/store
 * @param updates
 */
export function aggregateUpdates<T extends {id: string}>(
  updates: T[]
): Update<T>[] {
  return updates.map((i: T) => {
    return {
      id: (<{id: string}>i).id,
      changes: i
    };
  });
}
