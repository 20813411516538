<div class="flex flex-row">
  <div
    *ngIf="quiz()?.imagePath"
    class="w-12 h-12 block overflow-hidden bg-white/[.12] rounded mr-4 self-start">
    <img class="w-full h-full" dlcBlobImage [dlcImagePath]="quiz()?.imagePath" />
  </div>
  <div
    *ngIf="!quiz()?.imagePath"
    class="w-12 h-12 block overflow-hidden bg-white/[.12] rounded mr-4 self-start flex flex-row justify-center items-center">
    <mat-icon>image</mat-icon>
  </div>

  <div class="flex-auto">
    <p class="text-sm font-semibold leading-6 text-white">
      {{ quiz()?.name }}
    </p>
  </div>
</div>

<div class="w-full">
  <div class="flex flex-row justify-between items-center mt-4 w-full">
    <div class="flex-auto"></div>
    <button class="mr-4" dlc-rounded-text-icon-button color="primary" (click)="takeQuiz()">
      <mat-icon aria-hidden="false">play_circle_filled</mat-icon>
      Take
    </button>

    <button class="mr-4" dlc-rounded-text-icon-button color="primary" (click)="onEditQuiz()">
      <mat-icon aria-hidden="false">edit</mat-icon>
      Edit
    </button>

    <button dlc-rounded-icon-button [matMenuTriggerFor]="items">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<mat-menu #items>
  <button mat-menu-item (click)="onStats()">
    <mat-icon>analytics</mat-icon>
    Stats
  </button>

  <button mat-menu-item (click)="onSeeAllGrades()">
    <mat-icon aria-hidden="false">grading</mat-icon>
    Grades
  </button>

  <!--  <button mat-menu-item (click)="onEditQuiz()">-->
  <!--    <mat-icon>edit</mat-icon>-->
  <!--    Edit-->
  <!--  </button>-->

  <button mat-menu-item (click)="onCopyQuiz()">
    <mat-icon>content_copy</mat-icon>
    Copy
  </button>

  <button mat-menu-item (click)="onPublishQuiz()">
    <mat-icon>publish</mat-icon>
    Publish
  </button>

  <button mat-menu-item (click)="onDeleteQuiz()">
    <mat-icon>delete</mat-icon>
    Delete Quiz
  </button>
</mat-menu>
