<div
  class="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
  <span class="font-semibold text-indigo-600">{{ title }}</span>
  <span class="h-4 w-px bg-gray-900/10" aria-hidden="true"></span>
  <a href="#" (click)="navigateToCta($event)" class="flex items-center gap-x-1">
    <span class="absolute inset-0" aria-hidden="true"></span>
    {{ cta }}
    <svg
      class="-mr-2 h-5 w-5 text-gray-400"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true">
      <path
        fill-rule="evenodd"
        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
        clip-rule="evenodd" />
    </svg>
  </a>
</div>
