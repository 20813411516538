<ng-container *ngrxLet="userList$; let list">
  <ng-container *ngIf="list && list.length > 0">
    <h3 class="mat-h3">Mentoring Me</h3>
    <table mat-table [dataSource]="list" class="mat-elevation-z8">
      <!-- Position Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td mat-cell *matCellDef="let row">{{ row.username }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef>Display Name</th>
        <td mat-cell *matCellDef="let row">{{ row.displayName }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="uid">
        <th mat-header-cell *matHeaderCellDef>Remove</th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button matTooltip="Remove Mentor">
            <mat-icon>remove</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-container>
</ng-container>
