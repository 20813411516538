import {Injectable} from '@angular/core';
import {
  Quiz,
  selectAddedAndDeletedQuizzes
} from '@gigasoftware/evolving-cognition/domain';
import {NgPatAddedAndDeletedEntities} from '@ngpat/store';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {tap} from 'rxjs/operators';
import {QuizzesEngineStore} from './quizzes-engine-store.service';

/**
 * @description
 * This interacts with the quizzes.
 * Aggregates the list of quizzes in the QuizEngineStore.
 */
@Injectable({
  providedIn: 'root'
})
export class QuizEngineEffect {
  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        tap(() => {
          this.init();
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private quizzesEngineStore: QuizzesEngineStore
  ) {}

  init() {
    // console.log('QuizEngineEffect init');
    this.store
      .select(selectAddedAndDeletedQuizzes)
      .subscribe((quizEntities: NgPatAddedAndDeletedEntities<Quiz>) => {
        if (quizEntities.addedEntities.length) {
          this.quizzesEngineStore.upsertQuizzes(quizEntities.addedEntities);
        }

        if (quizEntities.deletedEntities.length) {
          this.quizzesEngineStore.deleteManyQuizzes(
            quizEntities.deletedEntities.map(quiz => quiz.id)
          );
        }
      });
  }
}
