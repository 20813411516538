export type InputFileType =
  | 'image'
  | 'video'
  | 'audio'
  | 'midi'
  | 'document'
  | 'other';

export enum INPUT_FILE_TYPE {
  IMAGE_STANDARD = 'image/jpeg, image/png, image/gif, image/bmp, image/webp',
  IMAGE_ANY = 'image/*',
  VIDEO_STANDARD = 'video/mp4, video/webm, video/ogg',
  VIDEO_ANY = 'video/*',
  MIDI_STANDARD = 'audio/midi, audio/x-midi, audio/x-mid, audio/x-mpeg, audio/x-wav',
  MIDI_ANY = 'audio/*',
  AUDIO_STANDARD = 'audio/mp3, audio/mpeg, audio/ogg, audio/wav, audio/webm, audio/mp4',
  AUDIO_ANY = 'audio/*',
  DOCUMENT_STANDARD = 'application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation',
  DOCUMENT_ANY = 'application/*'
}

export interface StandardInputConfig {
  accept: string;
  title: string;
  subTitle: string;
}

/**
 * Material design icon
 * photo_camera
 * https://material.io/resources/icons/?icon=insert_drive_file&style=baseline
 */
export const IMAGE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z"/></svg>`;

/**
 * Material design icon
 * audio_file
 * https://material.io/resources/icons/?icon=insert_drive_file&style=baseline
 */
export const AUDIO_ICON = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" ><g><rect fill="none" height="24" width="24"/></g><g><path d="M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M16,13h-3v3.75 c0,1.24-1.01,2.25-2.25,2.25S8.5,17.99,8.5,16.75c0-1.24,1.01-2.25,2.25-2.25c0.46,0,0.89,0.14,1.25,0.38V11h4V13z M13,9V3.5 L18.5,9H13z"/></g></svg>`;

/**
 * Material design icon
 * video_file
 * https://material.io/resources/icons/?icon=insert_drive_file&style=baseline
 */
export const VIDEO_ICON = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" ><g><rect fill="none" height="24" width="24"/></g><g><path d="M14,2H6.01c-1.1,0-2,0.89-2,2L4,20c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z M13,9V3.5L18.5,9H13z M14,14l2-1.06v4.12 L14,16v1c0,0.55-0.45,1-1,1H9c-0.55,0-1-0.45-1-1v-4c0-0.55,0.45-1,1-1h4c0.55,0,1,0.45,1,1V14z"/></g></svg>`;

/**
 * Material design icon
 * upload_file
 * https://material.io/resources/icons/?icon=insert_drive_file&style=baseline
 */
export const DOCUMENT_ICON = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-167l64 64 56-57-160-160-160 160 57 56 63-63v167ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>`;

/**
 * Material design icon
 * upload_file
 * https://material.io/resources/icons/?icon=insert_drive_file&style=baseline
 */
export const FILE_UPLOAD = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" ><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/></svg>`;

/**
 * Material design icon
 * piano
 * https://material.io/resources/icons/?icon=insert_drive_file&style=baseline
 */
export const MIDI_UPLOAD = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" ><rect fill="none" height="24" width="24"/><path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M14,14.5h0.25V19h-4.5v-4.5H10 c0.55,0,1-0.45,1-1V5h2v8.5C13,14.05,13.45,14.5,14,14.5z M5,5h2v8.5c0,0.55,0.45,1,1,1h0.25V19H5V5z M19,19h-3.25v-4.5H16 c0.55,0,1-0.45,1-1V5h2V19z"/></svg>`;
