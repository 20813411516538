

import {Directive} from '@angular/core';

/** The floating label for a `mat-form-field`. */
@Directive({
  selector: 'dlc-label, [dlc-label], [dlcLabel]',
  // standalone: true,
})
export class DlcLabelDirective {}
