import {generate8CharCodeLowercase} from '@ngpat/fn';
import {EC_QUIZ_ROLES, UserIdWithRole, UserPermissions} from '../models/user.model.deprecated';
import {
  CollaborativeProject,
  CreateProjectParams,
  CreateSubProjectParams,
  DEFAULT_PLATFORM_PROJECT_TYPE_DICT,
  Project
} from '../+project/project.model';
import {Classroom, CreateClassParams} from '../+classrooms/classroom.model';

export function createProject(
  params: CreateProjectParams | CreateSubProjectParams
): Project {
  let project = {
    // TreeConfig
    id: params.id,
    name: params.name,
    description: params.description,
    isPrivate: params.isPrivate,
    projectType: params.projectTypeValue,
    imagePath: null,
    isDefault: false,
    createdAt: null,
    updatedAt: null,
    isCollaborative: params.isCollaborative,
    parentProjectID: (<CreateSubProjectParams>params).parentProject
      ? (<CreateSubProjectParams>params).parentProject.id
      : null,
    parentProjectType: (<CreateSubProjectParams>params).parentProject
      ? (<CreateSubProjectParams>params).parentProject.projectType
      : null

    // Permissions
    // createdBy: user,
    // createdByUID: user.uid,
    // membersList: {
    //   [user.uid]: user
    // },
    // memberUIDs: [user.uid],
    // owners: {
    //   [user.uid]: user
    // },
    // ownerUIDs: [user.uid]
  };

  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.QUIZ.id) {
    project = <any>{
      ...project,
      isPublic: false,
      publicDisplayName: '',
      aggregateFalseAnswers: true,
      totalAnswers: 4
    };
  }

  if (params.isCollaborative) {
    project = <any>{
      ...project,
      // joinCode: generate8CharCodeLowercase(),
      mentorCode: generate8CharCodeLowercase()
    };
  }

  if (params.assigned !== null && params.assigned !== undefined) {
    project = <any>{
      ...project,
      assigned: params.assigned
    };
  }

  return project;
}

export function createClass(
  p: CreateClassParams,
  user: UserIdWithRole
): Classroom {
  return {
    ...p,
    name: <string>p.name,
    isPrivate: false,
    isDefault: false,
    createdAt: null,
    updatedAt: null,
    imagePath: null,
    isCollaborative: true,
    parentProjectID: null,
    parentProjectType: null,
    // joinCode: generate8CharCodeLowercase(),
    studentCode: generate8CharCodeLowercase(),
    teacherCode: generate8CharCodeLowercase(),
    mentorCode: generate8CharCodeLowercase(),
    ...createProjectUserArchitecture(user)
  };
}

export function createProjectUserArchitecture(
  user: UserIdWithRole
): UserPermissions {
  return {
    createdBy: user,
    createdByUID: user.uid,
    members: {
      [user.uid]: user
    },
    memberUIDs: [user.uid]
  };
}

export function removeProjectUserArchitecture(
  p: CollaborativeProject
): Project {
  const project: any = {
    ...p
  };

  delete project.createdBy;
  delete project.createdByUID;
  delete project.members;
  delete project.memberUIDs;

  return project as Project;
}

export function createCollaborativeProject(
  user: UserIdWithRole,
  params: CreateProjectParams | CreateSubProjectParams
) {
  return {
    ...createProject(params),
    ...createProjectUserArchitecture(user)
  };
}

export function addUserAndRoleToProject(
  p: CollaborativeProject,
  uid: string,
  role: EC_QUIZ_ROLES
): CollaborativeProject {
  return {
    ...p,
    memberUIDs: [...new Set([...p.memberUIDs, uid])],
    members: {
      ...p.members,
      [uid]: {
        uid,
        role
      }
    }
  };
}

export function removeUserAndRoleFromProject(
  p: CollaborativeProject,
  uid: string
) {
  const members = Object.values(p.members).reduce(
    (a: {[uid: string]: UserIdWithRole}, m: UserIdWithRole) => {
      if (m.uid !== uid) {
        a[m.uid] = m;
      }

      return a;
    },
    {}
  );

  return {
    ...p,
    memberUIDs: p.memberUIDs.filter((muid: string) => muid !== uid),
    members
  };
}
