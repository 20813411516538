// @ts-nocheck
import baseIsTypedArray from './_baseIsTypedArray';
import baseUnary from './_baseUnary';
import nodeUtil from './_nodeUtil';

/* Node.js helper references. */
var nodeIsTypedArray = nodeUtil && nodeUtil.isTypedArray;

/**
 * Checks if `value` is classified as a typed array.
 *
 * @static
 * @memberOf _
 * @since 3.0.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a typed array, else `false`.
 * @example
 *
 * _.isTypedArray(new Uint8Array);
 * // => true
 *
 * _.isTypedArray([]);
 * // => false
 */
var isTypedArray = nodeIsTypedArray
  ? baseUnary(nodeIsTypedArray)
  : baseIsTypedArray;

export default isTypedArray;
