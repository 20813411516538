import {Injectable} from '@angular/core';
import Dexie, {Table} from 'dexie';
import {Observable, Observer} from 'rxjs';

interface OTLIDStore {
  id: number;
  otlid: string;
}

export class AuthOtlidDB extends Dexie {
  auth!: Table<OTLIDStore, number>;

  constructor() {
    super('authOTLIDDB');
    this.version(3).stores({
      auth: 'id'
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthStorageSync {
  authDB = new AuthOtlidDB();

  constructor() {
    this.authDB.open();
  }

  clear() {
    this.authDB.auth.clear();
  }

  putAuthOTLID(otlid: string) {
    return this.authDB.auth.put({id: 1, otlid});
  }

  getAuthOtlidFromStorage$(): Observable<string | null> {
    return new Observable((observer: Observer<string | null>) => {
      this.authDB.auth
        .get({id: 1})
        .then((d: OTLIDStore | undefined) => {
          if (d && d.otlid && d.otlid.length > 0) {
            observer.next(d.otlid);
          } else {
            observer.next(null);
          }
        })
        .catch((e: any) => {
          observer.error(e);
        });
    });
  }
}
