import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
  convertBlobToPngBlob,
  defaultImageUploaderConfig,
  getFileFromEventTarget,
  GsAssetService,
  GSFirebaseUploadImageWithDocConfig
} from '@gigasoftware/shared/media';
import {DlcRoundedTextIconButtonComponent} from '../../button/dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';
import {DlcBlobImageDirective} from '../../image/blob/dlc-blob-image.directive';
import {ImageCropperDialogComponent} from '../../image/cropper/dialog/image-cropper-dialog.component';
import {ImageCroppedEvent} from '../../image/cropper/image-cropper/index';
import {InputFileComponent} from '../dlc-input-file/input-file.component';
import {getStandardInputFileTypeByString} from '../dlc-input-file/input-file.fns';

export interface DlcInputImageConfig {
  filenameWithoutExtension: string;
  imagePath: string;
  uploadConfig: GSFirebaseUploadImageWithDocConfig;
}

export interface DlcProgressActionBar {
  showUploadProgress: boolean;
  showDownloadProgress: boolean;
  showEditButtons: boolean;
  showDeleteProgress: boolean;
  showSaveCancelButtons: boolean;
  showError: boolean;
}

@Component({
  selector: 'dlc-input-image',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    DlcRoundedTextIconButtonComponent,
    InputFileComponent,
    DlcBlobImageDirective,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './dlc-input-image.component.html',
  styleUrls: ['./dlc-input-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'dlc-input-image dlc-input-image__container',
    '[class.dlc-input-image__container--uploading]': 'actionBarState().showUploadProgress',
    '[class.dlc-input-image__container--edit]': 'actionBarState().showEditButtons',
    '[class.dlc-input-image__container--downloading]': 'actionBarState().showDownloadProgress',
    '[class.dlc-input-image__container--deleting]': 'actionBarState().showDeleteProgress',
    '[class.dlc-input-image__container--save-cancel]': 'actionBarState().showSaveCancelButtons',
    '[class.dlc-input-image__container--error]': 'actionBarState().showError'
  }
})
export class DlcInputImageComponent {
  blobSignal: WritableSignal<Blob | null> = signal(null);

  inputErrorSignal: WritableSignal<string | null> = signal(null);

  imageEvent: Event | null = null;

  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  actionBarState: WritableSignal<DlcProgressActionBar> = signal({
    showUploadProgress: false,
    showDownloadProgress: false,
    showEditButtons: false,
    showDeleteProgress: false,
    showSaveCancelButtons: false,
    showError: false
  });

  inputImageConfig: WritableSignal<DlcInputImageConfig> = signal({
    filenameWithoutExtension: '',
    imagePath: '',
    uploadConfig: defaultImageUploaderConfig
  });

  acceptImage = getStandardInputFileTypeByString('image');

  previousEditImage: Blob | null = null;

  @Input()
  set config(c: DlcInputImageConfig | null | undefined) {
    if (c) {
      this.inputImageConfig.set(c);
    }
  }

  /**
   * Path of original image uploaded
   */
  // @Output() uploadPath: EventEmitter<string> = new EventEmitter<string>();

  @Output() fileChange: EventEmitter<Blob | null> = new EventEmitter<Blob | null>();

  @Output() deleteImage: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dialog: MatDialog,
    public assetService: GsAssetService
  ) {
    effect(
      () => {
        const config = this.inputImageConfig();

        if (config.imagePath && config.imagePath.length > 0) {
          this.setActionBarToDownload();
          this.assetService.downloadBlob(config.imagePath).then((blob: Blob) => {
            this.blobSignal.set(blob);
            this.setActionBarToEdit();
          });
        }
      },
      {allowSignalWrites: true}
    );
  }

  openFileBrowser() {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onDeleteImageSet(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const config = this.inputImageConfig();

    if (config.imagePath && config.imagePath.length > 0) {
      // this.blobSignal.set(null);
      // this.deleteImage.emit(true);
      this.setActionBarToDeleteProgress();

      if (config.uploadConfig.imageNameIsParentDirectory) {
        this.assetService
          .deleteAssetSet(config.filenameWithoutExtension, config.uploadConfig)
          .then(() => {
            this.blobSignal.set(null);
            this.deleteImage.emit(true);
            this.setActionBarToDefault();
          });
      }
    }
  }

  onCancelEdit(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.previousEditImage !== null) {
      this.blobSignal.set(this.previousEditImage);
    }

    if (this.blobSignal() !== null) {
      this.setActionBarToEdit();
    } else {
      this.setActionBarToDefault();
    }
  }

  onSaveEdit(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const pngBlob = this.blobSignal();
    this.previousEditImage = null;
    if (pngBlob) {
      this.uploadImages(pngBlob);
    }
  }

  editImage(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const file = this.blobSignal();
    this.previousEditImage = file;

    if (file) {
      const dialogRef: MatDialogRef<ImageCropperDialogComponent> = this.dialog.open(
        ImageCropperDialogComponent,
        {
          data: {
            imageFile: file
          }
        }
      );

      dialogRef.afterClosed().subscribe(async (imageFile: ImageCroppedEvent | null) => {
        if (imageFile && imageFile.blob) {
          this.blobSignal.set(imageFile.blob);
          this.setActionBarToSaveCancel();
        }
      });
    }
  }

  onInputUrl() {
    this.setActionBarToDownload();
  }

  onInputError(error: string) {
    this.inputErrorSignal.set(error);
    this.setActionBarToError();
  }

  async onInputBlob(blob: Blob) {
    const pngBlob = await convertBlobToPngBlob(blob);
    if (pngBlob) {
      this.uploadImages(pngBlob);
    }
  }

  async onUpdateImage(event: Event) {
    const file: File | undefined = getFileFromEventTarget(event);

    if (file) {
      const pngBlob = await convertBlobToPngBlob(file);
      if (pngBlob) {
        this.uploadImages(pngBlob);
      }
    }
  }

  private uploadImages(pngBlob: Blob) {
    this.setActionBarToUploadProgress();
    this.blobSignal.set(pngBlob);
    // this.fileChange.emit(pngBlob);

    const config: DlcInputImageConfig = this.inputImageConfig();


    this.assetService
      .uploadImages(pngBlob, config.filenameWithoutExtension, config.uploadConfig)
      .then(() => {
        this.setActionBarToEdit();
        this.assetService.uploadProgress.set(0);
        // this.uploadPath.emit(uploadPath);
      });
  }

  private setActionBarToUploadProgress() {
    this.actionBarState.set({
      showUploadProgress: true,
      showDownloadProgress: false,
      showEditButtons: false,
      showDeleteProgress: false,
      showSaveCancelButtons: false,
      showError: false
    });
  }

  private setActionBarToEdit() {
    this.actionBarState.set({
      showUploadProgress: false,
      showDownloadProgress: false,
      showEditButtons: true,
      showDeleteProgress: false,
      showSaveCancelButtons: false,
      showError: false
    });
  }

  private setActionBarToDownload() {
    this.actionBarState.set({
      showUploadProgress: false,
      showDownloadProgress: true,
      showEditButtons: false,
      showDeleteProgress: false,
      showSaveCancelButtons: false,
      showError: false
    });
  }

  private setActionBarToDeleteProgress() {
    this.actionBarState.set({
      showUploadProgress: false,
      showDownloadProgress: false,
      showEditButtons: false,
      showDeleteProgress: true,
      showSaveCancelButtons: false,
      showError: false
    });
  }

  private setActionBarToSaveCancel() {
    this.actionBarState.set({
      showUploadProgress: false,
      showDownloadProgress: false,
      showEditButtons: false,
      showDeleteProgress: false,
      showSaveCancelButtons: true,
      showError: false
    });
  }

  private setActionBarToError() {
    this.actionBarState.set({
      showUploadProgress: false,
      showDownloadProgress: false,
      showEditButtons: false,
      showDeleteProgress: false,
      showSaveCancelButtons: false,
      showError: true
    });
  }

  private setActionBarToDefault() {
    this.actionBarState.set({
      showUploadProgress: false,
      showDownloadProgress: false,
      showEditButtons: false,
      showDeleteProgress: false,
      showSaveCancelButtons: false,
      showError: false
    });
  }
}
