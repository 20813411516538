<h3 mat-dialog-title>Confirm Create Review Quiz</h3>
<mat-dialog-content class="text-sm">
  Do you want to create a review quiz based on the wrong answers of
  <span class="text-[--dlc-color-primary]"> {{ data.quizName }} </span>?
</mat-dialog-content>
<mat-dialog-actions>
  <button
    class="mr-4"
    dlc-rounded-text-button
    color="primary"
    (click)="dialogRef.close(false)">
    Cancel
  </button>
  <button
    dlc-rounded-text-button
    color="primary"
    (click)="dialogRef.close(true)">
    Create
  </button>
</mat-dialog-actions>
