import {Observable, ReplaySubject} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

/**
 * ResizeObserver$ is a wrapper around the ResizeObserver API that provides
 * an observable interface.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
 */
export class ResizeObserver$ {
  entries$: ReplaySubject<ResizeObserverEntry[]> = new ReplaySubject(1);
  contentRect$: ReplaySubject<DOMRectReadOnly> = new ReplaySubject(1);

  resizeObserver: ResizeObserver;
  constructor() {
    this.resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        this.entries$.next(entries);

        /**
         * Get contentRect from an ResizeObserverEntry
         */
        const contentRect = entries[0].contentRect;
        this.contentRect$.next(contentRect);
      }
    );
  }

  entries<T>(
    fn: (entries: ResizeObserverEntry[]) => T,
    debounceTimeValue = 0
  ): Observable<T> {
    return this.entries$.pipe(map(fn), debounceTime(debounceTimeValue));
  }

  disconnect() {
    this.resizeObserver.disconnect();
  }

  unobserve(target: Element) {
    this.resizeObserver.unobserve(target);
  }

  observe(target: Element, options?: ResizeObserverOptions) {
    const rect: DOMRect = target.getBoundingClientRect();
    this.contentRect$.next(rect);

    const borderBoxSize: ResizeObserverSize = {
      blockSize: rect.height,
      inlineSize: rect.width
    };

    const contentBoxSize: ResizeObserverSize = {
      blockSize: rect.height,
      inlineSize: rect.width
    };

    const devicePixelContentBoxSize: ResizeObserverSize = {
      blockSize: rect.height,
      inlineSize: rect.width
    };

    const entry: ResizeObserverEntry = Object.freeze({
      borderBoxSize,
      contentBoxSize,
      devicePixelContentBoxSize,
      contentRect: rect,
      target
    }) as any;

    this.entries$.next([entry]);

    this.resizeObserver.observe(target, options);
  }
}
