import {createReducer, on} from '@ngrx/store';
import {
  cancelAuth,
  clearAuthState,
  loadAuthActionFromFirestore,
  setOtlid,
  updateAuthAction
} from './login.actions';
import {AuthLoginAction} from './login.model';

export const loginFeatureKey = 'login';

export interface AuthState {
  // For Dexie IndexedDB
  action: AuthLoginAction;
  otlid: string | null;
}

export const loginInitialState: AuthState = {
  action: AuthLoginAction.LOGIN,
  otlid: null
};

export const loginReducer = createReducer(
  loginInitialState,

  on(updateAuthAction, loadAuthActionFromFirestore, (state, action) => {
    return {
      ...state,
      action: action.action
    };
  }),
  on(clearAuthState, state => ({...loginInitialState})),
  on(setOtlid, (state, action) => {
    return {
      ...state,
      otlid: action.otlid
    };
  }),
  on(cancelAuth, state => {
    return {
      ...state,
      action: AuthLoginAction.CANCEL
    };
  })
);
