import {Clipboard} from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {JoinCodeType} from '@gigasoftware/evolving-cognition/domain';
import {DlcRoundedIconButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {EcCopyJoinCodeDialogComponent} from './ec-copy-join-code-dialog/ec-copy-join-code-dialog.component';

@Component({
  selector: 'ec-copy-join-code',
  templateUrl: './ec-copy-join-code.component.html',
  styleUrls: ['./ec-copy-join-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-copy-join-code'
  },
  inputs: ['size'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, DlcRoundedIconButtonComponent]
})
export class EcCopyJoinCodeComponent {
  @Input() joinCode: string | undefined = '';
  @Input() joinCodeType: JoinCodeType = 'student';

  constructor(private _dialog: MatDialog, private _clipboard: Clipboard) {}

  openCode() {
    if (this.joinCode) {
      this._clipboard.copy(this.joinCode);
    }

    this._dialog.open(EcCopyJoinCodeDialogComponent, {
      backdropClass: 'dlc-global-dialog-background',
      panelClass: 'mat-typography',
      data: {
        joinCode: this.joinCode,
        joinCodeType: this.joinCodeType
      }
    });
  }
}
