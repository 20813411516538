import {Injectable} from '@angular/core';
import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {
  aggregateUpdates,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector
} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {Project} from '../+project/project.model';
import {
  firestoreCourseCollection,
  firestoreUserCourseCollection
} from '../firebase/database-paths';
import {deleteCourses, updateCourses, upsertCourses} from './course.actions';
import {Course} from './course.model';
import {courseFeatureKey} from './course.reducer';

@Injectable({
  providedIn: 'root'
})
export class CourseService implements NgPatFirebaseConnectionService {
  private _queryCollaborationService!: NgPatFirestoreCollectionQuery<Course>;
  private _queryPrivateService!: NgPatFirestoreCollectionQuery<Course>;

  connectionKey = courseFeatureKey;
  connection: NgPatServiceConnector = new NgPatServiceConnector(
    this,
    this.store
  );

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  ngPatOnInit() {
    this._queryCollaborationService = new NgPatFirestoreCollectionQuery<Course>(
      {
        queryMember: true,
        upsertManyAction: (courses: Course[]) => upsertCourses({courses}),
        updateManyAction: (courses: Course[]) =>
          updateCourses({courses: aggregateUpdates(courses)}),
        deleteManyAction: (ids: string[]) => deleteCourses({ids})
      },
      this.store,
      this.customFirestoreService
    );

    this._queryPrivateService = new NgPatFirestoreCollectionQuery<Course>(
      {
        queryMember: false,
        upsertManyAction: (courses: Course[]) => upsertCourses({courses}),
        updateManyAction: (courses: Course[]) =>
          updateCourses({courses: aggregateUpdates(courses)}),
        deleteManyAction: (ids: string[]) => deleteCourses({ids})
      },
      this.store,
      this.customFirestoreService
    );
  }

  deleteDoc$(course: Project | undefined, uid: string): Observable<boolean> {
    if (course) {
      const path: string = course.isPrivate
        ? firestoreUserCourseCollection(uid)
        : firestoreCourseCollection();

      // const path = studyGroup.isPrivate ?
      return <any>this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(
    course: Project | undefined,
    ids: string[],
    uid: string
  ): Observable<boolean | any> {
    if (course) {
      const isPrivate = course.isPrivate;
      const path: string = isPrivate
        ? firestoreUserCourseCollection(uid)
        : firestoreCourseCollection();
      return <any>this.customFirestoreService.deleteDocs$(path, ids);
    }
    return of(true);
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._queryCollaborationService.onConnect(
      firestoreCourseCollection(),
      null,
      <string>user.uid
    );
    this._queryPrivateService.onConnect(
      firestoreUserCourseCollection(<string>user.uid),
      null,
      null
    );
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._queryCollaborationService.onDisconnect();
    this._queryPrivateService.onDisconnect();
  }
}
