import {EC_QUIZ_ROLES} from '@gigasoftware/evolving-cognition/domain';

export interface JoinProjectTypeDisplay {
  role: EC_QUIZ_ROLES;
  displayValue: string;
}

export interface JoinGroupForm {
  joinCode: string;
  role: EC_QUIZ_ROLES;
}

export interface JoinProjectComponentState {
  error: string;
  hasError: boolean;
  inProgress: boolean;
  projectType: number;
}

export interface JoinDialogData {
  title: string;
  projectTypeID: number;
}

export enum JOIN_CLASS_ERROR {
  NOT_FOUND = 'notFound',
  IS_JOINED = 'isJoined',
  ERROR = 'error'
}
