import {
  Directive,
  effect,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import {
  filenameIsImage,
  getMaxImageSizeFromStandardImageSizes,
  getThumbnailPathFromImagePath,
  GsAssetService
} from '@gigasoftware/shared/media';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {ResizeObserver$} from '../core/utils/resize-observer-observable';

@Directive({
  selector: '[dlcImage]',
  standalone: true
})
export class DlcImageDirective implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _resizeObserverIsSet = false;

  resizeObserver = new ResizeObserver$();
  imgUrl: WritableSignal<string> = signal('');

  dlcSrcBySizeSignal: WritableSignal<string | null> = signal(null);

  /**
   * Enter the base path of the image.
   * The correct image path will be generated based on the size of the container.
   */
  @Input() set dlcSrcBySize(src: string | undefined) {
    if (src && src.length) {
      this.dlcSrcBySizeSignal.set(decodeURI(src));
    }
  }

  dlcImagePathSignal: WritableSignal<string | null> = signal(null);
  /**
   * Enter the path of the image to be displayed.
   */
  @Input() set dlcImagePath(src: string | null | undefined) {
    if (src && src.length) {
      this.dlcImagePathSignal.set(decodeURI(src));
    }
  }

  @HostBinding('attr.src') get src() {
    return this.imgUrl();
  }

  constructor(
    private el: ElementRef,
    private assetService: GsAssetService
  ) {
    this.resizeObserver.observe(this.el.nativeElement);

    effect(() => {
      const dlcSrcBySize = this.dlcSrcBySizeSignal();
      const dlcImagePath = this.dlcImagePathSignal();

      // console.log('dlcSrcBySize', dlcSrcBySize);
      // console.log('dlcImagePath', dlcImagePath);

      if (dlcSrcBySize && dlcSrcBySize.length > 0) {
        this.setDownloadUrl(dlcSrcBySize).then(() => {});
      } else if (dlcImagePath && dlcImagePath.length > 0 && !this._resizeObserverIsSet) {
        this.setResizeObserver(dlcImagePath);
      }
    });
  }

  setResizeObserver(imagePath: string) {
    this._resizeObserverIsSet = true;
    let currentWidth = 0;

    this.resizeObserver.contentRect$
      .pipe(
        takeUntil(this._onDestroy$),
        debounceTime(5),
        map((contentRect: DOMRectReadOnly) => {
          return getMaxImageSizeFromStandardImageSizes(contentRect.width);
        }),
        distinctUntilChanged(),
        filter((width: number) => {
          if (width > currentWidth) {
            currentWidth = width;
            return true;
          }
          return false;
        })
      )
      .subscribe({
        next: async (width: number): Promise<void> => {
          const thumbPath = getThumbnailPathFromImagePath(imagePath, width);

          this.setDownloadUrl(thumbPath).then(() => {});
        }
      });
  }

  async setDownloadUrl(thumbPath: string) {
    if (filenameIsImage(thumbPath)) {
      const downloadUrl = await this.assetService.getDownloadUrl(thumbPath);

      this.imgUrl.set(downloadUrl);
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }
}
