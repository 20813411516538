import {createAction, props} from '@ngrx/store';
import {Update, EntityMap, EntityMapOne} from '@ngrx/entity';

import {Investigation} from './investigation.model';

export const addInvestigation = createAction(
  '[Investigation/API] Add Investigation',
  props<{investigation: Investigation}>()
);

export const setInvestigation = createAction(
  '[Investigation/API] Set Investigation',
  props<{investigation: Investigation}>()
);

export const upsertInvestigation = createAction(
  '[Investigation/API] Upsert Investigation',
  props<{investigation: Investigation}>()
);

export const addInvestigations = createAction(
  '[Investigation/API] Add Investigations',
  props<{investigations: Investigation[]}>()
);

export const upsertInvestigations = createAction(
  '[Investigation/API] Upsert Investigations',
  props<{investigations: Investigation[]}>()
);

export const updateInvestigation = createAction(
  '[Investigation/API] Update Investigation',
  props<{investigation: Update<Investigation>}>()
);

export const updateInvestigations = createAction(
  '[Investigation/API] Update Investigations',
  props<{investigations: Update<Investigation>[]}>()
);

export const mapInvestigation = createAction(
  '[Investigation/API] Map Investigation',
  props<{entityMap: EntityMapOne<Investigation>}>()
);

export const mapInvestigations = createAction(
  '[Investigation/API] Map Investigations',
  props<{entityMap: EntityMap<Investigation>}>()
);

export const deleteInvestigation = createAction(
  '[Investigation/API] Delete Investigation',
  props<{id: string}>()
);

export const deleteInvestigations = createAction(
  '[Investigation/API] Delete Investigations',
  props<{ids: string[]}>()
);

export const loadInvestigations = createAction(
  '[Investigation/API] Load Investigations',
  props<{investigations: Investigation[]}>()
);

export const setInvestigations = createAction(
  '[Investigation/API] Set Investigations',
  props<{investigations: Investigation[]}>()
);

export const clearInvestigations = createAction(
  '[Investigation/API] Clear Investigations'
);

export const selectInvestigationID = createAction(
  '[Investigation/API] Select Investigation',
  props<{id: string}>()
);
