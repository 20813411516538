<ec-quiz-test-header [progress]="progress()" *ngIf="!showTestResults()"></ec-quiz-test-header>
<div
  *ngIf="!showTestResults()"
  class="overflow-y-auto flex flex-col justify-start align-items-stretch h-full p-6">
  <div class="mat-h4 text-center mt-8 mb-8">
    {{ quizTest().quiz.name }}
  </div>

  <div class="mb-6 pt-4 sm:pt-4" *ngIf="currentQuestion()?.question?.imagePath600x600">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <div class="flex flex-row justify-center items-center mt-6">
          <div class="aspect-square block w-full max-w-sm overflow-hidden rounded-2xl">
            <img
              class="w-full"
              dlcBlobImage
              [dlcImagePath]="currentQuestion()?.question?.imagePath"
              [alt]="currentQuestion()?.question?.question" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="question flex-auto flex justify-center align-items-center flex-col min-h-[12rem] mt-6 bg-white/5 rounded mb-4">
    <div class="text-center leading-loose">
      {{ currentQuestion()?.question?.question }}
    </div>
  </div>

  <div class="quest-test-answer">
    <ec-answer-true-false
      *ngIf="currentQuestion()?.question?.questionType === questionType.TRUE_FALSE"
      [question]="currentQuestion()"
      (answer)="quizTest().onAnswer($event)"></ec-answer-true-false>

    <ec-answer-multiple-choice
      *ngIf="currentQuestion()?.question?.questionType === questionType.MULTIPLE_CHOICE"
      [question]="currentQuestion()"
      (answer)="quizTest().onAnswer($event)">
    </ec-answer-multiple-choice>
  </div>
</div>

<ng-container *ngIf="showTestResults()">
  <!-- CONTENT -->
  <div class="overflow-y-auto flex flex-col justify-start items-stretch flex-auto p-6">
    <div class="mat-h4 text-center mt-8 mb-8">{{ quizTest().quiz.name }} results</div>
    <div>
      <dl
        class="mt-6 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
        <div class="flex flex-col bg-white/5 p-8">
          <dt class="text-sm font-semibold leading-6 quiz-test-results__correct">
            Correct Answers
          </dt>
          <dd class="order-first text-3xl font-semibold tracking-tight quiz-test-results__correct">
            {{ totalCorrect() }}
          </dd>
        </div>
        <div class="flex flex-col bg-white/5 p-8">
          <dt class="text-sm font-semibold leading-6 quiz-test-results__wrong">Wrong Answers</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight quiz-test-results__wrong">
            {{ totalWrong() }}
          </dd>
        </div>
        <div class="flex flex-col bg-white/5 p-8">
          <dt class="text-sm font-semibold leading-6 text-gray-300">Score</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight text-white">
            {{ currentPctScore() | number: "1.0-2" }}%
          </dd>
        </div>
        <div class="flex flex-col bg-white/5 p-8">
          <dt class="text-sm font-semibold leading-6 quiz-test-results__average-time">
            Average time per question
          </dt>
          <dd
            class="order-first text-3xl font-semibold tracking-tight quiz-test-results__average-time">
            {{ averageTimePerQuestionSeconds() | number: "1.1-2" }}
            seconds
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <!--  FOOTER-->
  <dlc-footer-bar>
    <button dlc-rounded-text-icon-button color="primary" (click)="onTryAgain()">
      <mat-icon aria-hidden="false">play_circle_filled</mat-icon>
      Try Again
    </button>

    <button dlc-rounded-text-icon-button color="primary" (click)="onReviewAnswers()">
      <mat-icon aria-hidden="false">read_more</mat-icon>
      Review
    </button>

    <button dlc-rounded-text-icon-button color="primary" (click)="onSeeAllGrades()">
      <mat-icon aria-hidden="false">grading</mat-icon>
      Quiz Grades
    </button>
  </dlc-footer-bar>
</ng-container>
