import {ObserversModule} from '@angular/cdk/observers';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DlcFormFieldLineRippleDirective} from './directives/line-ripple';
import {DlcFormFieldNotchedOutlineComponent} from './directives/notched-outline';
import {DlcFormFieldComponent} from './form-field.component';
import {DlcError} from './directives/error';
import {DlcFormFieldFloatingLabelDirective} from './directives/floating-label';
import {DlcHintDirective} from './directives/hint';
import {DlcLabelDirective} from './directives/label';
import {DLCPrefixDirective} from './directives/prefix';
import {DlcSuffixDirective} from './directives/suffix';

@NgModule({
  imports: [
    CommonModule,
    ObserversModule,
    DlcFormFieldNotchedOutlineComponent,
    DlcFormFieldLineRippleDirective,
    DlcFormFieldFloatingLabelDirective
  ],
  exports: [
    DlcFormFieldComponent,
    DlcLabelDirective,
    DlcHintDirective,
    DlcError,
    DLCPrefixDirective,
    DlcSuffixDirective
  ],
  declarations: [
    DlcFormFieldComponent,
    DlcLabelDirective,
    DlcError,
    DlcHintDirective,
    DLCPrefixDirective,
    DlcSuffixDirective
    // DlcFormFieldNotchedOutline,
    // DlcFormFieldLineRipple,
  ]
})
export class DlcFormFieldModule {}
