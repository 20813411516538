<h3 *ngIf="showTitle" class="mat-h3 p-accent">Teacher Subscription</h3>
<div class="mat-caption">
  <div class="h4">Quizzes / Study Groups / Classes</div>
  <ul>
    <li>Create unlimited quizzes.</li>
    <li>Create unlimited study groups.</li>
    <li>Add membersList to a study group.</li>
    <li>Add membersList to a study classroom.</li>
    <li>
      Invite and collaborate with other students in your study groups and
      classrooms.
    </li>
    <li>Only teacher can edit quizzes.</li>
    <li>
      Copy quizzes from private space to study groups or from study groups to
      private space.
    </li>
  </ul>
  <div class="h4">Privacy and Control</div>
  <ul>
    <li>Teacher is the moderator of a study group and classes they create.</li>
    <li>Teacher can invite and remove membersList.</li>
    <li>
      Quiz results are only visible to the quiz taker, not to the entire class.
      Teacher can see all grades. Mentors can see grades of their students only.
    </li>
  </ul>
  <div class="p-mt-20 cta-wrapper">
    <ng-content></ng-content>
  </div>
</div>
