import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {Quiz, QuizQueryEngine, QuizzesEngineStore} from '@gigasoftware/evolving-cognition/domain';
import {
  DlcImageDirective,
  DlcRoundedIconButtonComponent,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {WindowService} from '@ngpat/utils';
import {of, Subject, switchMap} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DlcBlobImageDirective} from '@gigasoftware/shared/ui-design-library';
import {EcConvertTimePipe} from '../../../pipes/ec-convert-time/convert-time.pipe';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {
  ConfirmCopyQuiz,
  ConfirmDeleteFirestoreAndAssets,
  EcConfirmDeleteFirestoreAndAssetsComponent,
  EcCopyQuizDialogComponent,
  EcPublishQuizComponent
} from '@gigasoftware/evolving-cognition/ui';

@Component({
  selector: 'ec-quiz-list-item, [ec-quiz-list-item]',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatChipsModule,
    EcConvertTimePipe,
    DlcBlobImageDirective,
    DlcImageDirective,
    DlcRoundedTextIconButtonComponent,
    DlcRoundedIconButtonComponent,
    MatMenuModule
  ],
  templateUrl: './ec-quiz-list-item.component.html',
  styleUrls: ['./ec-quiz-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-quiz-list-item'
  }
})
export class EcQuizListItemComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  @Input() quizId!: string;

  @Output() doSelectItem: EventEmitter<Quiz> = new EventEmitter<Quiz>();
  @Output() doTakeQuiz: EventEmitter<Quiz> = new EventEmitter<Quiz>();
  @Output() doSeeAllGrades: EventEmitter<Quiz> = new EventEmitter<Quiz>();
  @Output() doEditQuiz: EventEmitter<Quiz> = new EventEmitter<Quiz>();
  @Output() doStatus: EventEmitter<Quiz> = new EventEmitter<Quiz>();

  quiz: WritableSignal<Quiz | null | undefined> = signal(null);

  constructor(
    public quizStore: QuizzesEngineStore,
    private dialog: MatDialog,
    private winResize: WindowService
  ) {}

  ngOnInit() {
    this.quizStore
      .getQuizQueryEngineById(this.quizId)
      .pipe(
        takeUntil(this._onDestroy$),
        switchMap((q: QuizQueryEngine | null | undefined) => {
          if (q) {
            return q.selectQuiz$;
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: (q: Quiz | null) => {
          // console.log(q);
          this.quiz.set(q);
        }
      });
  }

  takeQuiz() {
    const p = this.quiz();

    if (p) {
      this.doTakeQuiz.emit(p);
    }
  }

  onSeeAllGrades() {
    const p = this.quiz();

    if (p) {
      this.doSeeAllGrades.emit(p);
    }
  }

  onEditQuiz() {
    const p = this.quiz();

    if (p) {
      this.doEditQuiz.emit(p);
    }
  }

  onStats() {
    const p = this.quiz();

    if (p) {
      this.doStatus.emit(p);
    }
  }

  onCopyQuiz() {
    const quiz = this.quiz();

    if (quiz) {
      this.dialog.open(EcCopyQuizDialogComponent, {
        backdropClass: 'dlc-global-dialog-background',
        data: <ConfirmCopyQuiz>{
          quiz
        },
        minWidth: Math.min(400, this.winResize.innerWidth),
        minHeight: 300
      });

      // dialogRef.afterClosed().subscribe((newQuiz: Quiz | null) => {
      //   if (newQuiz) {
      //     if (projectTypeIsStudyGroup(newQuiz.parentProjectType)) {
      //       this.urlRouteParams.navigateWithParams(NAV.MOBILE_CLASS_LIST_PAGE);
      //     } else if (projectTypeIsClassroom(newQuiz.parentProjectType)) {
      //       this.urlRouteParams.navigateWithParams(NAV.MOBILE_CLASS_LIST_PAGE);
      //     } else {
      //       this.urlRouteParams.navigateWithParams(NAV.MOBILE_QUIZ_LIST_PAGE);
      //     }
      //   }
      // });
    }
  }

  onPublishQuiz() {
    const quiz = this.quiz();

    if (quiz) {
      this.dialog.open(EcPublishQuizComponent, {
        backdropClass: 'dlc-global-dialog-background',
        data: <ConfirmCopyQuiz>{
          quiz
        },
        minWidth: Math.min(400, this.winResize.innerWidth),
        minHeight: 300
      });
    }
  }

  onDeleteQuiz() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const that = this;
    const quiz = this.quiz();

    if (quiz) {
      this.quizStore
        .getQuizQueryEngineById(quiz.id)
        .pipe(
          take(1),
          switchMap((q: QuizQueryEngine | null | undefined) => {
            if (q) {
              return q.getImagePaths$().pipe(take(1));
            } else {
              return of([]);
            }
          })
        )
        .subscribe({
          next: (imagePaths: string[]) => {
            that.dialog.open(EcConfirmDeleteFirestoreAndAssetsComponent, {
              backdropClass: 'dlc-global-dialog-background',
              disableClose: true,
              data: <ConfirmDeleteFirestoreAndAssets>{
                name: 'Quiz',
                message: quiz.name,
                project: quiz,
                imagePaths
              }
            });
          }
        });
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }
}
