<div class="flex flex-row justify-center items-center">
  <div class="aspect-square block w-full max-w-sm overflow-hidden">
    <dlc-input-image
      [config]="inputImageConfig()"
      (deleteImage)="onDeleteImage()"></dlc-input-image>
  </div>
</div>

<form
  [formGroup]="studyGroupFormGroup"
  class="relative flex flex-col justify-start items-stretch mt-8">
  <dlc-form-field appearance="outline" class="w-full">
    <dlc-label>Name</dlc-label>
    <input dlcInput formControlName="name" />
    <dlc-error
      *ngIf="studyGroupFormGroup.controls['name'].hasError('required')"
      >{{ getErrorMessage(studyGroupFormGroup.controls["name"]) }}</dlc-error
    >
  </dlc-form-field>

  <dlc-form-field appearance="outline" class="mt-4 w-full">
    <dlc-label>Description</dlc-label>
    <input dlcInput formControlName="description" />
  </dlc-form-field>
</form>
<div class="flex flex-row justify-start items-start mt-8">
  <button
    dlc-rounded-text-icon-button
    color="primary"
    class="mr-4"
    (click)="resetNameForm()"
    [disabled]="studyGroupFormGroup.pristine">
    <mat-icon>undo</mat-icon>
    Undo
  </button>

  <button
    dlc-rounded-text-icon-button
    color="primary"
    [disabled]="studyGroupFormGroup.pristine || studyGroupFormGroup.invalid"
    (click)="saveForm()">
    <mat-icon>check</mat-icon>
    Save
  </button>
</div>
