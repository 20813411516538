import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import {JsonPipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'spt-cancel-subscription-error',
  templateUrl: './cancel-stripe-subscription-error.component.html',
  styleUrls: ['./cancel-stripe-subscription-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, JsonPipe]
})
export class CancelStripeSubscriptionErrorComponent {
  constructor(
    public dialogRef: MatDialogRef<CancelStripeSubscriptionErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
