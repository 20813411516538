import {Quiz} from '../quiz.model';

export interface MemberQuizMapValue {
  quizID: string; // quiz id
  name: string; // quiz name
  // result: number;
}

export type MemberQuizMap = Map<string, MemberQuizMapValue>;

export function memberQuizMap(quizzes: Quiz[]): MemberQuizMap {
  return quizzes.reduce((m: MemberQuizMap, q: Quiz) => {
    m.set(q.id, {quizID: q.id, name: q.name});
    return m;
  }, new Map());
}
