import {
  BreakpointObserver,
  Breakpoints,
  MediaMatcher
} from '@angular/cdk/layout';
import {
  computed,
  Injectable,
  Signal,
  signal,
  WritableSignal
} from '@angular/core';

export enum DLC_SIZE {
  /* extra small */
  XS = 'xs',
  /* greater than extra small */
  GTXS = 'gt-xs',
  /* less than small */
  LTS = 'lt-s',
  /* small */
  SM = 'sm',
  /* greater than small */
  GTSM = 'gt-sm',
  /* less than medium */
  LTM = 'lt-m',
  /* medium */
  M = 'm',
  /* greater than medium */
  GTM = 'gt-m',
  /* less than large */
  LTL = 'lt-l',
  /* large */
  L = 'l',
  /* greater than large */
  GTL = 'gt-l',
  /* less than extra large */
  LTXL = 'lt-xl',
  /* extra large */
  XL = 'xl'
}

export const sizeMap: Record<DLC_SIZE, number> = {
  [DLC_SIZE.XS]: 10,
  [DLC_SIZE.GTXS]: 20,
  [DLC_SIZE.LTS]: 30,
  [DLC_SIZE.SM]: 40,
  [DLC_SIZE.GTSM]: 50,
  [DLC_SIZE.LTM]: 60,
  [DLC_SIZE.M]: 70,
  [DLC_SIZE.GTM]: 80,
  [DLC_SIZE.LTL]: 90,
  [DLC_SIZE.L]: 100,
  [DLC_SIZE.GTL]: 110,
  [DLC_SIZE.LTXL]: 120,
  [DLC_SIZE.XL]: 140
};

/**
 * Size is greater than or equal to the given size.
 */
export function isSizeGTE(size: DLC_SIZE, sizeToCompare: DLC_SIZE): boolean {
  return sizeMap[size] >= sizeMap[sizeToCompare];
}

/**
 * Size is less than or equal to the given size.
 */
export function isSizeLTE(size: DLC_SIZE, sizeToCompare: DLC_SIZE): boolean {
  return sizeMap[size] <= sizeMap[sizeToCompare];
}

/**
 * Size is greater than the given size.
 */
export function isSizeGT(size: DLC_SIZE, sizeToCompare: DLC_SIZE): boolean {
  return sizeMap[size] > sizeMap[sizeToCompare];
}

/**
 * Size is less than the given size.
 */
export function isSizeLT(size: DLC_SIZE, sizeToCompare: DLC_SIZE): boolean {
  return sizeMap[size] < sizeMap[sizeToCompare];
}

/**
 * Size is equal to the given size.
 */
export function isSizeEQ(size: DLC_SIZE, sizeToCompare: DLC_SIZE): boolean {
  return sizeMap[size] === sizeMap[sizeToCompare];
}

/**
 * Size is not equal to the given size.
 */
export function isSizeNEQ(size: DLC_SIZE, sizeToCompare: DLC_SIZE): boolean {
  return sizeMap[size] !== sizeMap[sizeToCompare];
}

/**
 * size is extra small
 */
export function isSizeXS(size: DLC_SIZE): boolean {
  return isSizeEQ(size, DLC_SIZE.XS);
}

/**
 * size is greater than or equal to extra small
 */
export function isSizeGTEXS(size: DLC_SIZE): boolean {
  return isSizeGTE(size, DLC_SIZE.XS);
}

/**
 * Size is greater than extra small
 */
export function isSizeGTXS(size: DLC_SIZE): boolean {
  return isSizeGT(size, DLC_SIZE.XS);
}

/**
 * Size is less than small
 */
export function isSizeLTS(size: DLC_SIZE): boolean {
  return isSizeLT(size, DLC_SIZE.SM);
}

/**
 * Size is less than or equal to small
 */
export function isSizeLTES(size: DLC_SIZE): boolean {
  return isSizeLTE(size, DLC_SIZE.SM);
}

/**
 * Size is small
 */
export function isSizeSM(size: DLC_SIZE): boolean {
  return isSizeEQ(size, DLC_SIZE.SM);
}

/**
 * size is greater than or equal to small
 */
export function isSizeGTESM(size: DLC_SIZE): boolean {
  return isSizeGTE(size, DLC_SIZE.SM);
}

/**
 * Size is greater than small
 */
export function isSizeGTSM(size: DLC_SIZE): boolean {
  return isSizeGT(size, DLC_SIZE.SM);
}

/**
 * Size is less than medium
 */
export function isSizeLTM(size: DLC_SIZE): boolean {
  return isSizeLT(size, DLC_SIZE.M);
}

/**
 * Size is less than or equal to medium
 */
export function isSizeLTEM(size: DLC_SIZE): boolean {
  return isSizeLTE(size, DLC_SIZE.M);
}

/**
 * Size is medium
 */
export function isSizeM(size: DLC_SIZE): boolean {
  return isSizeEQ(size, DLC_SIZE.M);
}

/**
 * Size is greater than or equal to medium
 */
export function isSizeGTEM(size: DLC_SIZE): boolean {
  return isSizeGTE(size, DLC_SIZE.M);
}

/**
 * Size is greater than medium
 */
export function isSizeGTM(size: DLC_SIZE): boolean {
  return isSizeGT(size, DLC_SIZE.M);
}

/**
 * Size is less than large
 */
export function isSizeLTL(size: DLC_SIZE): boolean {
  return isSizeLT(size, DLC_SIZE.L);
}

/**
 * Size is less than or equal to large
 */
export function isSizeLTEL(size: DLC_SIZE): boolean {
  return isSizeLTE(size, DLC_SIZE.L);
}

/**
 * Size is large
 */
export function isSizeL(size: DLC_SIZE): boolean {
  return isSizeEQ(size, DLC_SIZE.L);
}

/**
 * Size is greater than or equal to large
 */
export function isSizeGTEL(size: DLC_SIZE): boolean {
  return isSizeGTE(size, DLC_SIZE.L);
}

/**
 * Size is greater than large
 */
export function isSizeGTL(size: DLC_SIZE): boolean {
  return isSizeGT(size, DLC_SIZE.L);
}

/**
 * Size is less than extra large
 */
export function isSizeLTXL(size: DLC_SIZE): boolean {
  return isSizeLT(size, DLC_SIZE.XL);
}

/**
 * Size is less than or equal to extra large
 */
export function isSizeLTEXL(size: DLC_SIZE): boolean {
  return isSizeLTE(size, DLC_SIZE.XL);
}

/**
 * Size is extra large
 */
export function isSizeXL(size: DLC_SIZE): boolean {
  return isSizeEQ(size, DLC_SIZE.XL);
}

/**
 * Size is greater than or equal to extra large
 */
export function isSizeGTEXL(size: DLC_SIZE): boolean {
  return isSizeGTE(size, DLC_SIZE.XL);
}

/**
 * Size is greater than extra large
 */
export function isSizeGTXL(size: DLC_SIZE): boolean {
  return isSizeGT(size, DLC_SIZE.XL);
}

/**
 * @description Size class for responsive design.
 */
@Injectable()
export class DlcSize {
  static readonly SIZE = DLC_SIZE;

  sizeSignal: WritableSignal<DLC_SIZE> = signal(DLC_SIZE.M);

  set size(size: DLC_SIZE) {
    this.sizeSignal.set(size);
  }

  get size() {
    return this.sizeSignal();
  }

  sizeIsSX: Signal<boolean> = computed(() => {
    return this.sizeSignal() === DLC_SIZE.XS;
  });

  sizeIsGTEXS: Signal<boolean> = computed(() => {
    return isSizeGTEXS(this.sizeSignal());
  });

  sizeIsGTXS: Signal<boolean> = computed(() => {
    return isSizeGTXS(this.sizeSignal());
  });

  sizeIsLTS: Signal<boolean> = computed(() => {
    return isSizeLTS(this.sizeSignal());
  });

  sizeIsLTES: Signal<boolean> = computed(() => {
    return isSizeLTES(this.sizeSignal());
  });

  sizeIsSM: Signal<boolean> = computed(() => {
    return isSizeSM(this.sizeSignal());
  });

  sizeIsGTESM: Signal<boolean> = computed(() => {
    return isSizeGTESM(this.sizeSignal());
  });

  sizeIsGTSM: Signal<boolean> = computed(() => {
    return isSizeGTSM(this.sizeSignal());
  });

  sizeIsLTM: Signal<boolean> = computed(() => {
    return isSizeLTM(this.sizeSignal());
  });

  sizeIsLTEM: Signal<boolean> = computed(() => {
    return isSizeLTEM(this.sizeSignal());
  });

  sizeIsM: Signal<boolean> = computed(() => {
    return isSizeM(this.sizeSignal());
  });

  sizeIsGTEM: Signal<boolean> = computed(() => {
    return isSizeGTEM(this.sizeSignal());
  });

  sizeIsGTM: Signal<boolean> = computed(() => {
    return isSizeGTM(this.sizeSignal());
  });

  sizeIsLTL: Signal<boolean> = computed(() => {
    return isSizeLTL(this.sizeSignal());
  });

  sizeIsLTEL: Signal<boolean> = computed(() => {
    return isSizeLTEL(this.sizeSignal());
  });

  sizeIsL: Signal<boolean> = computed(() => {
    return isSizeL(this.sizeSignal());
  });

  sizeIsGTEL: Signal<boolean> = computed(() => {
    return isSizeGTEL(this.sizeSignal());
  });

  sizeIsGTL: Signal<boolean> = computed(() => {
    return isSizeGTL(this.sizeSignal());
  });

  sizeIsLTXL: Signal<boolean> = computed(() => {
    return isSizeLTXL(this.sizeSignal());
  });

  sizeIsLTEXL: Signal<boolean> = computed(() => {
    return isSizeLTEXL(this.sizeSignal());
  });

  sizeIsXL: Signal<boolean> = computed(() => {
    return isSizeXL(this.sizeSignal());
  });

  sizeIsGTEXL: Signal<boolean> = computed(() => {
    return isSizeGTEXL(this.sizeSignal());
  });

  sizeIsGTXL: Signal<boolean> = computed(() => {
    return isSizeGTXL(this.sizeSignal());
  });

  isGTE(size: DLC_SIZE): boolean {
    return isSizeGTE(this.size, size);
  }

  isLTE(size: DLC_SIZE): boolean {
    return isSizeLTE(this.size, size);
  }

  isGT(size: DLC_SIZE): boolean {
    return isSizeGT(this.size, size);
  }

  isLT(size: DLC_SIZE): boolean {
    return isSizeLT(this.size, size);
  }

  isEQ(size: DLC_SIZE): boolean {
    return isSizeEQ(this.size, size);
  }

  isNEQ(size: DLC_SIZE): boolean {
    return isSizeNEQ(this.size, size);
  }

  isXS(): boolean {
    return isSizeXS(this.size);
  }

  isGTEXS(): boolean {
    return isSizeGTEXS(this.size);
  }

  isGTXS(): boolean {
    return isSizeGTXS(this.size);
  }

  isLTS(): boolean {
    return isSizeLTS(this.size);
  }

  isLTES(): boolean {
    return isSizeLTES(this.size);
  }

  isSM(): boolean {
    return isSizeSM(this.size);
  }

  isGTESM(): boolean {
    return isSizeGTESM(this.size);
  }

  isGTSM(): boolean {
    return isSizeGTSM(this.size);
  }

  isLTM(): boolean {
    return isSizeLTM(this.size);
  }

  isLTEM(): boolean {
    return isSizeLTEM(this.size);
  }

  isM(): boolean {
    return isSizeM(this.size);
  }

  isGTEM(): boolean {
    return isSizeGTEM(this.size);
  }

  isGTM(): boolean {
    return isSizeGTM(this.size);
  }

  isLTL(): boolean {
    return isSizeLTL(this.size);
  }

  isLTEL(): boolean {
    return isSizeLTEL(this.size);
  }

  isL(): boolean {
    return isSizeL(this.size);
  }

  isGTEL(): boolean {
    return isSizeGTEL(this.size);
  }

  isGTL(): boolean {
    return isSizeGTL(this.size);
  }

  isLTXL(): boolean {
    return isSizeLTXL(this.size);
  }

  isLTEXL(): boolean {
    return isSizeLTEXL(this.size);
  }

  isXL(): boolean {
    return isSizeXL(this.size);
  }

  isGTEXL(): boolean {
    return isSizeGTEXL(this.size);
  }

  isGTXL(): boolean {
    return isSizeGTXL(this.size);
  }
}

/**
 * Responsive design layout
 */
@Injectable({
  providedIn: 'root'
})
export class DlcLayout extends DlcSize {
  constructor(
    private mediaMatcher: MediaMatcher,
    private breakpointObserver: BreakpointObserver
  ) {
    super();

    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ])
      .subscribe(result => {
        if (result.matches) {
          if (result.breakpoints[Breakpoints.XSmall]) {
            this.size = DLC_SIZE.XS;
          } else if (result.breakpoints[Breakpoints.Small]) {
            this.size = DLC_SIZE.SM;
          } else if (result.breakpoints[Breakpoints.Medium]) {
            this.size = DLC_SIZE.M;
          } else if (result.breakpoints[Breakpoints.Large]) {
            this.size = DLC_SIZE.L;
          } else if (result.breakpoints[Breakpoints.XLarge]) {
            this.size = DLC_SIZE.XL;
          }
        }
      });
  }
}
