<h3 class="mat-h3">Promo Code</h3>
<!--<p class="mat-caption">-->
<!--  Your privacy is our priority. Only your username is exposed to other users.-->
<!--  See settings for privacy controls.-->
<!--</p>-->
<div class="p-micro-form">
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>Enter Promo Code</mat-label>
    <input
      #input
      matInput
      [formControl]="promoCodeForm"
      placeholder="ABC123"
      required />
    <mat-hint align="end">{{ input.value.length || 0 }}</mat-hint>
    <mat-error *ngIf="promoCodeForm.invalid">{{ getErrorMessage() }}</mat-error>
    <button
      [disabled]="promoCodeForm.pristine"
      mat-icon-button
      matSuffix
      [color]="promoCodeForm.dirty ? 'accent' : null"
      (click)="reset()">
      <mat-icon>undo</mat-icon>
    </button>
  </mat-form-field>
</div>
<div>
  <button
    mat-flat-button
    [disabled]="promoCodeForm.pristine"
    [color]="promoCodeForm.dirty ? 'primary' : null"
    (click)="apply()"
    class="p-mt-11">
    <mat-icon>check</mat-icon>Apply
  </button>
  <button
    mat-flat-button
    [disabled]="!(promoCodeForm.value?.length > 0)"
    color="accent"
    (click)="delete()"
    class="p-mt-11 p-ml-20">
    <mat-icon>delete</mat-icon>Delete
  </button>
</div>
