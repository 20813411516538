
import {NgModule} from '@angular/core';
// import {DlcRippleModule} from '../ripple/index';
import {DlcPseudoCheckboxModule} from '../selection/index';
import {DlcCommonModule} from '../common-behaviors/common-module';
import {DlcOption} from './option';
import {DlcOptgroup} from './optgroup';

@NgModule({
  imports: [
    // DlcRippleModule,
    DlcCommonModule,
    DlcPseudoCheckboxModule
  ],
  exports: [DlcOption, DlcOptgroup],
  declarations: [DlcOption, DlcOptgroup],
})
export class DlcOptionModule {}

export * from './option';
export * from './optgroup';
export * from './option-parent';
