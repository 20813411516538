import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'ec-publish-quiz-terms',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ec-publish-quiz-terms.component.html',
  styleUrl: './ec-publish-quiz-terms.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-publish-quiz-terms'
  }
})
export class EcPublishQuizTermsComponent {}
