<ng-template #labelTemplate>
  <!--
    MDC recommends that the text-field is a `<label>` element. This rather complicates the
    setup because it would require every form-field control to explicitly set `aria-labelledby`.
    This is because the `<label>` itself contains more than the actual label (e.g. prefix, suffix
    or other projected content), and screen readers could potentially read out undesired content.
    Excluding elements from being printed out requires them to be marked with `aria-hidden`, or
    the form control is set to a scoped element for the label (using `aria-labelledby`). Both of
    these options seem to complicate the setup because we know exactly what content is rendered
    as part of the label, and we don't want to spend resources on walking through projected content
    to set `aria-hidden`. Nor do we want to set `aria-labelledby` on every form control if we could
    simply url the label to the control using the label `for` attribute.
  -->
  <!--  <label dlcFormFieldFloatingLabel
           [floating]="_shouldLabelFloat()"
           [monitorResize]="_hasOutline()"
           *ngIf="_hasFloatingLabel()"
           [id]="_labelId"
           [attr.for]="_control?.id">-->
  <label
    dlcFormFieldFloatingLabel
    *ngIf="_hasFloatingLabel()"
    (initalized)="onFloatingLabelInitialized($event)"
    [id]="_labelId"
    [attr.for]="_control.id"
    [style.left.px]="leftLabelOffset()">
    <ng-content select="dlc-label"></ng-content>
    <!--
      We set the required marker as a separate element, in order to make it easier to target if
      apps want to override it and to be able to set `aria-hidden` so that screen readers don't
      pick it up.
     -->
    <span
      *ngIf="!hideRequiredMarker && _control.required"
      aria-hidden="true"
      class="dlc-form-field-required-marker dlc-floating-label--required"></span>
  </label>
</ng-template>

<div class="dlc-text-field-wrapper" #textField>
  <!-- NOTCH CONTAINER -->
  <div class="dlc-text-field-notch">
    <div class="dlc-text-field-notch-top">
      <div class="dlc-text-field-notch-leading"></div>
      <div
        class="dlc-text-field-notch-label-container"
        [style.width.px]="expandedLabelWidthNotch()"></div>
      <div class="dlc-text-field-notch-middle"></div>
      <div class="dlc-text-field-notch-trailing"></div>
    </div>

    <div class="dlc-text-field-notch-bottom">
      <div class="dlc-text-field-notch-leading"></div>
      <div class="dlc-text-field-notch-middle"></div>
      <div class="dlc-text-field-notch-trailing"></div>
    </div>
  </div>

  <!-- PREFIX -->
  <div
    class="dlc-form-field-icon-prefix"
    *ngIf="_hasIconPrefix"
    #iconPrefixContainer>
    <ng-content select="[dlcPrefix], [dlcIconPrefix]"></ng-content>
  </div>
  <div
    class="dlc-form-field-text-prefix"
    *ngIf="_hasTextPrefix"
    #textPrefixContainer>
    <ng-content select="[dlcTextPrefix]"></ng-content>
  </div>

  <ng-template [ngTemplateOutlet]="labelTemplate"></ng-template>

  <!-- CONTENT - INPUT, SELECT, TEXTAREA, ETC -->
  <div class="dlc-text-field-infix">
    <ng-content></ng-content>
  </div>
  <!-- The dlc-input itself -->

  <!-- SUFFIX -->
  <div class="dlc-form-field-text-suffix" *ngIf="_hasTextSuffix">
    <ng-content select="[dlcTextSuffix]"></ng-content>
  </div>
  <div class="dlc-form-field-icon-suffix" *ngIf="_hasIconSuffix">
    <ng-content select="[dlcSuffix], [dlcIconSuffix]"></ng-content>
  </div>
</div>

<div
  class="dlc-form-field-subscript-wrapper dlc-form-field-bottom-align"
  [class.dlc-form-field-subscript-dynamic-size]="subscriptSizing === 'dynamic'"
  [ngSwitch]="_getDisplayedMessages()">
  <div
    class="dlc-form-field-error-wrapper"
    *ngSwitchCase="'error'"
    [style.padding-left.px]="errorLeftPadding()"
    [@transitionMessages]="_subscriptAnimationState">
    <ng-content select="dlc-error, [matError]"></ng-content>
  </div>

  <div
    class="dlc-form-field-hint-wrapper"
    *ngSwitchCase="'hint'"
    [@transitionMessages]="_subscriptAnimationState">
    <dlc-hint *ngIf="hintLabel" [id]="_hintLabelId">{{ hintLabel }}</dlc-hint>
    <ng-content select="dlc-hint:not([align='end'])"></ng-content>
    <div class="dlc-form-field-hint-spacer"></div>
    <ng-content select="dlc-hint[align='end']"></ng-content>
  </div>
</div>
