import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'dlc-rounded-letter, [dlcRoundedLetter]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dlc-rounded-letter.component.html',
  styleUrl: './dlc-rounded-letter.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'dlc-rounded-letter'
  }
})
export class DlcRoundedLetterComponent {}
