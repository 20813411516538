import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  CreateClassParams,
  createClassroomEffect,
  CreateClassroomForm,
  DEFAULT_PLATFORM_PROJECT_TYPE_DICT,
  DefaultProjectTypeDict
} from '@gigasoftware/evolving-cognition/domain';
import {
  DlcFormFieldModule,
  DlcInputDirective,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {uuid} from '@ngpat/fn';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';

@Component({
  selector: 'ec-create-classroom',
  templateUrl: './ec-create-classroom.component.html',
  styleUrls: ['./ec-create-classroom.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-create-classroom'
  },
  standalone: true,
  imports: [
    CommonModule,
    DlcFormFieldModule,
    DlcInputDirective,
    DlcRoundedTextIconButtonComponent,
    ReactiveFormsModule
  ]
})
export class EcCreateClassroomComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  projectTypeDict: DefaultProjectTypeDict = DEFAULT_PLATFORM_PROJECT_TYPE_DICT;

  classroomFormGroup: FormGroup;

  @Output() createClassAction: EventEmitter<CreateClassParams> =
    new EventEmitter<CreateClassParams>();

  constructor(private store: Store, private _fb: FormBuilder) {
    this.classroomFormGroup = this._fb.group({
      name: new FormControl(null, [Validators.required]),
      section: new FormControl(null),
      subject: new FormControl(null),
      room: new FormControl(null)
    });
  }

  // onCreateClass(p: CreateProjectParams) {
  //   this._urlRouteParams.navigateWithParamsAction(
  //     NAV.MOBILE_CLASS_ITEM_PAGE,
  //     getProjectIdProp(p, URL_PROPS.CLASSROOM_ID)
  //   );
  // }

  onSubmit() {
    if (this.classroomFormGroup.valid) {
      const formValue: CreateClassroomForm = this.classroomFormGroup.value;

      if (formValue.name) {
        const p: CreateClassParams = {
          name: <string>formValue.name,
          section: formValue.section,
          subject: formValue.subject,
          room: formValue.room,
          id: uuid(),
          projectType: DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id,
          isCollaborative: true,
          isPrivate: false,
          description: ''
        };

        this.store.dispatch(createClassroomEffect({params: p}));

        this.classroomFormGroup.reset(<CreateClassroomForm>{
          name: null,
          section: null,
          subject: null,
          room: null
        });

        this.createClassAction.emit(p);

        // this._urlRouteParams.navigateWithParamsAction(
        //   NAV.MOBILE_CLASS_ITEM_PAGE,
        //   getProjectIdProp(p, URL_PROPS.CLASSROOM_ID)
        // );
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
