import {DEFAULT_NAV_ITEM_ID, NavItem} from '@gigasoftware/shared/domain';
import {URL_PROPS} from '../+project/project.model';
import {NavItemDict} from '../+ui/ui.model';

export enum NAV {
  MOBILE_HOME_PAGE = DEFAULT_NAV_ITEM_ID,

  // ADMIN
  MOBILE_ADMIN_SUBJECTS_PAGE = '20',
  MOBILE_ADMIN_USERS_PAGE = '30',

  MOBILE_PROFILE_PAGE = '100',
  MOBILE_LOGIN_PAGE = '200',
  MOBILE_ACCOUNT_PAGE = '300',

  MOBILE_QUIZ_QUESTION_CREATE_PAGE = '625',
  MOBILE_QUIZ_QUESTION_EDIT_PAGE = '650',
  MOBILE_QUIZ_CREATE_PAGE = '400',
  MOBILE_QUIZ_EDIT_PAGE = '1200',
  MOBILE_QUIZ_GRADES_PAGE = '1600',
  MOBILE_QUIZ_STATS_PAGE = '600',
  MOBILE_QUIZ_LIST_PAGE = '500',
  MOBILE_QUIZ_REVIEW = '2300',
  MOBILE_QUIZ_TEST_PAGE = '1400',

  MOBILE_STUDY_GROUP_CREATE_PAGE = '700',
  MOBILE_STUDY_GROUP_LIST_PAGE = '800',
  MOBILE_STUDY_GROUP_ITEM_PAGE = '900',
  MOBILE_STUDY_GROUP_EDIT_PAGE = '2100',
  MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE = '1100',
  MOBILE_STUDY_GROUP_QUIZ_CREATE_PAGE = '1000',
  MOBILE_STUDY_GROUP_QUIZ_QUESTION_CREATE_PAGE = '1050',
  MOBILE_STUDY_GROUP_QUIZ_QUESTION_EDIT_PAGE = '1075',
  MOBILE_STUDY_GROUP_QUIZ_EDIT_PAGE = '1300',
  MOBILE_STUDY_GROUP_QUIZ_TEST_PAGE = '1500',
  MOBILE_STUDY_GROUP_QUIZ_GRADES_PAGE = '1700',
  MOBILE_STUDY_GROUP_ALL_GRADES_PAGE = '1900',

  MOBILE_STUDY_GROUP_QUIZ_REVIEW_PAGE = '2400',
  MOBILE_SUBSCRIBE_PAGE = '2500',

  MOBILE_SUPPORT_PAGE = '2600',

  MOBILE_PRIVACY_POLICY_PAGE = '2700',
  MOBILE_TERMS_OF_USE_PAGE = '2800',

  MOBILE_CLASS_CREATE_PAGE = '2900',
  MOBILE_CLASS_LIST_PAGE = '3000',
  MOBILE_CLASS_ITEM_PAGE = '3100',
  MOBILE_CLASS_EDIT_PAGE = '3200',
  MOBILE_CLASS_QUIZ_STATS_PAGE = '3400',
  MOBILE_CLASS_QUIZ_CREATE_PAGE = '3500',
  MOBILE_CLASS_QUIZ_EDIT_PAGE = '3600',
  MOBILE_CLASS_QUIZ_TEST_PAGE = '3700',
  MOBILE_CLASS_QUIZ_QUESTION_CREATE_PAGE = '3750',
  MOBILE_CLASS_QUIZ_QUESTION_EDIT_PAGE = '3775',
  MOBILE_CLASS_QUIZ_STUDENT_GRADES_PAGE = '3800',
  MOBILE_CLASS_ALL_GRADES_PAGE = '3900',
  MOBILE_CLASS_QUIZ_REVIEW_PAGE = '4000',
  MOBILE_CLASS_QUIZ_ALL_STUDENT_GRADES_PAGE = '4500',

  DASHBOARD_CLASSES_PAGE = '10000',
  DASHBOARD_PERSONAL_QUIZZES_PAGE = '10100',
  DASHBOARD_STUDY_GROUPS_PAGE = '10200',
  DASHBOARD_PROFILE_PAGE = '10300',
  DASHBOARD_ACCOUNT_PAGE = '10400',
  DASHBOARD_SUPPORT_PAGE = '10500',
  DASHBOARD_PRIVACY_POLICY_PAGE = '10600',
  DASHBOARD_TERMS_OF_USE_PAGE = '10700',
  DASHBOARD_STUDY_GROUP_PAGE = '10800'
}

export const navItemDict: NavItemDict = {
  [NAV.MOBILE_HOME_PAGE]: {
    id: NAV.MOBILE_HOME_PAGE,
    url: '/home',
    navigateTo: ['home'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'home',
    name: 'Home',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_ADMIN_SUBJECTS_PAGE]: {
    id: NAV.MOBILE_ADMIN_SUBJECTS_PAGE,
    url: '/mobile/admin-subjects',
    navigateTo: ['mobile', 'admin-subjects'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'subscriptions',
    name: 'Admin Subjects',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_ADMIN_USERS_PAGE]: {
    id: NAV.MOBILE_ADMIN_USERS_PAGE,
    url: '/mobile/admin-users',
    navigateTo: ['mobile', 'admin-users'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'subscriptions',
    name: 'Admin Users',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_SUBSCRIBE_PAGE]: {
    id: NAV.MOBILE_SUBSCRIBE_PAGE,
    url: '/mobile/subscribe',
    navigateTo: ['mobile', 'subscribe'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'subscriptions',
    name: 'Subscribe',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_PROFILE_PAGE]: {
    id: NAV.MOBILE_PROFILE_PAGE,
    url: '/mobile/profile',
    navigateTo: ['mobile', 'profile'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'account_circle',
    name: 'Profile',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_LOGIN_PAGE]: {
    id: NAV.MOBILE_LOGIN_PAGE,
    url: '/mobile/login',
    navigateTo: ['mobile', 'login'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'login',
    name: 'Login',
    paramKeys: [],
    includeTopNavForParent: false,
    includeLogoForParent: false
  },
  [NAV.MOBILE_ACCOUNT_PAGE]: {
    id: NAV.MOBILE_ACCOUNT_PAGE,
    url: '/mobile/account',
    navigateTo: ['mobile', 'account'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'account_circle',
    name: 'Account',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },

  [NAV.MOBILE_QUIZ_CREATE_PAGE]: {
    id: NAV.MOBILE_QUIZ_CREATE_PAGE,
    url: '/mobile/quiz-create',
    navigateTo: ['mobile', 'quiz-create'],
    previousNavItemID: NAV.MOBILE_QUIZ_LIST_PAGE,
    icon: 'add',
    name: 'Create Quiz',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_QUIZ_EDIT_PAGE]: {
    id: NAV.MOBILE_QUIZ_EDIT_PAGE,
    url: '/mobile/quiz-edit',
    navigateTo: ['mobile', 'quiz-edit'],
    previousNavItemID: NAV.MOBILE_QUIZ_LIST_PAGE,
    icon: 'edit',
    name: 'Edit Quiz',
    paramKeys: [URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_QUIZ_GRADES_PAGE]: {
    id: NAV.MOBILE_QUIZ_GRADES_PAGE,
    url: '/mobile/quiz-grades',
    navigateTo: ['mobile', 'quiz-grades'],
    previousNavItemID: NAV.MOBILE_QUIZ_LIST_PAGE,
    icon: 'grading',
    name: 'Grades',
    paramKeys: [URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_QUIZ_REVIEW]: {
    id: NAV.MOBILE_QUIZ_REVIEW,
    url: '/mobile/quiz-review',
    navigateTo: ['mobile', 'quiz-review'],
    previousNavItemID: NAV.MOBILE_QUIZ_GRADES_PAGE,
    icon: 'read_more',
    name: 'Review',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUIZ_REVIEW_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_QUIZ_QUESTION_CREATE_PAGE]: {
    id: NAV.MOBILE_QUIZ_QUESTION_CREATE_PAGE,
    url: '/mobile/quiz-question-create',
    navigateTo: ['mobile', 'quiz-question-create'],
    previousNavItemID: NAV.MOBILE_QUIZ_EDIT_PAGE,
    icon: 'edit',
    name: 'Create Question',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_QUIZ_QUESTION_EDIT_PAGE]: {
    id: NAV.MOBILE_QUIZ_QUESTION_EDIT_PAGE,
    url: '/mobile/quiz-question-edit',
    navigateTo: ['mobile', 'quiz-question-edit'],
    previousNavItemID: NAV.MOBILE_QUIZ_EDIT_PAGE,
    icon: 'edit',
    name: 'Edit Question',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_QUIZ_LIST_PAGE]: {
    id: NAV.MOBILE_QUIZ_LIST_PAGE,
    url: '/mobile/quiz-list',
    navigateTo: ['mobile', 'ec-quiz-list'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'quiz',
    name: 'Quizzes',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_QUIZ_STATS_PAGE]: {
    id: NAV.MOBILE_QUIZ_STATS_PAGE,
    url: '/mobile/quiz-stats',
    navigateTo: ['mobile', 'quiz-stats'],
    previousNavItemID: NAV.MOBILE_QUIZ_LIST_PAGE,
    icon: 'quiz',
    name: 'Quiz',
    paramKeys: [URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_QUIZ_TEST_PAGE]: {
    id: NAV.MOBILE_QUIZ_TEST_PAGE,
    url: '/mobile/quiz-test',
    navigateTo: ['mobile', 'quiz-test'],
    previousNavItemID: NAV.MOBILE_QUIZ_STATS_PAGE,
    icon: 'quiz',
    name: 'Quiz',
    paramKeys: [URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },

  [NAV.MOBILE_STUDY_GROUP_CREATE_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_CREATE_PAGE,
    url: '/mobile/study-group-create',
    navigateTo: ['mobile', 'study-group-create'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_LIST_PAGE,
    icon: 'add',
    name: 'Study Group',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_LIST_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_LIST_PAGE,
    url: '/mobile/study-group-list',
    navigateTo: ['mobile', 'study-group-list'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'groups',
    name: 'Study Groups',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_ITEM_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_ITEM_PAGE,
    url: '/mobile/study-group-item',
    navigateTo: ['mobile', 'study-group-item'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_LIST_PAGE,
    icon: 'groups',
    name: 'Study Group',
    paramKeys: [URL_PROPS.STUDY_GROUP_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE,
    url: '/mobile/study-group-quiz-stats',
    navigateTo: ['mobile', 'study-group-quiz-stats'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_ITEM_PAGE,
    icon: 'quiz',
    name: 'Quiz',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.STUDY_GROUP_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_TEST_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_TEST_PAGE,
    url: '/mobile/study-group-quiz-item-test',
    navigateTo: ['mobile', 'study-group-quiz-item-test'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE,
    icon: 'quiz',
    name: 'Quiz',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.STUDY_GROUP_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_CREATE_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_CREATE_PAGE,
    url: '/mobile/study-group-quiz-create',
    navigateTo: ['mobile', 'study-group-quiz-create'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_ITEM_PAGE,
    icon: 'add',
    name: 'Add Quiz',
    paramKeys: [URL_PROPS.STUDY_GROUP_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_EDIT_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_EDIT_PAGE,
    url: '/mobile/study-group-quiz-edit',
    navigateTo: ['mobile', 'study-group-quiz-edit'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE,
    icon: 'edit',
    name: 'Edit Quiz',
    paramKeys: [URL_PROPS.STUDY_GROUP_ID, URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_QUESTION_CREATE_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_QUESTION_CREATE_PAGE,
    url: '/mobile/study-group-quiz-question-create',
    navigateTo: ['mobile', 'study-group-quiz-question-create'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_EDIT_PAGE,
    icon: 'edit',
    name: 'Create Question',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_QUESTION_EDIT_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_QUESTION_EDIT_PAGE,
    url: '/mobile/study-group-quiz-question-edit',
    navigateTo: ['mobile', 'study-group-quiz-question-edit'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_EDIT_PAGE,
    icon: 'edit',
    name: 'Edit Question',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },

  [NAV.MOBILE_STUDY_GROUP_QUIZ_GRADES_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_GRADES_PAGE,
    url: '/mobile/study-group-quiz-grades',
    navigateTo: ['mobile', 'study-group-quiz-grades'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE,
    icon: 'grading',
    name: 'Grades',
    paramKeys: [URL_PROPS.STUDY_GROUP_ID, URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_REVIEW_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_REVIEW_PAGE,
    url: '/mobile/study-group-quiz-review',
    navigateTo: ['mobile', 'study-group-quiz-review'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_GRADES_PAGE,
    icon: 'read_more',
    name: 'Review',
    paramKeys: [URL_PROPS.STUDY_GROUP_ID, URL_PROPS.QUIZ_ID, URL_PROPS.QUIZ_REVIEW_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_ALL_GRADES_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_ALL_GRADES_PAGE,
    url: '/mobile/study-group-all-grades',
    navigateTo: ['mobile', 'study-group-all-grades'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_ITEM_PAGE,
    icon: 'grading',
    name: 'All Grades',
    paramKeys: [URL_PROPS.STUDY_GROUP_ID, URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_STUDY_GROUP_EDIT_PAGE]: {
    id: NAV.MOBILE_STUDY_GROUP_EDIT_PAGE,
    url: '/mobile/study-group-edit',
    navigateTo: ['mobile', 'study-group-edit'],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_LIST_PAGE,
    icon: 'edit',
    name: 'Edit',
    paramKeys: [URL_PROPS.STUDY_GROUP_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },

  [NAV.MOBILE_CLASS_CREATE_PAGE]: {
    id: NAV.MOBILE_CLASS_CREATE_PAGE,
    url: '/mobile/class-create',
    navigateTo: ['mobile', 'class-create'],
    previousNavItemID: NAV.MOBILE_CLASS_LIST_PAGE,
    icon: 'add',
    name: 'Class',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_LIST_PAGE]: {
    id: NAV.MOBILE_CLASS_LIST_PAGE,
    url: '/mobile/class-list',
    navigateTo: ['mobile', 'class-list'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'school',
    name: 'Classes',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_ITEM_PAGE]: {
    id: NAV.MOBILE_CLASS_ITEM_PAGE,
    url: '/mobile/class-item',
    navigateTo: ['mobile', 'class-item'],
    previousNavItemID: NAV.MOBILE_CLASS_LIST_PAGE,
    icon: 'school',
    name: 'Class',
    paramKeys: [URL_PROPS.CLASSROOM_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_QUIZ_STATS_PAGE]: {
    id: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
    url: '/mobile/class-quiz-stats',
    navigateTo: ['mobile', 'class-quiz-stats'],
    previousNavItemID: NAV.MOBILE_CLASS_ITEM_PAGE,
    icon: 'school',
    name: 'Class',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.CLASSROOM_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_QUIZ_TEST_PAGE]: {
    id: NAV.MOBILE_CLASS_QUIZ_TEST_PAGE,
    url: '/mobile/class-quiz-test',
    navigateTo: ['mobile', 'class-quiz-test'],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
    icon: 'school',
    name: 'Class',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.CLASSROOM_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_QUIZ_CREATE_PAGE]: {
    id: NAV.MOBILE_CLASS_QUIZ_CREATE_PAGE,
    url: '/mobile/class-quiz-create',
    navigateTo: ['mobile', 'class-quiz-create'],
    previousNavItemID: NAV.MOBILE_CLASS_ITEM_PAGE,
    icon: 'add',
    name: 'Add Quiz',
    paramKeys: [URL_PROPS.CLASSROOM_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_QUIZ_EDIT_PAGE]: {
    id: NAV.MOBILE_CLASS_QUIZ_EDIT_PAGE,
    url: '/mobile/class-quiz-edit',
    navigateTo: ['mobile', 'class-quiz-edit'],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
    icon: 'edit',
    name: 'Edit Quiz',
    paramKeys: [URL_PROPS.CLASSROOM_ID, URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_QUIZ_QUESTION_CREATE_PAGE]: {
    id: NAV.MOBILE_CLASS_QUIZ_QUESTION_CREATE_PAGE,
    url: '/mobile/class-quiz-question-create',
    navigateTo: ['mobile', 'class-quiz-question-create'],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_EDIT_PAGE,
    icon: 'edit',
    name: 'Create Question',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_QUIZ_QUESTION_EDIT_PAGE]: {
    id: NAV.MOBILE_CLASS_QUIZ_QUESTION_EDIT_PAGE,
    url: '/mobile/class-quiz-question-edit',
    navigateTo: ['mobile', 'class-quiz-question-edit'],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_EDIT_PAGE,
    icon: 'edit',
    name: 'Edit Question',
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_QUIZ_STUDENT_GRADES_PAGE]: {
    id: NAV.MOBILE_CLASS_QUIZ_STUDENT_GRADES_PAGE,
    url: '/mobile/class-quiz-student-grades',
    navigateTo: ['mobile', 'class-quiz-student-grades'],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
    icon: 'grading',
    name: 'Grades',
    paramKeys: [URL_PROPS.CLASSROOM_ID, URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  // [NAV.MOBILE_CLASS_QUIZ_ALL_STUDENTS_GRADES_PAGE]: {
  //   id: NAV.MOBILE_CLASS_QUIZ_ALL_STUDENTS_GRADES_PAGE,
  //   url: '/mobile/class-all-grades',
  //   navigateTo: ['mobile', 'class-quiz-all-student-grades'],
  //   previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
  //   icon: 'grading',
  //   name: 'All Grades',
  //   paramKeys: [URL_PROPS.CLASSROOM_ID, URL_PROPS.QUIZ_ID],
  //   includeTopNavForParent: true,
  //   includeLogoForParent: false
  // },
  [NAV.MOBILE_CLASS_QUIZ_ALL_STUDENT_GRADES_PAGE]: {
    id: NAV.MOBILE_CLASS_QUIZ_ALL_STUDENT_GRADES_PAGE,
    url: '/mobile/class-quiz-all-student-grades',
    navigateTo: [],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
    icon: 'grading',
    name: 'All Grades',
    paramKeys: [URL_PROPS.CLASSROOM_ID, URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_QUIZ_REVIEW_PAGE]: {
    id: NAV.MOBILE_CLASS_QUIZ_REVIEW_PAGE,
    url: '/mobile/class-quiz-review',
    navigateTo: ['mobile', 'class-quiz-review'],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STUDENT_GRADES_PAGE,
    icon: 'read_more',
    name: 'Review',
    paramKeys: [URL_PROPS.CLASSROOM_ID, URL_PROPS.QUIZ_ID, URL_PROPS.QUIZ_REVIEW_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_ALL_GRADES_PAGE]: {
    id: NAV.MOBILE_CLASS_ALL_GRADES_PAGE,
    url: '/mobile/class-all-grades',
    navigateTo: ['mobile', 'class-all-grades'],
    previousNavItemID: NAV.MOBILE_CLASS_ITEM_PAGE,
    icon: 'grading',
    name: 'All Grades',
    paramKeys: [URL_PROPS.STUDY_GROUP_ID, URL_PROPS.QUIZ_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_CLASS_EDIT_PAGE]: {
    id: NAV.MOBILE_CLASS_EDIT_PAGE,
    url: '/mobile/class-edit',
    navigateTo: ['mobile', 'class-edit'],
    previousNavItemID: NAV.MOBILE_CLASS_LIST_PAGE,
    icon: 'edit',
    name: 'Edit',
    paramKeys: [URL_PROPS.CLASSROOM_ID],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.MOBILE_SUPPORT_PAGE]: {
    id: NAV.MOBILE_SUPPORT_PAGE,
    url: '/mobile/support',
    navigateTo: ['mobile', 'support'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'support',
    name: 'Support',
    paramKeys: [],
    includeTopNavForParent: false,
    includeLogoForParent: false
  },
  [NAV.MOBILE_PRIVACY_POLICY_PAGE]: {
    id: NAV.MOBILE_PRIVACY_POLICY_PAGE,
    url: '/privacy-policy',
    navigateTo: ['privacy-policy'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'security',
    name: 'Privacy Policy',
    paramKeys: [],
    includeTopNavForParent: false,
    includeLogoForParent: false
  },
  [NAV.MOBILE_TERMS_OF_USE_PAGE]: {
    id: NAV.MOBILE_TERMS_OF_USE_PAGE,
    url: '/terms-of-use',
    navigateTo: ['terms-of-use'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'policy',
    name: 'Terms Of Use',
    paramKeys: [],
    includeTopNavForParent: false,
    includeLogoForParent: false
  },

  /**
   * Home page determined at:
   * libs/web-platform/data-access/src/lib/+ui/ui.selectors.ts:80
   */
  [NAV.DASHBOARD_CLASSES_PAGE]: {
    id: NAV.DASHBOARD_CLASSES_PAGE,
    url: '/dashboard/classes',
    navigateTo: ['dashboard', 'classes'],
    previousNavItemID: NAV.DASHBOARD_CLASSES_PAGE,
    icon: 'school',
    name: 'school',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },

  [NAV.DASHBOARD_PERSONAL_QUIZZES_PAGE]: {
    id: NAV.DASHBOARD_PERSONAL_QUIZZES_PAGE,
    url: '/dashboard/personal-quizzes',
    navigateTo: ['dashboard', 'personal-quizzes'],
    previousNavItemID: NAV.DASHBOARD_CLASSES_PAGE,
    icon: 'school',
    name: 'school',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },

  [NAV.DASHBOARD_STUDY_GROUPS_PAGE]: {
    id: NAV.DASHBOARD_STUDY_GROUPS_PAGE,
    url: '/dashboard/study-groups',
    navigateTo: ['dashboard', 'study-groups'],
    previousNavItemID: NAV.DASHBOARD_STUDY_GROUPS_PAGE,
    icon: 'school',
    name: 'school',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },

  [NAV.DASHBOARD_STUDY_GROUP_PAGE]: {
    id: NAV.DASHBOARD_STUDY_GROUP_PAGE,
    url: '/dashboard/study-group',
    navigateTo: ['dashboard', 'study-group'],
    previousNavItemID: NAV.DASHBOARD_STUDY_GROUP_PAGE,
    icon: 'school',
    name: 'school',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.DASHBOARD_PROFILE_PAGE]: {
    id: NAV.DASHBOARD_PROFILE_PAGE,
    url: '/dashboard/profile',
    navigateTo: ['dashboard', 'profile'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'account_circle',
    name: 'Profile',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.DASHBOARD_ACCOUNT_PAGE]: {
    id: NAV.MOBILE_ACCOUNT_PAGE,
    url: '/dashboard/account',
    navigateTo: ['dashboard', 'account'],
    previousNavItemID: NAV.DASHBOARD_ACCOUNT_PAGE,
    icon: 'account_circle',
    name: 'Account',
    paramKeys: [],
    includeTopNavForParent: true,
    includeLogoForParent: false
  },
  [NAV.DASHBOARD_SUPPORT_PAGE]: {
    id: NAV.DASHBOARD_SUPPORT_PAGE,
    url: '/dashboard/support',
    navigateTo: ['dashboard', 'support'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'support',
    name: 'Support',
    paramKeys: [],
    includeTopNavForParent: false,
    includeLogoForParent: false
  },
  [NAV.DASHBOARD_PRIVACY_POLICY_PAGE]: {
    id: NAV.MOBILE_PRIVACY_POLICY_PAGE,
    url: '/dashboard/privacy-policy',
    navigateTo: ['dashboard', 'privacy-policy'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'security',
    name: 'Privacy Policy',
    paramKeys: [],
    includeTopNavForParent: false,
    includeLogoForParent: false
  },
  [NAV.DASHBOARD_TERMS_OF_USE_PAGE]: {
    id: NAV.DASHBOARD_TERMS_OF_USE_PAGE,
    url: '/dashboard/terms-of-use',
    navigateTo: ['dashboard', 'terms-of-use'],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    icon: 'policy',
    name: 'Terms Of Use',
    paramKeys: [],
    includeTopNavForParent: false,
    includeLogoForParent: false
  }
};

export function getNavItem(nav: NAV): NavItem {
  return navItemDict[nav];
}

export function getNavItemUrl(nav: NAV): string {
  return navItemDict[nav]?.url;
}
