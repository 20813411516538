import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  cloneInitialQuizTestProgress,
  QuizTestProgress
} from '@gigasoftware/evolving-cognition/domain';

@Component({
  selector: 'ec-quiz-test-progress',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ec-quiz-test-progress.component.html',
  styleUrls: ['./ec-quiz-test-progress.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-quiz-test-progress ec-quiz'
  }
})
export class EcQuizTestProgressComponent {
  progressSig: WritableSignal<QuizTestProgress> = signal(
    cloneInitialQuizTestProgress()
  );

  @Input()
  set progress(value: QuizTestProgress) {
    this.progressSig.set(value);
  }
}
