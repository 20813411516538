<div class="current-plan" *ngIf="isOwned()">current plan</div>
<div class="flex items-center justify-between gap-x-4">
  <h3 id="tier-startup" class="text-lg font-semibold leading-8 text-white">
    {{ title() }}
  </h3>
  <p
    *ngIf="isMostPopular()"
    class="most-popular-button rounded-full px-2.5 py-1 text-xs font-semibold leading-5">
    Most popular
  </p>
</div>
<p class="mt-4 text-sm leading-6 text-gray-300">
  {{ description() }}
</p>
<p class="mt-6 flex items-baseline gap-x-1">
  @if (priceSig()?.isCustomPrice) {
    <span class="text-white text-4xl font-bold tracking-tight">Custom</span>
  } @else {
    <span class="text-4xl font-bold tracking-tight text-white">
      {{ priceInteger() }}
      <sup class="superscript">{{ priceDecimal() }}</sup>
    </span>
    <!-- Payment frequency, update based on frequency toggle state -->
    <span class="text-sm font-semibold leading-6 text-gray-300">/{{ interval() }}</span>
  }
</p>
@if (!isLoggedIn()) {
  <button
    dlc-rounded-text-button
    color="accent"
    (click)="doLogin.emit(true)"
    aria-describedby="tier-startup"
    class="buy-button">
    Login to Buy
  </button>
} @else if (
  purchaseButtonState() === btnState.PURCHASE || purchaseButtonState() === btnState.DISABLED
) {
  <button
    dlc-rounded-text-button
    color="primary"
    (click)="onSelectedOffer($event)"
    [disabled]="purchaseButtonState() === btnState.DISABLED"
    aria-describedby="tier-startup"
    class="buy-button">
    Buy
  </button>
} @else {
  <button
    dlc-rounded-text-button
    color="accent"
    (click)="onCancelOffer($event)"
    aria-describedby="tier-startup"
    class="buy-button">
    Cancel Subscription
  </button>
}

<h5 class="mt-8">Features Include:</h5>
<ul role="list" class="space-y-3 text-sm leading-6 text-gray-300 xl:mt-10 !pl-0">
  @for (i of priceSig()?.features; track i) {
    <li class="flex gap-x-3">
      <svg
        class="h-6 w-5 flex-none text-white"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true">
        <path
          fill-rule="evenodd"
          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
          clip-rule="evenodd" />
      </svg>
      {{ i }}
    </li>
  }
</ul>
