import {ApplicationConfig} from '@angular/core';
import {provideRouter} from '@angular/router';
import {createNgPatFirebaseAppInstance, NG_PAT_FIREBASE_INSTANCE} from '@ngpat/firebase';
import {environment} from '../environments/environment';
import {appRoutes} from './app.routes';

const appInstance = createNgPatFirebaseAppInstance(environment.firebaseConfigParams);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    {
      provide: NG_PAT_FIREBASE_INSTANCE,
      useValue: appInstance
    }
  ]
};
