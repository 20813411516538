import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Inject, OnInit, signal,
  ViewEncapsulation, WritableSignal
} from '@angular/core';
import {
  combinePublishedQuestionsAndPath,
  combineQuestionsAndPath,
  createOwnerUserRole,
  createProjectUserArchitecture, firestorePublishedQuizDoc,
  firestoreQuizQuestionsByProject, getImagePathsFromQuizAndQuestions,
  Project,
  Question, QuestionWithPath,
  Quiz,
  QuizService, updateImagePathWithID
} from '@gigasoftware/evolving-cognition/domain';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {GsAssetService} from '@gigasoftware/shared/media';
import { take } from 'rxjs/operators';
import {User} from 'firebase/auth';
import {uuid} from '@ngpat/fn';
import {CopyAsset, CopyAssetToPathParams} from '@gigasoftware/shared/entities';
import {NgPatProcessQueue} from '@ngpat/utils';
import {convertToUnixTimestamp} from '../../../../../domain/src/lib/models/time-stamp.model';

export interface ConfirmPublishQuiz {
  quiz: Quiz;
}

@Component({
  selector: 'ec-publish-quiz',
  templateUrl: './ec-publish-quiz.component.html',
  styleUrl: './ec-publish-quiz.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-publish-quiz'
  }
})
export class EcPublishQuizComponent implements OnInit {

  acceptedTerms: boolean = false;

  isCopying: WritableSignal<boolean> = signal(false);
  copyingQuiz: WritableSignal<string> = signal('');

  firestoreAssetPathProcessQueue: NgPatProcessQueue<CopyAssetToPathParams> =
    new NgPatProcessQueue<CopyAssetToPathParams>();

  imagePathsToCopy: CopyAsset[] = [];

  constructor(
    public dialogRef: MatDialogRef<EcPublishQuizComponent>,
    private store: Store,
    private firestore: NgPatFirestoreService,
    private assetService: GsAssetService,
    private cd: ChangeDetectorRef,
    private quizService: QuizService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPublishQuiz
  ) {
  }

  ngOnInit() {
    this.firestoreAssetPathProcessQueue.currentItem$.subscribe(
      async (item: CopyAssetToPathParams) => {
        const replace = new RegExp(<string>item.oldID, 'g');

        // console.log('item', item);

        const list = await this.assetService.getDirectoryList(item.oldBasePath);

        // console.log(list);

        list.forEach((path: string) => {
          this.imagePathsToCopy.push({
            oldAssetImagePath: path,
            newAssetImagePath: path.replace(replace, item.newID)
          });
        });
        this.firestoreAssetPathProcessQueue.next();
      }
    );
  }

  async onPublish() {
    const that = this;
    if (this.acceptedTerms) {
      this.firestore.user$.pipe(take(1))
        .subscribe(async (user: User) => {
          const uid = user.uid;

          /**
           * UPDATE UI VIEW TO LET USER KNOW
           * QUIZ, QUESTIONS, AND ASSETS ARE
           * BEING COPIED
           */

          const copyQuizToMessage = `Publishing ${that.data.quiz.name}`;

          this.copyingQuiz.set(copyQuizToMessage);
          this.isCopying.set(true);

          /**
           * SOURCE
           */

          const questionsFirestoreSourcePath = firestoreQuizQuestionsByProject(
            that.data.quiz as Project,
            uid
          );

          const sourceQuestions: Question[] = await this.firestore.collectionData(
            questionsFirestoreSourcePath
          );

          /**
           * CREATE NEW QUIZ AND QUESTIONS
           * WITH NEW ID
           */
          const newQuiz: Quiz = {
            ...that.data.quiz,
            id: uuid(),
            ...createProjectUserArchitecture(createOwnerUserRole(uid)),
            unixTimestamp: convertToUnixTimestamp(that.data.quiz.updatedAt),
            totalQuestions: sourceQuestions.length,
            publisherUID: uid
          };

          newQuiz.isCollaborative = false;
          newQuiz.parentProjectID = null;
          newQuiz.parentProjectType = null;

          newQuiz.isDefault = false;
          newQuiz.isPrivate = false;
          newQuiz.isPublished = true;
          newQuiz.assigned = true;


          const newQuestions: Question[] = sourceQuestions.map((q: Question) => {
            return {
              ...q,
              id: uuid(),
              quizID: newQuiz.id
            };
          });

          const firestoreDestinationPath = firestorePublishedQuizDoc(newQuiz.id);


          /**
           * NOTE: created after quiz params are updated
           */
          const newQuestionsWithFirestorePath: QuestionWithPath[] =
            combinePublishedQuestionsAndPath(newQuestions, newQuiz.id);

          const firestoreAssetsToCopy: CopyAssetToPathParams[] =
            getImagePathsFromQuizAndQuestions([newQuiz, ...newQuestions]);

          if (firestoreAssetsToCopy.length === 0) {
            that.setQuizAndQuestions(
              firestoreDestinationPath,
              newQuiz,
              newQuestionsWithFirestorePath
            );
          } else {
            that.firestoreAssetPathProcessQueue.addItems(
              firestoreAssetsToCopy,
              () => {
                that.setQuizAndQuestions(
                  firestoreDestinationPath,
                  newQuiz,
                  newQuestionsWithFirestorePath
                );
              }
            );
          }
        })
    }

  }

  async setQuizAndQuestions(
    firestoreDestinationPath: string,
    newQuiz: Quiz,
    newQuestions: QuestionWithPath[]
  ) {
    newQuiz = updateImagePathWithID<Quiz>(newQuiz);
    newQuestions = newQuestions.map((q: QuestionWithPath) => {
      return {
        ...q,
        question: updateImagePathWithID<Question>(q.question)
      };
    });

    // console.log('copying image assets', this.imagePathsToCopy);
    // console.log('firestoreDestinationPath', firestoreDestinationPath);
    // console.log('newQuiz', newQuiz);
    // console.log('newQuestions', newQuestions);

    if (this.imagePathsToCopy.length > 0) {
      await this.assetService.copyImageAssets(this.imagePathsToCopy);
    }

    await this.quizService.setQuizAndQuestions(
      firestoreDestinationPath,
      newQuiz,
      newQuestions
    );

    this.isCopying.set(false);
    this.dialogRef.close();
  }

}
