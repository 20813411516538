import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {SecondsToMillisecondsPipe} from '@ngpat/date';
import {
  NgPatStripeProductPrice,
  NgPatStripeSubscriptionUIdisplay,
  NgPatStripeSubscriptionUIItem,
  selectNagPatHasActiveSubscription,
  selectNgPatActiveStripeSubscriptionsUIDisplay,
  selectNgPatStripePriceByID
} from '@ngpat/store';
import {WINDOW} from '@ngpat/utils';
import {LetDirective, PushPipe} from '@ngrx/component';
import {Store} from '@ngrx/store';
import {lastValueFrom, Observable} from 'rxjs';
import {DlcRoundedTextButtonComponent} from '../../../button/dlc-rounded-text-button/dlc-rounded-text-button.component';

@Component({
  selector: 'dlc-stripe-subscriptions',
  templateUrl: './dlc-stripe-subscriptions.component.html',
  styleUrls: ['./dlc-stripe-subscriptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-subscriptions'
  },
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    PushPipe,
    SecondsToMillisecondsPipe,
    DlcRoundedTextButtonComponent
  ]
})
export class DlcStripeSubscriptionsComponent {
  subscriptions$: Observable<NgPatStripeSubscriptionUIdisplay | null>;
  hasActiveSubscription$: Observable<boolean>;

  @Input() showUpdateBtn = true;
  @Input() showCancelBtn = true;
  @Output() showCancelProgress: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private store: Store, private router: Router, @Inject(WINDOW) public win: Window) {
    this.subscriptions$ = this.store.select(selectNgPatActiveStripeSubscriptionsUIDisplay);

    this.hasActiveSubscription$ = this.store.select(selectNagPatHasActiveSubscription);
  }

  parsePrice(p: number) {
    return p / 100;
  }

  updateSubscription() {
    this.router.navigate(['subscribe']);
  }

  async onCancelSubscription(subscriptionUIItem: NgPatStripeSubscriptionUIItem) {
    this.showCancelProgress.next(true);

    console.log(subscriptionUIItem);

    // Select price based on id
    const price: NgPatStripeProductPrice | undefined = await lastValueFrom(
      this.store.select(selectNgPatStripePriceByID(subscriptionUIItem.itemId))
    );

    console.log(price);
  }
}
