import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthLoginAction} from './login.model';
import * as fromLogin from './login.reducer';

export const selectLoginState = createFeatureSelector<fromLogin.AuthState>(
  fromLogin.loginFeatureKey
);

export const selectLoginAction = createSelector(
  selectLoginState,
  (state: fromLogin.AuthState): AuthLoginAction => state.action
);

export const selectOtlid = createSelector(
  selectLoginState,
  (state: fromLogin.AuthState): string | null => state.otlid
);

export const selectCanNotLogin = createSelector(
  selectLoginAction,
  (action: AuthLoginAction): boolean => {
    return action !== AuthLoginAction.LOGIN;
  }
);
