import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { NAV } from '@gigasoftware/evolving-cognition/domain';
import { EcLogoComponent } from '@gigasoftware/evolving-cognition/ui';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'ecm-header, [ecm-header]',
  standalone: true,
  imports: [ CommonModule, EcLogoComponent, RouterModule ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  isLargeScreen: Signal<boolean> = <Signal<boolean>>toSignal(this.breakpointObserver.observe('(min-width: 1024px)').pipe(map((breakpointState: BreakpointState | undefined) => {
    return breakpointState?.matches || false;
  })));

  sidenavOpen: WritableSignal<boolean> = signal(false);

  constructor(private breakpointObserver: BreakpointObserver) {

  }

  ngOnInit() {
    this.breakpointObserver.observe('(min-width: 1024px)').pipe(
      map((breakpointState: BreakpointState | undefined) => {
        return breakpointState?.matches || false;
      }),
      distinctUntilChanged()
    )
      .subscribe((isLargeScreen: boolean) => {
        if (isLargeScreen) {
          this.sidenavOpen.set(false);
        }
      });
  }


  toggleSidenav() {
    this.sidenavOpen.set(!this.sidenavOpen());
  }

  closeSidenav() {
    this.sidenavOpen.set(false);
  }


  protected readonly nav = NAV;
}
