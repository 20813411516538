import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {navigateWithParams} from './actions';

export interface UrlParams {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class UrlParamsRouteService {
  store = inject(Store);

  /**
   * @param navItemKey
   * @param params
   */
  navigateWithParams(navItemKey: string | undefined, params: UrlParams = {}) {
    if (navItemKey) {
      this.store.dispatch(navigateWithParams(navItemKey, params));
    }
  }

  /**
   * @param key
   */
  navigate(key: string) {
    this.navigateWithParams(key);
  }
}
