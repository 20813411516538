import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ReactiveFormsModule
} from '@angular/forms';
import {
  SearchAlgoliaService,
  EC_QUIZ_ROLES,
  roleTypeDict,
  RoleTypeName
} from '@gigasoftware/evolving-cognition/domain';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteModule
} from '@angular/material/autocomplete';
import {Store} from '@ngrx/store';
import {
  debounceTime,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import {User} from 'firebase/auth';
import {NgPatAccountAlgolia, NgPatAccountState} from '@ngpat/store';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatOptionModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {LetDirective, PushPipe} from '@ngrx/component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NgIf, NgFor} from '@angular/common';
import {MatCardModule} from '@angular/material/card';

export interface InviteUserForm {
  search: string;
  role: number;
}

export interface InviteUser {
  user: NgPatAccountAlgolia;
  role: number;
}

export type InviteResultType = 'success' | 'error';

export interface InviteResult {
  type: InviteResultType;
  username: string;
  role: string;
}

@Component({
  selector: 'ui-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [SearchAlgoliaService],
  host: {
    class: 'ui-invite-user'
  },
  standalone: true,
  imports: [
    MatCardModule,
    NgIf,
    MatProgressBarModule,
    LetDirective,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    PushPipe
  ]
})
export class InviteUserComponent implements OnInit, AfterViewInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  inviteUser: UntypedFormGroup;
  rolesTypeNames: RoleTypeName[] = [];
  searchResults$: ReplaySubject<NgPatAccountAlgolia[]> = new ReplaySubject<
    NgPatAccountAlgolia[]
  >(1);
  searchInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  error$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );
  messages$: ReplaySubject<InviteResult | null> =
    new ReplaySubject<InviteResult | null>(1);

  @Input() roles: EC_QUIZ_ROLES[] = [];
  @Output() selectedUser: EventEmitter<InviteUser> =
    new EventEmitter<InviteUser>();

  constructor(
    private store: Store,
    private firestore: NgPatFirestoreService,
    private readonly fb: UntypedFormBuilder,
    private search: SearchAlgoliaService
  ) {
    this.inviteUser = this.buildFormGroup();
  }

  ngOnInit(): void {
    this.rolesTypeNames = this.roles.map((role: EC_QUIZ_ROLES) => roleTypeDict[role]);
  }

  ngAfterViewInit(): void {
    this.inviteUser.valueChanges
      .pipe(
        filter((r: any) => r.search && r.search.length > 2),
        debounceTime(300),
        distinctUntilKeyChanged('search'),
        switchMap((r: InviteUserForm) => {
          this.searchInProgress$.next(true);
          return this.search.searchUser(r.search).pipe(
            withLatestFrom(this.firestore.user$),
            map(([r, u]: [NgPatAccountAlgolia[], User]) => {
              // do not include logged in user's account
              return r.filter((_r: NgPatAccountAlgolia) => _r.uid !== u.uid);
            })
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((r: NgPatAccountAlgolia[]) => {
        this.searchInProgress$.next(false);
        this.searchResults$.next(r);
      });
  }

  buildFormGroup(): UntypedFormGroup {
    const group: any = {
      search: ['', [Validators.required]],
      role: [EC_QUIZ_ROLES.Student, [Validators.required]]
    };

    return this.fb.group(group);
  }

  getControl(controlName: string): AbstractControl {
    return this.inviteUser.controls[controlName];
  }

  displayOptionWith(option: NgPatAccountState): string {
    return option ? `${option.username}` : '';
  }

  getErrorMessage(controlName: string) {
    const control: AbstractControl = this.getControl(controlName);

    // order of priority
    if (control.hasError('required')) {
      return 'Required.';
    } else {
      // should never get to here
      return 'Invalid value.';
    }
  }

  emailOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.searchInProgress$.next(false);

    this.firestore.user$
      .pipe(
        take(1),
        map((user: User) => user.uid)
      )
      .subscribe((uid: string | null) => {
        if (uid && event && event.option && (<any>event.option).username) {
          if (uid !== event.option.value.uid) {
            // this.searchUserService.reset();

            this.inviteUser.controls['search'].setErrors(null);
            this.inviteUser.controls['search'].markAsTouched();
          } else {
            console.log('You cannot add yourself to your project.');
            this.error$.next('You cannot add yourself to your project.');
          }
        }
      });
  }

  reset(): void {
    this.error$.next(null);
    // this.showResetIcon$.next(false);

    this.inviteUser.controls['search'].reset('');

    // this.search.reset();

    this.searchInProgress$.next(false);
  }

  onSave() {
    if (this.inviteUser.valid) {
      this.selectedUser.emit({
        user: this.inviteUser.value.search,
        role: this.inviteUser.value.role
      });

      this.messages$.next({
        type: 'success',
        username: this.inviteUser.value.search.username,
        role: roleTypeDict[this.inviteUser.value.role].name
      });

      this.reset();
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
