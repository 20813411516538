<div class="app-content">
  <mat-progress-bar
    *ngIf="authLoginService.action() === authLoginService.loginStatus.LOGGING_IN"
    mode="indeterminate"
    color="accent"></mat-progress-bar>

  <mat-card>
    <mat-card-header *ngIf="showVerticalHeader">
      <mat-card-title>
        <div class="flex flex-col items-center w-full mt-4">
          <img class="border rounded overflow-hidden" src="assets/ec-auth-logo.png" alt="" />
          <div class="text-3xl font-light mt-4 mb-4">
            {{ authLoginService.header() }}
          </div>
        </div>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="!showVerticalHeader">
      <mat-card-title>
        <div class="flex flex-row items-center">
          <ng-content></ng-content>
          <div class="text-3xl font-light ml-2 mb-0">
            {{ authLoginService.header() }}
          </div>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      @if(authLoginService.action() === authLoginService.loginStatus.SUCCESS) {
      <div class="login-message">You may close this window.</div>
      } @else if(authLoginService.action() === authLoginService.loginStatus.CANCEL) {
      <div class="login-message">You may close this window.</div>
      } @else if(authLoginService.action() === authLoginService.loginStatus.ERROR) {
      <div class="login-message">Please close window and try again.</div>
      } @else if(authLoginService.action() === authLoginService.loginStatus.LOGGING_IN) {
      <div class="login-message">Do not close this window.</div>

      } @else {
      <div class="relative">
        <dlc-firebase-login (cancel)="authLoginService.cancelLogin()"></dlc-firebase-login>
      </div>

      }
    </mat-card-content>
    <mat-card-actions align="end" *ngIf="testStates">
      <div class="flex flex-col">
        <button mat-button (click)="authLoginService.testLogin()">login</button>
        <button mat-button (click)="authLoginService.testLoggingIn()">logging in</button>
        <button mat-button (click)="authLoginService.testSuccess()">success</button>
        <button mat-button (click)="authLoginService.testCancel()">cancel</button>
        <button mat-button (click)="authLoginService.testError()">error</button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
