import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {
  CreateProjectParams,
  CreateSubProjectParams,
  ProjectType
} from './project.model';

export const loadProjectTypes = createAction(
  '[ProjectType/API] Load ProjectTypes',
  props<{projectTypes: ProjectType[]}>()
);

export const addProjectType = createAction(
  '[ProjectType/API] Add ProjectType',
  props<{projectType: ProjectType}>()
);

export const upsertProjectType = createAction(
  '[ProjectType/API] Upsert ProjectType',
  props<{projectType: ProjectType}>()
);

export const addProjectTypes = createAction(
  '[ProjectType/API] Add ProjectTypes',
  props<{projectTypes: ProjectType[]}>()
);

export const upsertProjectTypes = createAction(
  '[ProjectType/API] Upsert ProjectTypes',
  props<{projectTypes: ProjectType[]}>()
);

export const updateProjectType = createAction(
  '[ProjectType/API] Update ProjectType',
  props<{projectType: Update<ProjectType>}>()
);

export const updateProjectTypes = createAction(
  '[ProjectType/API] Update ProjectTypes',
  props<{projectTypes: Update<ProjectType>[]}>()
);

export const deleteProjectType = createAction(
  '[ProjectType/API] Delete ProjectType',
  props<{id: string}>()
);

export const deleteProjectTypes = createAction(
  '[ProjectType/API] Delete ProjectTypes',
  props<{ids: string[]}>()
);

export const clearProjectTypes = createAction(
  '[ProjectType/API] Clear ProjectTypes'
);

export const createProjectParams = createAction(
  '[Project/API] Create Project By Type Value',
  props<{params: CreateProjectParams}>()
);

export const createSubProjectParams = createAction(
  '[Project/API] Create Sub Project By Type Value',
  props<{params: CreateSubProjectParams}>()
);
