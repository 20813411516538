<table mat-table [dataSource]="membersList" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef>Username</th>
    <td mat-cell *matCellDef="let user">{{ user.username }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef>Role</th>
    <td mat-cell *matCellDef="let user">{{ getRoleTitle(user.role) }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let user">
      <button
        *ngIf="canDelete$(user) | ngrxPush"
        dlc-rounded-text-icon-button
        (click)="onDelete(user)">
        <mat-icon>delete</mat-icon>
        Remove
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
