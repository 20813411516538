import {Injectable} from '@angular/core';
import {selectNgPatUserAccount} from '@ngpat/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {switchMap, withLatestFrom} from 'rxjs/operators';
import {Classroom} from '../+classrooms/classroom.model';
import {getClassroomByID} from '../+classrooms/classroom.selectors';
import {
  deleteInvestigation,
  deleteInvestigations
} from './investigation.actions';
import {PartialInvestigationState} from './investigation.reducer';
import {InvestigationService} from './investigation.service';

@Injectable({
  providedIn: 'root'
})
export class InvestigationEffects {
  deleteDoc$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(deleteInvestigation),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.id)),
            switchMap((classRoom: Classroom | undefined) =>
              this._investigationService.deleteDoc$(
                classRoom,
                <string>account.uid
              )
            )
          )
        )
      ),
    {dispatch: false}
  );

  deleteDocs$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(deleteInvestigations),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.ids[0])),
            switchMap((classRoom: Classroom | undefined) =>
              this._investigationService.deleteDocs$(
                classRoom,
                action.ids,
                <string>account.uid
              )
            )
          )
        )
      ),
    {dispatch: false}
  );

  constructor(
    private _actions$: Actions,
    private store: Store<PartialInvestigationState>,
    private _investigationService: InvestigationService
  ) {}
}
