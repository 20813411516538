import {NgPatMonitorAccounts, selectNgPatMentorAccountsDict} from '@ngpat/store';
import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {getMemberOwnerUID} from '../fns';
import {EC_QUIZ_ROLES, UserIdWithRole} from '../models/user.model.deprecated';
import {selectHasActiveSubscription} from '../subscription/subscription.selectors';
import {Member, MemberListItem} from './members.model';
import * as MembersReducer from './members.reducer';

export const selectMembersState = createFeatureSelector<MembersReducer.MembersState>(
  MembersReducer.membersFeatureKey
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  MembersReducer.membersAdapter.getSelectors();

export const selectAllMemberss = createSelector(selectMembersState, selectAll);
export const selectMembersEntities = createSelector(selectMembersState, selectEntities);
export const selectMembersIds = createSelector(selectMembersState, selectIds);
export const selectMembersTotal = createSelector(selectMembersState, selectTotal);
export const selectedMembersID = createSelector(
  selectMembersState,
  (state: MembersReducer.MembersState) => state.selectedMembersID
);

export const getMemberByID = (id: string | null | undefined) =>
  createSelector(selectMembersEntities, (entities: Dictionary<Member>) => {
    if (id) {
      return entities[id];
    }

    return undefined;
  });

export const getMemberUserNameByID = (id: string) =>
  createSelector(selectMembersEntities, (entities: Dictionary<Member>) => {
    if (entities[id]) {
      return entities[id]?.username;
    }
    return '';
  });

export const getMemberUserNameByProjectOwner = (members: {[uid: string]: UserIdWithRole}) =>
  createSelector(selectMembersEntities, (entities: Dictionary<Member>): string => {
    const uid: string | undefined | null = getMemberOwnerUID(members);
    if (uid && entities[uid] !== undefined) {
      return entities[uid]?.username as string;
    }
    return '';
  });

export function getMemberListItemsByProjectID(
  projectID: string | null | undefined,
  loggedInUserIsMentor: boolean,
  mentorAccounts: NgPatMonitorAccounts,
  members: Member[]
): MemberListItem[] {
  if (projectID) {
    return members
      .filter((m: Member) => m.projectIDs.includes(projectID))
      .filter((m: Member) => {
        if (loggedInUserIsMentor) {
          return mentorAccounts[m.uid];
        }

        return true;
      })
      .map(
        (m: Member) =>
          <MemberListItem>{
            uid: m.uid,
            username: m.username,
            projectID,
            role: m.projects[projectID].role,
            member: m
          }
      );
  }

  return [];
}

export const getMembersByProjectID = (projectID: string | null | undefined) =>
  createSelector(
    selectHasActiveSubscription,
    selectNgPatMentorAccountsDict,
    selectAllMemberss,
    (
      loggedInUserIsMentor: boolean,
      mentorAccounts: NgPatMonitorAccounts,
      members: Member[]
    ): MemberListItem[] => {
      return getMemberListItemsByProjectID(
        projectID,
        loggedInUserIsMentor,
        mentorAccounts,
        members
      );
    }
  );

export const getStudentsByProjectID = (projectID: string) =>
  createSelector(getMembersByProjectID(projectID), (members: MemberListItem[]) =>
    members.filter((m: MemberListItem) => m.role === EC_QUIZ_ROLES.Student)
  );
