// @ts-nocheck
import isLaziable from './_isLaziable';
import setData from './_setData';
import setWrapToString from './_setWrapToString';

/** Used to compose bitmasks for function metadata. */
var WRAP_BIND_FLAG = 1,
  WRAP_BIND_KEY_FLAG = 2,
  WRAP_CURRY_BOUND_FLAG = 4,
  WRAP_CURRY_FLAG = 8,
  WRAP_PARTIAL_FLAG = 32,
  WRAP_PARTIAL_RIGHT_FLAG = 64;

/**
 * Creates a function that wraps `func` to continue currying.
 *
 * @private
 * @param {Function} func The function to wrap.
 * @param {number} bitmask The bitmask flags. See `createWrap` for more details.
 * @param {Function} wrapFunc The function to create the `func` wrapper.
 * @param {*} placeholder The placeholder value.
 * @param {*} [thisArg] The `this` binding of `func`.
 * @param {Array} [partials] The arguments to prepend to those provided to
 *  the new function.
 * @param {Array} [holders] The `partials` placeholder indexes.
 * @param {Array} [argPos] The argument positions of the new function.
 * @param {number} [ary] The arity cap of `func`.
 * @param {number} [arity] The arity of `func`.
 * @returns {Function} Returns the new wrapped function.
 */
function createRecurry(
  func,
  bitmask,
  wrapFunc,
  placeholder,
  thisArg,
  partials,
  holders,
  argPos,
  ary,
  arity
) {
  var isCurry = bitmask & WRAP_CURRY_FLAG,
    newHolders = isCurry ? holders : undefined,
    newHoldersRight = isCurry ? undefined : holders,
    newPartials = isCurry ? partials : undefined,
    newPartialsRight = isCurry ? undefined : partials;

  bitmask |= isCurry ? WRAP_PARTIAL_FLAG : WRAP_PARTIAL_RIGHT_FLAG;
  bitmask &= ~(isCurry ? WRAP_PARTIAL_RIGHT_FLAG : WRAP_PARTIAL_FLAG);

  if (!(bitmask & WRAP_CURRY_BOUND_FLAG)) {
    bitmask &= ~(WRAP_BIND_FLAG | WRAP_BIND_KEY_FLAG);
  }
  var newData = [
    func,
    bitmask,
    thisArg,
    newPartials,
    newHolders,
    newPartialsRight,
    newHoldersRight,
    argPos,
    ary,
    arity
  ];

  var result = wrapFunc.apply(undefined, newData);
  if (isLaziable(func)) {
    setData(result, newData);
  }
  result.placeholder = placeholder;
  return setWrapToString(result, func, bitmask);
}

export default createRecurry;
