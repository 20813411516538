import {Signal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {NgPatChartStatus, NgPatCommonTooltip} from '../core/chart.models';
import {
  ChartTooltipConfig,
  ChartTooltipState,
  defaultChartTooltipConfig,
  defaultChartTooltipState
} from './charts-tooltip.model';

export class ChartsTooltipCalc<TooltipData> {
  tooltipData$: ReplaySubject<NgPatCommonTooltip<TooltipData>> =
    new ReplaySubject<NgPatCommonTooltip<TooltipData>>(1);

  tooltipStyle$: Observable<string> = this.tooltipData$.pipe(
    map((data: NgPatCommonTooltip<TooltipData>) => {
      return `transform: translate(${data.x}px, 0);`;
    }),
    distinctUntilChanged()
  );

  tooltipStyle: Signal<string> = <Signal<string>>toSignal(this.tooltipStyle$);

  status$: BehaviorSubject<NgPatChartStatus> =
    new BehaviorSubject<NgPatChartStatus>('none');

  chartTooltipState$: BehaviorSubject<ChartTooltipState> =
    new BehaviorSubject<ChartTooltipState>(defaultChartTooltipState);

  reversed$: Observable<boolean> = this.chartTooltipState$.pipe(
    map((state: ChartTooltipState) => state.reversed),
    distinctUntilChanged()
  );

  tooltipHoverClosed$: Observable<boolean> = this.chartTooltipState$.pipe(
    map((state: ChartTooltipState) => state.tooltipHoverClosed),
    distinctUntilChanged()
  );

  /**
   * Translates divot to x position.
   * Default translateDivotX value
   */
  translateDivotXValue$: BehaviorSubject<number> = new BehaviorSubject<number>(
    -12
  );
  translateDivotYValue$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  translateDivot$: BehaviorSubject<string> = new BehaviorSubject<string>(
    `translate( -12px, 0px)`
  );

  showTooltipHover$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  visible$: Observable<boolean> = this.chartTooltipState$.pipe(
    map((state: ChartTooltipState) => state.visible),
    distinctUntilChanged()
  );

  hover$: Observable<boolean> = this.chartTooltipState$.pipe(
    map((state: ChartTooltipState) => state.hover),
    distinctUntilChanged()
  );

  chartTooltipState: Signal<ChartTooltipState> = <Signal<ChartTooltipState>>(
    toSignal(this.chartTooltipState$)
  );

  calculateParams(config: Partial<ChartTooltipConfig>): void {
    const width = config.width || defaultChartTooltipConfig.width;
    const height = config.height || defaultChartTooltipConfig.height;
    const rx = config.rx || defaultChartTooltipConfig.rx;

    const viewBox = `0 0 ${width} ${height}`;
    const svgWidth = width;
    const svgHeight = height;
    const maskWidth = width;
    const maskHeight = height;
    const backgroundWidth = width - 1;
    const backgroundHeight = height - 16;
    const divotWidth = config.divotWidth || defaultChartTooltipState.divotWidth;
    const divotHeight =
      config.divotHeight || defaultChartTooltipState.divotHeight;
    const translateDivotX =
      config.translateDivotX !== undefined
        ? config.translateDivotX
        : defaultChartTooltipState.translateDivotX;
    const reversed: boolean =
      config.reversed !== undefined
        ? config.reversed
        : defaultChartTooltipConfig.reversed;
    const tooltipHoverClosed =
      config.tooltipHoverClosed !== undefined
        ? config.tooltipHoverClosed
        : defaultChartTooltipState.tooltipHoverClosed;

    const visible =
      config.visible !== undefined
        ? config.visible
        : defaultChartTooltipState.visible;

    const hover =
      config.hover !== undefined
        ? config.hover
        : defaultChartTooltipConfig.hover;

    // Divo should overlap the background
    // so there is not gap from the background
    // curved corners
    const divotVerticalStart = backgroundHeight - rx;

    /**
     * Original divot path:
     * M0 44H24L12 65L0 44Z
     *
     * M means moveto command and
     * H means horizontal lineto command
     * L means lineto command
     * Z means closepath command
     *
     * The example above is equivalent to:
     *  M0 44
     *  H24
     *  L12 65
     *  L0 44
     *
     */
    const divotPath = `M0 ${divotVerticalStart}H24L12 ${height}L0 ${divotVerticalStart}Z`;

    this.setTranslateDivotX(translateDivotX);

    this.chartTooltipState$.next({
      viewBox,
      svgWidth,
      svgHeight,
      maskWidth,
      maskHeight,
      backgroundWidth,
      backgroundHeight,
      divotWidth,
      divotHeight,
      translateDivotX,
      rx,
      reversed,
      tooltipHoverClosed,
      divotPath,
      visible,
      hover
    });
  }

  setConfig(
    config: Partial<ChartTooltipConfig> | undefined | null
  ): ChartTooltipConfig {
    let _config: ChartTooltipConfig = defaultChartTooltipConfig;

    if (config) {
      _config = {
        ...defaultChartTooltipConfig,
        ...config
      };
    }

    this.calculateParams(_config);

    return _config;
  }

  setTranslateDivotX(x: number): void {
    const y = this.translateDivotYValue$.value;
    this.translateDivot$.next(`translate( ${x}px, ${y}px)`);
  }

  setTranslateDivotY(y: number): void {
    const x = this.translateDivotXValue$.value;
    this.translateDivot$.next(`translate( ${x}px, ${y}px)`);
  }

  setTooltipHover(s: boolean): void {
    this.showTooltipHover$.next(s);
  }

  setTooltipData(d: NgPatCommonTooltip<TooltipData>): void {
    this.tooltipData$.next(d);
    this.setTranslateDivotX(d.tooltipDivotX);
  }

  setStatus(s: NgPatChartStatus): void {
    this.status$.next(s);
  }

  setReversed(r: boolean): void {
    this.calculateParams({
      ...this.chartTooltipState$.value,
      reversed: r
    });
  }
}
