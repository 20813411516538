import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {StudyGroup} from '@gigasoftware/evolving-cognition/domain';
import {EcStudyGroupListItemComponent} from './ec-study-group-list-item/ec-study-group-list-item.component';

@Component({
  selector: 'ec-study-group-list',
  standalone: true,
  imports: [CommonModule, EcStudyGroupListItemComponent],
  templateUrl: './ec-study-group-list.component.html',
  styleUrl: './ec-study-group-list.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-study-group-list'
  }
})
export class EcStudyGroupListComponent {
  @Input() list: StudyGroup[] = [];

  @Output() doSelectItem: EventEmitter<StudyGroup> =
    new EventEmitter<StudyGroup>();

  @Output() doEditItem: EventEmitter<StudyGroup> =
    new EventEmitter<StudyGroup>();

  @Output() doDeleteItem: EventEmitter<StudyGroup> =
    new EventEmitter<StudyGroup>();

  @Output() doLeaveItem: EventEmitter<StudyGroup> =
    new EventEmitter<StudyGroup>();
}
