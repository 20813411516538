

import {NgModule} from '@angular/core';
import {DlcPseudoCheckbox} from './pseudo-checkbox';
import {DlcCommonModule} from '../../common-behaviors/common-module';

@NgModule({
  imports: [DlcCommonModule],
  exports: [DlcPseudoCheckbox],
  declarations: [DlcPseudoCheckbox],
})
export class DlcPseudoCheckboxModule {}
