import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {EcCopyQuizDialogComponent} from './ec-copy-quiz-dialog.component';

@NgModule({
  declarations: [EcCopyQuizDialogComponent],
  exports: [EcCopyQuizDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    DlcRoundedTextIconButtonComponent,
    MatProgressSpinnerModule
  ]
})
export class EcCopyQuizDialogModule {}
