import {
  getProjectTypeByNumber,
  NgPatAccountState,
  NgPatUserAccount,
  selectNgPatAccountState,
  selectNgPatLoggedInUID,
  selectNgPatUserAccount
} from '@ngpat/store';
import {Dictionary} from '@ngrx/entity';
import {createSelector} from '@ngrx/store';
import {Classroom, selectAllClassrooms, selectClassroomEntities} from './+classrooms';
import {classroomFeatureKey} from './+classrooms/classroom.reducer';
import {selectClassroomMemberMap} from './+classrooms/classroom.selectors';
import {courseFeatureKey} from './+courses/course.reducer';
import {Course, selectCourseEntities} from './+courses/index';
import {Investigation, selectInvestigationEntities} from './+investigation/index';
import {investigationFeatureKey} from './+investigation/investigation.reducer';
import {concatMember} from './+members/member.fns';
import {MemberUIDProjectID} from './+members/members.model';
import {
  CollaborativeProject,
  DEFAULT_PLATFORM_PROJECT_TYPE_DICT,
  PLATFORM_PROJECT_TYPES
} from './+project/project.model';
import {CopyQuizParams, Quiz} from './+quizzes/quiz.model';
import {selectAllQuizzes, selectQuizMemberMap} from './+quizzes/quiz.selectors';
import {Research, selectResearchEntities} from './+research/index';
import {researchFeatureKey} from './+research/research.reducer';
import {StudyGroup} from './+study-groups/study-group.model';
import {studyGroupFeatureKey} from './+study-groups/study-group.reducer';
import {
  selectAllStudyGroups,
  selectCurrentStudyGroup,
  selectStudyGroupEntities,
  selectStudyGroupMemberMap
} from './+study-groups/study-group.selectors';
import {selectCurrentClassroom, selectCurrentStudyGroupID} from './+ui/ui.selectors';
import {UserPermissions} from './models/user.model.deprecated';
import {selectHasActiveSubscription} from './subscription/subscription.selectors';
import {isEcRoleAdmin, isEcRoleRoot} from './models/user.roles';

/**
 * @deprecated use selectProjectByTypeAndID
 * @param projectType
 * @param id
 */
export const selectProjectByType = (projectType: number | null, id: string | null) => {
  return (state: {[key: string]: any}) => {
    if (projectType !== null && id) {
      if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id) {
        return state[studyGroupFeatureKey].entities[id];
      }
      if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
        return state[classroomFeatureKey].entities[id];
      }
      if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.RESEARCH.id) {
        return state[researchFeatureKey].entities[id];
      }
      if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.COURSE.id) {
        return state[courseFeatureKey].entities[id];
      }
      if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.INVESTIGATION.id) {
        return state[investigationFeatureKey].entities[id];
      }
    }

    return null;
  };
};

export const selectProjectByTypeAndID = (projectType: number | null, id: string | null) =>
  createSelector(
    selectStudyGroupEntities,
    selectClassroomEntities,
    selectResearchEntities,
    selectCourseEntities,
    selectInvestigationEntities,
    (
      sg: Dictionary<StudyGroup>,
      c: Dictionary<Classroom>,
      r: Dictionary<Research>,
      course: Dictionary<Course>,
      i: Dictionary<Investigation>
    ): CollaborativeProject | null | undefined => {
      if (projectType !== null && id) {
        if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id) {
          return sg[id];
        }
        if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
          return c[id];
        }
        if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.RESEARCH.id) {
          return r[id];
        }
        if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.COURSE.id) {
          return course[id];
        }
        if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.INVESTIGATION.id) {
          return i[id];
        }
      }

      return null;
    }
  );

export const concatMemberUIDProjectID = createSelector(
  selectStudyGroupMemberMap,
  selectQuizMemberMap,
  selectClassroomMemberMap,
  (groups: MemberUIDProjectID[], quizzes: MemberUIDProjectID[], classes: MemberUIDProjectID[]) => {
    return concatMember([...groups, ...quizzes, ...classes]);
  }
);

export const selectAllProjectsToCopyTo = createSelector(
  selectAllStudyGroups,
  selectAllClassrooms,
  selectNgPatUserAccount,
  // selectNgPatHasActiveStripeSubscription,
  (
    studyGroups: StudyGroup[],
    classrooms: Classroom[],
    user: NgPatUserAccount
    // hasActiveSubscription: boolean
  ): CopyQuizParams[] => {
    let params: CopyQuizParams[] = [];

    const allProjects: CollaborativeProject[] = [...studyGroups, ...classrooms];

    // if (hasActiveSubscription && studyGroups && studyGroups.length) {
    if (allProjects && allProjects.length) {
      const _sg = allProjects.map((g: CollaborativeProject) => {
        return <CopyQuizParams>{
          name: g.name,
          isPersonal: false,
          projectTypeName: getProjectTypeByNumber(g.projectType, PLATFORM_PROJECT_TYPES).name,
          copyToParentProject: g,
          uid: <string>user.uid
        };
      });

      params = [..._sg];
    }

    return [
      ...params,
      <CopyQuizParams>{
        name: 'My Quizzes',
        isPersonal: true,
        // projectTypeName: getProjectTypeByNumber(0, projectTypes).name,
        projectTypeName: 'Personal ',
        copyToParentProject: null,
        uid: <string>user.uid
      }
    ];
  }
);

export const getProjectIsCreatedByLoggedInUser = (p: UserPermissions) =>
  createSelector(selectNgPatLoggedInUID, (uid: string | null) => {
    return p?.createdBy?.uid === uid;
  });

// STUDY GROUP
export const selectCurrentStudyGroupQuizzes = createSelector(
  selectCurrentStudyGroupID,
  selectAllQuizzes,
  (studyGroupID: string | null | undefined, quizzes: Quiz[]) => {
    if (studyGroupID) {
      return quizzes.filter((q: Quiz) => q.parentProjectID === studyGroupID);
    }
    return [];
  }
);

export const selectCurrentStudyGroupQuizIDs = createSelector(
  selectCurrentStudyGroupQuizzes,
  (quizzes: Quiz[]) => {
    return quizzes.map((q: Quiz) => q.id);
  }
);

// Classroom
export const selectCurrentClassroomQuizzesForTeacher = createSelector(
  selectAllQuizzes,
  selectCurrentClassroom,
  selectHasActiveSubscription,
  (quizzes: Quiz[], classroom: Classroom | null | undefined, hasTeacherSubscription: boolean) => {
    if (classroom) {
      return quizzes
        .filter((q: Quiz) => q.parentProjectID === classroom.id)
        .filter((q: Quiz) => hasTeacherSubscription || q.assigned);
    }

    return [];
  }
);

export const selectCurrentClassroomQuizzes = createSelector(
  selectAllQuizzes,
  selectCurrentClassroom,
  (quizzes: Quiz[], classroom: Classroom | null | undefined) => {
    if (classroom) {
      return quizzes.filter((q: Quiz) => q.parentProjectID === classroom.id);
    }

    return [];
  }
);

export const selectCurrentClassroomQuizIDs = createSelector(
  selectCurrentClassroomQuizzes,
  (quizzes: Quiz[]) => {
    return quizzes.map((q: Quiz) => q.id);
  }
);

export const selectLoggedInUserIsClassroomOwner = createSelector(
  selectNgPatUserAccount,
  selectCurrentClassroom,
  (user: NgPatUserAccount, classroom: Classroom | null | undefined) => {
    if (user && classroom) {
      return classroom.createdBy.uid === user.uid;
    }

    return false;
  }
);

export const selectLoggedInUserIsStudyGroupOwner = createSelector(
  selectNgPatUserAccount,
  selectCurrentStudyGroup,
  (user: NgPatUserAccount, studyGroup: StudyGroup | null | undefined) => {
    if (user && studyGroup) {
      return studyGroup.createdBy.uid === user.uid;
    }

    return false;
  }
);

export const selectLoggedInUserIsEcAdmin = createSelector(
  selectNgPatAccountState,
  (user: NgPatAccountState | null): boolean => {
    return isEcRoleAdmin(user);
  }
);

export const selectLoggedInUserIsEcRoot = createSelector(
  selectNgPatAccountState,
  (user: NgPatAccountState | null): boolean => {
    return isEcRoleRoot(user);
  }
);

export const selectLoggedInUserIsEcAdminOrRoot = createSelector(
  selectNgPatAccountState,
  (user: NgPatAccountState | null): boolean => {
    return isEcRoleAdmin(user) || isEcRoleRoot(user);
  }
);

export interface CanDeletePublishedQuiz {
  loggedInUserUID: string | null;
  isAdminOrRoot: boolean;
}

export const selectCanDeletePublishedQuiz = createSelector(
  selectNgPatAccountState,
  selectLoggedInUserIsEcAdminOrRoot,
  (user: NgPatAccountState | null, isAdminOrRoot: boolean): CanDeletePublishedQuiz => {
    return {
      loggedInUserUID: user?.uid || null,
      isAdminOrRoot
    };
  }
);
