

import {Directive, InjectionToken, Input} from '@angular/core';

/**
 * Injection token that can be used to reference instances of `DlcSuffixDirective`. It serves as
 * alternative token to the actual `DlcSuffixDirective` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const DLC_SUFFIX = new InjectionToken<DlcSuffixDirective>('DlcSuffixDirective');

/** Suffix to be placed at the end of the form field. */
@Directive({
  selector: '[dlcSuffix], [dlcIconSuffix], [dlcTextSuffix]',
  // standalone: true,
  providers: [{provide: DLC_SUFFIX, useExisting: DlcSuffixDirective}],
})
export class DlcSuffixDirective {
  @Input('dlcTextSuffix')
  set _isTextSelector(value: '') {
    this._isText = true;
  }

  _isText = false;
}
