<ul role="list" class="pl-0">
  <li
    ec-quiz-list-item
    class="mb-5"
    *ngFor="let quizId of quizzes()"
    (doTakeQuiz)="doTakeQuiz.emit($event)"
    (doSeeAllGrades)="doSeeAllGrades.emit($event)"
    (doEditQuiz)="doEditQuiz.emit($event)"
    (doStatus)="doStatus.emit($event)"
    [quizId]="quizId"></li>
</ul>
