import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {Router} from '@angular/router';
import {
  DlcGooglePlayStoreComponent,
  DlcIosAppStoreComponent
} from '@gigasoftware/shared/ui-design-library';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {HomeCtaComponent} from './home-cta/home-cta.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'ecm-home',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    HomeCtaComponent,
    DlcIosAppStoreComponent,
    DlcGooglePlayStoreComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private firestore: NgPatFirestoreService
  ) {}

  appUrl = environment.appUrl;

  ngOnInit() {
    this.firestore.logEvent('home_opened');
  }
}
