import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  Validators
} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {NgPatEmailAuthProviderConfig} from '../ng-pat-firebase-material-ui.models';

/**
 * Email authentication is enabled by default.
 */
export function showEmailAuthenticationForm(
  email: undefined | null | NgPatEmailAuthProviderConfig
) {
  return !(email !== undefined && email !== null && email.enabled === false);
}

export interface EmailSignInFormValue {
  email: string;
  password: string;
}

export function createEmailSignInForm(formBuilder: FormBuilder): FormGroup {
  return formBuilder.group({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });
}

export function createEmailRegistrationForm(
  formBuilder: FormBuilder,
  email: NgPatEmailAuthProviderConfig | null | undefined
): FormGroup {
  if (email && email.username) {
    return formBuilder.group({
      username: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }
  return createEmailSignInForm(formBuilder);
}

export function statusChanges(form: AbstractControl): Observable<boolean> {
  return form.statusChanges.pipe(
    startWith(<FormControlStatus>'INVALID'),
    map((status: FormControlStatus) => {
      return status === 'INVALID';
    })
  );
}
