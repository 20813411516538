export interface TimeStamp {
  nanoseconds: number | null;
  seconds: number | null;
}

/**
 * Convert TimeStamp to a unix timestamp.
 */
export function convertToUnixTimestamp(timeStamp: TimeStamp | null): number | null {
  if (timeStamp && timeStamp.seconds !== null) {
    const milliseconds = (timeStamp.seconds * 1000) + (timeStamp.nanoseconds || 0) / 1e6;
    return milliseconds;
  }
  return null; // Handle the case when seconds is null
}
