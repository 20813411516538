import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'dlc-footer-bar',
  templateUrl: './dlc-footer-bar.component.html',
  styleUrls: ['./dlc-footer-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: `dlc-footer-bar`
  },
  standalone: true
})
export class DlcFooterBarComponent {}
