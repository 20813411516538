import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'dlc-update-subscription-success',
  templateUrl: './update-stripe-subscription-success.component.html',
  styleUrls: ['./update-stripe-subscription-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule]
})
export class UpdateStripeSubscriptionSuccessComponent {}
