import {Auth, GoogleAuthProvider} from 'firebase/auth';
import {NgPatGoogleAuthProviderConfig} from '../ng-pat-firebase-material-ui.models';

/**
 * Creates a GoogleAuthProvider instance.
 * See https://firebase.google.com/docs/auth/web/google-signin.
 * @param auth
 * @param config
 */
export function createGoogleAuthProvider(
  auth: Auth,
  config?: NgPatGoogleAuthProviderConfig
): GoogleAuthProvider | null {
  if (!config) {
    return null;
  }

  const provider = new GoogleAuthProvider();

  if (config.scopes && config.scopes.length) {
    for (const scope of config.scopes) {
      provider.addScope(scope);
    }
  }

  if (config.languageCode) {
    provider.setDefaultLanguage(config.languageCode);
  }

  if (config.customParameters) {
    provider.setCustomParameters(config.customParameters);
  }

  return provider;
}
