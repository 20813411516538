import {createAction, props} from '@ngrx/store';

/**
 * @description
 * Navigate action to be dispatched from a component.
 * This action will be handled by the UiEffects.
 */
export const navigateWithParamsAction = createAction(
  '[Ui] Navigate With Params',
  props<{navItemKey: string; navParams: {[key: string]: string}}>()
);

export function navigateWithParams(navItemKey: string, params: {[key: string]: string} = {}) {
  return navigateWithParamsAction({navItemKey, navParams: params});
}
