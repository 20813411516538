import {createAction, props} from '@ngrx/store';
import {EntityMap, EntityMapOne, Update} from '@ngrx/entity';

import {StudyGroup} from './study-group.model';

export const addStudyGroup = createAction(
  '[StudyGroup/API] Add StudyGroup',
  props<{studyGroup: StudyGroup}>()
);

export const setStudyGroup = createAction(
  '[StudyGroup/API] Set StudyGroup',
  props<{studyGroup: StudyGroup}>()
);

export const upsertStudyGroup = createAction(
  '[StudyGroup/API] Upsert StudyGroup',
  props<{studyGroup: StudyGroup}>()
);

export const addStudyGroups = createAction(
  '[StudyGroup/API] Add StudyGroups',
  props<{studyGroups: StudyGroup[]}>()
);

export const upsertStudyGroups = createAction(
  '[StudyGroup/API] Upsert StudyGroups',
  props<{studyGroups: StudyGroup[]}>()
);

export const updateStudyGroup = createAction(
  '[StudyGroup/API] Update StudyGroup',
  props<{studyGroup: Update<StudyGroup>}>()
);

export const updateStudyGroupEffect = createAction(
  '[StudyGroup/API] Update StudyGroup Effect',
  props<{studyGroup: StudyGroup}>()
);

export const updateStudyGroups = createAction(
  '[StudyGroup/API] Update StudyGroups',
  props<{studyGroups: Update<StudyGroup>[]}>()
);

export const mapStudyGroup = createAction(
  '[StudyGroup/API] Map StudyGroup',
  props<{entityMap: EntityMapOne<StudyGroup>}>()
);

export const mapStudyGroups = createAction(
  '[StudyGroup/API] Map StudyGroups',
  props<{entityMap: EntityMap<StudyGroup>}>()
);

export const deleteStudyGroup = createAction(
  '[StudyGroup/API] Delete StudyGroup',
  props<{id: string}>()
);

export const deleteStudyGroups = createAction(
  '[StudyGroup/API] Delete StudyGroups',
  props<{ids: string[]}>()
);
export const deleteStudyGroupFromfirestore = createAction(
  '[StudyGroup/API] Delete Study Group From Firestore',
  props<{id: string}>()
);

export const updateFirestorePartialStudyGroup = createAction(
  '[StudyGroup/API] Save Partial StudyGroup',
  props<{changes: Partial<StudyGroup>; studyGroup: StudyGroup}>()
);

export const loadStudyGroups = createAction(
  '[StudyGroup/API] Load StudyGroups',
  props<{studyGroups: StudyGroup[]}>()
);

export const setStudyGroups = createAction(
  '[StudyGroup/API] Set StudyGroups',
  props<{studyGroups: StudyGroup[]}>()
);

export const clearStudyGroups = createAction(
  '[StudyGroup/API] Clear StudyGroups'
);

export const selectStudyGroupID = createAction(
  '[StudyGroup/API] Select StudyGroup',
  props<{id: string}>()
);
