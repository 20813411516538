import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import {JsonPipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'dlc-update-subscription-error',
  templateUrl: './update-stripe-subscription-error.component.html',
  styleUrls: ['./update-stripe-subscription-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, JsonPipe]
})
export class UpdateStripeSubscriptionErrorComponent {
  constructor(
    public dialogRef: MatDialogRef<UpdateStripeSubscriptionErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
``;
