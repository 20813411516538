export interface ChartTooltipConfig {
  width: number;
  height: number;
  divotWidth: number;
  divotHeight: number;
  rx: number;
  visible: boolean;
  hover: boolean;
  reversed: boolean;
  translateDivotX?: number;
  tooltipHoverClosed?: boolean;
}

export const defaultChartTooltipConfig: ChartTooltipConfig = {
  width: 62,
  height: 65,
  divotWidth: 24,
  divotHeight: 21,
  rx: 4,
  visible: true,
  hover: false,
  reversed: true,
  translateDivotX: -12, // divotWidth / 2
  tooltipHoverClosed: false
};

export interface ChartTooltipState {
  viewBox: string;
  svgWidth: number;
  svgHeight: number;
  maskWidth: number;
  maskHeight: number;
  backgroundWidth: number;
  backgroundHeight: number;
  divotWidth: number;
  divotHeight: number;
  translateDivotX: number;
  rx: number;
  reversed: boolean;
  tooltipHoverClosed: boolean;
  divotPath: string;
  visible: boolean;
  hover: boolean;
}

export const defaultChartTooltipState: ChartTooltipState = {
  viewBox: '0 0 62 65',
  svgWidth: 62,
  svgHeight: 65,
  maskWidth: 62,
  maskHeight: 65,
  backgroundWidth: 62,
  backgroundHeight: 49,
  translateDivotX: -12, // divotWidth / 2
  divotWidth: 24,
  divotHeight: 21,
  rx: 4,
  reversed: true,
  tooltipHoverClosed: false,
  divotPath: `M0 44H24L12 65L0 44Z`,
  visible: true,
  hover: false
};
