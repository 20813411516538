import {Observable, Observer} from 'rxjs';

/**
 * Convert File to ArrayBuffer as callback
 */
export function fileToArrayBufferCallback(
  file: Blob | File,
  callback: (arrayBuffer: ArrayBuffer) => void
) {
  const reader = new FileReader();

  function readerHandler() {
    callback(reader.result as ArrayBuffer);
    reader.removeEventListener('loadend', readerHandler);
  }

  reader.addEventListener('loadend', readerHandler);
  reader.readAsArrayBuffer(file);
}

/**
 * Converts file to ArrayBuffer as promise
 */
export function fileToArrayBufferPromise(file: Blob | File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    function readerHandler() {
      resolve(reader.result as ArrayBuffer);
      reader.removeEventListener('loadend', readerHandler);
    }

    reader.addEventListener('loadend', readerHandler);
    reader.readAsArrayBuffer(file);
  });
}

/**
 * Converts a file to an array buffer as observable
 * @param file
 */
export function fileToArrayBuffer$(file: Blob | File): Observable<ArrayBuffer> {
  return new Observable((observer: Observer<any>) => {
    const reader = new FileReader();

    function readerHandler() {
      observer.next(reader.result);
      reader.removeEventListener('loadend', readerHandler);
    }

    reader.addEventListener('loadend', readerHandler);
    reader.readAsArrayBuffer(file);
  });
}

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

// Use a lookup table to find the index.
const lookup = typeof Uint8Array === 'undefined' ? [] : new Uint8Array(256);
for (let i = 0; i < chars.length; i++) {
  lookup[chars.charCodeAt(i)] = i;
}

export function convertArrayBufferToBase64(arraybuffer: ArrayBuffer): string {
  let i,
    base64 = '';
  const bytes = new Uint8Array(arraybuffer),
    len = bytes.length;
  for (i = 0; i < len; i += 3) {
    base64 += chars[bytes[i] >> 2];
    base64 += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
    base64 += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
    base64 += chars[bytes[i + 2] & 63];
  }

  if (len % 3 === 2) {
    base64 = base64.substring(0, base64.length - 1) + '=';
  } else if (len % 3 === 1) {
    base64 = base64.substring(0, base64.length - 2) + '==';
  }

  return base64;
}

export function conertBase64ToArrayBuffer(base64: string): ArrayBuffer {
  const len = base64.length;
  let bufferLength = base64.length * 0.75,
    i,
    p = 0,
    encoded1,
    encoded2,
    encoded3,
    encoded4;

  if (base64[base64.length - 1] === '=') {
    bufferLength--;
    if (base64[base64.length - 2] === '=') {
      bufferLength--;
    }
  }

  const arraybuffer = new ArrayBuffer(bufferLength),
    bytes = new Uint8Array(arraybuffer);

  for (i = 0; i < len; i += 4) {
    encoded1 = lookup[base64.charCodeAt(i)];
    encoded2 = lookup[base64.charCodeAt(i + 1)];
    encoded3 = lookup[base64.charCodeAt(i + 2)];
    encoded4 = lookup[base64.charCodeAt(i + 3)];

    bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
    bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
    bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
  }

  return arraybuffer;
}
