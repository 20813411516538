import {CommonModule} from '@angular/common';
import {
  Component,
  effect,
  inject,
  input,
  Input,
  output,
  OutputEmitterRef,
  Signal,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {
  ngPatBuyNativeAction,
  NgPatNormalizedOffer,
  NgPatNormalizedPrice,
  NgPatNormalizedPriceIntervalValueEnum,
  NgPatNormalizedPricePlatform,
  selectNgPatIsLoggedIn
} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {coerceThemePalette} from '../../../button/button.fns';
import {DlcRoundedTextButtonComponent} from '../../../button/dlc-rounded-text-button/dlc-rounded-text-button.component';
import {DlcWebPricingService} from '../../dlc-web-pricing/dlc-web-pricing/dlc-web-pricing.service';

enum PurchaseButtonState {
  PURCHASE = 'Purchase',
  DISABLED = 'Disabled',
  CANCEL = 'Cancel'
}

@Component({
  selector: 'dlc-price-toggle',
  standalone: true,
  imports: [CommonModule, DlcRoundedTextButtonComponent],
  templateUrl: './price-toggle.component.html',
  styleUrl: './price-toggle.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-price-toggle',
    '[class.dlc-price-toggle--most-popular]': 'isMostPopular()',
    '[class.dlc-price-toggle-primary]':
      'color() === "primary" || color() === "none" || color() === undefined',
    '[class.dlc-price-toggle-accent]': 'color() === "accent"',
    '[class.dlc-price-toggle-warn]': 'color() === "warn"'
  }
})
export class PriceToggleComponent {
  store: Store = inject(Store);
  webPriceService: DlcWebPricingService = inject(DlcWebPricingService);

  btnState = PurchaseButtonState;

  priceSig: WritableSignal<NgPatNormalizedPrice | undefined> = signal(undefined);

  isMostPopular: WritableSignal<boolean> = signal(false);
  isOwned: WritableSignal<boolean> = signal(false);
  title: WritableSignal<string> = signal('');
  description: WritableSignal<string> = signal('');
  purchaseButtonState: WritableSignal<PurchaseButtonState> = signal(PurchaseButtonState.PURCHASE);
  isLoggedIn: Signal<boolean> = <Signal<boolean>>this.store.selectSignal(selectNgPatIsLoggedIn);
  doLogin: OutputEmitterRef<boolean> = output();

  @Input()
  set price(value: NgPatNormalizedPrice) {
    // console.log('[DLC] PRICE COMPONENT NgPatNormalizedPrice', value);
    this.priceSig.set(value);
  }

  priceInteger: WritableSignal<string> = signal('');
  priceDecimal: WritableSignal<string> = signal('');
  interval: WritableSignal<NgPatNormalizedPriceIntervalValueEnum | undefined> = signal(undefined);

  @Input() set selectedInterval(interval: NgPatNormalizedPriceIntervalValueEnum) {
    this.interval.set(interval);
  }

  color = input('primary', {
    transform: coerceThemePalette
  });

  displayedOffer: WritableSignal<NgPatNormalizedOffer | undefined> = signal(undefined);

  get isNativePlatform(): boolean {
    return Capacitor.isNativePlatform();
  }

  showCheckoutProgressBar: OutputEmitterRef<boolean> = output();

  constructor() {
    effect(
      () => {
        const price: NgPatNormalizedPrice | undefined = this.priceSig();
        const interval: NgPatNormalizedPriceIntervalValueEnum | undefined = this.interval();

        if (price && interval) {
          const selectedOffer: NgPatNormalizedOffer | undefined = price.prices.find(
            p => p.interval === interval
          );

          this.displayedOffer.set(selectedOffer);

          if (selectedOffer) {
            if (price.isCustomPrice) {
              this.title.set(price.title);
            } else {
              this.title.set(selectedOffer.title);
            }
            this.isMostPopular.set(selectedOffer.isMostPopular);
            this.isOwned.set(selectedOffer.owned);
            this.description.set(selectedOffer.description);

            if (selectedOffer.owned) {
              if (selectedOffer.platform === NgPatNormalizedPricePlatform.WEB) {
                this.purchaseButtonState.set(PurchaseButtonState.CANCEL);
              } else {
                this.purchaseButtonState.set(PurchaseButtonState.DISABLED);
              }
            } else {
              this.purchaseButtonState.set(PurchaseButtonState.PURCHASE);
            }

            // const priceString = formatCurrency(selectedInterval.price * 0.01, 'en', '$');
            // split price string into integer and decimal
            const priceInteger = selectedOffer.price.split('.')[0];
            const priceDecimal = selectedOffer.price.split('.')[1];
            // if price decimal is 0, set it to empty string
            if (!priceDecimal || priceDecimal === '00' || priceDecimal === '0') {
              this.priceDecimal.set('');
            } else {
              this.priceDecimal.set(`.${priceDecimal}`);
            }

            this.priceInteger.set(priceInteger);
          }
        }
      },
      {
        allowSignalWrites: true
      }
    );
  }

  onSelectedOffer(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const price: NgPatNormalizedOffer | undefined = this.displayedOffer();

    if (price) {
      if (this.isNativePlatform) {
        this.store.dispatch(ngPatBuyNativeAction({price}));
      } else {
        this.showCheckoutProgressBar.emit(true);
        this.webPriceService.checkout(price);
      }
    }
  }

  onCancelOffer(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const price: NgPatNormalizedOffer | undefined = this.displayedOffer();

    if (price) {
      this.webPriceService.cancelSubscription(price);
    }
  }
}
