/**
 * Take a blobSignal from a file as jpeg and convert it to a png blobSignal
 * @param blob
 * @param imageType = 'image/png', 'image/jpeg', 'image/webp'
 */
export function convertBlobToTypeBlob(
  blob: Blob,
  imageType: string
): Promise<Blob | null> {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (ctx) {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob: Blob | null) => {
          resolve(blob);
        }, imageType);
      }
    };
    img.src = URL.createObjectURL(blob);
  });
}

/**
 * Convert a blobSignal to a base64 string
 * @param blob
 */
export function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
}

/**
 * Convert a blobSignal to a png blobSignal
 * @param blob
 */
export function convertBlobToPngBlob(blob: Blob): Promise<Blob | null> {
  return convertBlobToTypeBlob(blob, 'image/png');
}

/**
 * Convert a blobSignal to a jpeg blobSignal
 * @param blob
 */
export function convertBlobToJpegBlob(blob: Blob): Promise<Blob | null> {
  return convertBlobToTypeBlob(blob, 'image/jpeg');
}
