import {Injectable} from '@angular/core';
import {DocumentReference, Unsubscribe} from '@firebase/firestore';
import {Exists, NgPatFirestoreService} from '@ngpat/firebase';
import {Store} from '@ngrx/store';
import {onAuthStateChanged, User} from 'firebase/auth';
import {DocumentData, DocumentSnapshot, onSnapshot} from 'firebase/firestore';
import {
  clearAuthState,
  clearAuthStorage,
  loadAuthActionFromFirestore,
  updateAuthAction
} from './login.actions';
import {AuthLoginAction, firestoreOtlidById, OTLIDFirestore} from './login.model';

@Injectable({
  providedIn: 'root'
})
export class AuthFirestoreSyncService {
  docSnapshotSubscription: Unsubscribe | null = null;
  onAuthStateChangeSubscription: Unsubscribe | null = null;

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  initFirestoreSync(otlid: string | null = null) {
    if (otlid) {
      const q: DocumentReference<DocumentData> = this.customFirestoreService.docRef(
        firestoreOtlidById(otlid)
      );

      if (this.docSnapshotSubscription) {
        this.docSnapshotSubscription();
      }

      this.docSnapshotSubscription = onSnapshot(q, (snapshot: DocumentSnapshot<DocumentData>) => {
        const authObject: OTLIDFirestore | null = snapshot.data() as OTLIDFirestore;

        if (authObject) {
          this.store.dispatch(
            loadAuthActionFromFirestore({action: authObject.action as AuthLoginAction})
          );
        }

        const action = authObject?.action as AuthLoginAction;

        if (
          action === AuthLoginAction.CANCEL ||
          action === AuthLoginAction.SUCCESS ||
          action === AuthLoginAction.ERROR
        ) {
          this.store.dispatch(clearAuthStorage());
        }
      });
    }
  }

  saveStatusToFiresbase(otlid: string, status: AuthLoginAction) {
    const docRef = this.customFirestoreService.docRef(firestoreOtlidById(otlid));

    this.customFirestoreService
      // Only update if document exists
      .updateIfExists<OTLIDFirestore>(firestoreOtlidById(otlid), {action: status})
      .then((result: Exists<OTLIDFirestore>) => {
        console.log('status saved', result);
      })
      .catch((e: any) => {
        console.log('status save error', e);
      });
  }

  async cancelLogin(otlid: string | null) {
    if (otlid) {
      const authObject: OTLIDFirestore = {
        otlid: otlid,
        jwtToken: null,
        uid: null,
        email: null,
        displayName: null,
        action: 'cancel'
      };

      await this.customFirestoreService.updateIfExists(firestoreOtlidById(otlid), authObject);
    }
  }

  watchForAuthStateChanges(otlid: string) {
    const that = this;

    if (this.onAuthStateChangeSubscription) {
      this.onAuthStateChangeSubscription();
    }

    this.onAuthStateChangeSubscription = onAuthStateChanged(
      this.customFirestoreService.auth,
      (user: User | null) => {
        if (user) {
          this.store.dispatch(updateAuthAction({action: AuthLoginAction.LOGGING_IN}));

          user.getIdToken().then((idToken: string) => {
            /**
             * Callable function defined here apps/firebase/functions/src/index.ts:124
             */
            const createAuthToken = that.customFirestoreService.httpsCallable('createAuthTokenV2');

            if (otlid) {
              that.customFirestoreService.logEvent('auth_create_auth_token');
              /**
               * Creates a token in a firebase function and saves token
               * to firestore with the otlid as the document id.
               * See apps/firebase/functions/src/index.ts
               */
              createAuthToken({
                otlid: otlid,
                idToken,
                action: 'success',
                email: user.email,
                displayName: user.displayName,
                env: this.customFirestoreService.env
              })
                .then(() => {
                  that.customFirestoreService.logEvent('auth_token_created');
                  that.customFirestoreService.logEvent('auth_token_sign_out');
                  that.customFirestoreService.signOut();
                  this.store.dispatch(updateAuthAction({action: AuthLoginAction.SUCCESS}));
                  this.store.dispatch(clearAuthState());

                  // that.redirectToApp(true);
                })
                .catch(error => {
                  console.log(error);
                  that.customFirestoreService.signOut();
                  this.store.dispatch(updateAuthAction({action: AuthLoginAction.ERROR}));
                  this.store.dispatch(clearAuthState());
                });
            } else {
              this.customFirestoreService.signOut();
              this.store.dispatch(clearAuthState());
            }
          });
        }
      }
    );
  }
}
