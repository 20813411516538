import {ChangeDetectionStrategy, Component, input, InputSignal} from '@angular/core';
import {Route} from '@angular/router';
import {fadeInAnimation} from '@gigasoftware/shared/ui-design-library';

@Component({
  selector: 'ec-privacy-policy',
  templateUrl: './ec-privacy-policy.component.html',
  styleUrls: ['./ec-privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[@fadeInAnimation]': '',
    class: 'ec-privacy-policy dlc-global-mobile-page',
    '[class.is-light-theme]': 'isLightTheme() === true'
  },
  animations: [fadeInAnimation],
  standalone: true
})
export class EcPrivacyPolicyComponent {
  lastUpdated = '12 February 2024';

  isLightTheme: InputSignal<boolean> = input(false);
}

export const EC_POLICY_ROUTES: Route[] = [
  {path: '', pathMatch: 'full', component: EcPrivacyPolicyComponent}
];
