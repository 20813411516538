<h3 class="mat-h3">User Name</h3>
<p class="mat-caption">
  Your privacy is our priority. Only your username is exposed to other users.
</p>
<div class="p-micro-form">
  <mat-form-field appearance="fill" floatLabel="always" class="p-input-with-240">
    <mat-label>Enter Username</mat-label>
    <input #input matInput [formControl]="usernameForm" placeholder="myUsername" required />
    <mat-hint align="end">{{ input.value.length || 0 }}</mat-hint>
    <mat-error *ngIf="usernameForm.invalid">{{ getErrorMessage() }}</mat-error>
    <button
      [disabled]="usernameForm.pristine"
      mat-icon-button
      matSuffix
      [color]="usernameForm.dirty ? 'accent' : 'primary'"
      (click)="reset()">
      <mat-icon>undo</mat-icon>
    </button>
  </mat-form-field>

  <button
    class="ml-5"
    mat-flat-button
    [disabled]="usernameForm.pristine"
    [color]="usernameForm.dirty ? 'primary' : 'primary'"
    (click)="apply()">
    <mat-icon>check</mat-icon>Apply
  </button>
</div>
