<div cdk-overlay-origin
     class="dlc-select-trigger-container"
     (click)="toggle()"
     #fallbackOverlayOrigin="cdkOverlayOrigin"
     #trigger>

  <div class="dlc-select-value" [attr.id]="_valueId">
    @if (empty) {
      <span class="dlc-select-placeholder dlc-select-min-line">{{placeholder}}</span>
    } @else {
      <span class="dlc-select-value-text">
        @if (customTrigger) {
          <ng-content select="dlc-select-trigger"></ng-content>
        } @else {
          <span class="dlc-select-min-line">{{triggerValue}}</span>
        }
      </span>
    }
  </div>

  <div class="dlc-select-arrow-wrapper">
    <div class="dlc-select-arrow">
      <!-- Use an inline SVG, because it works better than a CSS triangle in high contrast mode. -->
      <svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false" aria-hidden="true">
        <path d="M7 10l5 5 5-5z"/>
      </svg>
    </div>
  </div>
</div>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayPanelClass]="_overlayPanelClass"
  [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
  [cdkConnectedOverlayOrigin]="_preferredOverlayOrigin || fallbackOverlayOrigin"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayPositions]="_positions"
  [cdkConnectedOverlayWidth]="_overlayWidth"
  (backdropClick)="close()"
  (attach)="_onAttached()"
  (detach)="close()">
  <div
    #panel
    role="listbox"
    tabindex="-1"
    class="dlc-select-panel dlc-menu-surface dlc-menu-surface--open {{ _getPanelTheme() }}"
    [attr.id]="id + '-panel'"
    [attr.aria-multiselectable]="multiple"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="_getPanelAriaLabelledby()"
    [ngClass]="panelClass"
    [@transformPanel]="'showing'"
    (@transformPanel.done)="_panelDoneAnimatingStream.next($event.toState)"
    (keydown)="_handleKeydown($event)">
    <ng-content></ng-content>
  </div>
</ng-template>
