import {createSelector} from '@ngrx/store';
import {IAPProduct, selectAllAppstoreInAppProducts} from '@ngpat/store';
import {IN_APP_IOS_SUBSCRIPTION_ID} from './in-app-purchase/ec-in-app-purchase.products';

export const inAppSubscriptionUserIsStudent = createSelector(
  selectAllAppstoreInAppProducts,
  (subs: IAPProduct[]): boolean => {
    const owned = subs.find((s: IAPProduct) => s.owned);
    return owned !== undefined && owned !== null && owned.id === IN_APP_IOS_SUBSCRIPTION_ID.BASIC;
  }
);

export const inAppSubscriptionUserIsMentor = createSelector(
  selectAllAppstoreInAppProducts,
  (subs: IAPProduct[]): boolean => {
    const owned = subs.find((s: IAPProduct) => s.owned);
    return owned !== undefined && owned !== null && owned.id === IN_APP_IOS_SUBSCRIPTION_ID.BASIC;
  }
);

export const inAppSubscriptionUserIsTeacher = createSelector(
  selectAllAppstoreInAppProducts,
  (subs: IAPProduct[]): boolean => {
    const owned = subs.find((s: IAPProduct) => s.owned);
    return owned !== undefined && owned !== null && owned.id === IN_APP_IOS_SUBSCRIPTION_ID.BASIC;
  }
);
