// @ts-nocheck
import add from './add';
import ceil from './ceil';
import divide from './divide';
import floor from './floor';
import max from './max';
import maxBy from './maxBy';
import mean from './mean';
import meanBy from './meanBy';
import min from './min';
import minBy from './minBy';
import multiply from './multiply';
import round from './round';
import subtract from './subtract';
import sum from './sum';
import sumBy from './sumBy';

export default {
  add,
  ceil,
  divide,
  floor,
  max,
  maxBy,
  mean,
  meanBy,
  min,
  minBy,
  multiply,
  round,
  subtract,
  sum,
  sumBy
};
