import {Injectable} from '@angular/core';
import {selectNgPatUserAccount} from '@ngpat/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {EMPTY} from 'rxjs';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {createCollaborativeProject, createOwnerUserRole, createProject} from '../fns';
import {UserIdWithRole} from '../models/user.model.deprecated';
import {configurePlatform} from '../platform.actions';
import {createProjectParams, createSubProjectParams} from './project.actions';
import {checkForDuplicateProjectTypes} from './project.fns';
import {CollaborativeProject, CreateProjectParams, CreateSubProjectParams, Project} from './project.model';
import {ProjectService} from './project.service';

@Injectable()
export class ProjectEffects {
  detectDuplicateProjectTypes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configurePlatform),
        map(action => action.projectTypes),
        checkForDuplicateProjectTypes
      ),
    {dispatch: false}
  );

  createProjectParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createProjectParams),
        withLatestFrom(this.store.pipe(select(selectNgPatUserAccount))),
        switchMap(([action, userAccount]) => {
          if (userAccount.uid) {
            const params: CreateProjectParams = {
              ...action.params
            };
            // params.isCollaborative =
            //   params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id ||
            //   params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id
            //     ? false
            //     : params.isCollaborative;

            const user: UserIdWithRole = createOwnerUserRole(userAccount.uid);
            const project: Project | CollaborativeProject =
              params.isCollaborative
                ? createCollaborativeProject(user, params)
                : createProject(action.params);

            return this.projectService.createProject$(
              project,
              params,
              userAccount.uid
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  createSubProjectParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createSubProjectParams),
        withLatestFrom(this.store.pipe(select(selectNgPatUserAccount))),
        switchMap(([action, userAccount]) => {
          if (userAccount.uid) {
            const params: CreateSubProjectParams = {
              ...action.params
            };

            const baseParams: CreateSubProjectParams = {
              ...action.params,
              projectTypeValue: action.params.parentProject.projectType
            };

            // params.isCollaborative =
            //   params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id ||
            //   params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id
            //     ? false
            //     : params.isCollaborative;

            const user: UserIdWithRole = createOwnerUserRole(userAccount.uid);

            const project: Project | CollaborativeProject =
              params.isCollaborative
                ? createCollaborativeProject(user, params)
                : createProject(action.params);

            return this.projectService.createSubProject$(
              project,
              baseParams,
              params,
              userAccount.uid
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private projectService: ProjectService
  ) {}

}
