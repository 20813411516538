<dlc-label-container class="dlc-input-link--container">
  <dlc-label>
    <div class="dlc-input-link--container-label">
      <mat-icon class="mr-2">link</mat-icon>
      <span>Link</span>
    </div>
  </dlc-label>
  <div class="dlc-input-link--container-content" [formGroup]="formGroup">
    <dlc-form-field [appearance]="'outline'">
      <dlc-label>Label</dlc-label>
      <input
        dlcInput
        placeholder="Name of link"
        formControlName="label"
        autocomplete="off" />
    </dlc-form-field>

    <dlc-form-field [appearance]="'outline'">
      <dlc-label>URL</dlc-label>
      <input
        dlcInput
        placeholder="http://referencelink.com"
        formControlName="url"
        autocomplete="off" />
    </dlc-form-field>

    <div class="flex flex-row justify-start items-center self-stretch">
      <button dlc-rounded-icon-button class="mr-4" (click)="deleteLink.emit()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <div
      @expandEnterAnimation
      *ngIf="formGroup.errors?.['urlNeedsValue'] && (formGroup.touched || formGroup.dirty)"
      class="dlc-input-link--container-error">
      URL is required.
    </div>
  </div>
</dlc-label-container>
