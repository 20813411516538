import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgPatFirestoreService } from '@ngpat/firebase';

@Component({
  selector: 'ecm-features',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './features.component.html',
  styleUrl: './features.component.scss',
})
export class FeaturesComponent implements OnInit {

  constructor(private firestore: NgPatFirestoreService) {
  }

  ngOnInit() {
    this.firestore.logEvent('features_opened');
  }
}
