import {
  INPUT_FILE_TYPE,
  InputFileType,
  StandardInputConfig
} from './input-file.model';

/**
 * Return standard input file type by string
 * @param type
 */
export function getStandardInputFileTypeByString(type: InputFileType): string {
  switch (type) {
    case 'image':
      return INPUT_FILE_TYPE.IMAGE_STANDARD;
    case 'video':
      return INPUT_FILE_TYPE.VIDEO_STANDARD;
    case 'audio':
      return INPUT_FILE_TYPE.AUDIO_STANDARD;
    case 'midi':
      return INPUT_FILE_TYPE.MIDI_STANDARD;
    case 'document':
      return INPUT_FILE_TYPE.DOCUMENT_STANDARD;
    default:
      return '*';
  }
}

/**
 * Return any input file type by string
 * @param type
 */
export function getAnyInputFileTypeByString(type: InputFileType): string {
  switch (type) {
    case 'image':
      return INPUT_FILE_TYPE.IMAGE_ANY;
    case 'video':
      return INPUT_FILE_TYPE.VIDEO_ANY;
    case 'audio':
      return INPUT_FILE_TYPE.AUDIO_ANY;
    case 'midi':
      return INPUT_FILE_TYPE.MIDI_ANY;
    case 'document':
      return INPUT_FILE_TYPE.DOCUMENT_ANY;
    default:
      return '*';
  }
}

/**
 * Configurable input file type by InputFileType
 * return StandardInputConfig
 */
export function getInputFileFileConfig(
  inputType: InputFileType
): StandardInputConfig {
  switch (inputType) {
    case 'image':
      return {
        accept: getStandardInputFileTypeByString(inputType),
        title: 'Upload Image',
        subTitle: 'png, jpg, gif'
      };
    case 'video':
      return {
        accept: getStandardInputFileTypeByString(inputType),
        title: 'Upload Video',
        subTitle: 'mp4, webm'
      };
    case 'audio':
      return {
        accept: getStandardInputFileTypeByString(inputType),
        title: 'Upload Audio',
        subTitle: 'mp3, ogg, wav'
      };
    case 'midi':
      return {
        accept: getStandardInputFileTypeByString(inputType),
        title: 'Upload Midi',
        subTitle: 'midi, mid'
      };
    case 'document':
      return {
        accept: getStandardInputFileTypeByString(inputType),
        title: 'Upload Document',
        subTitle: 'pdf, doc, docx'
      };
    default:
      return {
        accept: getAnyInputFileTypeByString(inputType),
        title: 'Upload File',
        subTitle: 'any file'
      };
  }
}
