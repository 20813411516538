

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgZone,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Internal component that creates an instance of the MDC notched-outline component.
 *
 * The component sets up the HTML structure and styles for the notched-outline. It provides
 * inputs to toggle the notch state and width.
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[dlcFormFieldNotchedOutline]',
  standalone: true,
  templateUrl: './notched-outline.html',
  host: {
    'class': 'dlc-notched-outline',
    // Besides updating the notch state through the DLC component, we toggle this class through
    // a host binding in order to ensure that the notched-outline renders correctly on the server.
    '[class.dlc-notched-outline--notched]': 'open',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DlcFormFieldNotchedOutlineComponent implements AfterViewInit {
  /** Whether the notch should be opened. */


  private _open = false;

  @Input()
  set dlcFormFieldNotchedOutlineOpen(value: boolean) {
    this._open = value;
  }

  get dlcFormFieldNotchedOutlineOpen() {
    return this._open;
  }

  get open() {
    return this._open;
  }

  set open (value: boolean) {
    this._open = value;
  }


  @ViewChild('notch') _notch!: ElementRef;

  constructor(private _elementRef: ElementRef<HTMLElement>, private _ngZone: NgZone) {}

  ngAfterViewInit(): void {
    const label = this._elementRef.nativeElement.querySelector<HTMLElement>('.dlc-floating-label');
    if (label) {
      this._elementRef.nativeElement.classList.add('dlc-notched-outline--upgraded');

      if (typeof requestAnimationFrame === 'function') {
        label.style.transitionDuration = '0s';
        this._ngZone.runOutsideAngular(() => {
          requestAnimationFrame(() => (label.style.transitionDuration = ''));
        });
      }
    } else {
      this._elementRef.nativeElement.classList.add('dlc-notched-outline--no-label');
    }
  }

  _setNotchWidth(labelWidth: number) {
    if (!this.open || !labelWidth) {
      this._notch.nativeElement.style.width = '';
    } else {
      const NOTCH_ELEMENT_PADDING = 8;
      const NOTCH_ELEMENT_BORDER = 1;
      this._notch.nativeElement.style.width = `calc(${labelWidth}px * var(--dlc-form-field-floating-label-scale, 0.75) + ${
        NOTCH_ELEMENT_PADDING + NOTCH_ELEMENT_BORDER
      }px)`;
    }
  }
}
