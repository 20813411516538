import {
  AbstractControl,
  Form,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder
} from '@angular/forms';

/**
 * FormArray that wraps adding controls to array
 */
export abstract class AbstractFormArray<T> {
  public array: FormArray;

  get forms() {
    return this.array.controls as FormGroup[];
  }

  protected constructor(protected fb: UntypedFormBuilder) {
    this.array = new FormArray<any>([]);
  }

  abstract addLink(value: T | null): void;

  removeAt(linkIndex: number) {
    this.array.removeAt(linkIndex);

    if (this.array.length === 0) {
      this.addLink(null);
    }

    this.array.markAsDirty();
  }

  clear(options?: {emitEvent?: boolean}) {
    this.array.clear(options);
  }

  getFormAt(index: number): FormGroup {
    return this.array.at(index) as FormGroup;
  }
}
