

/** @docs-private */
export function getDlcFormFieldPlaceholderConflictError(): Error {
  return Error('Placeholder attribute and child element were both specified.');
}

/** @docs-private */
export function getDlcFormFieldDuplicatedHintError(align: string): Error {
  return Error(`A hint was already declared for 'align="${align}"'.`);
}

/** @docs-private */
export function getDlcFormFieldMissingControlError(): Error {
  return Error('dlc-form-field must contain a DlcFormFieldControl.');
}
