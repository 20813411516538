import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {
  selectLoggedInUserIsStudyGroupOwner,
  StudyGroup
} from '@gigasoftware/evolving-cognition/domain';
import {
  DlcImageDirective,
  DlcRoundedIconButtonComponent,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {Store} from '@ngrx/store';

@Component({
  selector: 'ec-study-group-list-item, [ec-study-group-list-item]',
  standalone: true,
  imports: [
    CommonModule,
    DlcImageDirective,
    DlcRoundedIconButtonComponent,
    DlcRoundedTextIconButtonComponent,
    MatIconModule,
    MatMenuModule
  ],
  templateUrl: './ec-study-group-list-item.component.html',
  styleUrl: './ec-study-group-list-item.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-study-group-list-item'
  }
})
export class EcStudyGroupListItemComponent {
  loggedInUserIsOwner: Signal<boolean> = this.store.selectSignal(
    selectLoggedInUserIsStudyGroupOwner
  );

  @Input() studyGroup!: StudyGroup;

  @Output() doSelectItem: EventEmitter<StudyGroup> =
    new EventEmitter<StudyGroup>();
  @Output() doEditItem: EventEmitter<StudyGroup> =
    new EventEmitter<StudyGroup>();
  @Output() doDeleteItem: EventEmitter<StudyGroup> =
    new EventEmitter<StudyGroup>();
  @Output() doLeaveItem: EventEmitter<StudyGroup> =
    new EventEmitter<StudyGroup>();

  constructor(private store: Store) {}

  onSelectStudyGroup() {
    this.doSelectItem.emit(this.studyGroup);
  }

  onEditStudyGroup() {
    this.doEditItem.emit(this.studyGroup);
  }

  onLeaveStudyGroup() {
    this.doLeaveItem.emit(this.studyGroup);
  }

  onDeleteStudyGroup() {
    this.doDeleteItem.emit(this.studyGroup);
  }
}
