// @ts-nocheck
import clamp from './clamp';
import inRange from './inRange';
import random from './random';

export default {
  clamp,
  inRange,
  random
};
