

import {Directive, Input} from '@angular/core';

let nextUniqueId = 0;

/** Hint text to be shown underneath the form field control. */
@Directive({
  selector: 'dlc-hint, [dlc-hint], [dlcHint]',
  // standalone: true,
  host: {
    'class': 'dlc-form-field-hint dlc-form-field-bottom-align',
    '[class.dlc-form-field-hint-end]': 'align === "end"',
    '[id]': 'id',
    // Remove align attribute to prevent it from interfering with layout.
    '[attr.align]': 'null',
  },
})
export class DlcHintDirective {
  /** Whether to align the hint label at the start or end of the line. */
  @Input() align: 'start' | 'end' = 'start';

  /** Unique ID for the hint. Used for the aria-describedby on the form field control. */
  @Input() id: string = `dlc-hint-${nextUniqueId++}`;
}
