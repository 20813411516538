import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {
  NgPatAccountEffects,
  ngPatAccountFeatureKey,
  ngPatAccountReducer,
  ngPatInitialAccountState,
  ngPatInitialWebsocketRegistryState,
  ngPatWebSocketReducer,
  ngPatWebsocketRegistryFeatureKey
} from '@ngpat/store';

/**
 * Stripe
 */

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forRoot(
      {
        // TODO change feature key in ng-patterns to ngPatAccountFeatureKey
        [ngPatAccountFeatureKey]: ngPatAccountReducer,
        [ngPatWebsocketRegistryFeatureKey]: ngPatWebSocketReducer
      },
      {
        initialState: {
          [ngPatAccountFeatureKey]: ngPatInitialAccountState,
          [ngPatWebsocketRegistryFeatureKey]: ngPatInitialWebsocketRegistryState
        },
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true
        }
      }
    ),
    EffectsModule.forRoot([NgPatAccountEffects])
  ],
  providers: []
})
export class WebPlatformLoginDataAccessModule {}
