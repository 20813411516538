import {Auth, OAuthProvider} from 'firebase/auth';
import {NgPatAppleAuthProviderConfig} from '../ng-pat-firebase-material-ui.models';

/**
 * Creates an AppleAuthProvider instance.
 * See https://firebase.google.com/docs/auth/web/apple.
 * @param auth
 * @param config
 */
export function createAppleAuthProvider(
  auth: Auth,
  config?: NgPatAppleAuthProviderConfig
): OAuthProvider | null {
  if (!config) {
    return null;
  }

  const provider = new OAuthProvider('apple.com');

  if (config.scopes && config.scopes.length) {
    for (const scope of config.scopes) {
      provider.addScope(scope);
    }
  }

  if (config.locale) {
    provider.setDefaultLanguage(config.locale);
  }

  if (config.customParameters) {
    provider.setCustomParameters(config.customParameters);
  }

  return provider;
}
