<mat-card>
  <mat-progress-bar
    *ngIf="searchInProgress$ | ngrxPush"
    color="accent"
    mode="indeterminate"></mat-progress-bar>
  <div class="messages">
    <ng-container *ngrxLet="messages$; let m">
      <div *ngIf="m?.type === 'success'">
        Added <span class="p-primary">{{ m?.username }}</span> as
        {{ m?.role }} !
      </div>
    </ng-container>
  </div>
  <mat-card-content>
    <form [formGroup]="inviteUser">
      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Search Username</mat-label>

        <input
          matInput
          placeholder="username"
          formControlName="search"
          autocomplete="off"
          [matAutocomplete]="auto" />
        <!--      <mat-error>{{ error$ | ngrxPush }}</mat-error>-->
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayOptionWith"
          (optionSelected)="emailOptionSelected($event)">
          <mat-option
            *ngFor="let option of searchResults$ | ngrxPush"
            [value]="option">
            {{ option.username }}
          </mat-option>
        </mat-autocomplete>

        <mat-icon matSuffix (click)="reset()">close</mat-icon>
      </mat-form-field>

      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Select Role</mat-label>
        <mat-select placeholder="Role" formControlName="role">
          <mat-option *ngFor="let role of rolesTypeNames" [value]="role.type">
            {{ role.name }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="getControl('role').invalid">{{
          getErrorMessage("role")
        }}</mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-flat-button
      color="primary"
      (click)="onSave()"
      [disabled]="inviteUser.invalid || inviteUser.pristine">
      Save
    </button>
  </mat-card-actions>
</mat-card>
