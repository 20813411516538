import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as InvestigationReducer from './investigation.reducer';

export const selectInvestigationState =
  createFeatureSelector<InvestigationReducer.InvestigationState>(
    InvestigationReducer.investigationFeatureKey
  );

const {selectIds, selectEntities, selectAll, selectTotal} =
  InvestigationReducer.investigationAdapter.getSelectors();

export const selectAllInvestigations = createSelector(
  selectInvestigationState,
  selectAll
);
export const selectInvestigationEntities = createSelector(
  selectInvestigationState,
  selectEntities
);
export const selectInvestigationIds = createSelector(
  selectInvestigationState,
  selectIds
);
export const selectInvestigationTotal = createSelector(
  selectInvestigationState,
  selectTotal
);
export const selectedInvestigationID = createSelector(
  selectInvestigationState,
  (state: InvestigationReducer.InvestigationState) =>
    state.selectedInvestigationID
);
