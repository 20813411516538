import {animate, style, transition, trigger} from '@angular/animations';

export const fadeInAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('fadeInAnimation', [
    // route 'enter' transition
    transition(':enter', [
      // css styles at start of transition
      style({opacity: 0, position: 'absolute', right: 0, left: 0}),

      // animation and styles at end of transition
      animate('.3s', style({opacity: 1}))
    ]),
    // route 'enter' transition
    transition(':leave', [
      // css styles at start of transition
      style({position: 'absolute', right: 0, left: 0}),

      // animation and styles at end of transition
      animate('.3s', style({opacity: 0}))
    ])
  ]);
