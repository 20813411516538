import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  signal,
  Signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {startWith, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {ChartsTooltipCalc} from './charts-tooltip.calc';
import {
  ChartTooltipState,
  defaultChartTooltipState
} from './charts-tooltip.model';

@Component({
  selector: 'ng-pat-charts-tooltip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './charts-tooltip.component.html',
  styleUrl: './charts-tooltip.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ng-pat-charts-tooltip',
    '[style.width.px]': 'chartTooltipState().svgWidth - 1'
  }
})
export class ChartsTooltipComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _calc!: ChartsTooltipCalc<any>;

  @Input() set calc(c: ChartsTooltipCalc<any>) {
    this._calc = c;
  }

  get calc() {
    return this._calc;
  }

  chartTooltipState: WritableSignal<ChartTooltipState> = signal(
    defaultChartTooltipState
  );

  translateDivot: WritableSignal<string> = signal(`translate( -12px, 0px)`);

  // private _config!: Partial<ChartTooltipConfig>;
  // @Input() set config(config: Partial<ChartTooltipConfig>) {
  //   this._config = config;
  //
  //   if (this._calc) {
  //     this._calc.calculateParams(config);
  //   }
  // }

  constructor(private _cd: ChangeDetectorRef) {}

  ngOnInit() {
    this._calc.chartTooltipState$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((state: ChartTooltipState) => {
        this.chartTooltipState.set(state);
        this._cd.detectChanges();
      });

    this._calc.translateDivot$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((translateDivot: string) => {
        this.translateDivot.set(translateDivot);
        this._cd.detectChanges();
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
