import {inject, Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Browser} from '@capacitor/browser';
import {
  AUTH_URLS,
  AuthURLs,
  NavItem,
  SUBSCRIBE_NAV_ITEM,
  UrlParamsRouteService
} from '@gigasoftware/shared/domain';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {uuid} from '@ngpat/fn';
import {browserLocalPersistence} from 'firebase/auth';
import firebase from 'firebase/compat';
import {
  DocumentSnapshot,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  where
} from 'firebase/firestore';
import {Subject} from 'rxjs';
import {
  firestoreOtlidById,
  firestoreOtlidCollection,
  OTLID_QUERY_PARAM,
  OTLIDFirestore
} from '../auth-login/+login/login.model';
import DocumentData = firebase.firestore.DocumentData;
import Unsubscribe = firebase.Unsubscribe;

/**
 * State of the login/subscribe button
 *
 */
export enum LOGSUB_STATE {
  SHOW_LOGIN = 'SHOW_LOGIN',
  SHOW_SUBSCRIBE = 'SHOW_SUBSCRIBE',
  SHOW_DEFAULT = 'SHOW_DEFAULT'
}

@Injectable({
  providedIn: 'root'
})
export class AppLoginService {
  router = inject(Router);
  customFirestoreService = inject(NgPatFirestoreService);
  urlRouteParams: UrlParamsRouteService = inject(UrlParamsRouteService);
  authURLs: AuthURLs = inject(AUTH_URLS);
  navItem: NavItem = <NavItem>inject(SUBSCRIBE_NAV_ITEM);

  private _onDestroy$: Subject<boolean> = new Subject();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unsubscribe: Unsubscribe = () => {};
  otlid: string | null = null;

  private _subscribeRoute: string[] = ['subscribe'];

  get subscribeRoute(): string[] {
    return this._subscribeRoute;
  }

  set subscribeRoute(value: string[]) {
    this._subscribeRoute = value;
  }

  constructor() {
    this.otlid = localStorage.getItem(OTLID_QUERY_PARAM);
    if (this.otlid) {
      this.watchFiresstoreOtlid(this.otlid);
    }
  }

  private async onCallbackFromAuthSite(otlid: string) {
    const doc: OTLIDFirestore | null = await this.customFirestoreService.docData(
      firestoreOtlidById(otlid)
    );
    await this.processAuth(doc);
  }

  private async processAuth(doc: OTLIDFirestore | null | undefined) {
    if (doc && doc.uid && doc.jwtToken) {
      await this.deleteFirestoreOtlibNodes(doc.uid);

      await this.customFirestoreService.setPersistence(browserLocalPersistence);

      await this.customFirestoreService.signInWithCustomToken(<string>doc.jwtToken);
    }

    if (doc) {
      localStorage.removeItem(OTLID_QUERY_PARAM);
      this.customFirestoreService.deleteDoc(firestoreOtlidById(doc.otlid));
    }
  }

  private async deleteFirestoreOtlibNodes(uid: string): Promise<boolean> {
    const _pathRef = this.customFirestoreService.collectionRef(firestoreOtlidCollection());

    const q = query(_pathRef, where('uid', '==', uid));

    return new Promise((resolve, reject) => {
      getDocs(q).then((querySnapshot: QuerySnapshot<DocumentData>) => {
        const batch = this.customFirestoreService.writeBatch();

        for (let q = 0; q < querySnapshot.docs.length; q++) {
          /**
           * QueryDocumentSnapshot id
           */
          const docRef = this.customFirestoreService.docRef(
            firestoreOtlidById(querySnapshot.docs[q].id)
          );

          /**
           * Delete doc
           */
          batch.delete(docRef);
        }

        batch
          .commit()
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      });
    });
  }

  login() {
    const that = this;

    if (!this.otlid) {
      this.otlid = uuid().replace(/-/gi, '');
    }

    localStorage.setItem(OTLID_QUERY_PARAM, this.otlid);

    const authObject: OTLIDFirestore = {
      otlid: this.otlid,
      jwtToken: null,
      uid: null,
      email: null,
      displayName: null,
      action: 'login'
    };

    this.customFirestoreService
      .setDoc(firestoreOtlidById(this.otlid), authObject)
      .then(() => {
        if (this.otlid) {
          // const url = `${this.authURLs.authURL}?${OTLID_QUERY_PARAM}=${otlid}&${REDIRECT_QUERY_PARAM}=${redirectUrl}`;
          const url = `${this.authURLs.authURL}?${OTLID_QUERY_PARAM}=${this.otlid}`;

          this.watchFiresstoreOtlid(this.otlid);

          /**
           * Open the browser
           * toolbarColor is the color of the top bar
           * the color should be the same as --mat-sidenav-content-background-color
           * which is currently 06090f
           */
          Browser.open({url, toolbarColor: '#06090f'});
        }
      })
      .catch(() => {
        if (this.otlid) {
          // that.customFirestoreService.deleteDoc(firestoreOtlidById(this.otlid));
        }
      });

    // this.windowService.window.open(this.authDomain.authURL, '_self');
  }

  watchFiresstoreOtlid(otlid: string) {
    const that = this;

    const docRef = this.customFirestoreService.docRef(firestoreOtlidById(otlid));

    // Watch for document changes
    this.unsubscribe = onSnapshot(docRef, async (_doc: DocumentSnapshot<DocumentData>) => {
      const doc = _doc.data() as OTLIDFirestore;

      if (doc && doc.action) {
        if ((doc && doc.action && doc.action === 'success') || doc.action === 'cancel') {
          that.unsubscribe();
        }

        if (doc && doc.action) {
          if (doc.action === 'success') {
            await that.processAuth(doc);
          }
          if (doc.action === 'cancel') {
            localStorage.removeItem(OTLID_QUERY_PARAM);
            that.customFirestoreService.deleteDoc(firestoreOtlidById(otlid));
          }
        }
      }
    });
  }

  loginOrSubscribe(state: LOGSUB_STATE) {
    if (state === LOGSUB_STATE.SHOW_LOGIN) {
      this.login();
    } else if (state === LOGSUB_STATE.SHOW_SUBSCRIBE) {
      // this.urlRouteParams.navigateWithParams()
      this.urlRouteParams.navigateWithParams(this.navItem.id);
    }
  }

  destroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();

    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
