import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, ViewEncapsulation} from '@angular/core';
import {MatDivider} from '@angular/material/divider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {Route} from '@angular/router';
import {NavItem, SUBSCRIBE_NAV_ITEM, UrlParamsRouteService} from '@gigasoftware/shared/domain';
import {
  DlcHeaderBarComponent,
  DlcInputCodeComponent,
  DlcMobilePageContentComponent,
  DlcRoundedTextButtonComponent,
  DlcStripeSubscriptionsComponent,
  fadeInAnimation,
  UiNoSubscriptionTextComponent
} from '@gigasoftware/shared/ui-design-library';
import {
  ngPatRemoveCodeFromAccount,
  ngPatSelectRemoteConfigsContainedInBothAccountAndRemoteConfig,
  ngPatSetCodeOnAccount,
  NgPatStripeTrialParams,
  selectHasActiveStripeSubscription,
  selectNagPatHasActiveSubscription,
  selectNgPatHasActiveMobileSubscription,
  selectNgPatStripeTrialParams
} from '@ngpat/store';
import {LetDirective, PushPipe} from '@ngrx/component';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {PatDeleteAccountComponent} from './delete-account/pat-delete-account.component';

@Component({
  selector: 'ec-app-account-page',
  templateUrl: './app-account.component.html',
  styleUrls: ['./app-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[@fadeInAnimation]': '',
    class: 'ec-app-account-page  dlc-global-mobile-page'
  },
  animations: [fadeInAnimation],
  standalone: true,
  imports: [
    NgIf,
    MatProgressBarModule,
    DlcStripeSubscriptionsComponent,
    LetDirective,
    UiNoSubscriptionTextComponent,
    PatDeleteAccountComponent,
    PushPipe,
    DlcRoundedTextButtonComponent,
    DlcMobilePageContentComponent,
    DlcHeaderBarComponent,
    DlcInputCodeComponent,
    MatDivider
  ]
})
export class AppAccountComponent {
  store: Store = inject(Store);
  urlRouteParams: UrlParamsRouteService = inject(UrlParamsRouteService);
  navItem: NavItem = <NavItem>inject(SUBSCRIBE_NAV_ITEM);

  trialParams$: Observable<NgPatStripeTrialParams>;
  showCheckoutProgress = false;
  hasActiveSubscription = this.store.selectSignal(selectNagPatHasActiveSubscription);
  remoteConfigs = this.store.selectSignal(
    ngPatSelectRemoteConfigsContainedInBothAccountAndRemoteConfig
  );

  hasAppStoreSubscription$: Observable<boolean>;
  hasStripeSubscription$: Observable<boolean>;

  constructor() {
    // @Inject(SUBSCRIBE_NAV_ITEM) public navItem: NavItem
    this.trialParams$ = this.store.pipe(select(selectNgPatStripeTrialParams), debounceTime(300));

    this.hasAppStoreSubscription$ = this.store.pipe(select(selectNgPatHasActiveMobileSubscription));

    this.hasStripeSubscription$ = this.store.pipe(select(selectHasActiveStripeSubscription));
  }

  onSubscribe() {
    this.urlRouteParams.navigateWithParams(this.navItem.id);
  }

  addCode(code: string) {
    this.store.dispatch(ngPatSetCodeOnAccount({code}));
  }

  removeCode(code: string) {
    this.store.dispatch(ngPatRemoveCodeFromAccount({code}));
  }
}

export const SHARED_ACCOUNT_ROUTES: Route[] = [
  {
    path: '',
    component: AppAccountComponent
  }
];
