import {Component, OnInit} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {HeaderComponent} from './components/header/header.component';

@Component({
  standalone: true,
  imports: [RouterModule, HeaderComponent],
  selector: 'ecm-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(private firestore: NgPatFirestoreService) {}

  ngOnInit() {
    this.firestore.logEvent('app_opened');
  }
}
