import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  combineQuestionsAndPath,
  CopyQuizParams,
  createOwnerUserRole,
  createProjectUserArchitecture,
  firestoreQueryPathByProject,
  firestoreQuizQuestionsByProject,
  firestoreUserQuizDoc,
  getImagePathsFromQuizAndQuestions,
  Project,
  Question,
  QuestionWithPath,
  Quiz,
  QuizService,
  selectAllProjectsToCopyTo,
  updateImagePathWithID
} from '@gigasoftware/evolving-cognition/domain';
import {GsAssetService} from '@gigasoftware/shared/media';
import {CopyAsset, CopyAssetToPathParams} from '@gigasoftware/shared/entities';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {uuid} from '@ngpat/fn';
import {NgPatProcessQueue} from '@ngpat/utils';
import {select, Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export interface ConfirmCopyQuiz {
  quiz: Quiz;
}

@Component({
  // 'plat-copy-quiz'
  selector: 'ec-copy-quiz',
  templateUrl: './ec-copy-quiz-dialog.component.html',
  styleUrls: ['./ec-copy-quiz-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EcCopyQuizDialogComponent implements OnDestroy, OnInit {
  private _onDestroy$: Subject<boolean> = new Subject();

  isCopying: WritableSignal<boolean> = signal(false);
  copyingQuiz: WritableSignal<string> = signal('');

  copyToOptions: CopyQuizParams[] = [];

  displayedColumns = ['name', 'projectTypeName', 'copyAction'];

  firestoreAssetPathProcessQueue: NgPatProcessQueue<CopyAssetToPathParams> =
    new NgPatProcessQueue<CopyAssetToPathParams>();

  imagePathsToCopy: CopyAsset[] = [];
  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<EcCopyQuizDialogComponent>,
    private firestore: NgPatFirestoreService,
    private assetService: GsAssetService,
    private cd: ChangeDetectorRef,
    private quizService: QuizService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmCopyQuiz
  ) {}
  ngOnDestroy(): void {
    this._onDestroy$.next(true);
  }

  ngOnInit() {
    this.store
      .pipe(select(selectAllProjectsToCopyTo), takeUntil(this._onDestroy$))
      .subscribe((c: CopyQuizParams[]) => {
        this.copyToOptions = [...c];
        this.cd.detectChanges();
      });

    this.firestoreAssetPathProcessQueue.currentItem$.subscribe(
      async (item: CopyAssetToPathParams) => {
        const replace = new RegExp(<string>item.oldID, 'g');

        // console.log('item', item);

        const list = await this.assetService.getDirectoryList(item.oldBasePath);

        // console.log(list);

        list.forEach((path: string) => {
          this.imagePathsToCopy.push({
            oldAssetImagePath: path,
            newAssetImagePath: path.replace(replace, item.newID)
          });
        });
        this.firestoreAssetPathProcessQueue.next();
      }
    );
  }

  async onCopyQuizAndAssets(params: CopyQuizParams) {
    const that = this;

    const action = {
      params,
      quiz: this.data.quiz
    };

    /**
     * UPDATE UI VIEW TO LET USER KNOW
     * QUIZ, QUESTIONS, AND ASSETS ARE
     * BEING COPIED
     */
    const copyQuizTargetName = params.isPersonal
      ? 'Personal Quizzes'
      : params?.copyToParentProject?.name || '';

    const copyQuizToMessage = `Copying ${params.name} to ${copyQuizTargetName}`;

    this.copyingQuiz.set(copyQuizToMessage);
    this.isCopying.set(true);

    /**
     * SOURCE
     */

    const questionsFirestoreSourcePath = firestoreQuizQuestionsByProject(
      action.quiz as Project,
      action.params.uid
    );

    const sourceQuestions: Question[] = await this.firestore.collectionData(
      questionsFirestoreSourcePath
    );

    /**
     * CREATE NEW QUIZ AND QUESTIONS
     * WITH NEW ID
     */
    const newQuiz: Quiz = {
      ...action.quiz,
      name: `Copy of ${action.quiz.name}`,
      id: uuid(),
      ...createProjectUserArchitecture(createOwnerUserRole(action.params.uid))
    };

    if (action.params.isPersonal) {
      newQuiz.isCollaborative = false;
      newQuiz.parentProjectID = null;
      newQuiz.parentProjectType = null;
    } else if (action.params.copyToParentProject !== null) {
      newQuiz.isCollaborative = true;
      newQuiz.parentProjectID = action.params.copyToParentProject.id;
      newQuiz.parentProjectType = action.params.copyToParentProject.projectType;
    }

    newQuiz.isDefault = false;
    newQuiz.isPrivate = false;
    newQuiz.isPublished = false;
    newQuiz.assigned = true;

    const newQuestions: Question[] = sourceQuestions.map((q: Question) => {
      return {
        ...q,
        id: uuid(),
        quizID: newQuiz.id
      };
    });

    /**
     * DESTINATION
     */

    let firestoreDestinationPath = '';

    if (
      !action.params.isPersonal &&
      action.params.copyToParentProject !== null
    ) {
      firestoreDestinationPath = firestoreQueryPathByProject(
        newQuiz as Project,
        action.params.uid
      );
    } else {
      firestoreDestinationPath = firestoreUserQuizDoc(
        action.params.uid,
        newQuiz.id
      );
    }

    /**
     * NOTE: created after quiz params are updated
     */
    const newQuestionsWithFirestorePath: QuestionWithPath[] =
      combineQuestionsAndPath(newQuestions, newQuiz, action.params.uid);

    // const _newQuestionsOnly: Question[] = newQuestionsWithFirestorePath.map(
    //   (q: QuestionWithPath) => ({
    //     ...q.question
    //   })
    // );

    const firestoreAssetsToCopy: CopyAssetToPathParams[] =
      getImagePathsFromQuizAndQuestions([newQuiz, ...newQuestions]);

    if (firestoreAssetsToCopy.length === 0) {
      this.setQuizAndQuestions(
        firestoreDestinationPath,
        newQuiz,
        newQuestionsWithFirestorePath
      );
    } else {
      this.firestoreAssetPathProcessQueue.addItems(
        firestoreAssetsToCopy,
        () => {
          that.setQuizAndQuestions(
            firestoreDestinationPath,
            newQuiz,
            newQuestionsWithFirestorePath
          );
        }
      );
    }
  }

  async setQuizAndQuestions(
    firestoreDestinationPath: string,
    newQuiz: Quiz,
    newQuestions: QuestionWithPath[]
  ) {
    newQuiz = updateImagePathWithID<Quiz>(newQuiz);
    newQuestions = newQuestions.map((q: QuestionWithPath) => {
      return {
        ...q,
        question: updateImagePathWithID<Question>(q.question)
      };
    });

    // console.log('copying image assets', this.imagePathsToCopy);
    // console.log('firestoreDestinationPath', firestoreDestinationPath);
    // console.log('newQuiz', newQuiz);
    // console.log('newQuestions', newQuestions);

    if (this.imagePathsToCopy.length > 0) {
      await this.assetService.copyImageAssets(this.imagePathsToCopy);
    }

    await this.quizService.setQuizAndQuestions(
      firestoreDestinationPath,
      newQuiz,
      newQuestions
    );

    this.isCopying.set(false);
    this.dialogRef.close(newQuiz);
  }
}
