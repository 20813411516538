import {CollaborativeProject} from '../+project/project.model';
import {
  Member,
  MemberUIDProjectDict,
  MemberUIDProjectID
} from './members.model';

export function convertCollaborativeProjectToMemberUIDProjectID(
  groups: CollaborativeProject[]
): MemberUIDProjectID[] {
  if (groups && groups.length) {
    return groups
      .filter((p: CollaborativeProject) => p.memberUIDs && p.memberUIDs.length)
      .reduce((a: MemberUIDProjectID[], g: CollaborativeProject) => {
        const members: MemberUIDProjectID[] = g.memberUIDs.reduce(
          (_a: MemberUIDProjectID[], memberID: string) => {
            _a.push({
              uid: memberID,
              projectID: g.id,
              projects: {
                [g.id]: {
                  projectID: g.id,
                  role: g.members[memberID].role
                }
              }
            });

            return _a;
          },
          []
        );

        return [...a, ...members];
      }, <MemberUIDProjectID[]>[]);
  }

  return [];
}

export function concatMember(m: MemberUIDProjectID[]) {
  const dict: MemberUIDProjectDict = m.reduce(
    (a: MemberUIDProjectDict, i: MemberUIDProjectID) => {
      if (!a[i.uid]) {
        a[i.uid] = {
          uid: i.uid,
          username: '',
          projectIDs: [],
          projects: {}
        };
      }

      a[i.uid].projectIDs = [...new Set([...a[i.uid].projectIDs, i.projectID])];

      a[i.uid].projects = {
        ...a[i.uid].projects,
        ...i.projects
      };

      return a;
    },
    <MemberUIDProjectDict>{}
  );

  return Object.values(dict);
}

export function removeProjectFromMember(
  _m: Member,
  p: CollaborativeProject
): Member {
  const m: Member = JSON.parse(JSON.stringify(_m));

  /**
   * If Member includes project id, but p does not include
   * the member id, from the project from the member
   */
  if (m.projects[p.id] && !p.memberUIDs.includes(m.uid)) {
    const idIndex = m.projectIDs.indexOf(p.id);

    if (idIndex > -1) {
      m.projectIDs.splice(idIndex, 1);
    }

    delete (<any>m).projects[p.id];
  }

  return m;
}

export function iterateChanges<State>(
  action: any,
  key: string,
  _state: {entities: {[uid: string]: Member}}
): State {
  for (let i = 0; i < action[key].length; i++) {
    if (action[key][i].changes.isCollaborative) {
      const p: CollaborativeProject = <CollaborativeProject>(
        action[key][i].changes
      );

      for (const uid in _state.entities) {
        if (_state.entities[uid]) {
          _state.entities[uid] = removeProjectFromMember(
            _state.entities[uid],
            p
          );
        }
      } // end for
    } // end if
  } // end for

  return (<unknown>_state) as State;
}
