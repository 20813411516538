<ng-container *ngIf="sigWidth() && sigHeight()">
  <button
    mat-button
    (click)="clickAction.emit()"
    [style.min-width.px]="sigWidth()"
    [style.height.px]="sigHeight()">
    <img
      src="assets/ec-ui/ec-logo-42x32.png"
      alt="Evolving Cognition"
      [style.width.px]="42"
      [style.height.px]="32" />
  </button>
</ng-container>
