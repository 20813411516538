import {
  ChangeDetectionStrategy,
  Component, Input,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'dlc-mobile-page-content',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dlc-mobile-page-content.component.html',
  styleUrls: ['./dlc-mobile-page-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: `dlc-mobile-page-content`,
    '[class.dlc-mobile-page-content--height-full]': 'heightFull'
  }
})
export class DlcMobilePageContentComponent {
  @Input() heightFull = false;

}
