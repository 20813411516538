import {SearchResponse} from '@algolia/client-search';
import {Inject, Injectable} from '@angular/core';
import {ENVIRONMENT, IEnvironmentState} from '@gigasoftware/shared/models';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {NgPatAccountAlgolia} from '@ngpat/store';
import {Store} from '@ngrx/store';
import algoliasearch, {SearchIndex} from 'algoliasearch';
import {from, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Injectable()
export class SearchAlgoliaService {
  private searchIndex: SearchIndex;
  private _queryConfig: {[key: string]: number} = {
    hitsPerPage: 10
  };
  private _cancelCurrentQuery$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState
  ) {
    const _client = algoliasearch(
      this.environment.algolia.appId,
      this.environment.algolia.apiKey
    );
    this.searchIndex = _client.initIndex('users');
  }

  searchUser(search: string): Observable<any> {
    this._cancelCurrentQuery$.next(true);

    return from(this.searchIndex.search(search, this._queryConfig)).pipe(
      takeUntil(this._cancelCurrentQuery$),
      map((results: SearchResponse<unknown>) => {
        return <SearchResponse<NgPatAccountAlgolia>>{
          ...results
        };
      }),
      map((results: SearchResponse<NgPatAccountAlgolia>) => results.hits)
    );

    // return this.store.pipe(select(selectDoConnect)).pipe(
    //   filter((doConnect: boolean) => doConnect),
    //   take(1),
    //   switchMap((): any => {
    //     return combineLatest([this.searchEmail(search), this.searchStagename(search)]).pipe(
    //       take(1),
    //       map(([emails, usernames]: [AccountState[], AccountState[]]) => {
    //         return [...emails, ...usernames];
    //       })
    //     );
    //   })
    // );
  }
}
