import {
  animate,
  AnimationTriggerMetadata,
  group,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const opacityAnimation: AnimationTriggerMetadata = trigger('opacityAnimation', [
  state(
    'void, hidden',
    style({
      opacity: 0
    })
  ),
  transition(':enter, hidden => visible', [
    group([
      animate('0.03s linear', style({ opacity: 1 }))
    ])
  ]),
  transition(':leave, visible => hidden', [ animate('0.075s linear', style({ opacity: 0 })) ])
]);

export const displayNoneInlineFlexAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('displayNoneInlineFlexAnimation', [

    // route 'enter' transition
    transition(':enter', [
      animate('200ms', keyframes(
        [
          style({ opacity: 0, display: 'none', offset: 0 }),
          style({ opacity: 0, display: 'inline-flex', offset: 0.01 }),
          style({ opacity: 1, display: 'inline-flex', 'z-index': 10, offset: 1.0 }),
        ]))
    ]),
    transition(':leave', [
      animate('200ms', keyframes(
        [
          style({ opacity: 1, display: 'inline-flex', offset: 0 }),
          style({ opacity: 0, display: 'inline-flex', offset: 0.99 }),
          style({ opacity: 0, 'z-index': 0, display: 'none', offset: 1.0 }),
        ]))
    ])
  ]);
