import {Route} from '@angular/router';
import {ContactComponent} from './pages/contact/contact.component';
import {FeaturesComponent} from './pages/features/features.component';
import {HomeComponent} from './pages/home/home.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {TocComponent} from './pages/toc/toc.component';

export const appRoutes: Route[] = [
  {
    path: 'home',
    pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: 'features',
    pathMatch: 'full',
    component: FeaturesComponent
  },
  {
    path: 'privacy',
    pathMatch: 'full',
    component: PrivacyComponent
  },
  {
    path: 'toc',
    pathMatch: 'full',
    component: TocComponent
  },
  {
    path: 'contact',
    pathMatch: 'full',
    component: ContactComponent
  },
  {path: '**', redirectTo: 'home', pathMatch: 'full'}
];
