import {
  CreateProjectParams,
  createProjectParamsFromParentProject,
  createProjectParamsObject,
  DEFAULT_PLATFORM_PROJECT_TYPE_DICT,
  Project
} from '../+project/';
import {Quiz} from '../+quizzes';

export type PathFunction = () => string;

const USERS = 'users';

const QUIZZES = 'quizzes';
const ANSWERS = 'answers';

const COURSES = 'courses';
const STUDY_GROUPS = 'studyGroups';
const CLASSES = 'classes';
const RESEARCH = 'researchTopics';
const INVESTIGATIONS = 'investigations';
const PROJECTS = 'projects';
const CHAT_TOPICS = 'chatTopics';
const QUESTIONS = 'questions';
const QUIZ_RESULTS = 'quizGrades';
// const NOTIFICATIONS = 'notifications';
const PROMO_CODES = 'promoCodes';
const APP = 'app';
const QUIZ_KIT_TRIAL = 'quiz-kit-trial';
const PUBLISHED_QUIZZES = 'publishedQuizzes';

export const SUBJECTS = 'subjects';

export function firestoreEvolvingCognitionAppVersion() {
  return `app/ec-app-release`;
}

export function firestoreQuizKitMarketingVersion() {
  return `app/quiz-kit-marketing-release`;
}

export function firestorePatternsVersion() {
  return `app/pattern-app-release`;
}

export function firestorePromoCodeCollection() {
  return `${PROMO_CODES}`;
}

export function firestoreTrialPath() {
  return `${APP}/${QUIZ_KIT_TRIAL}`;
}

/**
 * User paths
 */
export function firestoreUserCollection() {
  return `${USERS}`;
}

export function firestoreUserAccountDoc(uid: string): string {
  return `${USERS}/${uid}`;
}

export function firestoreUserQuizCollection(uid: string): string {
  return `${USERS}/${uid}/${QUIZZES}`;
}
export function firestoreUserQuizDoc(uid: string, quizID: string): string {
  return `${USERS}/${uid}/${QUIZZES}/${quizID}`;
}
export function firestoreUserCourseCollection(uid: string): string {
  return `${USERS}/${uid}/${COURSES}`;
}
export function firestoreUserCourseDoc(uid: string, courseID: string): string {
  return `${USERS}/${uid}/${COURSES}/${courseID}`;
}
export function firestoreUserStudyGroupCollection(uid: string): string {
  return `${USERS}/${uid}/${STUDY_GROUPS}`;
}
export function firestoreUserStudyGroupDoc(uid: string, studyGroupID: string): string {
  return `${USERS}/${uid}/${STUDY_GROUPS}/${studyGroupID}`;
}
export function firestoreUserClassCollection(uid: string): string {
  return `${USERS}/${uid}/${CLASSES}`;
}
export function firestoreUserClassDoc(uid: string, classID: string): string {
  return `${USERS}/${uid}/${CLASSES}/${classID}`;
}
export function firestoreUserResearchCollection(uid: string): string {
  return `${USERS}/${uid}/${RESEARCH}`;
}
export function firestoreUserResearchDoc(uid: string, researchID: string): string {
  return `${USERS}/${uid}/${RESEARCH}/${researchID}`;
}
export function firestoreUserInvestigationCollection(uid: string): string {
  return `${USERS}/${uid}/${INVESTIGATIONS}`;
}
export function firestoreUserInvestigationDoc(uid: string, investigationID: string): string {
  return `${USERS}/${uid}/${INVESTIGATIONS}/${investigationID}`;
}

/**
 * Projects paths
 */
export function firestorePublishedQuizzesCollection() {
  return `${PUBLISHED_QUIZZES}`;
}

export function firestorePublishedQuizDoc(quizID: string): string {
  return `${PUBLISHED_QUIZZES}/${quizID}`;
}

export function firestorePublishedQuizQuestionDoc(quizID: string, questionID: string): string {
  return `${PUBLISHED_QUIZZES}/${quizID}/${QUESTIONS}/${questionID}`;
}

export function firestoreQuizCollection() {
  return `${QUIZZES}`;
}
export function firestoreQuizDoc(quizID: string): string {
  return `${QUIZZES}/${quizID}`;
}
export function firestoreQuizChatTopicsCollection(quizID: string): string {
  return `${QUIZZES}/${quizID}/${CHAT_TOPICS}`;
}
export function firestoreQuizChatTopicsDoc(quizID: string, chatTopicID: string): string {
  return `${QUIZZES}/${quizID}/${CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreCourseCollection() {
  return `${COURSES}`;
}
export function firestoreCourseDoc(courseID: string): string {
  return `${COURSES}/${courseID}`;
}
export function firestoreCourseChatTopicsCollection(courseID: string): string {
  return `${COURSES}/${courseID}/${CHAT_TOPICS}`;
}
export function firestoreCourseChatTopicsDoc(courseID: string, chatTopicID: string): string {
  return `${COURSES}/${courseID}/${CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreStudyGroupCollection() {
  return `${STUDY_GROUPS}`;
}
export function firestoreStudyGroupDoc(studyGroupID: string): string {
  return `${STUDY_GROUPS}/${studyGroupID}`;
}
export function firestoreStudyGroupChatTopicsCollection(studyGroupID: string): string {
  return `${STUDY_GROUPS}/${studyGroupID}/${CHAT_TOPICS}`;
}
export function firestoreStudyGroupChatTopicsDoc(
  studyGroupID: string,
  chatTopicID: string
): string {
  return `${STUDY_GROUPS}/${studyGroupID}/${CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreStudyGroupByIDQuizzesCollection(studyGroupID: string): string {
  return `${STUDY_GROUPS}/${studyGroupID}/${QUIZZES}`;
}

export function firestoreClassCollection() {
  return `${CLASSES}`;
}
export function firestoreClassDoc(classID: string): string {
  return `${CLASSES}/${classID}`;
}
export function firestoreClassChatTopicsCollection(classID: string): string {
  return `${CLASSES}/${classID}/${CHAT_TOPICS}`;
}
export function firestoreClassChatTopicsDoc(classID: string, chatTopicID: string): string {
  return `${CLASSES}/${classID}/${CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreResearchCollection() {
  return `${RESEARCH}`;
}
export function firestoreResearchDoc(researchID: string): string {
  return `${RESEARCH}/${researchID}`;
}
export function firestoreResearchChatTopicsCollection(researchID: string): string {
  return `${RESEARCH}/${researchID}/${CHAT_TOPICS}`;
}
export function firestoreResearchChatTopicsDoc(researchID: string, chatTopicID: string): string {
  return `${RESEARCH}/${researchID}/${CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreInvestigationCollection() {
  return `${INVESTIGATIONS}`;
}
export function firestoreInvestigationDoc(investigationsID: string): string {
  return `${INVESTIGATIONS}/${investigationsID}`;
}
export function firestoreInvestigationChatTopicsCollection(investigationsID: string): string {
  return `${INVESTIGATIONS}/${investigationsID}/${CHAT_TOPICS}`;
}
export function firestoreInvestigationChatTopicsDoc(
  investigationsID: string,
  chatTopicID: string
): string {
  return `${INVESTIGATIONS}/${investigationsID}/${CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreProjectsCollection() {
  return `${PROJECTS}`;
}
export function firestoreProjectDoc(projectId: string) {
  return `${PROJECTS}/${projectId}`;
}

/**
 * Permission paths
 */
export function firestorePermissionsCollection() {
  return `permissions`;
}

export function firestoreProjectSubPath(params: CreateProjectParams, projectID: string) {
  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.QUIZ.id) {
    return firestoreQuizDoc(projectID);
  }
  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.COURSE.id) {
    return firestoreCourseDoc(projectID);
  }
  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.RESEARCH.id) {
    return firestoreResearchDoc(projectID);
  }

  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
    return firestoreClassDoc(projectID);
  }

  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.INVESTIGATION.id) {
    return firestoreInvestigationDoc(projectID);
  }

  return '';
}

export function firestoreCollectionByProjectType(projectType: number) {
  if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
    return firestoreClassCollection();
  }

  if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id) {
    return firestoreStudyGroupCollection();
  }

  return '';
}

/**
 * Project based on ProjectType and Path
 */
export function firestoreProjectPathByParams(
  params: CreateProjectParams,
  uid: string,
  projectID: string
): string {
  if (params.isCollaborative) {
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.QUIZ.id) {
      return firestoreQuizDoc(projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.COURSE.id) {
      return firestoreCourseDoc(projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.RESEARCH.id) {
      return firestoreResearchDoc(projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.INVESTIGATION.id) {
      return firestoreInvestigationDoc(projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id) {
      return firestoreStudyGroupDoc(projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
      return firestoreClassDoc(projectID);
    }
  } else {
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.QUIZ.id) {
      return firestoreUserQuizDoc(uid, projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.COURSE.id) {
      return firestoreUserCourseDoc(uid, projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.RESEARCH.id) {
      return firestoreUserResearchDoc(uid, projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.INVESTIGATION.id) {
      return firestoreUserInvestigationDoc(uid, projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id) {
      return firestoreUserStudyGroupDoc(uid, projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
      return firestoreUserClassDoc(uid, projectID);
    }
  }

  return '';
}

export function firestoreQueryPathByProject(p: Project, uid: string) {
  const params: CreateProjectParams = createProjectParamsObject(p);

  if (
    p.isCollaborative &&
    p.parentProjectID &&
    p.parentProjectID.length &&
    p.parentProjectType !== null
  ) {
    const parentParams: CreateProjectParams = {
      ...params,
      projectTypeValue: p.parentProjectType
    };

    return `${firestoreProjectPathByParams(
      parentParams,
      uid,
      p.parentProjectID
    )}/${firestoreProjectSubPath(params, p.id)}`;
  } else {
    return firestoreProjectPathByParams(params, uid, p.id);
  }
}

export function firestoreQuizQuestionsByProject(p: Project, uid: string) {
  return `${firestoreQueryPathByProject(p, uid)}/${QUESTIONS}`;
}

// published quiz quiestions
export function firestorePublishedQuizQuestions(p: Quiz) {
  return `${firestorePublishedQuizDoc(p.id)}/${QUESTIONS}`;
}

export function firestoreQuizQuestionByProjectAndQuestionId(
  p: Project,
  questionID: string,
  uid: string
) {
  return `${firestoreQueryPathByProject(p, uid)}/${QUESTIONS}/${questionID}`;
}

export function firestoreQuizQuestionsAnswersByProject(
  p: Project,
  uid: string,
  questionId: string
) {
  return `${firestoreQueryPathByProject(p, uid)}/${QUESTIONS}/${questionId}/${ANSWERS}`;
}

export function firestoreQuizGradesByProject(p: Project, uid: string) {
  if (
    p.parentProjectID &&
    p.parentProjectID.length &&
    p.parentProjectType !== null &&
    p.parentProjectType !== undefined
  ) {
    const params: CreateProjectParams = createProjectParamsFromParentProject(p);
    return `${firestoreProjectPathByParams(params, uid, p.parentProjectID)}/${QUIZ_RESULTS}`;
  } else {
    return `${firestoreQueryPathByProject(p, uid)}/${QUIZ_RESULTS}`;
  }
}

export function firestoreQuizGradeByProject(p: Project, uid: string, resultID: string) {
  return `${firestoreQuizGradesByProject(p, uid)}/${resultID}`;
}

// SUBJECTS
export function firestoreSubjectsCollection() {
  return `${SUBJECTS}`;
}

export function firestoreSubjectDoc(subjectID: string): string {
  return `${SUBJECTS}/${subjectID}`;
}
