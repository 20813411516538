import {Answer, Question, QUESTION_TYPE, Quiz} from './quiz.model';
import {createMultichoiceAnswer, createQuestion, createTruFalseQuestion} from './quiz.fns';

export interface OpenAiMultipleChoiceAnswer {
  answer: string;
  isCorrect: boolean;
}

export interface OpenAiTrueFalseChoiceAnswer {
  answer: boolean;
  isCorrect: boolean;
}

/**
 * JSON format like this:
 * {
 *     'type': 'multiple_choice',
 *     'question': 'What is the value of the polynomial 2x^2 + 3x - 5 at x = 2?',
 *     'answers': [
 *       {'answer': '9', 'isCorrect': true},
 *       {'answer': '7', 'isCorrect': false},
 *       {'answer': '11', 'isCorrect': false},
 *       {'answer': '5', 'isCorrect': false}
 *     ]
 *   }
 */
export interface OpenAiMultipleChoiceQuestion {
  type: 'multiple_choice';
  question: string;
  answers: OpenAiMultipleChoiceAnswer[];
}

export interface OpenAiTrueFalseQuestion {
  type: 'true_false';
  question: string;
  answers: OpenAiTrueFalseChoiceAnswer[];
}

export interface OpenAiQuestion {
  type: 'multiple_choice' | 'true_false';
  question: string;
  answers: OpenAiMultipleChoiceAnswer[] | OpenAiTrueFalseChoiceAnswer[];
}

export function createOpenAiMultipleChoiceQuestion(
  question: OpenAiMultipleChoiceQuestion,
  quiz: Quiz
): Question {
  const answers: Answer[] = question.answers.map(answer => {
    const answerObj: Answer = createMultichoiceAnswer();
    answerObj.answerText = answer.answer;
    answerObj.isCorrect = answer.isCorrect;
    return answerObj;
  });

  const questionObj: Question = createQuestion(quiz, QUESTION_TYPE.MULTIPLE_CHOICE);

  questionObj.question = question.question;

  answers.forEach((currentAnswer: Answer) => {
    questionObj.answers[currentAnswer.id] = currentAnswer;
  });

  return questionObj;
}

export function createOpenAiTrueFalseQuestion(
  question: OpenAiTrueFalseQuestion,
  quiz: Quiz
): Question {
  const trueFalseAnswer = question.answers.reduce(
    (trueFalseAnswer: boolean, answer: OpenAiTrueFalseChoiceAnswer) => {
      if (answer.isCorrect) {
        trueFalseAnswer = answer.answer;
      }

      return trueFalseAnswer;
    },
    false
  );

  const questionObj: Question = createTruFalseQuestion(quiz, QUESTION_TYPE.TRUE_FALSE);

  questionObj.question = question.question;

  const answerObj: Answer = questionObj.answers[Object.keys(questionObj.answers)[0]];
  answerObj.trueFalseAnswer = trueFalseAnswer;

  questionObj.answers[answerObj.id] = answerObj;

  return questionObj;
}

export function createQuestionsFromOpenAiResponse(
  quiz: Quiz,
  openAiQuestions: OpenAiQuestion[]
): Question[] {
  // console.log(openAiQuestions);

  return openAiQuestions.map((openAiQuestion: OpenAiQuestion) => {
    if (openAiQuestion.type === 'true_false') {
      return createOpenAiTrueFalseQuestion(<OpenAiTrueFalseQuestion>openAiQuestion, quiz);
    }
    return createOpenAiMultipleChoiceQuestion(<OpenAiMultipleChoiceQuestion>openAiQuestion, quiz);
  });
}
