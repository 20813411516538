<h3 mat-dialog-title>Confirm Leave {{ data.name }}</h3>
<mat-dialog-content>
  Are you sure you want to&nbsp;leave
  <span *ngIf="data?.message" class="p-primary project-name">
    {{ data.message }}
  </span>
  ?
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button color="primary" (click)="dialogRef.close(false)">
    Cancel
  </button>
  <button mat-flat-button color="primary" (click)="dialogRef.close(true)">
    Leave
  </button>
</mat-dialog-actions>
