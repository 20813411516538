<dlc-header-bar>
  <span>Account</span>
</dlc-header-bar>

<dlc-mobile-page-content class="mt-5">
  <!-- BROWSER -->
  <ng-container *ngIf="hasStripeSubscription$ | ngrxPush">
    <dlc-stripe-subscriptions
      [showUpdateBtn]="false"
      (showCancelProgress)="showCheckoutProgress = $event"></dlc-stripe-subscriptions>
    <ng-container *ngrxLet="trialParams$; let p">
      <div class="trial-notification" *ngIf="!p.isInTrial && !hasActiveSubscription()">
        <ui-no-subscription-text></ui-no-subscription-text>
      </div>
    </ng-container>
  </ng-container>

  <!-- NOT HAS USER_ACTIVE SUBSCRIPTIONS -->
  @if(!hasActiveSubscription()) {
  <div class="mb-2">You have no active subscriptions.</div>
  <div>
    <button dlc-rounded-text-button color="primary" (click)="onSubscribe()">Subscribe</button>
  </div>

  }

  <mat-divider class="block !mt-5 !mb-5"></mat-divider>

  <dlc-input-code
    [dataSource]="remoteConfigs()"
    (doAdd)="addCode($event)"
    (doRemove)="removeCode($event)"></dlc-input-code>

  <mat-divider class="block !mt-5 !mb-5"></mat-divider>

  <ec-delete-account></ec-delete-account>
</dlc-mobile-page-content>

<mat-progress-bar
  *ngIf="showCheckoutProgress"
  color="accent"
  mode="indeterminate"></mat-progress-bar>
