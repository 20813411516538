<dlc-header-bar>
  <span>Profile</span>
</dlc-header-bar>
<dlc-mobile-page-content class="mt-5">
  <div class="flex flex-row justify-start items-center">
    <span>
      Email inquiries at
      <a href="mailto:support@gigasoftware.io">{{ email }}</a
      >.
    </span>
    <button mat-icon-button class="ml-5" (click)="copyEmail()">
      <mat-icon> content_copy</mat-icon>
    </button>
    <div
      class="invisible"
      #copyTooltip="matTooltip"
      matTooltip="Copied!"
      matTooltipPosition="right"></div>
  </div>
</dlc-mobile-page-content>
