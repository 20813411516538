import {CommonModule} from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  Optional,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  DlcFormFieldFloatingLabelDirective,
  FLOATING_LABEL_PARENT
} from '../../form-field/directives/floating-label';
import {
  DLC_FORM_FIELD_DEFAULT_OPTIONS,
  DlcFormFieldAppearance,
  DlcFormFieldDefaultOptions
} from '../../form-field/form-field.component';
import {DlcFormFieldModule} from '../../form-field/form-field.module';

const DEFAULT_APPEARANCE: DlcFormFieldAppearance = 'fill';

@Component({
  selector: 'dlc-label-container',
  standalone: true,
  imports: [
    CommonModule,
    DlcFormFieldFloatingLabelDirective,
    DlcFormFieldModule
  ],
  templateUrl: './dlc-label-container.component.html',
  styleUrls: ['./dlc-label-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'dlc-label-container',
    '[class.dlc-label-container-appearance-outline]': 'appearance == "outline"',
    '[class.dlc-label-container-float-label]': 'true'
  },
  providers: [
    {
      provide: FLOATING_LABEL_PARENT,
      useExisting: DlcLabelContainerComponent
    }
  ]
})
export class DlcLabelContainerComponent implements AfterContentChecked {
  @ViewChild(DlcFormFieldFloatingLabelDirective) _floatingLabel!:
    | DlcFormFieldFloatingLabelDirective
    | undefined;

  @Input() required = false;

  labelWidthChanges: WritableSignal<number> = signal(0);

  isObservingLabel = false;

  labelResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    const label = entries[0];
    if (label && label.contentRect.width !== this.labelWidthChanges()) {
      this.labelWidthChanges.set(label.contentRect.width);
      this._changeDetectorRef.detectChanges();
    }
  });

  leftLabelOffset: WritableSignal<number> = signal(0);

  private _appearance: DlcFormFieldAppearance = DEFAULT_APPEARANCE;
  private _needsOutlineLabelOffsetUpdateOnStable = false;

  @Input()
  get appearance(): DlcFormFieldAppearance {
    return this._appearance;
  }

  set appearance(value: DlcFormFieldAppearance) {
    const oldValue = this._appearance;
    const newAppearance =
      value || this._defaults?.appearance || DEFAULT_APPEARANCE;
    if (newAppearance !== 'fill' && newAppearance !== 'outline') {
      throw new Error(
        `DlcFormField: Invalid appearance "${newAppearance}", valid values are "fill" or "outline".`
      );
    }
    this._appearance = newAppearance;
    if (this._appearance === 'outline' && this._appearance !== oldValue) {
      // If the appearance has been switched to `outline`, the label offset needs to be updated.
      // The update can happen once the view has been re-checked, but not immediately because
      // the view has not been updated and the notched-outline floating label is not present.
      this._needsOutlineLabelOffsetUpdateOnStable = true;
    }

    if (this._appearance !== 'outline' && this._appearance !== 'fill') {
      this.leftLabelOffset.set(0);
    }

    if (this._appearance === 'outline' || this._appearance === 'fill') {
      this.leftLabelOffset.set(10);
    }

    this._changeDetectorRef.detectChanges();
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional()
    @Inject(DLC_FORM_FIELD_DEFAULT_OPTIONS)
    private _defaults?: DlcFormFieldDefaultOptions
  ) {}

  ngAfterContentChecked(): void {
    const label = this._floatingLabel;

    if (label && !this.isObservingLabel) {
      this.isObservingLabel = true;
      this.labelResizeObserver.observe(label?.element);
    }
  }
}
