import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {
  CollaborativeProject,
  EC_QUIZ_ROLES,
  getRoleTitle,
  MemberListItem
} from '@gigasoftware/evolving-cognition/domain';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {selectNgPatLoggedInUserCanDelete} from '@ngpat/store';
import {PushPipe} from '@ngrx/component';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {
  ConfirmDelete,
  EcConfirmDeleteDialogComponent
} from '../../dialogs/ec-confirm-delete-dialog/ec-confirm-delete-dialog.component';

@Component({
  selector: 'ec-user-list',
  templateUrl: './ec-user-list.component.html',
  styleUrls: ['./ec-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-user-list'
  },
  standalone: true,
  imports: [
    CommonModule,
    DlcRoundedTextIconButtonComponent,
    PushPipe,
    MatIconModule,
    MatTableModule
  ]
})
export class EcUserListComponent {
  displayedColumns: string[] = ['username', 'role', 'actions'];
  roles = EC_QUIZ_ROLES;

  membersList: MemberListItem[] = [];

  loggedInUserCanRemove$: Observable<boolean>;

  @Input()
  set members(m: MemberListItem[] | undefined) {
    if (m !== null && m !== undefined) {
      this.membersList = [...m];
      this._cd.markForCheck();
    }
  }

  @Input() ownerRoleNumber: number | undefined;

  private _collaborativeProject$: BehaviorSubject<CollaborativeProject | null> =
    new BehaviorSubject<CollaborativeProject | null>(null);

  @Input()
  set collaborativeProject(c: CollaborativeProject | null | undefined) {
    if (c) {
      this._collaborativeProject$.next(c);
    }
  }

  @Output() deleteAction: EventEmitter<MemberListItem> = new EventEmitter<MemberListItem>();

  constructor(private _dialog: MatDialog, private store: Store, private _cd: ChangeDetectorRef) {
    this.loggedInUserCanRemove$ = this._collaborativeProject$.pipe(
      filter((c: CollaborativeProject | null) => {
        return c !== null;
      }),
      switchMap(c => {
        if (c) {
          return this.store.pipe(select(selectNgPatLoggedInUserCanDelete(c.members)));
        } else {
          return of(false);
        }
      })
    );
  }

  getRoleTitle(roleNumber: number) {
    if (this.ownerRoleNumber !== undefined && roleNumber === EC_QUIZ_ROLES.Owner) {
      return getRoleTitle(this.ownerRoleNumber);
    }
    return getRoleTitle(roleNumber);
  }

  canDelete$(user: MemberListItem): Observable<boolean> {
    return this.loggedInUserCanRemove$.pipe(
      map(
        (loggedInUserCanRemove: boolean) =>
          loggedInUserCanRemove && user.role !== EC_QUIZ_ROLES.Owner
      )
    );
  }

  onDelete(u: MemberListItem) {
    const ref = this._dialog.open(EcConfirmDeleteDialogComponent, {
      backdropClass: 'dlc-global-dialog-background',
      data: <ConfirmDelete>{
        name: u.username,
        showRemove: true
      }
    });

    ref.afterClosed().subscribe((doDelete: boolean) => {
      if (doDelete) {
        this.deleteAction.emit(u);
      }
    });
  }
}
