<h1 mat-dialog-title>Checkout Error</h1>
<div mat-dialog-content>{{ data }}</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    mat-dialog-close="true"
    color="primary"
    cdkFocusInitial>
    Ok
  </button>
</div>
