<dlc-label-container *ngIf="showID" class="grow mt-1">
  <dlc-label>Question ID</dlc-label>
  <span>
    {{ questionSignal().id }}
  </span>
</dlc-label-container>

<dlc-label-container *ngIf="showID" class="grow mt-1">
  <dlc-label>Image ID Path</dlc-label>
  <span>
    {{ questionSignal().imagePath }}
  </span>
</dlc-label-container>

<div class="flex flex-row w-full">
  <dlc-label-container class="grow mt-1">
    <dlc-label>Question</dlc-label>
    <span>
      {{ questionSignal().question }}
    </span>
  </dlc-label-container>

  <div
    *ngIf="questionSignal().imagePath"
    class="ml-4 flex-none w-12 h-12 block overflow-hidden bg-white/[.12] rounded">
    <img class="w-full h-full" dlcBlobImage [dlcImagePath]="questionSignal().imagePath" />
  </div>
</div>
<div class="flex flex-row w-full mt-4">
  <dlc-label-container class="grow mr-4 mt-1">
    <dlc-label>Answers</dlc-label>
    <div>
      <div class="mb-2" *ngFor="let answer of answers()">
        @if (isTrueFalseQuestion()) {
          <ec-true-false-answer [answer]="answer"></ec-true-false-answer>
        } @else if (isMultipleChoiceQuestion()) {
          <ec-multiple-choice-answer [answer]="answer"></ec-multiple-choice-answer>
        }
      </div>
    </div>
  </dlc-label-container>
</div>

<div class="flex flex-row w-full mt-4" *ngIf="referenceLinks().length > 0">
  <dlc-label-container class="flex grow mr-4 mt-1">
    <dlc-label>Reference Links</dlc-label>
    <ec-reference-link class="w-full" [links]="referenceLinks()"></ec-reference-link>
  </dlc-label-container>
</div>

<div class="flex flex-row justify-end w-full mt-4">
  <button dlc-rounded-text-icon-button class="mr-2" color="primary" (click)="onDeleteQuestion()">
    <mat-icon>delete</mat-icon>
    Delete
  </button>
  <button dlc-rounded-text-icon-button color="primary" (click)="onEditQuestion()">
    <mat-icon>edit</mat-icon>
    Edit
  </button>
</div>
