import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ReactiveFormsModule
} from '@angular/forms';
import {
  CollaborativeProject,
  EC_QUIZ_ROLES
} from '@gigasoftware/evolving-cognition/domain';
import {
  DlcFormFieldModule,
  DlcInputDirective,
  DlcRoundedTextButtonComponent,
  DlcSelectModule,
  expandEnterAnimation
} from '@gigasoftware/shared/ui-design-library';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {JoinGroupWithCodeDialogService} from './join-group-with-code-dialog.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import {JoinDialogData} from './join-group-with-code-dialog.model';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {LetDirective, PushPipe} from '@ngrx/component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NgIf, NgFor} from '@angular/common';

@Component({
  selector: 'ec-join-group-with-code-dialog',
  templateUrl: './join-group-with-code-dialog.component.html',
  styleUrls: ['./join-group-with-code-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ui-join-group-with-code-dialog'
  },
  providers: [JoinGroupWithCodeDialogService],
  standalone: true,
  animations: [expandEnterAnimation],
  imports: [
    NgIf,
    MatProgressBarModule,
    MatDialogModule,
    LetDirective,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
    PushPipe,
    DlcRoundedTextButtonComponent,
    DlcFormFieldModule,
    DlcInputDirective,
    DlcSelectModule
  ]
})
export class JoinGroupWithCodeDialogComponent implements OnDestroy, OnInit {
  private _onDestroy$: Subject<boolean> = new Subject();

  joinFormGroup: UntypedFormGroup;

  constructor(
    private _fb: UntypedFormBuilder,
    private store: Store,
    public cmpSvc: JoinGroupWithCodeDialogService,
    private _dialog: MatDialogRef<JoinGroupWithCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JoinDialogData
  ) {
    this.joinFormGroup = this._fb.group({
      joinCode: new UntypedFormControl(null, [Validators.required]),
      role: new UntypedFormControl(EC_QUIZ_ROLES.Student, [Validators.required])
    });
  }

  ngOnInit() {
    this.cmpSvc.setProjectType(this.data.projectTypeID);
  }

  joinGroup() {
    this.cmpSvc
      .joinGroup(this.joinFormGroup.value)
      .subscribe((p: CollaborativeProject | null) => {
        this.cmpSvc.setInProgress(false);
        if (p) {
          this._dialog.close(p);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
  }
}
