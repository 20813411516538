export interface ResizeConfig {
  filenameWithoutExtension: string;
  filenameExtension: string;
  thumbSize: number;
}

export interface GSStorageAssetBlob {
  filenameWithoutExtension: string;
  filenameExtension: string;
  blob: Blob | null;
}

// 600x600,400x400,200x200,100x100,50x50

export enum FIREBASE_STORAGE_IMAGE_SIZE {
  FULL = 'full',
  THUMB_600 = '600x600',
  THUMB_400 = '400x400',
  THUMB_200 = '200x200',
  THUMB_100 = '100x100',
  THUMB_50 = '50x50'
}

export interface GsImageThumbails {
  imagePath600x600: string;
  imagePath400x400: string;
  imagePath200x200: string;
  imagePath100x100: string;
  imagePath50x50: string;
}

export const GS_INITIAL_THUMBNAIL_PATHS: GsImageThumbails = {
  imagePath600x600: '',
  imagePath400x400: '',
  imagePath200x200: '',
  imagePath100x100: '',
  imagePath50x50: ''
};

export enum GS_IMAGE_THUMBNAIL_PATH_PROPERTY {
  IMAGE_PATH_600 = 'imagePath600x600',
  IMAGE_PATH_400 = 'imagePath400x400',
  IMAGE_PATH_200 = 'imagePath200x200',
  IMAGE_PATH_100 = 'imagePath100x100',
  IMAGE_PATH_50 = 'imagePath50x50'
}

export const FIREBASE_STORAGE_IMAGE_SIZE_ARRAY: FIREBASE_STORAGE_IMAGE_SIZE[] = [
  FIREBASE_STORAGE_IMAGE_SIZE.FULL,
  FIREBASE_STORAGE_IMAGE_SIZE.THUMB_600,
  FIREBASE_STORAGE_IMAGE_SIZE.THUMB_400,
  FIREBASE_STORAGE_IMAGE_SIZE.THUMB_200,
  FIREBASE_STORAGE_IMAGE_SIZE.THUMB_100,
  FIREBASE_STORAGE_IMAGE_SIZE.THUMB_50
];

export const MAX_IMAGE_SIZES: number[] = [600, 400, 200, 100, 50];

export function getTotalBytesFromResizedImages(resizedImages: GSStorageAssetBlob[]): number {
  return resizedImages.reduce((acc, curr: GSStorageAssetBlob) => {
    const currentSize = curr.blob?.size || 0;
    return acc + currentSize;
  }, 0);
}

/**
 * Input a number representing the width or height of an image.
 * Output the image size that is larger but closest to the input
 * from the MAX_IMAGE_SIZES array.
 * @param imageSize
 */
export function getMaxImageSizeFromStandardImageSizes(imageSize: number): number {
  const maxSizeFromMaxImageSizes = Math.max(...MAX_IMAGE_SIZES);
  const minSizeFromMaxImageSizes = Math.min(...MAX_IMAGE_SIZES);

  if (imageSize >= maxSizeFromMaxImageSizes) {
    return maxSizeFromMaxImageSizes;
  }

  if (imageSize <= minSizeFromMaxImageSizes) {
    return minSizeFromMaxImageSizes;
  }

  const maxImageSizes = MAX_IMAGE_SIZES.filter((size: number) => {
    return size >= imageSize;
  });

  return Math.min(...maxImageSizes);
}

/**
 * Get image path to thumbnail from image path to full size image.
 * @param imagePath - path/to/image.png
 * @param thumbSize number - 200
 * @return string - path/to/image/image_200x200.png
 */
export function getThumbnailPathFromImagePath(imagePath: string, thumbSize: number): string {
  const pathParts = imagePath.split('/');
  if (pathParts && pathParts.length > 0) {
    const filename = pathParts.pop();
    const filenameParts = filename?.split('.') || [];
    const filenameWithoutExtension = filenameParts[0];
    const filenameExtension = filenameParts[1];

    return `${pathParts.join(
      '/'
    )}/${filenameWithoutExtension}_${thumbSize}x${thumbSize}.${filenameExtension}`;
  } else {
    return '';
  }
}
