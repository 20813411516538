import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable, of} from 'rxjs';
import {
  addUserAndRoleToProject,
  CollaborativeProject,
  DEFAULT_PLATFORM_PROJECT_TYPE_DICT,
  firestoreCollectionByProjectType,
  getClassroomByID,
  getStudyGroupByID,
  EC_QUIZ_ROLES,
  selectHasActiveSubscription
} from '@gigasoftware/evolving-cognition/domain';
import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  map,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import {ComponentStore} from '@ngrx/component-store';
import {
  JOIN_CLASS_ERROR,
  JoinGroupForm,
  JoinProjectComponentState,
  JoinProjectTypeDisplay
} from './join-group-with-code-dialog.model';
import {isDefinedOperator} from '@ngpat/rxjs';
import {selectNgPatLoggedInUID} from '@ngpat/store';
import {NgPatFirestoreService} from '@ngpat/firebase';

@Injectable()
export class JoinGroupWithCodeDialogService extends ComponentStore<JoinProjectComponentState> {
  joinRoles$: Observable<JoinProjectTypeDisplay[]>;

  readonly selectError$: Observable<JoinProjectComponentState> = this.select(state => state).pipe(
    distinctUntilKeyChanged('hasError')
  );

  readonly selectInProgress$: Observable<boolean> = this.select(state => state.inProgress).pipe(
    distinctUntilChanged()
  );

  readonly selectProjectType$: Observable<number> = this.select(state => state.projectType).pipe(
    distinctUntilChanged()
  );

  constructor(private store: Store, private _firestore: NgPatFirestoreService) {
    super(<JoinProjectComponentState>{
      error: '',
      hasError: false,
      inProgress: false,
      projectType: DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id
    });

    this.joinRoles$ = this.store.pipe(select(selectHasActiveSubscription)).pipe(
      map(hasSubscription => {
        const projectTypes: JoinProjectTypeDisplay[] = [];

        // if (hasSubscription) {
        //   projectTypes.push({
        //     role: EC_QUIZ_ROLES.Student,
        //     displayValue: 'Student'
        //   });
        // }
        // TODO: subscription check
        projectTypes.push({
          role: EC_QUIZ_ROLES.Student,
          displayValue: 'Student'
        });

        return projectTypes;
      })
    );
  }

  readonly setProjectType = this.updater((state, projectType: number) => ({
    ...state,
    projectType
  }));

  readonly setInProgress = this.updater((state, inProgress: boolean) => ({
    ...state,
    inProgress
  }));

  readonly setError = this.updater((state, error: JOIN_CLASS_ERROR | string) => {
    let message: string;
    if (error === JOIN_CLASS_ERROR.IS_JOINED) {
      message = 'Already Joined';
    } else if (error === JOIN_CLASS_ERROR.NOT_FOUND) {
      message = 'Not Found';
    } else {
      message = error;
    }

    return {
      ...state,
      error: message,
      hasError: true
    };
  });

  readonly clearError = this.updater(state => ({
    ...state,
    error: '',
    hasError: false
  }));

  joinGroup(f: JoinGroupForm): Observable<CollaborativeProject | null> {
    this.setInProgress(true);
    this.clearError();

    return this.selectProjectType$.pipe(
      take(1),
      withLatestFrom(
        this.store.pipe(select(selectNgPatLoggedInUID), isDefinedOperator<unknown, string>())
      ),
      switchMap(([projectType, uid]) => {
        const firestorePath: string = firestoreCollectionByProjectType(projectType);

        let fieldPath = '';

        if (f.role === EC_QUIZ_ROLES.Student) {
          fieldPath = 'studentCode';
        }

        if (f.role === EC_QUIZ_ROLES.Teacher) {
          fieldPath = 'teacherCode';
        }

        if (f.role === EC_QUIZ_ROLES.Mentor) {
          fieldPath = 'mentorCode';
        }

        if (!fieldPath.length) {
          return of(null);
        }

        return this._firestore
          .queryCollection<CollaborativeProject>(firestorePath, fieldPath, '==', f.joinCode)
          .pipe(
            switchMap((projects: CollaborativeProject[]) => {
              const id: string | undefined =
                projects && projects.length ? projects[0].id : undefined;

              if (id) {
                let selectProjectByID$: Observable<CollaborativeProject | undefined>;

                if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
                  selectProjectByID$ = this.store.pipe(select(getClassroomByID(id)));
                } else {
                  selectProjectByID$ = this.store.pipe(select(getStudyGroupByID(id)));
                }

                return selectProjectByID$.pipe(
                  map((project: CollaborativeProject | null | undefined) => {
                    if (project && project.id == id) {
                      this.setError(JOIN_CLASS_ERROR.IS_JOINED);
                      return null;
                    }

                    if (uid && projects && projects.length) {
                      // FOUND
                      return addUserAndRoleToProject(
                        <CollaborativeProject>projects[0],
                        uid,
                        f.role
                      );
                    }

                    return null;
                  })
                );
              }

              this.setError(JOIN_CLASS_ERROR.NOT_FOUND);
              return of(null);
            }),
            catchError((error: string) => {
              this.setError(error);
              return of(null);
            })
          );
      })
    );
  }
}
