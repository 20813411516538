import {Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {firestoreUserAccountDoc, firestoreUserCollection} from '../database-paths';
import {collection, getDocs, query, where} from 'firebase/firestore';
import {NgPatAccountState} from '@ngpat/store';


@Injectable({
  providedIn: 'root'
})
export class UserFirestoreUsersService {

  userPath = firestoreUserCollection();

  constructor(
    private customerFirestoreService: NgPatFirestoreService
  ) {
  }

  async searchUser(email: string): Promise<NgPatAccountState | undefined> {
    // const q = query(collection(db, "cities"), where("capital", "==", true));
    const q = query(
      collection(this.customerFirestoreService.db, this.userPath),
      where('email', '==', email)
    );

    const querySnapshot = await getDocs(q);

    return new Promise((resolve) => {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const user: NgPatAccountState = <NgPatAccountState>doc.data();
        if (user.email === email) {
          resolve(user);
        }
      });
    });
  }

  async updateUser(user: NgPatAccountState): Promise<void> {

    if (user && user.uid) {
      const path = firestoreUserAccountDoc(user.uid);
      await this.customerFirestoreService.setDoc(path, user);
    }
  }
}
