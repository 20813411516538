import {GsImageThumbails} from '@gigasoftware/shared/media';
import {ReferenceLink} from '@gigasoftware/shared/ui-design-library';
import {CollaborativeProject, Project} from '../+project/project.model';

export interface TakeQuizAggregateMultiChoiceAnswers {
  aggregateFalseAnswers: boolean;
  totalAnswers: number;
}

export interface Quiz extends CollaborativeProject {
  id: string;
  // name of quiz
  name: string;
  // description of quiz
  description: string | null;
  // grade level of quiz - 1st grade, 2nd grade, etc
  // formatted as number - 1, 2, 3, etc
  gradeLevel: number | null;
  //Math, Chemistry, English, Music, etc
  subjects: string | null;
  // createdAt formatted in unix timestamp for algolia
  unixTimestamp: number | null;

  imagePath: undefined | null | string;
  isPublished: boolean;
  aggregateFalseAnswers: boolean;
  totalQuestions: number;
  // deprecated
  totalAnswers: number;
  assigned: boolean;
  publisherUID?: string;
}

export interface GradeLevel {
  level: number;
  name: string;
}



/**
 * Copy to Algolioa firestore config
 * id,name,description,imagePath,gradeLevel,subject,totalQuestions,unixTimestamp
 */
export type AlgoliaPublishedQuizProps = Pick<Quiz,
  'id' |
  'name' |
  'description' |
  'imagePath' |
  'gradeLevel' |
  'subjects' |
  'totalQuestions' |
  'unixTimestamp'>;

export interface AlgoliaMatchWords {
  value: string;
  matchLevel: 'none' | 'partial' | 'full';
  matchedWords: string[];
}

export interface AlgoliaPublishedQuiz extends AlgoliaPublishedQuizProps {
  lastmodified: number;
  objectID: string;
  path: string;
  publisherUID: string;
  _highlightResult: {
    id: AlgoliaMatchWords,
    lastmodified: AlgoliaMatchWords,
    name: AlgoliaMatchWords;
    path: AlgoliaMatchWords;
    description: AlgoliaMatchWords;
    gradeLevel: AlgoliaMatchWords;
    subject: AlgoliaMatchWords;
  }
}

export interface AlgoliaPublishedQuizIsMyQuiz extends AlgoliaPublishedQuiz {
  canDeletePublishedQuiz: boolean;
}


export interface CreateQuizParams {
  id: string;
  projectTypeValue: number;
  name: string;
  description: string;
  isCollaborative: boolean;
  isPrivate: boolean;
  assigned?: boolean;
  gradeLevel: number | null;
  subjects: string | null;
  aggregateFalseAnswers: boolean;
}

export interface CreateSubQuizParams extends CreateQuizParams {
  parentProject: Project;
}

export type QuizWithComputedProperties = Quiz & GsImageThumbails;

export interface QuestionType {
  TRUE_FALSE: number;
  MULTIPLE_CHOICE: number;
}


export const QUESTION_TYPE: QuestionType = {
  TRUE_FALSE: 0,
  MULTIPLE_CHOICE: 1
};

export interface Answer {
  id: string;
  isCorrect: boolean;
  answerText: string;
  imagePath: undefined | null | string;

  /**
   * Used only for True / False answer.
   */
  trueFalseAnswer: boolean;
}

export interface UserAnswer {
  answer: Answer;
  /**
   * Used only for True / False answer.
   */
  trueFalseAnswer: boolean;
  multipleChoiceChecked: boolean;
}

export interface Question {
  questionType: number;
  id: string;
  quizID: string;
  question: string;
  placeholder: string;
  imagePath: undefined | null | string;

  // html url to an external reference source
  /**
   * @deprecated
   */
  referenceLink?: ReferenceLink | string;
  referenceLinks: ReferenceLink[] | string[];

  answers: {[answerID: string]: Answer};
}

export type QuestionWithComputedProperties = Question & GsImageThumbails;

export interface QuestionAction {
  quiz: Quiz;
  question: Question;
}

export interface QuestionPropertyAction extends QuestionAction {
  update: any;
}

export interface QuestionWithPath {
  question: Question;
  path: string;
}

/**
 * Taking a quiz
 */

export interface QuestionWithAnswer {
  /**
   * The original question
   */
  question: Question;
  questionID: string;

  /**
   * The answer from the user
   */
  answers: {
    [answerID: string]: UserAnswer;
  } | null;
  isCorrect: boolean | null;
  isAnswered: boolean;
  timeToAnswerMS: number;
}

export interface QuestionWithComputedPropertiesWithAnswer
  extends QuestionWithAnswer {
  question: QuestionWithComputedProperties;
}

/**
 * Only used to display quiz result
 */
export interface ResultAnswer extends QuestionWithAnswer {
  questionText: string;
}

export interface TakeQuizResult {
  id: string;
  quiz: Quiz;
  quizID: string;

  /**
   * User answered questions
   */
  questions: {[questionID: string]: QuestionWithAnswer};
  isPrivate: boolean;
  createdByUID: string;
  timestamp: number;
}

export interface QuizGrade<T> {
  result: TakeQuizResult;
  id: string; // quiz id
  name: string; // quiz name
  timestamp: number;
  totalCorrect: number;
  totalWrong: number;
  rawData: T;

  /**
   * Grade and progress are the same
   * Grade is for user experience in quiz item page
   * Progress is for chart data
   */
  grade: number;

  /**
   * Total time to take quiz in milliseconds
   */
  totalTimeToTakeQuizMS: number;

  // BulletChartData
  chartData: {
    max: number;
    progress: number;
    min: number;
    /* units suffix such as Kb, Db, MHz */
    units: string;
    status: 'error' | 'warn' | 'success' | null;
  };
}

export interface HighestGradeTimeToTakeQuiz {
  highestGrade: number;
  totalTimeToTakeQuizMS: number;
}

export interface CreateReviewQuizFromWrongAnswers {
  quiz: Quiz;
  questions: Question[];
}

export interface CopyQuizParams {
  name: string;
  isPersonal: boolean;
  projectTypeName: string;
  copyToParentProject: Project | null;
  uid: string;
}


