<div class="bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
      <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
        <div>
          <h2 class="text-3xl font-bold tracking-tight text-gray-900">Questions</h2>
          <p class="mt-4 leading-7 text-gray-600">Let us know any concerns for feedback.</p>
        </div>
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
          <div class="rounded-2xl bg-gray-50 p-10">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Contact Us</h3>
            <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
              <div>
                <dt class="sr-only">Email</dt>
                <dd><a class="font-semibold text-indigo-600" href="mailto:collaborate&#64;example.com">questions&#64;gigasoftware.io</a></dd>
              </div>
<!--              <div class="mt-1">-->
<!--                <dt class="sr-only">Phone number</dt>-->
<!--                <dd>+1 (555) 905-2345</dd>-->
<!--              </div>-->
            </dl>
          </div>
<!--          <div class="rounded-2xl bg-gray-50 p-10">-->
<!--            <h3 class="text-base font-semibold leading-7 text-gray-900">Press</h3>-->
<!--            <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">-->
<!--              <div>-->
<!--                <dt class="sr-only">Email</dt>-->
<!--                <dd><a class="font-semibold text-indigo-600" href="mailto:press&#64;example.com">press&#64;example.com</a></dd>-->
<!--              </div>-->
<!--              <div class="mt-1">-->
<!--                <dt class="sr-only">Phone number</dt>-->
<!--                <dd>+1 (555) 905-3456</dd>-->
<!--              </div>-->
<!--            </dl>-->
<!--          </div>-->
<!--          <div class="rounded-2xl bg-gray-50 p-10">-->
<!--            <h3 class="text-base font-semibold leading-7 text-gray-900">Join our team</h3>-->
<!--            <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">-->
<!--              <div>-->
<!--                <dt class="sr-only">Email</dt>-->
<!--                <dd><a class="font-semibold text-indigo-600" href="mailto:careers&#64;example.com">careers&#64;example.com</a></dd>-->
<!--              </div>-->
<!--              <div class="mt-1">-->
<!--                <dt class="sr-only">Phone number</dt>-->
<!--                <dd>+1 (555) 905-4567</dd>-->
<!--              </div>-->
<!--            </dl>-->
<!--          </div>-->
<!--          <div class="rounded-2xl bg-gray-50 p-10">-->
<!--            <h3 class="text-base font-semibold leading-7 text-gray-900">Say hello</h3>-->
<!--            <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">-->
<!--              <div>-->
<!--                <dt class="sr-only">Email</dt>-->
<!--                <dd><a class="font-semibold text-indigo-600" href="mailto:hello&#64;example.com">hello&#64;example.com</a></dd>-->
<!--              </div>-->
<!--              <div class="mt-1">-->
<!--                <dt class="sr-only">Phone number</dt>-->
<!--                <dd>+1 (555) 905-5678</dd>-->
<!--              </div>-->
<!--            </dl>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
