<div class="flex flex-row justify-center items-center">
  <div class="aspect-square block w-full max-w-sm overflow-hidden">
    <dlc-input-image
      [config]="inputImageConfig()"
      (deleteImage)="onDeleteImage()"></dlc-input-image>
  </div>
</div>

<form
  [formGroup]="classroomFormGroup"
  class="relative flex flex-col justify-start items-stretch mt-8">
  <dlc-form-field class="w-full" appearance="outline">
    <dlc-label>Class Name</dlc-label>
    <input
      dlcInput
      formControlName="name"
      placeholder="Class name (required)" />
    <dlc-error *ngIf="classroomFormGroup.get('name')?.hasError('required')">
      Required
    </dlc-error>
  </dlc-form-field>

  <dlc-form-field class="mt-4 w-full" appearance="outline">
    <dlc-label>Section</dlc-label>
    <input dlcInput formControlName="section" placeholder="Section" />
  </dlc-form-field>

  <dlc-form-field class="mt-4 w-full" appearance="outline">
    <dlc-label>Subject</dlc-label>
    <input dlcInput formControlName="subject" placeholder="Subject" />
  </dlc-form-field>

  <dlc-form-field class="mt-4 w-full" appearance="outline">
    <dlc-label>Room</dlc-label>
    <input dlcInput formControlName="room" placeholder="Room" />
  </dlc-form-field>
</form>
<div class="flex flex-row justify-start items-start mt-8">
  <button
    dlc-rounded-text-icon-button
    color="primary"
    class="mr-4"
    (click)="resetNameForm()"
    [disabled]="classroomFormGroup.pristine">
    <mat-icon>undo</mat-icon>
    Undo
  </button>

  <button
    dlc-rounded-text-icon-button
    color="primary"
    (click)="saveForm()"
    [disabled]="classroomFormGroup.pristine || classroomFormGroup.invalid">
    <mat-icon>check</mat-icon>
    Save
  </button>
</div>
