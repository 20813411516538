<form
  [formGroup]="nameFormGroup"
  class="flex flex-col justify-start items-start w-full">
  <dlc-form-field appearance="outline">
    <dlc-label>{{ projectTypeName }} Name</dlc-label>
    <input dlcInput formControlName="name" autocomplete="off" />
    <dlc-error *ngIf="nameFormGroup.controls['name'].hasError('required')"
      >Required</dlc-error
    >
  </dlc-form-field>

  <dlc-form-field appearance="outline">
    <dlc-label>{{ projectTypeName }} Description</dlc-label>
    <input dlcInput formControlName="description" autocomplete="off" />
  </dlc-form-field>
</form>

<button
  dlc-rounded-text-button
  color="primary"
  [disabled]="nameFormGroup.invalid || projectTypeForm.invalid"
  (click)="onSubmit()">
  CREATE
</button>
