import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {ASPECT_RATIO, aspectRatioValues, evaluateAspectRatio} from '@gigasoftware/shared/media';

import {Subject} from 'rxjs';
import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {DLC_SIZE, DlcLayout} from '../../../layout/sizes';
import {DlcRoundedIconButtonComponent} from '../../button/dlc-rounded-icon-button/dlc-rounded-icon-button.component';
import {DlcRoundedTextButtonComponent} from '../../button/dlc-rounded-text-button/dlc-rounded-text-button.component';
import {DlcRoundedTextIconButtonComponent} from '../../button/dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';
import {DlcLabelContainerComponent} from '../../containers/dlc-label-container/dlc-label-container.component';
import {DlcFormFieldModule} from '../../form-field/form-field.module';
import {DlcInputDirective} from '../../input/dlc-input/input';
import {DlcSelectModule} from '../../select/module';
import {
  convertScaleToPercent,
  restrictRotationTo360Degrees,
  restrictScaleToTwoDecimals
} from './image-cropper.component.fns';
import {initalImageState} from './image-cropper.component.model';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageCropperModule,
  ImageTransform
} from './image-cropper/index';

/**
 * This is a port from https://stackblitz.com/edit/image-cropper?file=app%2Fapp.component.html&file=app%2Fapp.component.ts
 *
 * GitHub repo: https://github.com/Mawi137/ngx-image-cropper
 *
 *
 * This wraps the ngx-image-cropper component.
 */
@Component({
  selector: 'dlc-image-cropper',
  standalone: true,
  imports: [
    CommonModule,
    ImageCropperModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    DlcRoundedIconButtonComponent,
    MatProgressSpinnerModule,
    DlcRoundedTextButtonComponent,
    DlcFormFieldModule,
    DlcSelectModule,
    DlcInputDirective,
    ReactiveFormsModule,
    DlcRoundedTextIconButtonComponent,
    DlcLabelContainerComponent
  ],
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'dlc-image-cropper dlc-image-cropper__container'
  }
})
export class DlcImageCropperComponent implements OnDestroy, OnInit {
  private _onDestroy$: Subject<boolean> = new Subject();

  aspectRatios = aspectRatioValues;

  rotationForm = new FormControl(0);

  /**
   * Percentage value.
   */
  zoomForm = new FormControl(100);

  selectedAspectRatio: WritableSignal<string> = signal(ASPECT_RATIO.ONE_ONE);

  loading: WritableSignal<boolean> = signal(false);

  canvasRotation: WritableSignal<number> = signal(0);

  scale = 1;

  containWithinAspectRatio: WritableSignal<boolean> = signal(false);

  transform: WritableSignal<ImageTransform> = signal({
    ...initalImageState()
  });

  showAspectRatioSelectorSignal: WritableSignal<boolean> = signal(true);

  @Input()
  set hideAspectSeletor(value: boolean) {
    this.showAspectRatioSelectorSignal.set(!value);
  }

  showZoomInputSignal: WritableSignal<boolean> = signal(true);

  @Input()
  set hideZoomInput(value: boolean) {
    this.showZoomInputSignal.set(!value);
  }

  showRotationInputSignal: WritableSignal<boolean> = signal(true);

  @Input()
  set hideRotationInput(value: boolean) {
    this.showRotationInputSignal.set(!value);
  }

  disableIfZoomIs100 = computed(() => {
    return this.transform().scale === 1;
  });

  disableIfRotateIs0 = computed(() => {
    return this.transform().rotate === 0;
  });

  disableResetImage = computed(() => {
    const transform: ImageTransform = this.transform();
    const intialTransform: ImageTransform = initalImageState();

    /**
     * Check if the current transform is the same as the initial transform.
     */
    return Object.keys(transform).every((key: string) => {
      return (<any>transform)[key] === (<any>intialTransform)[key];
    });
  });

  showCropper: WritableSignal<string | null> = signal('none');

  @Input() size: DLC_SIZE = DLC_SIZE.GTSM;

  /**
   * @description Event from loading image file.
   * @param event
   */
  @Input() imageFile: File | null | undefined = null;

  @Input()
  set aspectRatio(value: string) {
    this.selectedAspectRatio.set(value);
  }

  evaluatedAspectRatio: Signal<number> = computed(() => {
    return evaluateAspectRatio(this.selectedAspectRatio());
  });

  @Output() imageCroppedEvent: EventEmitter<ImageCroppedEvent> =
    new EventEmitter<ImageCroppedEvent>();

  constructor(public dlcLayout: DlcLayout) {}

  ngOnInit() {
    this.zoomForm.valueChanges
      .pipe(
        takeUntil(this._onDestroy$),
        debounceTime(300),
        map<number | null, number>((zoomPercent: number | null): number => {
          if (zoomPercent === null) {
            return 1;
          } else {
            return zoomPercent / 100;
          }
        })
      )
      .subscribe((zoomDecimal: number) => {
        this.scale = zoomDecimal;

        const transform = this.transform();

        this.transform.set({
          ...transform,
          scale: this.scale
        });
      });

    this.rotationForm.valueChanges
      .pipe(
        takeUntil(this._onDestroy$),
        debounceTime(300),
        map<number | null, number>((rotate: number | null): number => {
          if (rotate === null) {
            return 0;
          } else {
            return restrictRotationTo360Degrees(rotate);
          }
        })
      )
      .subscribe((rotate: number) => {
        const transform = this.transform();

        this.transform.set({
          ...transform,
          rotate
        });

        this.rotationForm.setValue(rotate, {
          emitEvent: false
        });
      });
  }

  imageLoaded() {
    this.showCropper.set(null);
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
    this.loading.set(false);
  }

  rotateLeft() {
    const transform = this.transform();

    const currentRotation = transform.rotate || 0;

    const rotate = restrictRotationTo360Degrees(currentRotation - 90);

    this.transform.set({
      ...transform,
      rotate
    });

    this.rotationForm.setValue(rotate, {
      emitEvent: false
    });

    // this.loading.set(true);
    // this.canvasRotation.set(this.canvasRotation() - 1);
    // this.flipAfterRotate();
  }

  rotateRight() {
    // this.loading.set(true);

    const transform = this.transform();

    const currentRotation = transform.rotate || 0;

    const rotate = restrictRotationTo360Degrees(currentRotation + 90);

    this.transform.set({
      ...transform,
      rotate
    });

    this.rotationForm.setValue(rotate, {
      emitEvent: false
    });

    // this.canvasRotation.set(this.canvasRotation() + 1);
    // this.flipAfterRotate();
  }

  rotate0() {
    const transform = this.transform();

    const rotate = 0;

    this.transform.set({
      ...transform,
      rotate
    });

    this.rotationForm.setValue(rotate, {
      emitEvent: false
    });
  }

  private flipAfterRotate() {
    const transform = this.transform();

    const flippedH = transform.flipH;
    const flippedV = transform.flipV;

    this.transform.update((t: ImageTransform) => {
      t.flipH = flippedV;
      t.flipV = flippedH;
      return t;
    });

    // this.translateH = 0;
    // this.translateV = 0;
  }

  flipHorizontal() {
    const transform = this.transform();

    this.transform.set({
      ...transform,
      flipH: !transform.flipH
    });
  }

  flipVertical() {
    const transform = this.transform();

    this.transform.set({
      ...transform,
      flipV: !transform.flipV
    });
  }

  zoomOut() {
    this.scale = restrictScaleToTwoDecimals(this.scale - 0.1);

    const transform = this.transform();

    this.transform.set({
      ...transform,
      scale: this.scale
    });

    this.zoomForm.setValue(convertScaleToPercent(this.scale), {
      emitEvent: false
    });
  }

  zoomIn() {
    this.scale = restrictScaleToTwoDecimals(this.scale + 0.1);

    const transform = this.transform();

    this.transform.set({
      ...transform,
      scale: this.scale
    });

    this.zoomForm.setValue(convertScaleToPercent(this.scale), {
      emitEvent: false
    });
  }

  zoom100() {
    this.scale = 1;

    const transform = this.transform();

    this.transform.set({
      ...transform,
      scale: this.scale
    });

    this.zoomForm.setValue(convertScaleToPercent(this.scale), {
      emitEvent: false
    });
  }

  toggleContainWithinAspectRatio() {
    this.loading.set(true);
    this.containWithinAspectRatio.set(!this.containWithinAspectRatio());
  }

  fillAspectRatio() {
    this.loading.set(true);
    this.containWithinAspectRatio.set(false);
  }

  containAspectRatio() {
    this.loading.set(true);
    this.containWithinAspectRatio.set(true);
  }

  transformChange(event: ImageTransform) {
    this.transform.set(event);
    this.loading.set(false);
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.imageCroppedEvent.emit(event);
  }

  resetImage() {
    this.transform.set(initalImageState());
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
