import {createAction, props} from '@ngrx/store';
import {AuthLoginAction} from './login.model';

// export const LoginActions = createActionGroup({
//   source: 'Login',
//   events: {
//     'Load Logins': emptyProps()
//   }
// });

export const initAuthAction = createAction('[Login] Init Auth');

export const updateAuthAction = createAction(
  '[Login] Update Auth Status',
  props<{action: AuthLoginAction}>()
);

export const loadAuthActionFromFirestore = createAction(
  '[Login] Load Auth Status From Firestore',
  props<{action: AuthLoginAction}>()
);

export const setOtlid = createAction('[Login] Set Otlid', props<{otlid: string}>());

export const clearAuthState = createAction('[Login] Clear Auth State');

export const clearAuthStorage = createAction('[Login] Clear Auth Storage');

export const cancelAuth = createAction('[Login] Cancel Auth');
