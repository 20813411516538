import {Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router} from '@angular/router';
import {NgPatFirestoreService} from '@ngpat/firebase';

@Component({
  selector: 'ecm-home-cta',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home-cta.component.html',
  styleUrl: './home-cta.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ecm-home-cta'
  }
})
export class HomeCtaComponent {
  @Input() title = '';
  @Input() cta = '';
  @Input() ctaLink: string[] = [];

  constructor(private router: Router, private firestore: NgPatFirestoreService) {}

  navigateToCta(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.firestore.logEvent('cta_clicked', {cta: this.cta});
    this.router.navigate(this.ctaLink);
  }
}
