<div class="text-base">
  Copy
  <span class="font-medium text-[--dlc-color-primary]">{{ joinCodeType }}</span>
  code
  <span class="font-medium text-[--dlc-color-primary]">{{ joinCode }}</span>
</div>
<button
  class="ml-4"
  dlc-rounded-icon-button
  color="primary"
  (click)="openCode()">
  <mat-icon>content_copy</mat-icon>
</button>
