<h3 mat-dialog-title>Publish Quiz</h3>

<mat-dialog-content *ngIf="isCopying()">
  <div class="mb-8">{{ copyingQuiz() }}</div>
  <div class="flex flex-row justify-center items-center mb-8">
    <mat-spinner mode="indeterminate" color="primary"></mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="!isCopying()">
  <p class="mb-4">Are you sure you want to publish the quiz <span
    class="text-[--dlc-color-primary]">{{ data.quiz.name }}</span>?</p>
  <div class="w-full border-b border-white/25"></div>
  <ec-publish-quiz-terms class="overflow-y-scroll max-h-40"></ec-publish-quiz-terms>
  <div class="w-full border-t border-white/25"></div>
  <mat-checkbox
    class="mt-4"
    [disableRipple]="true"
    [(ngModel)]="acceptedTerms"
    color="accent">I accept the terms to publish this quiz.</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
  <button dlc-rounded-text-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
    Cancel
  </button>
  <button dlc-rounded-text-icon-button
          [disabled]="!acceptedTerms"
          color="primary"
          class="ml-2"
          (click)="onPublish()">
    <mat-icon>publish</mat-icon>
    Publish
  </button>
</mat-dialog-actions>
