<h2 mat-dialog-title>Delete Account</h2>
<div mat-dialog-content>
  <div *ngIf="acctType.QUIZKIT_APP === data">
    You are about to delete your account. This will remove
    <ul>
      <li>All of your quizzes</li>
      <li>All of your study groups</li>
      <li>Your personal account data</li>
    </ul>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-flat-button
    color="primary"
    (click)="onDeleteAccount()"
    cdkFocusInitial>
    Delete
  </button>
  <mat-progress-bar
    *ngIf="showCheckoutProgress"
    color="accent"
    mode="indeterminate"></mat-progress-bar>
</mat-dialog-actions>
