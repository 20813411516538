<dlc-header-bar [showProgressBar]="showCheckoutProgressBar()">
  <span class="dlc-header-bar__title-text-primary">Pricing</span>
</dlc-header-bar>
<dlc-mobile-page-content>
  <dlc-pricing
    [prices]="prices()"
    [showHeader]="false"
    [showIntervalToggle]="false"
    [showBothPricesAtSameTime]="true"
    [hasActiveSubscription]="hasActiveSubscription()"
    (doLogin)="onLogin()"
    (showCheckoutProgressBar)="showCheckoutProgressBar.set($event)"></dlc-pricing>
</dlc-mobile-page-content>
<dlc-footer-bar class="!items-center">
  <a href="#" routerLink="/privacy-policy" class="text-[--dlc-color-primary] underline">
    Privacy Policy
  </a>
  <a href="#" routerLink="/terms-of-use" class="text-[--dlc-color-primary] underline">
    Terms of Use
  </a>
</dlc-footer-bar>
