import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {
  Answer,
  ecQuizImageUploaderConfig,
  getReferenceLinksFromQuestion,
  Question,
  QuestionAction,
  QuestionPropertyAction,
  Quiz,
  QuizQueryEngine,
  QuizzesEngineStore,
  selectTrueFalseAnswer
} from '@gigasoftware/evolving-cognition/domain';
import {GSFirebaseUploadImageWithDocConfig} from '@gigasoftware/shared/media';
import {
  DlcAutoResizeTextAreaDirective,
  DlcAutoResizeTextareaModule,
  DlcFormFieldModule,
  DlcFormStatus,
  DlcInputImageComponent,
  DlcInputImageConfig,
  DlcInputLinkComponent,
  DlcRoundedTextIconButtonComponent,
  ReferenceLinksService
} from '@gigasoftware/shared/ui-design-library';
import {Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ec-create-true-false-answer',
  templateUrl: './ec-create-true-false-answer.component.html',
  styleUrls: ['./ec-create-true-false-answer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-create-true-false-answer'
  },
  providers: [ReferenceLinksService],
  standalone: true,
  imports: [
    CommonModule,
    DlcInputImageComponent,
    DlcAutoResizeTextAreaDirective,
    DlcAutoResizeTextareaModule,
    DlcInputLinkComponent,
    DlcFormFieldModule,
    DlcRoundedTextIconButtonComponent,
    ReactiveFormsModule,
    MatIconModule,
    MatRadioModule
  ]
})
export class EcCreateTrueFalseAnswerComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  qe: Signal<QuizQueryEngine | null | undefined> = <
    Signal<QuizQueryEngine | null | undefined>
  >toSignal(this.quizStore.selectCurrentQuizQueryEngine$);

  question$: ReplaySubject<Question> = new ReplaySubject<Question>(1);

  inputImageConfig$: Observable<DlcInputImageConfig | null> =
    this.question$.pipe(
      switchMap((question: Question) => {
        return this.quizStore.selectCurrentQuizQueryEngine$.pipe(
          switchMap((qe: QuizQueryEngine | null | undefined) => {
            if (qe) {
              return qe.getQuestionFirestoreDocPath$(question);
            }

            return of(null);
          }),
          map((docPath: string | undefined | null) => {
            if (docPath) {
              return {
                filenameWithoutExtension: question?.id || '',
                imagePath: question?.imagePath || '',
                uploadConfig: <GSFirebaseUploadImageWithDocConfig>{
                  ...ecQuizImageUploaderConfig,
                  firestoreDoc: {
                    // doc: question,
                    docProperty: 'imagePath',
                    firestoreDocPath: docPath
                  }
                }
              };
            }

            return null;
          })
        );
      })
    );

  inputImageConfig: Signal<DlcInputImageConfig | null> = <
    Signal<DlcInputImageConfig | null>
  >toSignal(this.inputImageConfig$);

  @Input() questionPlaceholder = 'Type your question here ...';

  questionForm: FormGroup;

  @Input() quiz: Quiz | null | undefined = null;

  questionSignal: Signal<Question> = <Signal<Question>>toSignal(this.question$);

  @Input()
  set question(question: Question | null | undefined) {
    if (question) {
      this.question$.next(question);
    }
  }

  @Input() onSave: Observable<void> | undefined;

  @Output() deleteAction: EventEmitter<QuestionAction> =
    new EventEmitter<QuestionAction>();
  @Output() saveAction: EventEmitter<QuestionAction> =
    new EventEmitter<QuestionAction>();

  @Output() updateAction: EventEmitter<QuestionPropertyAction> =
    new EventEmitter<QuestionPropertyAction>();

  @Output() status: EventEmitter<DlcFormStatus> =
    new EventEmitter<DlcFormStatus>();

  get imagePathFormControl() {
    return this.questionForm.get('imagePath') as FormControl;
  }

  get referenceLinks() {
    return this.questionForm.get('links') as FormArray;
  }

  get questionControl() {
    return this.questionForm.get('question') as FormControl;
  }

  get trueFalseAnswerControl() {
    return this.questionForm.get('trueFalseAnswer') as FormControl;
  }

  constructor(
    private quizStore: QuizzesEngineStore,
    private fb: FormBuilder,
    public linksService: ReferenceLinksService
  ) {
    this.questionForm = this.fb.group({
      question: new FormControl(null, [Validators.required]),
      trueFalseAnswer: new FormControl(true, [Validators.required]),
      imagePath: new FormControl(null),
      links: this.linksService.array
    });
  }

  addLink() {
    this.linksService.addLink({url: '', label: ''});
  }

  deleteLink(linkIndex: number) {
    this.referenceLinks.markAsDirty();
    this.linksService.removeAt(linkIndex);
  }

  ngOnInit(): void {
    this.questionForm.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.status.emit({
          dirty: this.questionForm.dirty,
          valid: this.questionForm.valid,
          invalid: this.questionForm.invalid,
          pristine: this.questionForm.pristine
        });
      });

    if (this.onSave) {
      this.onSave.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
        this.saveQuestion();
      });
    }

    const question: Question | null = this.questionSignal();

    if (question) {
      this.questionControl.setValue(question.question);

      const answer: Answer | null | undefined = selectTrueFalseAnswer(question);

      this.linksService.addLinks(getReferenceLinksFromQuestion(question));

      // Add a blank link
      // No links exist
      if (this.linksService.array.length === 0) {
        this.addLink();
      }

      if (answer) {
        this.trueFalseAnswerControl.setValue(answer.trueFalseAnswer);
      }
    }
  }

  getErrorMessage(control: AbstractControl) {
    if (control.hasError('required')) {
      return 'You must enter a value';
    }

    return '';
  }

  /**
   * Saving Question, image, reference links, and answers.
   * This also requires update the parent quiz.
   */
  saveQuestion() {
    const question: Question | null = this.questionSignal();

    if (this.questionForm.valid && this.quiz && question) {
      const _question: Question = this.getQuestionFromForm(question);

      this.saveAction.emit({
        quiz: this.quiz,
        question: _question
      });
    }
  }

  private getQuestionFromForm(question: Question): Question {
    const _question: Question = {
      ...question,
      question: this.questionForm.controls['question'].value,
      imagePath: this.imagePathFormControl.value,
      referenceLinks: this.linksService.values()
    };

    const correctAnswer: Answer | null | undefined =
      selectTrueFalseAnswer(question);

    if (correctAnswer) {
      correctAnswer!.trueFalseAnswer = coerceBooleanProperty(
        this.trueFalseAnswerControl.value
      );
      _question.answers[correctAnswer!.id] = correctAnswer;
    }

    delete _question.referenceLink;

    return _question;
  }

  // undoQuestion() {
  //   this.getQuestionOnce().subscribe((question: Question) => {
  //     (<any>this.questionForm.controls).question.reset(question.question);
  //   });
  // }

  // undoReferenceLink(linkIndex: number) {
  //   this.getQuestionOnce().subscribe((question: Question) => {
  //     this.linksService.undoReferenceLink(linkIndex, question);
  //   });
  // }

  // clearQuestion() {
  //   (<any>this.questionForm.controls).question.setValue(null);
  //   (<any>this.questionForm.controls).question.markAsDirty({onlySelf: true});
  // }

  // saveQuestion() {
  //   this._question$.pipe(take(1)).subscribe((question: Question) => {
  //     if (
  //       this.questionForm.valid &&
  //       this.linksService.array.valid &&
  //       this.quiz &&
  //       question
  //     ) {
  //       const _question: Question = {
  //         ...question,
  //         question: (<any>this.questionForm.controls).question.value,
  //         referenceLinks: this.linksService.values()
  //       };
  //
  //       const correctAnswer: Answer | null | undefined =
  //         selectTrueFalseAnswer(question);
  //
  //       if (correctAnswer) {
  //         correctAnswer!.trueFalseAnswer =
  //           (<any>this.questionForm.controls).trueFalseAnswer.value === 'true';
  //         _question.answers[correctAnswer!.id] = correctAnswer;
  //
  //         delete _question.referenceLink;
  //
  //         this.saveAction.emit({
  //           quiz: this.quiz,
  //           question: _question
  //         });
  //       }
  //     }
  //   });
  // }

  onDelete() {
    // TODO delete image
    const question: Question | null = this.questionSignal();

    if (this.quiz && question) {
      this.deleteAction.emit({
        quiz: this.quiz,
        question: question
      });
    }
  }

  onDeleteImage() {
    const question: Question | null = this.questionSignal();

    if (question && this.quiz) {
      this.imagePathFormControl.setValue(null);

      const _question: Question = this.getQuestionFromForm(question);

      this.updateAction.emit({
        quiz: this.quiz,
        question: _question,
        update: <Pick<Question, 'imagePath'>>{
          imagePath: null
        }
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
