<!--<mat-progress-bar-->
<!--  *ngIf="showCheckoutProgress"-->
<!--  color="accent"-->
<!--  mode="indeterminate"-->
<!--&gt;</mat-progress-bar>-->
<!--<ui-subscriptions></ui-subscriptions>-->

<dlc-header-bar>
  <span>Profile</span>
</dlc-header-bar>
<dlc-mobile-page-content class="mt-5">
  <ng-container *ngrxLet="selectProviderIDAndEmail$; let providerIDAndEmail">
    <h3>Account</h3>
    <p>
      <span> Authenticated with {{ providerIDAndEmail.providerId }}.</span><br />
      <span>Email: {{ providerIDAndEmail.email }}.</span>
    </p>
  </ng-container>

  <mat-divider></mat-divider>

  <!--USER NAME-->
  <ui-username></ui-username>

  <!--LINK MOBILE_ACCOUNT_PAGE-->
  <ng-container *ngIf="loggedInUserCanLinkAccount$ | ngrxPush">
    <mat-divider></mat-divider>
    <div class="settings-column">
      <h3>Link Account</h3>
      <button dlc-rounded-text-icon-button color="primary" (click)="onLinkAccount()">
        <mat-icon>url</mat-icon>
        Link Account
      </button>
    </div>
  </ng-container>

  <!--MENTOR CODE -->
  <ng-container *ngrxLet="loggedInUserIsStudent$; let loggedInUserIsParent">
    <ng-container *ngrxLet="accountMentorCode$; let mentorCode">
      <ng-container *ngIf="loggedInUserIsParent">
        <mat-divider></mat-divider>
        <h3 class="mat-h3">Mentor / Guardian Code</h3>
        <div class="mat-description">
          Give this code to your mentor or guardian to url accounts.
        </div>
        <ec-copy-join-code
          class="mt-10"
          [title]="'Mentor Code'"
          [joinCodeType]="'mentor'"
          [joinCode]="mentorCode"></ec-copy-join-code>
      </ng-container>
    </ng-container>
  </ng-container>

  <!--MENTORING ACCOUNTS-->
  <ng-container *ngrxLet="hasMentorAccounts$; let hasMentorAccounts">
    <ng-container *ngIf="hasMentorAccounts">
      <mat-divider></mat-divider>
      <ui-mentor-list></ui-mentor-list>
    </ng-container>
  </ng-container>

  <!--MENTORING ME ACCOUNTS-->
  <ng-container *ngrxLet="hasMentoringMeAccounts$; let hasMentoringMeAccounts">
    <ng-container *ngIf="hasMentoringMeAccounts">
      <mat-divider></mat-divider>
      <ui-mentoring-me-list></ui-mentoring-me-list>
    </ng-container>
  </ng-container>
</dlc-mobile-page-content>
