import {Injectable} from '@angular/core';
import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {
  aggregateUpdates,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector
} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {Project} from '../+project/project.model';
import {
  firestoreResearchCollection,
  firestoreUserResearchCollection
} from '../firebase/database-paths';
import {
  deleteResearchs,
  updateResearchs,
  upsertResearchs
} from './research.actions';
import {Research} from './research.model';
import {researchFeatureKey} from './research.reducer';

@Injectable({
  providedIn: 'root'
})
export class ResearchService implements NgPatFirebaseConnectionService {
  private _queryCollaborationService!: NgPatFirestoreCollectionQuery<Research>;
  private _queryPrivateService!: NgPatFirestoreCollectionQuery<Research>;

  connectionKey = researchFeatureKey;
  connection: NgPatServiceConnector = new NgPatServiceConnector(
    this,
    this.store
  );

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  ngPatOnInit() {
    this._queryCollaborationService =
      new NgPatFirestoreCollectionQuery<Research>(
        {
          queryMember: true,
          upsertManyAction: (researchs: Research[]) =>
            upsertResearchs({researchs}),
          updateManyAction: (researchs: Research[]) =>
            updateResearchs({researchs: aggregateUpdates(researchs)}),
          deleteManyAction: (ids: string[]) => deleteResearchs({ids})
        },
        this.store,
        this.customFirestoreService
      );

    this._queryPrivateService = new NgPatFirestoreCollectionQuery<Research>(
      {
        queryMember: false,
        upsertManyAction: (researchs: Research[]) =>
          upsertResearchs({researchs}),
        updateManyAction: (researchs: Research[]) =>
          updateResearchs({researchs: aggregateUpdates(researchs)}),
        deleteManyAction: (ids: string[]) => deleteResearchs({ids})
      },
      this.store,
      this.customFirestoreService
    );
  }

  deleteDoc$(classroom: Project | undefined, uid: string) {
    if (classroom) {
      const path: string = classroom.isPrivate
        ? firestoreUserResearchCollection(uid)
        : firestoreResearchCollection();

      // const path = studyGroup.isPrivate ?
      return this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(classroom: Project | undefined, ids: string[], uid: string) {
    if (classroom) {
      const isPrivate = classroom.isPrivate;
      const path: string = isPrivate
        ? firestoreUserResearchCollection(uid)
        : firestoreResearchCollection();
      return this.customFirestoreService.deleteDocs$(path, ids);
    }
    return of(true);
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._queryCollaborationService.onConnect(
      firestoreResearchCollection(),
      null,
      <string>user.uid
    );
    this._queryPrivateService.onConnect(
      firestoreUserResearchCollection(<string>user.uid),
      null,
      null
    );
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._queryCollaborationService.onDisconnect();
    this._queryPrivateService.onDisconnect();
  }
}
