import {Injectable} from '@angular/core';
import {ActivatedRoute, Router, UrlTree} from '@angular/router';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {Store} from '@ngrx/store';
import {Observable, switchMap} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {selectUiStateIsLoaded} from '../+ui/ui.selectors';

/**
 * https://medium.com/@ryanchenkie_40935/angular-authentication-using-route-guards-bf7a4ca13ae3
 * https://juristr.com/blog/2018/11/better-route-guard-redirects/
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(
    private _router: Router,
    private store: Store,
    private _activatedRoute: ActivatedRoute,
    private firestore: NgPatFirestoreService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    // Make sure the UI state is initialized
    return this.store.select(selectUiStateIsLoaded).pipe(
      // Wait for the UI state to be initialized
      filter(isLoaded => isLoaded),
      // Make sure the Firestore is loaded
      switchMap(() => this.firestore.isLoaded$),
      // Wait for the Firestore to be loaded
      filter(firestoreIsLoaded => firestoreIsLoaded),
      // Make sure the user is logged in
      switchMap(() => this.firestore.isLoggedIn$),
      map((isLoggedIn: boolean) => {
        // console.log('isLoggedIn', isLoggedIn);
        if (!isLoggedIn) {
          // If the user is not logged in, redirect to the login page
          this._router.navigate(['login']).then(() => {
            // noop
          });
          // return this._router.parseUrl('/start');
        }

        return isLoggedIn;
      })
    );
  }
}
