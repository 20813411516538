<ul role="list" class="pl-0">
  <li
    ec-class-list-item
    class="mb-5"
    *ngFor="let item of classes"
    [classroom]="item"
    (doEditItem)="doEditItem.emit($event)"
    (doDeleteItem)="doDeleteItem.emit($event)"
    (doSelectItem)="doSelectItem.emit($event)"
    (doLeaveItem)="doLeaveItem.emit($event)"></li>
</ul>
