import {ChangeDetectionStrategy, Component, input, InputSignal} from '@angular/core';
import {Route} from '@angular/router';
import {fadeInAnimation} from '@gigasoftware/shared/ui-design-library';

@Component({
  selector: 'ec-terms-of-use',
  templateUrl: './ec-terms-of-use.component.html',
  styleUrls: ['./ec-terms-of-use.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[@fadeInAnimation]': '',
    class: 'ec-terms-of-use dlc-global-mobile-page',
    '[class.is-light-theme]': 'isLightTheme() === true'
  },
  animations: [fadeInAnimation],
  standalone: true
})
export class EcTermsOfUseComponent {
  lastUpdated = '12 February 2024';

  isLightTheme: InputSignal<boolean> = input(false);
}

export const EC_TERMS_OF_USE_ROUTES: Route[] = [
  {path: '', pathMatch: 'full', component: EcTermsOfUseComponent}
];
