import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, input, ViewEncapsulation} from '@angular/core';
import {coerceThemePalette} from '../button.fns';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[dlc-rounded-icon-button], button[dlcRoundedIconButton]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dlc-rounded-icon-button.component.html',
  styleUrls: ['./dlc-rounded-icon-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'dlc-rounded-icon-button  dlc-rounded-button',
    '[attr.disabled]': `disabled() === true ? '' : null`,
    '[class.dlc-rounded-icon-button-primary]': 'color() === "primary"',
    '[class.dlc-rounded-icon-button-accent]': 'color() === "accent"',
    '[class.dlc-rounded-icon-button-warn]': 'color() === "warn"',
    '[class.dlc-rounded-icon-button-none]': 'color() === "none" || color() === undefined'
  }
})
export class DlcRoundedIconButtonComponent {
  disabled = input(false, {transform: coerceBooleanProperty});
  color = input('none', {
    transform: coerceThemePalette
  });
}
