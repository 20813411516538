import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
  CollaborativeProject, firestorePublishedQuizDoc,
  firestoreQueryPathByProject,
  Project,
  Quiz
} from '@gigasoftware/evolving-cognition/domain';
import {GsAssetService} from '@gigasoftware/shared/media';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {User} from 'firebase/auth';

export interface ConfirmDeleteFirestoreAndAssets {
  name: string;
  showRemove?: boolean;
  message?: string;
  project: CollaborativeProject | Quiz;
  imagePaths: string[];
}

@Component({
  selector: 'ec-confirm-delete-firestore-and-assets',
  standalone: true,
  imports: [
    CommonModule,
    DlcRoundedTextIconButtonComponent,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './ec-confirm-delete-firestore-and-assets.component.html',
  styleUrls: ['./ec-confirm-delete-firestore-and-assets.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EcConfirmDeleteFirestoreAndAssetsComponent {
  isDeleting: WritableSignal<boolean> = signal(false);
  deleteError: WritableSignal<string> = signal('');
  hasDeleteError: WritableSignal<boolean> = signal(false);

  constructor(
    public dialogRef: MatDialogRef<EcConfirmDeleteFirestoreAndAssetsComponent>,
    private customFirestoreService: NgPatFirestoreService,
    private assetService: GsAssetService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteFirestoreAndAssets
  ) {}

  async onDeleteQuizAndAssets() {
    const that = this;

    const user: User | null = this.customFirestoreService.user();
    // Quiz, Classroom, StudyGroup, or Project
    const project: Project | Quiz | undefined = this.data.project;

    if (user && project) {
      this.isDeleting.set(true);

      if (this.data.imagePaths.length > 0) {
        const baseImagePaths: Promise<string[]>[] = this.data.imagePaths.map(
          (imagePath: string) => {
            return this.assetService.getDirectoryList(
              this.assetService.getBaseAssetDirectoryFromPath(imagePath)
            );
          }
        );

        const baseImagePathsResults: string[][] = await Promise.all(
          baseImagePaths
        );

        const baseImagePathsFlat: string[] = baseImagePathsResults.flat();

        await this.assetService.deleteAssets(baseImagePathsFlat);
      }

      let path = firestoreQueryPathByProject(project, user.uid);

      if ((<Quiz>this.data.project).isPublished) {
        path = firestorePublishedQuizDoc((<Quiz>this.data.project).id);
      }

      return this.customFirestoreService
        .recursiveDelete(path)
        .then(() => {
          that.dialogRef.close(true);
        })
        .catch((error: any) => {
          that.isDeleting.set(false);
          that.hasDeleteError.set(true);
          that.deleteError.set(error.message);
        });
    }
  }
}
