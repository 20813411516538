import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Answer, QuestionWithAnswer, UserAnswer} from '@gigasoftware/evolving-cognition/domain';
import {QuizAnswerComponent} from '../../../button/quiz-answer/quiz-answer.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ec-answer-true-false',
  templateUrl: './ec-answer-true-false.component.html',
  styleUrls: ['../answers.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [QuizAnswerComponent],
  host: {
    class: 'ec-answer-true-false answer__container'
  }
})
export class EcAnswerTrueFalseComponent {
  @Input()
  question: QuestionWithAnswer | null | undefined;

  @Output() answer: EventEmitter<QuestionWithAnswer> = new EventEmitter<QuestionWithAnswer>();

  constructor(private _cd: ChangeDetectorRef) {}

  onTrueFalseAnswer(userAnswer: boolean) {
    if (this.question) {
      const answer: Answer = Object.values(this.question.question.answers)[0];

      const recordedAnswer: QuestionWithAnswer = {
        ...this.question,
        isAnswered: true,
        isCorrect: answer.trueFalseAnswer === userAnswer,
        answers: {
          [answer.id]: <UserAnswer>{
            answer,
            trueFalseAnswer: userAnswer,
            multipleChoiceChecked: false
          }
        }
      };

      this.answer.emit(recordedAnswer);
    }
  }
}
