import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@Component({
  // 'plat-failed-to-checkout'
  selector: 'ui-failed-to-checkout',
  templateUrl: './plat-failed-to-purchase.component.html',
  styleUrls: ['./plat-failed-to-purchase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule]
})
export class PlatFailedToPurchaseComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PlatFailedToPurchaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}
}
