import {Injectable} from '@angular/core';
import {selectNgPatUserAccount} from '@ngpat/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {switchMap, withLatestFrom} from 'rxjs/operators';
import {Classroom} from '../+classrooms/classroom.model';
import {getClassroomByID} from '../+classrooms/classroom.selectors';
import {deleteResearch, deleteResearchs} from './research.actions';
import {PartialResearchState} from './research.reducer';
import {ResearchService} from './research.service';

@Injectable({
  providedIn: 'root'
})
export class ResearchEffects {
  deleteDoc$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(deleteResearch),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.id)),
            switchMap((classRoom: Classroom | undefined) =>
              this._researchService.deleteDoc$(classRoom, <string>account.uid)
            )
          )
        )
      ),
    {dispatch: false}
  );

  deleteDocs$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(deleteResearchs),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.ids[0])),
            switchMap((classRoom: Classroom | undefined) =>
              this._researchService.deleteDocs$(
                classRoom,
                action.ids,
                <string>account.uid
              )
            )
          )
        )
      ),
    {dispatch: false}
  );

  constructor(
    private _actions$: Actions,
    private store: Store<PartialResearchState>,
    private _researchService: ResearchService
  ) {}
}
