

import {Attribute, Directive, ElementRef, InjectionToken, Input} from '@angular/core';

let nextUniqueId = 0;

/**
 * Injection token that can be used to reference instances of `EcError`. It serves as
 * alternative token to the actual `EcError` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const DLC_ERROR = new InjectionToken<DlcError>('DlcError');

/** Single error message to be shown underneath the form-field. */
@Directive({
  selector: 'dlc-error, [dlc-error], [dlcError]',
  // standalone: true,
  host: {
    'class': 'dlc-form-field-error dlc-form-field-bottom-align',
    'aria-atomic': 'true',
    '[id]': 'id',
  },
  providers: [{provide: DLC_ERROR, useExisting: DlcError}],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class DlcError {
  @Input() id = `ec-error-${nextUniqueId++}`;

  constructor(@Attribute('aria-live') ariaLive: string, elementRef: ElementRef) {
    // If no aria-live value is set add 'polite' as a default. This is preferred over setting
    // role='alert' so that screen readers do not interrupt the current task to read this aloud.
    if (!ariaLive) {
      elementRef.nativeElement.setAttribute('aria-live', 'polite');
    }
  }
}
