import {getFileExtension} from '@ngpat/fn';

export interface ElectronDialogFileExtensionFilterItem {
  name: string;
  extensions: string[];
}

export declare type ElectronDialogFileExtensionFilter =
  ElectronDialogFileExtensionFilterItem[];

// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
export type ImageFileType =
  | 'image/jpeg'
  | 'image/jpg'
  | 'image/png'
  | 'image/gif'
  | string;

export enum IMAGE_FILE_TYPE {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  GIF = 'image/gif'
}

// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/codecs_parameter
export type AudioFileType =
  | 'audio/mp3'
  | 'audio/mp4'
  | 'audio/mpeg'
  | 'audio/ogg'
  | 'audio/wav'
  | 'audio/webm'
  | string;

export enum AUDIO_FILE_TYPE {
  MP3 = 'audio/mp3',
  MP4 = 'audio/mp4',
  MPEG = 'audio/mpeg',
  OGG = 'audio/ogg',
  WAV = 'audio/wav',
  WEBM = 'audio/webm'
}

export type VideoFileType = 'video/mp4' | 'video/webm' | string;

export enum VIDEO_FILE_TYPE {
  MP4 = 'video/mp4',
  WEBM = 'video/webm'
}

export type DataFileType =
  | 'application/octet-stream'
  | 'application/json'
  | string;

export enum DATA_FILE_TYPE {
  ARRAY_BUFFER = 'arraybuffer',
  FLOAT_32_ARRAY = 'Float32Array',
  OCTET_STREAM = 'application/octet-stream',
  JSON = 'application/json'
}

export type MidiFiletype = 'audio/midi' | 'audio/mid' | string;

export enum MIDI_FILE_TYPE {
  MIDI = 'audio/midi',
  MID = 'audio/mid'
}

export type DatFileType = 'dat' | string;

export enum DAT_FILE_TYPE {
  DAT = 'dat'
}

export type FileType =
  | ImageFileType
  | AudioFileType
  | VideoFileType
  | DataFileType
  | MidiFiletype
  | DatFileType;

export enum FILE_TYPE {
  ARRAY_BUFFER = 'arraybuffer',
  DAT = 'dat',
  MIDI = 'mid',
  AUDIO_MP3 = 'audio/mp3',
  AUDIO_MP4 = 'audio/mp4',
  AUDIO_MPEG = 'audio/mpeg',
  AUDIO_OGG = 'audio/ogg',
  AUDIO_WAV = 'audio/wav',
  AUDIO_WEBM = 'audio/webm',
  FLOAT_32_ARRAY = 'Float32Array',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_JPG = 'image/jpg',
  IMAGE_PNG = 'image/png',
  IMAGE_GIF = 'image/gif',
  VIDEO_MP4 = 'video/mp4',
  VIDEO_WEBM = 'video/webm',
  OCTET_STREAM = 'application/octet-stream',
  JSON = 'application/json',
  MID = 'audio/mid'
}

export const ExtensionToType: {[key: string]: FILE_TYPE} = {
  dat: FILE_TYPE.DAT,
  mid: FILE_TYPE.MIDI,
  mpeg: FILE_TYPE.AUDIO_MPEG,
  mp3: FILE_TYPE.AUDIO_MP3,
  mp4: FILE_TYPE.AUDIO_MP4,
  ogg: FILE_TYPE.AUDIO_OGG,
  wav: FILE_TYPE.AUDIO_WAV,
  webm: FILE_TYPE.AUDIO_WEBM,
  json: FILE_TYPE.ARRAY_BUFFER,
  arraybuffer: FILE_TYPE.ARRAY_BUFFER,
  Float32Array: FILE_TYPE.FLOAT_32_ARRAY,
  jpeg: FILE_TYPE.IMAGE_JPEG,
  jpg: FILE_TYPE.IMAGE_JPG,
  png: FILE_TYPE.IMAGE_PNG,
  gif: FILE_TYPE.IMAGE_GIF
};

export function getTypeByExtension(extension: string): FILE_TYPE {
  return ExtensionToType[extension];
}

export const TypeToExtention: {[key: string]: string} = {
  [FILE_TYPE.DAT]: 'dat',
  [FILE_TYPE.MIDI]: 'mid',
  [FILE_TYPE.AUDIO_MP3]: 'mp3',
  [FILE_TYPE.AUDIO_MP4]: 'mp4',
  [FILE_TYPE.AUDIO_MPEG]: 'mpeg',
  [FILE_TYPE.AUDIO_OGG]: 'ogg',
  [FILE_TYPE.AUDIO_WAV]: 'wav',
  [FILE_TYPE.AUDIO_WEBM]: 'webm',
  [FILE_TYPE.FLOAT_32_ARRAY]: 'Float32Array',
  [FILE_TYPE.IMAGE_JPEG]: 'jpeg',
  [FILE_TYPE.IMAGE_JPG]: 'jpg',
  [FILE_TYPE.IMAGE_PNG]: 'png',
  [FILE_TYPE.IMAGE_GIF]: 'gif',
  [FILE_TYPE.VIDEO_MP4]: 'mp4',
  [FILE_TYPE.VIDEO_WEBM]: 'webm',
  [FILE_TYPE.OCTET_STREAM]: 'octet-stream',
  [FILE_TYPE.JSON]: 'json',
  [FILE_TYPE.MID]: 'mid'
};

export function isImage(fileType: FileType): boolean {
  return (
    fileType === FILE_TYPE.IMAGE_JPEG ||
    fileType === FILE_TYPE.IMAGE_JPG ||
    fileType === FILE_TYPE.IMAGE_PNG ||
    fileType === FILE_TYPE.IMAGE_GIF
  );
}

export function isAudio(fileType: FileType): boolean {
  return (
    fileType === FILE_TYPE.AUDIO_MP3 ||
    fileType === FILE_TYPE.AUDIO_MP4 ||
    fileType === FILE_TYPE.AUDIO_MPEG ||
    fileType === FILE_TYPE.AUDIO_OGG ||
    fileType === FILE_TYPE.AUDIO_WAV ||
    fileType === FILE_TYPE.AUDIO_WEBM
  );
}

export function isVideo(fileType: FileType): boolean {
  return fileType === FILE_TYPE.VIDEO_MP4 || fileType === FILE_TYPE.VIDEO_WEBM;
}

export function isData(fileType: FileType): boolean {
  return (
    fileType === FILE_TYPE.ARRAY_BUFFER ||
    fileType === FILE_TYPE.OCTET_STREAM ||
    fileType === FILE_TYPE.JSON
  );
}

export function isMidi(fileType: FileType): boolean {
  return fileType === FILE_TYPE.MIDI || fileType === FILE_TYPE.MID;
}

export function isDat(fileType: FileType): boolean {
  return fileType === FILE_TYPE.DAT;
}

export function isFileType(fileType: FileType): boolean {
  return (
    isImage(fileType) ||
    isAudio(fileType) ||
    isVideo(fileType) ||
    isData(fileType) ||
    isMidi(fileType) ||
    isDat(fileType)
  );
}

export function isFileTypeString(fileType: string): boolean {
  return (
    isImage(fileType) ||
    isAudio(fileType) ||
    isVideo(fileType) ||
    isData(fileType) ||
    isMidi(fileType) ||
    isDat(fileType)
  );
}

export function getFileTypeFromExtension(extension: string): FileType {
  return getTypeByExtension(extension);
}

export function fileExtensionIsImage(
  extension: string | null | undefined
): boolean {
  if (extension && extension.length > 0) {
    return isImage(getFileTypeFromExtension(extension));
  }

  return false;
}

export function filenameIsImage(filename: string | null | undefined): boolean {
  if (filename && filename.length > 0) {
    return fileExtensionIsImage(getFileExtension(filename));
  }

  return false;
}

export function fileExtensionIsAudio(
  extension: string | null | undefined
): boolean {
  if (extension && extension.length > 0) {
    return isAudio(getFileTypeFromExtension(extension));
  }

  return false;
}

export function filenameIsAudio(filename: string | null | undefined): boolean {
  if (filename && filename.length > 0) {
    return fileExtensionIsAudio(getFileExtension(filename));
  }

  return false;
}

export function fileExtensionIsVideo(
  extension: string | null | undefined
): boolean {
  if (extension && extension.length > 0) {
    return isVideo(getFileTypeFromExtension(extension));
  }

  return false;
}

export function filenameIsVideo(filename: string | null | undefined): boolean {
  return fileExtensionIsVideo(getFileExtension(filename));
}

export function fileExtensionIsData(
  extension: string | null | undefined
): boolean {
  if (extension && extension.length > 0) {
    return isData(getFileTypeFromExtension(extension));
  }

  return false;
}

export function filenameIsData(filename: string | null | undefined): boolean {
  return fileExtensionIsData(getFileExtension(filename));
}

export function fileExtensionIsMidi(
  extension: string | null | undefined
): boolean {
  if (extension && extension.length > 0) {
    return isMidi(getFileTypeFromExtension(extension));
  }

  return false;
}

export function filenameIsMidi(filename: string | null | undefined): boolean {
  return fileExtensionIsMidi(getFileExtension(filename));
}

export function fileExtensionIsDat(
  extension: string | null | undefined
): boolean {
  if (extension && extension.length > 0) {
    return isDat(getFileTypeFromExtension(extension));
  }

  return false;
}

export function filenameIsDat(filename: string | null | undefined): boolean {
  return fileExtensionIsDat(getFileExtension(filename));
}
