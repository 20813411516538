@for (n of notifications(); track n.id) {
  <div mat-menu-item (click)="notificationAction.emit(n)">
    <div class="flex flex-row justify-start items-center">
      <div class="flex flex-col justify-start items-start">
        <div class="text-[--dlc-color-primary] text-xs font-bold whitespace-nowrap">
          {{ n.title }}
        </div>
        <div class="text-xs font-light mt-1">{{ n.message }}</div>
      </div>
      <div class="ec-alert-theme ml-4" *ngIf="n.id === defaultNotifications.APP_UPDATE">
        <button dlc-rounded-icon-button>
          <mat-icon class="!m-0">get_app</mat-icon>
        </button>
      </div>
    </div>
  </div>
}
