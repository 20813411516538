import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AutoResizeTextareaComponent} from './auto-resize-textarea.component';
import {AutoResizeTextareaControlsComponent} from './controls/auto-resize-textarea-controls.component';

@NgModule({
  declarations: [
    AutoResizeTextareaControlsComponent,
    AutoResizeTextareaComponent
  ],
  imports: [CommonModule],
  exports: [AutoResizeTextareaControlsComponent, AutoResizeTextareaComponent]
})
export class DlcAutoResizeTextareaModule {}
