import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcPrivacyPolicyComponent } from '@gigasoftware/evolving-cognition/ui';
import { NgPatFirestoreService } from '@ngpat/firebase';

@Component({
  selector: 'ecm-privacy',
  standalone: true,
  imports: [ CommonModule, EcPrivacyPolicyComponent ],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss',
})
export class PrivacyComponent implements OnInit {

  constructor(private firestore: NgPatFirestoreService) {
  }

  ngOnInit() {
    this.firestore.logEvent('privacy_opened');
  }
}
