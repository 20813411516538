import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
  selector: 'ui-teacher-subscription-text',
  templateUrl: './teacher-subscription-text.component.html',
  styleUrls: ['./teacher-subscription-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf]
})
export class TeacherSubscriptionTextComponent implements OnInit {
  @Input() showTitle = false;
  constructor() {}

  ngOnInit(): void {}
}
