<h3 mat-dialog-title>Link Account</h3>
<mat-dialog-content>
  <p class="mat-caption">Enter url code from account to monitor.</p>
  <div class="p-micro-form">
    <mat-form-field
      appearance="fill"
      floatLabel="always"
      class="p-input-with-240">
      <mat-label>Add Link Code</mat-label>
      <input matInput [formControl]="linkForm" />
      <mat-error *ngIf="linkForm.dirty && linkForm.value?.length === 0"
        >Required</mat-error
      >
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button color="primary" (click)="dialogRef.close(false)">
    Cancel
  </button>
  <button mat-flat-button color="primary" (click)="linkAccount()">
    <mat-icon>url</mat-icon> Link
  </button>
</mat-dialog-actions>
