import {
  GSFirebaseUploadImageConfig,
  MAX_IMAGE_SIZES
} from '@gigasoftware/shared/media';

export const EC_QUIZ_BASE_IMAGE_PATH = 'ec/images';

export const ecQuizImageUploaderConfig: Partial<GSFirebaseUploadImageConfig> = {
  baseImageDirectory: EC_QUIZ_BASE_IMAGE_PATH,
  imageNameIsParentDirectory: true,
  maxImageSizes: MAX_IMAGE_SIZES
};
