import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {
  CollaborationProjectForm,
  createProjectParams,
  CreateProjectParams,
  DEFAULT_PLATFORM_PROJECT_TYPE_DICT,
  getProjectTypeByName,
  PLATFORM_PROJECT_TYPES,
  ProjectType
} from '@gigasoftware/evolving-cognition/domain';
import {
  DlcFormFieldModule,
  DlcInputDirective,
  DlcRoundedTextButtonComponent,
  DlcSelectModule
} from '@gigasoftware/shared/ui-design-library';
import {generateRandomString, uuid} from '@ngpat/fn';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';

@Component({
  selector: 'ec-create-study-group',
  templateUrl: './ec-create-study-group.component.html',
  styleUrls: ['./ec-create-study-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-create-study-group'
  },
  standalone: true,
  imports: [
    CommonModule,
    DlcFormFieldModule,
    DlcSelectModule,
    DlcRoundedTextButtonComponent,
    DlcInputDirective,
    ReactiveFormsModule,
    MatCheckboxModule
  ]
})
export class EcCreateStudyGroupComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  projectTypes: ProjectType[] = PLATFORM_PROJECT_TYPES;

  projectTypeNames: string[] = this.projectTypes.map(
    (p: ProjectType) => p.name
  );

  showProjectTypeSelect: WritableSignal<boolean> = signal(true);
  projectTypeForm: FormControl = new FormControl(DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.name, [Validators.required]);

  nameFormGroup: FormGroup;
  selectProjectType: ProjectType  = DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP;

  @Input() showIsPrivate = false;

  @Output() createAction: EventEmitter<CreateProjectParams> =
    new EventEmitter<CreateProjectParams>();

  get projectTypeName() {
    return this.selectProjectType?.name;
  }

  // get showCollaborationOption() {
  //   return (
  //     this.selectProjectType &&
  //     this.selectProjectType.id !==
  //       DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id &&
  //     this.selectProjectType.id !== DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id
  //   );
  // }
  //
  // get showPrivateCheckbox() {
  //   return (
  //     this.selectProjectType &&
  //     this.selectProjectType.id !==
  //       DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id &&
  //     this.selectProjectType.id !== DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id
  //   );
  // }

  constructor(
    private store: Store,
    private _fb: FormBuilder,
  ) {
    this.nameFormGroup = this._fb.group({
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      isPrivate: new FormControl(false),
      isCollaborative: new FormControl(true)
    });
  }


  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  onSubmit() {
    const params: CreateProjectParams = {
      id: uuid(),
      projectTypeValue: getProjectTypeByName(this.projectTypeForm.value).id,
      ...this.nameFormGroup.value,
      joinCode: generateRandomString(8, '', false)
    };

    this.store.dispatch(createProjectParams({params}));

    this.nameFormGroup.reset(
      <CollaborationProjectForm>{
        name: null,
        description: null,
        isPrivate: this.nameFormGroup.value.isPrivate,
        isCollaborative: this.nameFormGroup.value.isCollaborative
      },
      {emitEvent: false}
    );

    this.createAction.emit(params);
  }
}
