import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output
} from '@angular/core';
import {
  Classroom,
  CollaborativeProject,
  updateClassroomEffect
} from '@gigasoftware/evolving-cognition/domain';
import {Store} from '@ngrx/store';
import {EcClassListItemComponent} from './ec-class-list-item/ec-class-list-item.component';

@Component({
  selector: 'ec-class-list',
  templateUrl: './ec-class-list.component.html',
  styleUrls: ['./ec-class-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-class-list'
  },
  standalone: true,
  imports: [CommonModule, EcClassListItemComponent]
})
export class EcClassListComponent {
  @Input() classes: Classroom[] = [];

  @Output() doSelectItem: EventEmitter<Classroom> =
    new EventEmitter<Classroom>();

  @Output() doEditItem: EventEmitter<Classroom> = new EventEmitter<Classroom>();

  @Output() doDeleteItem: EventEmitter<Classroom> =
    new EventEmitter<Classroom>();

  @Output() doLeaveItem: EventEmitter<Classroom> =
    new EventEmitter<Classroom>();

  constructor(
    private _cd: ChangeDetectorRef,
    private _zone: NgZone,
    private store: Store
  ) {}

  onJoinGroup(p: CollaborativeProject) {
    this._zone.run(() => {
      this.store.dispatch(
        updateClassroomEffect({
          classroom: p
        })
      );
    });
  }
}
