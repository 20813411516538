import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'dlc-auto-resize-textarea-controls',
  templateUrl: './auto-resize-textarea-controls.component.html',
  styleUrls: ['./auto-resize-textarea-controls.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'dlc-auto-resize-textarea-controls'
  }
})
export class AutoResizeTextareaControlsComponent {}
