<fieldset
  class="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white">
  <legend class="sr-only">{{ srLabel }}</legend>
  @for(item of items; track item) {
  <label
    class="cursor-pointer rounded-full px-2.5 py-1"
    (click)="onSelect(item)"
    [ngClass]="{'dlc-text-toggle--active': _selected()?.value === item?.value}">
    <input type="radio" name="frequency" value="{{ item?.value }}" class="sr-only" />
    <span>{{ item?.title }}</span>
  </label>
  }
</fieldset>
