import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as ResearchReducer from './research.reducer';

export const selectResearchState =
  createFeatureSelector<ResearchReducer.ResearchState>(
    ResearchReducer.researchFeatureKey
  );

const {selectIds, selectEntities, selectAll, selectTotal} =
  ResearchReducer.researchAdapter.getSelectors();

export const selectAllResearchs = createSelector(
  selectResearchState,
  selectAll
);
export const selectResearchEntities = createSelector(
  selectResearchState,
  selectEntities
);
export const selectResearchIds = createSelector(selectResearchState, selectIds);
export const selectResearchTotal = createSelector(
  selectResearchState,
  selectTotal
);
export const selectedResearchID = createSelector(
  selectResearchState,
  (state: ResearchReducer.ResearchState) => state.selectedResearchID
);
