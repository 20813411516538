export function restrictScaleToTwoDecimals(scale: number): number {
  /**
   * Check if scale has more than two decimal places.
   */
  const scaleString = scale.toString();
  const decimalIndex = scaleString.indexOf('.');
  const decimalPlaces = scaleString.substring(decimalIndex + 1).length;

  if (decimalPlaces > 2) {
    scale = Number(scale.toFixed(2));
  }

  return scale;
}

export function convertScaleToPercent(scale: number): number {
  const percent = scale * 100;

  return restrictScaleToTwoDecimals(percent);
}

/**
 * Keep rotation within 0-360 degrees.
 */
export function restrictRotationTo360Degrees(rotation: number): number {
  if (rotation === 360 || rotation === -360) {
    return 0;
  }

  if (rotation > 360 || rotation < -360) {
    const numRotations = Math.floor(rotation / 360);
    rotation = rotation - 360 * numRotations;
  }

  return rotation;
}
