import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  Signal,
  WritableSignal
} from '@angular/core';
import {Route, RouterLink} from '@angular/router';
import {
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment,
  selectHasActiveSubscription
} from '@gigasoftware/evolving-cognition/domain';
import {
  APP,
  AppLoginService,
  DlcFooterBarComponent,
  DlcHeaderBarComponent,
  DlcMobilePageContentComponent,
  DlcPricingComponent
} from '@gigasoftware/shared/ui-design-library';
import {NgPatNormalizedPrice, selectAllProductsWithPrices} from '@ngpat/store';
import {WINDOW} from '@ngpat/utils';
import {Store} from '@ngrx/store';

@Component({
  selector: 'ec-in-app-subscribe-page',
  templateUrl: './ec-in-app-subscribe-page.component.html',
  styleUrls: ['./ec-in-app-subscribe-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-in-app-subscribe-page dlc-global-mobile-page'
  },
  standalone: true,
  imports: [
    CommonModule,
    DlcMobilePageContentComponent,
    DlcHeaderBarComponent,
    DlcPricingComponent,
    DlcFooterBarComponent,
    RouterLink
  ],
  providers: []
})
export class EcInAppSubscribePageComponent {
  private appAuthService: AppLoginService = inject(AppLoginService);
  private store: Store = inject(Store);
  // private webPriceService: DlcWebPricingService = inject(DlcWebPricingService);
  win: Window = <Window>inject(WINDOW);
  env: EvolutionCognitionEnvironment = <EvolutionCognitionEnvironment>inject(EC_ENVIRONMENT);

  showCheckoutProgress = false;

  prices: Signal<NgPatNormalizedPrice[] | undefined | null> = <
    Signal<NgPatNormalizedPrice[] | undefined | null>
  >this.store.selectSignal(selectAllProductsWithPrices);

  hasActiveSubscription: Signal<boolean> = <Signal<boolean>>(
    this.store.selectSignal(selectHasActiveSubscription)
  );

  // get isNativePlatform(): boolean {
  //   return Capacitor.isNativePlatform();
  // }

  app: APP = APP.EC;

  showCheckoutProgressBar: WritableSignal<boolean> = signal(false);

  // ngOnInit() {
  //   if (!this.isNativePlatform) {
  //     this.webPriceService.loadWebCheckoutScript(this.app);
  //   }
  // }

  onLogin() {
    this.appAuthService.login();
  }
}

export const EC_IN_APP_SUBSCRIBE_ROUTES: Route[] = [
  {
    path: '',
    component: EcInAppSubscribePageComponent
  }
];
