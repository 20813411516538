<h3 mat-dialog-title>Copy</h3>

<mat-dialog-content *ngIf="isCopying()">
  <div class="mb-8">{{ copyingQuiz() }}</div>
  <div class="flex flex-row justify-center items-center mb-8">
    <mat-spinner mode="indeterminate" color="primary"></mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="!isCopying()">
  <div>{{ data.quiz.name }} to:</div>
  <div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="copyToOptions">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <!--        <th mat-header-cell *matHeaderCellDef>Name</th>-->
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Type projectTypeName -->
      <ng-container matColumnDef="projectTypeName">
        <!--        <th mat-header-cell *matHeaderCellDef>Type</th>-->
        <td mat-cell *matCellDef="let element">
          {{ element.projectTypeName }}
        </td>
      </ng-container>

      <!-- Type copyAction -->
      <ng-container matColumnDef="copyAction">
        <!--        <th mat-header-cell *matHeaderCellDef></th>-->
        <td mat-cell *matCellDef="let element">
          <mat-icon>content_copy</mat-icon>
        </td>
      </ng-container>

      <!--      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>-->
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onCopyQuizAndAssets(row)"></tr>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button dlc-rounded-text-icon-button (click)="dialogRef.close(null)">
    Cancel
  </button>
</mat-dialog-actions>
