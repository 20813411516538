import {CommonModule, DOCUMENT} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  Inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {Subject, takeUntil} from 'rxjs';
import {displayNoneInlineFlexAnimation} from '../../../animations/visible.animation';
import {coerceThemePalette} from '../../button/button.fns';
import {DlcSquareIconButtonComponent} from '../../button/dlc-square-icon-button/dlc-square-icon-button.component';
import {DlcFormFieldAppearance} from '../../form-field/form-field.component';

@Component({
  selector: 'dlc-mat-inline-edit, input[dlcMatInlineEdit], input[dlc-mat-inline-edit]',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    DlcSquareIconButtonComponent
  ],
  templateUrl: './dlc-mat-inline-edit.component.html',
  styleUrl: './dlc-mat-inline-edit.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [displayNoneInlineFlexAnimation],
  host: {
    class: 'dlc-mat-inline-edit',
    '[class.dlc-mat-inline-edit--center]': 'center',
    '[class.dlc-mat-inline-edit--focus]': 'inputFocusCss()',
    '[class.dlc-mat-inline-edit--dirty]': 'dirty()',
    '[class.dlc-primary]': 'color() !== "accent" && color() !== "warn"',
    '[class.dlc-accent]': 'color() === "accent"',
    '[class.dlc-warn]': 'color() === "warn"'
  }
})
export class DlcMatInlineEditComponent implements OnDestroy, OnInit {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _value: WritableSignal<string> = signal('');
  private _formControlValue: WritableSignal<string> = signal('');
  @ViewChild('input') input!: ElementRef;

  inputFocusCss: WritableSignal<boolean> = signal(false);

  dirty: Signal<boolean> = computed(() => {
    return this._formControlValue() !== this._value();
  });

  private _placeholder = '';

  @Input() formControl: FormControl<string> = new FormControl();
  @Input()
  set placeholder(value: string) {
    if (value && value.length) {
      this._placeholder = value;
    }
  }
  get placeholder() {
    return this._placeholder;
  }
  @Input() center = false;
  @Input() label = '';

  @Input()
  set value(v: string | undefined) {
    if (v && v.length) {
      this._value.set(v);
    }

    if (this.formControl && v && v.length) {
      this.formControl.setValue(v);
    }
  }

  @Output() valueChanges: Subject<string> = new Subject();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  focusBtnsDisabledSig: WritableSignal<string> = signal('true');

  @Input() appearance: DlcFormFieldAppearance = 'outline';

  color = input(undefined, {
    transform: coerceThemePalette
  });

  @Input()
  set disabled(value: boolean) {
    this.focusBtnsDisabledSig.set(`${value}`);
    if (value) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  /**
   * the blur event on the input is triggered before any
   * other event and causes a race condition,
   * so we need to listen to the mousedown on the document.
   */
  // blur$ = fromEvent(this.document, 'mousedown').pipe(
  //   takeUntil(this._onDestroy$)
  // );

  constructor(@Inject(DOCUMENT) private doc: Document) {}

  ngOnInit() {
    if (this.formControl) {
      this.formControl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(value => {
        this.valueChanges.next(value);
        this._formControlValue.set(value);

        this.focusBtnsDisabledSig.set(`${value === this._value()}`);
      });

      if (this._value() && this.formControl.value !== this._value()) {
        this.formControl.setValue(this._value());
        this.formControl.markAsPristine();
      }

      // this.formControl.statusChanges
      //   .pipe(
      //     takeUntil(this._onDestroy$)
      //   )
      //   .subscribe((status: FormControlStatus) => {
      //     console.log('status', status);
      //   })
    }

    // this.blur$.subscribe(() => {
    //   console.log('blur$');
    // });
  }

  // timer: any;

  addDocumentEventListener() {
    const that = this;
    // clearTimeout(this.timer);
    // this.doc.addEventListener(
    //   'mousedown',
    //   function documentEventHandler() {
    //     console.log('mousedown');
    //     that.inputFocusCss.set(false);
    //   },
    //   {once: true}
    // );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  onInputFocus(event: FocusEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.inputFocusCss.set(true);
    this.addDocumentEventListener();
  }

  onEdit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.inputFocusCss.set(true);

    this.input.nativeElement.focus();
  }

  onDone(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.valueChange.emit(this.formControl.value);
    this._value.set(this.formControl.value);
    // this.formControl.markAsPristine();

    this.inputFocusCss.set(false);
  }

  onEnter(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.valueChange.emit(this.formControl.value);
    this._value.set(this.formControl.value);
    this.addDocumentEventListener();
  }

  onBlur(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.inputFocusCss.set(false);
    }, 100);
  }

  onUndo(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.formControl.setValue(this._value());

    this.inputFocusCss.set(false);
  }
}
