import {Injectable} from '@angular/core';
import {
  Exists,
  NgPatFirestoreCollectionQuery,
  ngPatFirestoreCollectionQueryFactory,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {
  aggregateUpdates,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector
} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {Project} from '../+project/project.model';
import {deleteQuizs, updateQuizs, upsertQuizs} from '../+quizzes/quiz.actions';
import {Quiz} from '../+quizzes/quiz.model';
import {
  firestoreQueryPathByProject,
  firestoreQuizCollection,
  firestoreStudyGroupCollection,
  firestoreUserStudyGroupCollection
} from '../firebase/database-paths';
import {QueryEngineCache} from '../services/query-engine-cache';
import {
  deleteStudyGroups,
  updateStudyGroups,
  upsertStudyGroups
} from './study-group.actions';
import {StudyGroup} from './study-group.model';
import {studyGroupFeatureKey} from './study-group.reducer';
import {selectAllStudyGroups} from './study-group.selectors';

@Injectable({
  providedIn: 'root'
})
export class StudyGroupService implements NgPatFirebaseConnectionService {
  private _queryCollaborationService!: NgPatFirestoreCollectionQuery<StudyGroup>;
  private _queryPrivateQuizService!: NgPatFirestoreCollectionQuery<StudyGroup>;
  private _studyGroupQuizQueryCache!: QueryEngineCache<Quiz>;

  connectionKey = studyGroupFeatureKey;
  connection: NgPatServiceConnector = new NgPatServiceConnector(
    this,
    this.store
  );

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  ngPatOnInit() {
    this._queryCollaborationService =
      new NgPatFirestoreCollectionQuery<StudyGroup>(
        {
          queryMember: true,
          upsertManyAction: (studyGroups: StudyGroup[]) =>
            upsertStudyGroups({studyGroups}),
          updateManyAction: (studyGroups: StudyGroup[]) =>
            updateStudyGroups({studyGroups: aggregateUpdates(studyGroups)}),
          deleteManyAction: (ids: string[]) => deleteStudyGroups({ids})
        },
        this.store,
        this.customFirestoreService
      );

    // TODO Not needed
    const queryStudyGroupConfig = ngPatFirestoreCollectionQueryFactory(
      {
        queryMember: false,
        upsertManyAction: (studyGroups: StudyGroup[]) =>
          upsertStudyGroups({studyGroups}),
        updateManyAction: (studyGroups: StudyGroup[]) =>
          updateStudyGroups({studyGroups: aggregateUpdates(studyGroups)}),
        deleteManyAction: (ids: string[]) => deleteStudyGroups({ids})
      },
      this.store,
      this.customFirestoreService
    );

    this._queryPrivateQuizService =
      queryStudyGroupConfig.createFirestoreCollectionQuery();

    const queryQuizConfig = ngPatFirestoreCollectionQueryFactory(
      {
        queryMember: false,
        upsertManyAction: (quizs: Quiz[]) => upsertQuizs({quizs}),
        updateManyAction: (quizs: Quiz[]) =>
          updateQuizs({quizs: aggregateUpdates(quizs)}),
        deleteManyAction: (ids: string[]) => deleteQuizs({ids})
      },
      this.store,
      this.customFirestoreService
    );

    const quizPathGenerator = (project: Quiz, uid: string) =>
      `${firestoreQueryPathByProject(
        project as Project,
        uid
      )}/${firestoreQuizCollection()}`;

    this._studyGroupQuizQueryCache = new QueryEngineCache<Quiz>(
      queryQuizConfig,
      this.store,
      selectAllStudyGroups,
      quizPathGenerator,
      'id'
    );
  }

  deleteDoc$(classroom: Project | undefined, uid: string) {
    if (classroom) {
      const path: string = classroom.isPrivate
        ? firestoreUserStudyGroupCollection(uid)
        : firestoreStudyGroupCollection();

      // const path = studyGroup.isPrivate ?
      return this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(classroom: Project | undefined, ids: string[], uid: string) {
    if (classroom) {
      const isPrivate = classroom.isPrivate;
      const path: string = isPrivate
        ? firestoreUserStudyGroupCollection(uid)
        : firestoreStudyGroupCollection();
      return this.customFirestoreService.deleteDocs$(path, ids);
    }

    return of(true);
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._queryCollaborationService.onConnect(
      firestoreStudyGroupCollection(),
      null,
      <string>user.uid
    );
    this._queryPrivateQuizService.onConnect(
      firestoreUserStudyGroupCollection(<string>user.uid),
      null,
      null
    );
    this._studyGroupQuizQueryCache.onConnect(user);
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._queryCollaborationService.onDisconnect();
    this._queryPrivateQuizService.onDisconnect();
    this._studyGroupQuizQueryCache.onDisconnect();

    // Unsubscribe to query before calling this
  }

  updateDoc(g: StudyGroup) {
    return this.customFirestoreService.user$.pipe(
      take(1),
      switchMap((u: User) => {
        const path = firestoreQueryPathByProject(g, <string>u.uid);
        return this.customFirestoreService.merge$(path, g);
      })
    );
  }

  updatePartialFirestore$(
    changes: Partial<StudyGroup>,
    quiz: StudyGroup,
    uid: string | null
  ): Observable<StudyGroup> {
    if (uid) {
      return this.customFirestoreService
        .merge$<StudyGroup>(firestoreQueryPathByProject(quiz, uid), changes)
        .pipe(map((r: Exists<StudyGroup>) => r.data));
    }

    return of(quiz);
  }
}
