import {
  Directive,
  DoCheck,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';

export interface AutoResizeElementOptions {
  extraHeight?: number;
}

export class AutoResizeElement {
  extraHeight = 10;
  nativeElement: HTMLTextAreaElement | undefined;
  constructor(options?: AutoResizeElementOptions) {
    this.extraHeight = options?.extraHeight ?? this.extraHeight;
  }

  setElement(element: ElementRef<HTMLTextAreaElement> | undefined) {
    if (element) {
      this.nativeElement = element.nativeElement;
      // get initial height of element
      this.autoResize();
    }
  }

  extraResizeHeight(extraHeight: number) {
    this.extraHeight = extraHeight;
  }

  setNativeElement(nativeElement: HTMLTextAreaElement | undefined) {
    this.nativeElement = nativeElement;
    // get initial height of element
    this.autoResize();
  }

  setOptions(options: AutoResizeElementOptions) {
    this.extraHeight = options?.extraHeight ?? this.extraHeight;
  }

  autoResize() {
    if (this.nativeElement) {
      this.nativeElement.style.height = `0px`;
      this.nativeElement.style.height = `${
        this.nativeElement.scrollHeight + this.extraHeight
      }px`;
    }
  }
}

@Directive({
  standalone: true,
  selector: '[dlcAutoResizeTextArea]'
})
export class DlcAutoResizeTextAreaDirective
  implements DoCheck, OnInit, OnDestroy
{
  private autoResizeElement = new AutoResizeElement();

  private _previousNativeValue: any;

  @Input() extraResizeHeight = 0;

  isFocusedSignal: WritableSignal<boolean> = signal(false);

  constructor(private el: ElementRef) {
    this.autoResizeElement.setElement(this.el);
    this.el.nativeElement.addEventListener(
      'focus',
      this.onFocusHandler.bind(this)
    );
    this.el.nativeElement.addEventListener(
      'blur',
      this.onBlurHandler.bind(this)
    );
  }

  ngOnInit() {
    if (this.extraResizeHeight) {
      this.autoResizeElement.extraResizeHeight(this.extraResizeHeight);
    }
  }

  onFocusHandler() {
    this.isFocusedSignal.set(true);
  }

  onBlurHandler() {
    this.isFocusedSignal.set(false);
  }

  ngDoCheck(): void {
    // manual dirty check

    const newValue = this.el.nativeElement.value;

    if (this._previousNativeValue !== newValue) {
      this._previousNativeValue = newValue;
      this.autoResizeElement.autoResize();
    }
  }

  ngOnDestroy() {
    this.el.nativeElement.removeEventListener('focus', this.onFocusHandler);
    this.el.nativeElement.removeEventListener('blur', this.onBlurHandler);
  }
}
