<div>
  <div class="mx-auto max-w-7xl px-6 lg:px-8" *ngIf="hasActiveSubscription()">
    <dlc-stripe-subscriptions [showUpdateBtn]="false"></dlc-stripe-subscriptions>
  </div>
  <mat-divider class="!mt-5" *ngIf="hasActiveSubscription()"></mat-divider>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <dlc-pricing-header
      *ngIf="showHeader()"
      [headerTitle]="headerTitle()"
      [headerSubtitle]="headerSubtitle()"></dlc-pricing-header>
    <div
      class="mt-16 flex justify-center"
      *ngIf="showIntervalToggle() && !showBothPricesAtSameTime()">
      <dlc-text-toggle
        (selectedChange)="onIntervalChange($event)"
        [items]="priceIntervals"
        [selected]="selectedPriceInterval()"></dlc-text-toggle>
    </div>

    <div
      class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 justify-items-center"
      [ngClass]="pricesColumnsClassSig()">
      @for (price of pricesSig(); track price) {
        <dlc-feature-list
          *ngIf="showBothPricesAtSameTime()"
          [price]="price"
          [showFeatureDescription]="showFeatureDescription()"
          (showCheckoutProgressBar)="showCheckoutProgressBar.emit($event)"
          (doLogin)="doLogin.emit($event)"></dlc-feature-list>

        <dlc-price-toggle
          *ngIf="!showBothPricesAtSameTime()"
          [selectedInterval]="selectedPriceIntervalEnum()"
          [price]="price"
          (showCheckoutProgressBar)="showCheckoutProgressBar.emit($event)"
          (doLogin)="doLogin.emit($event)"></dlc-price-toggle>
      }
    </div>
  </div>
</div>
