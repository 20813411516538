<div *ngIf="hasMultipleAnswers()" class="answer__container-message">
  Choose multiple answers.
</div>
<form [formGroup]="questionForm">
  <div
    class="answer__container-checkbox"
    formArrayName="formArray"
    *ngFor="let answerFormGroup of answerChoices; index as i">
    <button ui-quiz-answer-button [index]="i" (click)="toggleCheckbox(i)">
      {{ getAnswerText(i) }}
    </button>
    <form
      class="answer__container-checkbox-form"
      [formGroup]="answerFormGroup">
      <mat-checkbox formControlName="checked"></mat-checkbox>
    </form>
  </div>
</form>
<div class="answer__container-submit-btn">
  <button mat-flat-button color="primary" (click)="submitAnswer()">
    Submit
  </button>
</div>
