import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {JoinGroupWithCodeDialogComponent} from './join-group-with-code-dialog/join-group-with-code-dialog.component';
import {
  CollaborativeProject,
  DEFAULT_PLATFORM_PROJECT_TYPE_DICT
} from '@gigasoftware/evolving-cognition/domain';
import {JoinDialogData} from './join-group-with-code-dialog/join-group-with-code-dialog.model';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'ui-join-group-with-code',
  templateUrl: './join-group-with-code.component.html',
  styleUrls: ['./join-group-with-code.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class JoinGroupWithCodeComponent {
  @Input() title = 'Group';
  @Input() projectType = DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id;
  @Output() onJoinGroup: EventEmitter<CollaborativeProject> =
    new EventEmitter<CollaborativeProject>();

  constructor(private _dialog: MatDialog) {}

  openDialog() {
    const ref = this._dialog.open(JoinGroupWithCodeDialogComponent, {
      backdropClass: 'dlc-global-dialog-background',
      data: <JoinDialogData>{
        title: this.title,
        projectTypeID: this.projectType
      }
    });

    ref.afterClosed().subscribe((p: CollaborativeProject) => {
      if (p) {
        this.onJoinGroup.emit(p);
      }
    });
  }
}
