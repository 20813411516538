import {FileType} from './file-type';
import {conertBase64ToArrayBuffer} from './convert-to-arraybuffer';

export function convertArrayBufferToBlob(arraybuffer: ArrayBuffer, fileType: FileType): Blob {
  return new Blob([new Uint8Array(arraybuffer)], {type: fileType});
}

/**
 * Convert BASE64 to BLOB
 * @param base64String Pass Base64 image data to convert into the BLOB
 * @param format Pass image format
 */
export function convertBase64ToBlob(base64String: string, format: string) {
  return new Blob([new Uint8Array(conertBase64ToArrayBuffer(base64String))], {
    type: `image/${format}`
  });
}
