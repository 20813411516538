import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {MembersService} from './members.service';
import {PartialMembersState} from './members.reducer';

@Injectable({
  providedIn: 'root'
})
export class MembersEffects {
  // deleteDoc$ = createEffect(
  //   () =>
  //     this._actions$.pipe(
  //       ofType(deleteMembersFromfirestore),
  //       withLatestFrom(this.store.select(selectUserAccount)),
  //       switchMap(([action, account]) =>
  //         this.store.pipe(
  //           select(getMemberByID(action.id)),
  //           switchMap((membersList: Member | undefined) =>
  //             this._membersService.deleteDoc$(membersList, <string>account.uid)
  //             .pipe(map(() => deleteMember({id: action.id}) ))
  //         )
  //       )
  //     )
  // );

  // deleteDocs$ = createEffect(
  //   () =>
  //     this._actions$.pipe(
  //       ofType(deleteMemberss),
  //       withLatestFrom(this.store.select(selectUserAccount)),
  //       switchMap(([action, account]) =>
  //         this.store.pipe(
  //           select(getMemberByID(action.ids[0])),
  //           switchMap((membersList: Member | undefined) => this._membersService.deleteDocs$(membersList, action.ids, <string>account.uid))
  //         )
  //       )
  //     ),
  //   {dispatch: false}
  // );

  // updateFirestorePartialMembers$ = createEffect(
  //     () =>
  //       this._actions$.pipe(
  //         ofType(updateFirestorePartialMembers),
  //         withLatestFrom(this.store.select(selectUserAccount)),
  //         switchMap(([action, account]) =>
  //           this._membersService.updatePartialFirestore(action.changes, action.membersList, account.uid)
  //         )
  //       ),
  //     {dispatch: false}
  //   );

  constructor(
    private _actions$: Actions,
    private store: Store<PartialMembersState>,
    private _membersService: MembersService
  ) {}
}
