<mat-form-field>
  <mat-label *ngIf="label && label.length > 0">{{ label }}</mat-label>
  <input
    matInput
    #input
    type="text"
    [placeholder]="placeholder"
    [size]="formControl.value ? formControl.value.length + 1 : 1"
    (keydown.enter)="onEnter($event)"
    [formControl]="formControl"
    (blur)="onBlur($event)"
    (focus)="onInputFocus($event)" />
  <div matIconSuffix class="dlc-mat-inline-edit__icon-container">
    <div
      class="dlc-mat-inline-edit__icon-container--set"
      @displayNoneInlineFlexAnimation
      *ngIf="!inputFocusCss()">
      <button dlc-square-icon-button class="dlc-mat-inline-edit-icon-btn" (click)="onEdit($event)">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

    <div
      class="dlc-mat-inline-edit__icon-container--set"
      @displayNoneInlineFlexAnimation
      *ngIf="inputFocusCss()">
      <button
        dlc-square-icon-button
        (click)="onDone($event)"
        [disabled]="focusBtnsDisabledSig() === 'true'"
        class="dlc-mat-inline-edit__icon-container--set-btn">
        <mat-icon>done</mat-icon>
      </button>

      <button
        dlc-square-icon-button
        (click)="onUndo($event)"
        [disabled]="focusBtnsDisabledSig() === 'true'"
        class="dlc-mat-inline-edit__icon-container--set-btn">
        <mat-icon>undo</mat-icon>
      </button>
    </div>
  </div>
</mat-form-field>
