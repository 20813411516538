import {selectNgPatAddedAndDeletedEntities} from '@ngpat/store';
import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {convertCollaborativeProjectToMemberUIDProjectID} from '../+members/member.fns';
import {CollaborativeProject, DEFAULT_PLATFORM_PROJECT_TYPE_DICT, Project} from '../+project/project.model';
import {getCreatedAtNanoseconds} from '../fns/get-created-at-nanoseconds-prop';
import {getQuizByParentProjectIdOrAllQuizzes} from './quiz.fns';
import {Quiz} from './quiz.model';
import {quizAdapter, quizFeatureKey, QuizState} from './quiz.reducer';

export const selectQuizState = createFeatureSelector<QuizState>(quizFeatureKey);

const {selectIds, selectEntities, selectAll, selectTotal} =
  quizAdapter.getSelectors();

// export const selectAllQuizzes = createSelector(selectQuizState, selectAll);
export const selectQuizEntities = createSelector(
  selectQuizState,
  selectEntities
);
export const selectQuizIds = createSelector(selectQuizState, selectIds);
// export const selectQuizTotal = createSelector(selectQuizState, selectTotal);

export const selectAllQuizzes = createSelector(selectQuizState, selectAll);
export const selectQuizTotal = createSelector(selectQuizState, selectTotal);

export const selectQuizMemberMap = createSelector(
  selectAllQuizzes,
  (quizzes: Quiz[]) => {
    return convertCollaborativeProjectToMemberUIDProjectID(quizzes as CollaborativeProject[]);
  }
);

export const getQuizByID = (id: string | null | undefined) =>
  createSelector(selectQuizEntities, (entities: Dictionary<Quiz>) => {
    if (id) {
      return entities[<string>id];
    }
    return undefined;
  });

export const getQuizzesByParentID = (parentID: string) =>
  createSelector(selectAllQuizzes, (quizs: Quiz[]): Quiz[] => {
    return quizs.filter(
      (q: Quiz | undefined) => q !== undefined && q.parentProjectID === parentID
    );
  });

export const getQuizAssignedByParentID = (parentID: string) =>
  createSelector(selectAllQuizzes, (quizs: Quiz[]): Quiz[] => {
    return quizs
      .filter(
        (q: Quiz | undefined) =>
          q !== undefined && q.parentProjectID === parentID
      )
      .filter((q: Quiz) => q.assigned);
  });

export const getQuizAssignedByParentIDAndQuizId = (
  parentID: string,
  quizId: string
) =>
  createSelector(selectAllQuizzes, (quizs: Quiz[]): Quiz | undefined => {
    return quizs
      .filter(
        (q: Quiz | undefined) =>
          q !== undefined && q.parentProjectID === parentID
      )
      .filter((q: Quiz) => q.assigned)
      .filter((q: Quiz) => q.id === quizId)[0];
  });

export const getQuizByTimestamp = (nanoseconds: string | null | undefined) =>
  createSelector(selectAllQuizzes, (quiz: Quiz[]) => {
    return quiz.reduce(
      (found: Quiz | null | undefined, i: Quiz | undefined) => {
        if (!found && i && getCreatedAtNanoseconds(i as Project) === nanoseconds) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const getQuizById = (id: string | null | undefined) =>
  createSelector(selectAllQuizzes, (quiz: Quiz[]) => {
    return quiz.reduce(
      (found: Quiz | null | undefined, i: Quiz | undefined) => {
        if (!found && i && i.id === id) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const selectAllPersonalQuizzes = createSelector(
  selectAllQuizzes,
  (quizzes: Quiz[]) => quizzes.filter((q: Quiz) => !q.isCollaborative)
);

export const selectAllPersonalQuizzesIds = createSelector(
  selectAllPersonalQuizzes,
  (quizzes: Quiz[]) => quizzes.map((q: Quiz) => q.id)
);

export const getQuizByParentIdOrAllQuizzes = (
  parentID: string | null | undefined
) =>
  createSelector(
    selectAllQuizzes,
    selectAllPersonalQuizzes,
    (quizs: Quiz[], personalQuizzes: Quiz[]): Quiz[] => {
      return getQuizByParentProjectIdOrAllQuizzes(
        quizs,
        personalQuizzes,
        parentID
      );
    }
  );

export const selectAllStudyGroupQuizzes = createSelector(
  selectAllQuizzes,
  (quizs: Quiz[]): Quiz[] => {
    return quizs.filter(
      (q: Quiz) =>
        q.parentProjectType ===
        DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id
    );
  }
);

export const selectAllClassroomQuizzes = createSelector(
  selectAllQuizzes,
  (quizs: Quiz[]): Quiz[] => {
    return quizs.filter(
      (q: Quiz) =>
        q.parentProjectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id
    );
  }
);

export const selectAddedAndDeletedQuizzes =
  selectNgPatAddedAndDeletedEntities<Quiz>(selectQuizEntities);
