<h3 mat-dialog-title>Confirm Delete {{ data.name }}</h3>
<mat-dialog-content>
  <p *ngIf="data?.message" class="project-name font-medium">
    {{ data.message }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button dlc-rounded-text-icon-button (click)="dialogRef.close(false)">
    <mat-icon>close</mat-icon>
    Cancel
  </button>
  <button
    class="ml-4"
    dlc-rounded-text-icon-button
    color="warn"
    (click)="dialogRef.close(true)">
    <mat-icon>delete</mat-icon>
    Delete
  </button>
</mat-dialog-actions>
