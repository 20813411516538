import {initialUiState, uiFeatureKey} from './+ui/ui.model';
import * as fromUi from './+ui/ui.reducer';
import {UiEffects} from './+ui/ui.effects';
import * as fromCourses from './+courses/course.reducer';
import {CourseEffects} from './+courses/course.effects';
import {ProjectEffects} from './+project/project.effects';
import * as fromStudyGroups from './+study-groups/study-group.reducer';
import {StudyGroupEffects} from './+study-groups/study-group.effects';
import * as fromClassroom from './+classrooms/classroom.reducer';
import {ClassroomEffects} from './+classrooms/classroom.effects';
import * as fromResearch from './+research/research.reducer';
import {ResearchEffects} from './+research/research.effects';
import * as fromInvestigation from './+investigation/investigation.reducer';
import {InvestigationEffects} from './+investigation/investigation.effects';
import * as fromMemberState from './+members/members.reducer';
import {MembersEffects} from './+members/members.effects';
import {MembersService} from './+members/members.service';
import {
  NG_PAT_FIREBASE_ROOT_EFFECTS,
  NG_PAT_FIREBASE_ROOT_PROVIDERS,
  NG_PAT_FIREBASE_ROOT_REDUCERS,
  NG_PAT_FIREBASE_ROOT_STATE_INITIALIZERS,
  NG_PAT_WEB_EFFECTS,
  NG_PAT_WEB_INITIALIZERS,
  NG_PAT_WEB_REDUCERS,
  NG_PAT_WEB_SERVICES
} from '@ngpat/store';

import * as fromQuizzes from './+quizzes/quiz.reducer';
import {QuizEffects} from './+quizzes/quiz.effects';
import {QuizEngineEffect} from './+quizzes/quiz-engine/quiz-engine-effect';

export const SHARED_NGRX_REDUCERS = {
  ...NG_PAT_FIREBASE_ROOT_REDUCERS,
  // [accountFeatureKey]: fromAccountState.reducer,
  [fromClassroom.classroomFeatureKey]: fromClassroom.reducer,
  [fromCourses.courseFeatureKey]: fromCourses.reducer,
  [fromInvestigation.investigationFeatureKey]: fromInvestigation.reducer,
  [fromMemberState.membersFeatureKey]: fromMemberState.reducer,
  [fromResearch.researchFeatureKey]: fromResearch.reducer,
  [fromStudyGroups.studyGroupFeatureKey]: fromStudyGroups.reducer,
  [uiFeatureKey]: fromUi.reducer,
  // [websocketRegistryFeatureKey]: fromWebsockRegistory.reducer,
  // [fromRemoteConfig.remoteConfigFeatureKey]: fromRemoteConfig.reducer,

  // ...appStoreReducers,

  [fromQuizzes.quizFeatureKey]: fromQuizzes.reducer,
  // Stripe
  ...NG_PAT_WEB_REDUCERS
};

export const SHARED_NGRX_INITIALIZERS = {
  ...NG_PAT_FIREBASE_ROOT_STATE_INITIALIZERS,
  // [accountFeatureKey]: initialAccountState,
  [uiFeatureKey]: initialUiState,
  [fromClassroom.classroomFeatureKey]: fromClassroom.initialClassroomState,
  [fromCourses.courseFeatureKey]: fromCourses.initialCourseState,
  [fromInvestigation.investigationFeatureKey]: fromInvestigation.initialInvestigationState,
  [fromMemberState.membersFeatureKey]: fromMemberState.initialMembersState,
  [fromResearch.researchFeatureKey]: fromResearch.initialResearchState,
  [fromStudyGroups.studyGroupFeatureKey]: fromStudyGroups.initialStudyGroupState,

  // [websocketRegistryFeatureKey]: initialWebsocketRegistryState,
  // [fromRemoteConfig.remoteConfigFeatureKey]:
  //   fromRemoteConfig.initialRemoteConfigState,

  // ...appStoreInitialStates,

  [fromQuizzes.quizFeatureKey]: fromQuizzes.initialQuizState,
  // Stripe
  ...NG_PAT_WEB_INITIALIZERS
};

export const SHARED_NGRX_EFFECTS = [
  ...NG_PAT_FIREBASE_ROOT_EFFECTS,
  QuizEngineEffect,
  // AccountEffects,
  ClassroomEffects,
  ProjectEffects,
  InvestigationEffects,
  CourseEffects,
  ResearchEffects,
  StudyGroupEffects,
  UiEffects,
  MembersEffects,

  // RemoteConfigEffects,

  QuizEffects
];

export const SHARED_DATA_ACCESS_PROVIDERS = [MembersService, ...NG_PAT_FIREBASE_ROOT_PROVIDERS];
// @NgModule({
//   imports: [
//     CommonModule,
//     MatDialogModule,
//     MatSnackBarModule,
//
//     StoreModule.forRoot(
//       {
//         ...NG_PAT_FIREBASE_ROOT_REDUCERS,
//         // [accountFeatureKey]: fromAccountState.reducer,
//         [fromProjects.projectsFeatureKey]: fromProjects.reducer,
//         [fromClassroom.classroomFeatureKey]: fromClassroom.reducer,
//         [fromCourses.courseFeatureKey]: fromCourses.reducer,
//         [fromInvestigation.investigationFeatureKey]: fromInvestigation.reducer,
//         [fromMemberState.membersFeatureKey]: fromMemberState.reducer,
//         [fromResearch.researchFeatureKey]: fromResearch.reducer,
//         [fromNotification.notificationFeatureKey]: fromNotification.reducer,
//         [fromStudyGroups.studyGroupFeatureKey]: fromStudyGroups.reducer,
//         [uiFeatureKey]: fromUi.reducer,
//         // [websocketRegistryFeatureKey]: fromWebsockRegistory.reducer,
//         // [fromRemoteConfig.remoteConfigFeatureKey]: fromRemoteConfig.reducer,
//
//         ...appStoreReducers,
//
//         ...quizReducers,
//         // Stripe
//         ...stripeReducers
//       },
//       {
//         initialState: {
//           ...NG_PAT_FIREBASE_ROOT_STATE_INITIALIZERS,
//           // [accountFeatureKey]: initialAccountState,
//           [uiFeatureKey]: initialUiState,
//           [fromProjects.projectsFeatureKey]: fromProjects.initialProjectState,
//           [fromClassroom.classroomFeatureKey]:
//             fromClassroom.initialClassroomState,
//           [fromCourses.courseFeatureKey]: fromCourses.initialCourseState,
//           [fromInvestigation.investigationFeatureKey]:
//             fromInvestigation.initialInvestigationState,
//           [fromMemberState.membersFeatureKey]:
//             fromMemberState.initialMembersState,
//           [fromResearch.researchFeatureKey]: fromResearch.initialResearchState,
//           [fromStudyGroups.studyGroupFeatureKey]:
//             fromStudyGroups.initialStudyGroupState,
//           [fromNotification.notificationFeatureKey]:
//             fromNotification.initialNotificationState,
//           // [websocketRegistryFeatureKey]: initialWebsocketRegistryState,
//           // [fromRemoteConfig.remoteConfigFeatureKey]:
//           //   fromRemoteConfig.initialRemoteConfigState,
//
//           ...appStoreInitialStates,
//
//           ...quizInitialState,
//           // Stripe
//           ...stripeInitialStates
//         },
//         runtimeChecks: {
//           strictStateImmutability: true,
//           strictActionImmutability: true,
//           strictStateSerializability: true,
//           strictActionSerializability: true,
//           strictActionWithinNgZone: true,
//           strictActionTypeUniqueness: true
//         }
//       }
//     ),
//     EffectsModule.forRoot([
//       ...NG_PAT_FIREBASE_ROOT_EFFECTS,
//       // AccountEffects,
//       ClassroomEffects,
//       ProjectEffects,
//       InvestigationEffects,
//       CourseEffects,
//       ResearchEffects,
//       StudyGroupEffects,
//       UiEffects,
//       MembersEffects,
//       NotificationEffects,
//       // RemoteConfigEffects,
//
//       ...appStoresEffects,
//
//       ...quizEffects,
//       // Stripe
//       ...stripeEffects
//     ])
//   ],
//   providers: [
//     MembersService,
//     NotificationService,
//     EcInAppPurchaseService,
//     ...stripeServices
//   ]
// })
// export class SharedDataAccessModule {}
