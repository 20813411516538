import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  ReactiveFormsModule
} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {distinctUntilChanged, take, takeUntil} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  ngPatAccountSaveFirebase,
  NgPatPartialAccountStates,
  selectNgPatAccountUsername
} from '@ngpat/store';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'ui-username',
  templateUrl: './ui-username.component.html',
  styleUrls: ['./ui-username.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgIf,
    MatButtonModule,
    MatIconModule
  ]
})
export class UiUsernameComponent implements OnDestroy, OnInit {
  private _onDestroy$: Subject<boolean> = new Subject();

  usernameForm: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(3)
  ]);

  constructor(
    private store: Store<NgPatPartialAccountStates>,
    private _snackBar: MatSnackBar,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store
      .pipe(
        select(selectNgPatAccountUsername),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((username: string | null) => {
        this.usernameForm.reset(username, {emitEvent: false});
        this._cd.detectChanges();
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  getErrorMessage() {
    if (this.usernameForm.hasError('required')) {
      return 'You must enter a value';
    }

    if (this.usernameForm.hasError('minlength')) {
      return 'Minimum of 3 characters required';
    }

    return '';
  }

  reset(): void {
    this.store
      .pipe(select(selectNgPatAccountUsername), take(1))
      .subscribe((username: string | null) => {
        this.usernameForm.reset(username, {emitEvent: false});
      });
  }

  apply() {
    if (this.usernameForm.valid) {
      this.store.dispatch(
        ngPatAccountSaveFirebase({
          payload: {
            username: this.usernameForm.value
          }
        })
      );

      this._snackBar.open('User Name Updated!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    }
  }
}
