// @ts-nocheck
import arrayReduceRight from './_arrayReduceRight';
import baseEachRight from './_baseEachRight';
import baseIteratee from './_baseIteratee';
import baseReduce from './_baseReduce';
import isArray from './isArray';

/**
 * This method is like `_.reduce` except that it iterates over elements of
 * `collection` from right to left.
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Collection
 * @param {Array|Object} collection The collection to iterate over.
 * @param {Function} [iteratee=_.identity] The function invoked per iteration.
 * @param {*} [accumulator] The initial value.
 * @returns {*} Returns the accumulated value.
 * @see _.reduce
 * @example
 *
 * var array = [[0, 1], [2, 3], [4, 5]];
 *
 * _.reduceRight(array, function(flattened, other) {
 *   return flattened.concat(other);
 * }, []);
 * // => [4, 5, 2, 3, 0, 1]
 */
function reduceRight(collection, iteratee, accumulator) {
  var func = isArray(collection) ? arrayReduceRight : baseReduce,
    initAccum = arguments.length < 3;

  return func(
    collection,
    baseIteratee(iteratee, 4),
    accumulator,
    initAccum,
    baseEachRight
  );
}

export default reduceRight;
