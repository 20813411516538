import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgIf, DecimalPipe} from '@angular/common';
import {LetDirective} from '@ngrx/component';
import {
  NgPatStripeTrialParams,
  selectNgPatStripeTrialParams
} from '@ngpat/store';

@Component({
  selector: 'ui-trial-ends-text',
  templateUrl: './ui-trial-ends-text.component.html',
  styleUrls: ['./ui-trial-ends-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-trial-ends-text p-accent'
  },
  standalone: true,
  imports: [LetDirective, NgIf, DecimalPipe]
})
export class UiTrialEndsTextComponent {
  trialParams$: Observable<NgPatStripeTrialParams>;

  @Input() size: 'small' | null = null;

  constructor(private store: Store) {
    this.trialParams$ = this.store.pipe(
      select(selectNgPatStripeTrialParams),
      debounceTime(300)
    );
  }
}
