@for (grade of grades(); track grade.name) {
<div
  class="mb-5 bg-white/5 flex flex-col justify-start items-stretch gap-x-6 p-5 rounded">
  <div class="flex flex-row justify-between text-sm leading-6 text-white">
    <div class="font-semibold">
      {{ grade.timestamp | date : "mediumDate" }}
    </div>
    <div class="font-normal">{{ grade.timestamp | date : "mediumTime" }}</div>
  </div>
  <div class="flex flex-row justify-start mt-2">
    <div class="ec-chip-wrong-answers-count">
      <span class="ec-wrong-answers__bold">{{ grade.totalWrong }}</span
      >&nbsp;
      <span class="ec-wrong-answers__light">Wrong</span>
    </div>
    <div class="ec-chip-correct-answers-count">
      <span class="ec-correct-answers__bold">{{ grade.totalCorrect }}</span
      >&nbsp;
      <span class="ec-correct-answers__light">Correct</span>
    </div>
  </div>
  <div class="mt-5 overflow-hidden flex flex-auto">
    <ng-pat-bullet-chart [config]="bulletChartConfig" [data]="grade.chartData">
      <div ngPatChartsTooltipContent class="w-full text-center">
        {{ grade.chartData.progress }}%
      </div>
    </ng-pat-bullet-chart>
  </div>
  <div class="flex flex-row justify-start items-start pb-5 mt-2.5">
    <button dlc-rounded-text-icon-button color="warn" (click)="delete(grade)">
      <mat-icon>delete</mat-icon>
      Delete Result
    </button>

    <button
      dlc-rounded-text-icon-button
      color="primary"
      class="ml-4"
      (click)="reviewAction.emit(grade.rawData)">
      <mat-icon>read_more</mat-icon>
      Review Answers
    </button>
  </div>
</div>
} @empty {
<div class="font-semibold text-center">
  This quiz has not been attempted yet.
</div>
}
